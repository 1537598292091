define("E4E/components/dialogs/organizations/upload-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeUploadVideoDialog: function () {
        this.closeUploadVideoDialog();
      },
      uploadVideo: function () {
        let video = {
          title: this.get('title'),
          description: this.get('description'),
          file: this.get('fileVideo')
        };
        this.uploadVideo(video);
      }
    }
  });

  _exports.default = _default;
});