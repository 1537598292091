define("E4E/controllers/companies/profile", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    organizationService: Ember.inject.service('organization'),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    activeJobOffers: Ember.computed('model.organization.jobOffers.@each', function () {
      if (this.get('model.organization') != null) {
        return this.get('model.organization.jobOffers').filter(function (jobOffer) {
          return new Date(jobOffer.get('deadline')) >= new Date() || jobOffer.get('deadline') == null;
        });
      } else {
        return [];
      }
    }),
    init: function () {
      this._super(...arguments);

      this.set('model', {
        organization: null
      });
    },
    findOrganization: (0, _emberConcurrency.task)(function* () {
      let organization = yield this.organizationService.load(this.get('model.id'), this.get('model.organizationParams'));
      this.set('model.organization', organization);
    }).enqueue(),
    currentUserService: Ember.inject.service('current-user'),
    actions: {
      findOrganization: function () {
        this.get('findOrganization').perform();
      },
      goToJobOffer: function (jobOffer) {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferId', jobOffer.id);
        cookieService.write('jobOfferTitle', jobOffer.title);
        this.get('router').transitionTo("job-seekers.job-offers");
      }
    }
  });

  _exports.default = _default;
});