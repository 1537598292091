define("E4E/components/users/workexperience-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAttachments: false,
    currentUserService: Ember.inject.service('current-user'),
    actions: {
      editWorkExperience: function (workExperience, index) {
        this.editWorkExperience(workExperience, index);
      },
      cancelDeleteWorkExperience: function () {
        this.set('deleteWorkExperienceDialog', false);
      },
      openDeleteDialog: function () {
        this.set('deleteWorkExperienceDialog', true);
      },
      deleteWorkExperience: function (workExperience) {
        this.deleteWorkExperience(workExperience);
      },
      showAttachments: function () {
        this.set('showAttachments', true);
      }
    }
  });

  _exports.default = _default;
});