define("E4E/helpers/browser-name", ["exports", "ember-browser-checker/helpers/browser-name"], function (_exports, _browserName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "browserName", {
    enumerable: true,
    get: function () {
      return _browserName.browserName;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _browserName.default;
    }
  });
});