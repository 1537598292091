define("E4E/routes/recruiters/dashboard", ["exports", "ember-simple-auth/mixins/application-route-mixin", "E4E/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    currentUserService: Ember.inject.service('current-user'),
    page: 0,
    types: ['FULL_TIME', 'PART_TIME', 'FULL_TIME_PART_TIME', 'FREELANCER', 'NONE'],
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    beforeModel: function () {
      let jobOffersActive = this.store.query('joboffer', {
        filterCompanies: {
          title: '',
          organizationId: this.currentUserService.get('user.organizationId'),
          country: '',
          etype: this.get('types').toString(),
          city: '',
          workExperience: '',
          status: 'ACTIVE',
          yearsExperience: '',
          education: '',
          educationScope: '',
          educationNumber: '',
          educationDateIssue: '',
          languageName: '',
          languageScope: '',
          languageNumber: '',
          languageDateIssue: '',
          drivingLicenses: '',
          competences: '',
          page: this.get('page'),
          size: 7
        }
      });
      this.set('activeJobOffers', jobOffersActive);
      this.set('meta', jobOffersActive.meta);
    },
    model: function () {
      return Ember.RSVP.hash({
        activeJobOffers: this.get('activeJobOffers'),
        meta: this.get('meta'),
        pendingApplications: this.authorizedGet('/jobapplications/search/countStatus?organization=' + this.get('currentUserService.user.userOrganization.name') + '&status=PENDING')
      });
    }
  });

  _exports.default = _default;
});