define("E4E/services/report", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    filter: function (title, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/reportblogitems/search/filter?" + "title=" + title.toLowerCase() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          reportblogitem: null,
          meta: null
        };
        searchResponse.reportblogitem = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    }
  });

  _exports.default = _default;
});