define("E4E/components/jobapplications/evaluate-application", ["exports", "E4E/config/environment", "pell", "ember-concurrency"], function (_exports, _environment, _pell, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    step1: true,
    dialogService: Ember.inject.service('dialog-service'),
    fileService: Ember.inject.service('file-service'),
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    init: function () {
      this._super(...arguments);

      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    authorizedDelete: (0, _emberConcurrency.task)(function* (fileId) {
      let self = this;
      yield $.ajax({
        type: 'DELETE',
        url: _environment.default.APP.SPRING_HOST + "/files/" + fileId,
        headers: self.get('headers'),
        error: function (error) {
          return true;
        },
        success: function (resp) {
          return resp;
        }
      });
    }),
    actions: {
      didSelectFilesCertificate: function (question, files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          this.set('certificateDocument', files[0]);

          let done = function (url) {
            self.fileService.uploadCertificateQuestions.perform(files[0], question);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      cancelEvaluateApplication: function () {
        let self = this;
        this.get('jobOffer.questionBundlesTempEval').forEach(function (jobOfferQuestionBundle, index) {
          jobOfferQuestionBundle.get('questions').forEach(function (question, index) {
            if (question.get('questionType') === 'CERTIFICATE') {
              if (question.get('certificateResponse.file') != null) {
                self.get('store').findRecord('file', question.get('certificateResponse.file')).then(response => {
                  self.authorizedDelete.perform(question.get('certificateResponse.file'));
                  response.destroyRecord();
                });
                question.set('certificateResponse.file', null);
                question.set('certificateResponse.value', null);
              }
            }
          });
        });
        this.cancelEvaluateApplication();
      },
      evaluateApplication: function () {
        let questions = [],
            jsonTemp = {};
        this.get('jobOffer.questionBundlesTempEval').forEach(function (jobOfferQuestionBundle) {
          jobOfferQuestionBundle.get('questions').forEach(function (question) {
            let questionTemp = {},
                json = JSON.parse(question.get('json')),
                questionType = question.get('questionType');
            questionTemp.id = question.get('id');
            questionTemp.questionType = questionType;
            questionTemp.questionText = question.get('questionText');

            switch (questionType) {
              case "SINGLE_CHOICE":
                json.options.forEach(function (element) {
                  if (element.optionText === question.get('singleChoiceResponse')) {
                    element.selected = true;
                  } else {
                    element.selected = false;
                  }
                });
                questionTemp.json = JSON.stringify(json);
                questions.push(questionTemp);
                break;

              case "MULTIPLE_CHOICE":
                questionTemp.json = JSON.stringify(question.get('multipleChoiceJson'));
                questions.push(questionTemp);
                break;

              case "LIKERT_SCALE":
                questionTemp.json = JSON.stringify(question.get('likertScaleJson'));
                questions.push(questionTemp);
                break;

              case "OPEN_TEXT":
                json.answerText = question.get('openTextResponse');
                questionTemp.json = JSON.stringify(json);
                questions.push(questionTemp);
                break;

              case "NUMERIC":
                json.answerNumber = question.get('numericResponse');
                questionTemp.json = JSON.stringify(json);
                questions.push(questionTemp);
                break;

              case "DATE":
                json.answerDate = question.get('dateResponse');
                questionTemp.json = JSON.stringify(json);
                questions.push(questionTemp);
                break;

              case "CERTIFICATE":
                json.answerCertificate = question.get('certificateResponse');
                questionTemp.json = JSON.stringify(json);
                questions.push(questionTemp);
                break;
            }
          });
        });
        jsonTemp.questions = questions;
        let jobApplication = {
          jsonTemp: jsonTemp
        };
        this.evaluateApplication(jobApplication);
      }
    }
  });

  _exports.default = _default;
});