define("E4E/components/job-seekers/job-applications/results-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scrollContainer(event) {
      if (Math.ceil($("#search_container_job_appications").scrollTop() + $("#search_container_job_appications").outerHeight()) == $("#search_container_job_appications")[0].scrollHeight) {
        if (!this.get('meta.last')) {
          this.nextPage();
        }
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      $("#search_container_job_appications").on('scroll', this._boundScroll);
    },

    actions: {
      selectJobApplication: function (id) {
        this.selectJobApplication(id);
      }
    }
  });

  _exports.default = _default;
});