define("E4E/services/picture", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    filterPicturesJobOffer: function (jobOfferId, page, size) {
      return this.store.query('picture', {
        filterPicturesJobOffer: {
          jobOfferId: jobOfferId,
          page: page,
          size: size
        }
      });
    },
    filterPicturesAuthor: function (authorId, page, size) {
      return this.store.query('picture', {
        filterPicturesAuthor: {
          authorId: authorId,
          page: page,
          size: size
        }
      });
    },
    filterPicturesOrganization: function (organizationId, page, size) {
      return this.store.query('picture', {
        filterPicturesOrganization: {
          organizationId: organizationId,
          page: page,
          size: size
        }
      });
    }
  });

  _exports.default = _default;
});