define("E4E/helpers/check-doneinternships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkDoneinternships = checkDoneinternships;
  _exports.default = void 0;

  function checkDoneinternships(params
  /*, hash*/
  ) {
    if (params[0] != undefined) {
      return params[0].filter(item => item.status === true).length > 0;
    }
  }

  var _default = Ember.Helper.helper(checkDoneinternships);

  _exports.default = _default;
});