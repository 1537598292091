define("E4E/helpers/math-likertscaletwodimensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mathLikertscaletwodimensions = mathLikertscaletwodimensions;

  function mathLikertscaletwodimensions(params
  /*, hash*/
  ) {
    let indexV = params[0];
    let indexH = params[1];
    let lengthV = params[2];
    let lengthH = params[3];
    let maxValue = lengthV * lengthH;

    switch (indexV) {
      case 0:
        if (indexH === 0) {
          return maxValue;
        } else if (indexH === 1) {
          return maxValue - 1;
        } else if (indexH === 2) {
          return maxValue - 4;
        } else if (indexH === 3) {
          return maxValue - 5;
        }

      case 1:
        if (indexH === 0) {
          return maxValue - 2;
        } else if (indexH === 1) {
          return maxValue - 3;
        } else if (indexH === 2) {
          return maxValue - 6;
        } else if (indexH === 3) {
          return maxValue - 7;
        }

      case 2:
        if (indexH === 0) {
          return maxValue - 8;
        } else if (indexH === 1) {
          return maxValue - 9;
        } else if (indexH === 2) {
          return maxValue - 12;
        } else if (indexH === 3) {
          return maxValue - 13;
        }

      case 3:
        if (indexH === 0) {
          return maxValue - 10;
        } else if (indexH === 1) {
          return maxValue - 11;
        } else if (indexH === 2) {
          return maxValue - 14;
        } else if (indexH === 3) {
          return maxValue - 15;
        }

    }
  }

  var _default = Ember.Helper.helper(mathLikertscaletwodimensions);

  _exports.default = _default;
});