define("E4E/models/sector", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('number'),
    organizations: _emberData.default.hasMany('organization', {
      async: false
    }),
    users: _emberData.default.hasMany('user', {
      async: false
    }),
    jobOffers: _emberData.default.hasMany('joboffer', {
      async: false
    }),
    count: _emberData.default.attr('number'),
    links: _emberData.default.attr()
  });

  _exports.default = _default;
});