define("E4E/components/login-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authentication: Ember.inject.service('authentication'),
    router: Ember.inject.service(),
    actions: {
      authenticate: function () {
        this.authenticate(this.email, this.password); //this.get('authentication').authenticate();
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      openLoginDialog: function () {
        this.set('openLoginDialog', true);
        this.set('openRegisterOptionsDialog', false);
      },
      closeLoginDialog: function () {
        this.set('openLoginDialog', false);
      },
      openSelectionOptions: function () {
        this.set('openLoginDialog', false);
        this.set('openRegisterOptionsDialog', true);
      },
      closeSelectionOptions: function () {
        this.set('openRegisterOptionsDialog', false);
      },
      goToJobSeekerRegistration: function () {
        this.router.transitionTo("jobseeker-registration");
      },
      goToCompanyRegistration: function () {
        this.router.transitionTo("company-registration");
      }
    }
  });

  _exports.default = _default;
});