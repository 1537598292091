define("E4E/templates/components/organizations/avatar-image-cropper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0CbfsJ+V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"class\",\"onClose\",\"origin\"],[\"flex-50 flex-sm-70 flex-xs-80\",[27,\"action\",[[22,0,[]],\"closeCropper\"],null],[23,[\"dialogOrigin\"]]]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,[[\"class\"],[\"layout-row layout-align-space-between-center\"]],{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-24-type-1\"],[9],[1,[27,\"t\",[\"label.cropimage\"],null],false],[10],[0,\"\\n      \"],[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[true,[27,\"action\",[[22,0,[]],\"closeCropper\"],null]]],{\"statements\":[[1,[27,\"paper-icon\",null,[[\"icon\"],[\"close\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cropper\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"cropper-container\"],[11,\"style\",\"width: 100%; max-width: 800px; max-height: 800px;\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[28,[[21,\"fileAvatarSrc\"]]]],[11,\"style\",\"max-width:100%\"],[11,\"class\",\"cropper-hidden\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-dialog-actions\",null,[[\"class\"],[\"layout-row layout-align-end-center\"]],{\"statements\":[[4,\"paper-button\",null,[[\"primary\",\"raised\",\"onClick\"],[true,true,[27,\"action\",[[22,0,[]],\"cropImage\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"label.cropimage\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/organizations/avatar-image-cropper.hbs"
    }
  });

  _exports.default = _default;
});