define("E4E/components/users/edit-socialmedia", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    socialMediaCopy: {
      name: "",
      link: ""
    },
    disableSave: Ember.computed('socialMediaName', 'socialMediaTemp', 'socialMediaLink', function () {
      if (this.get('socialMediaTemp') == null) {
        return true;
      } else {
        if (this.get('socialMediaLink') == null) {
          return true;
        } else {
          if (this.get('socialMediaTemp.name') === "Other") {
            if (this.get('socialMediaName') == null) {
              return true;
            } else {
              return false;
            }
          }

          return false;
        }
      }
    }),
    socialMedias: [{
      name: "Facebook"
    }, {
      name: "Instagram"
    }, {
      name: "Twitter"
    }, {
      name: "Linkedin"
    }, {
      name: "Other"
    }],
    socialMediaName: "",
    socialMediaLink: "",
    socialMediaTemp: null,
    init: function () {
      this._super(...arguments);

      this.get('setDataToEdit').perform();
    },
    setDataToEdit: (0, _emberConcurrency.task)(function* () {
      switch (this.get('socialMedia.name')) {
        case "Facebook":
          this.set('socialMediaTemp', {
            name: "Facebook"
          });
          break;

        case "Instagram":
          this.set('socialMediaTemp', {
            name: "Instagram"
          });
          break;

        case "Twitter":
          this.set('socialMediaTemp', {
            name: "Twitter"
          });
          break;

        case "Linkedin":
          this.set('socialMediaTemp', {
            name: "Linkedin"
          });
          break;

        default:
          this.set('socialMediaTemp', {
            name: "Other"
          });
          this.set('socialMediaName', this.get('socialMedia.name'));
          break;
      }

      this.set('socialMediaLink', this.get('socialMedia.link'));
    }),
    actions: {
      saveEditSocialMedia: function () {
        let socialMedia = Ember.copy(this.socialMediaCopy, true);
        socialMedia.link = this.get('socialMediaLink');

        if (this.get('socialMediaTemp.name') === "Other") {
          socialMedia.name = this.get('socialMediaName');
        } else {
          socialMedia.name = this.get('socialMediaTemp.name');
        }

        this.saveEditSocialMedia(socialMedia);
      },
      closeEditSocialMedia: function () {
        this.closeEditSocialMedia();
      }
    }
  });

  _exports.default = _default;
});