define("E4E/helpers/internships-infoset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.internshipsInfoset = internshipsInfoset;

  function internshipsInfoset(params
  /*, hash*/
  ) {
    let education = params[0];
    let internships = [];
    education.chapters.forEach(function (chapter) {
      chapter.modules.forEach(function (module) {
        module.submodules.forEach(function (submodule) {
          if (submodule.internship != undefined) {
            submodule.internship.forEach(function (intern) {
              let internTemp = internships.filter(int => {
                return int.name === intern.name;
              });

              if (internTemp.length > 0) {
                if (intern.status) {
                  internTemp[0].competences.push(submodule.title);
                }
              } else {
                let internshipObject = {
                  name: intern.name,
                  start: intern.startDate,
                  end: intern.endDate,
                  fieldOfActivity: intern.fieldOfActivity,
                  hoursPerWeek: intern.hoursPerWeek,
                  evaluation: intern.evaluation,
                  comment: intern.comment,
                  competences: []
                };

                if (intern.status) {
                  internshipObject.competences.push(submodule.title);
                }

                internships.pushObject(internshipObject);
              }
            });
          }
        });
      });
    });
    return internships;
  }

  var _default = Ember.Helper.helper(internshipsInfoset);

  _exports.default = _default;
});