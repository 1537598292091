define("E4E/controllers/terms-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: '',
    password: '',
    authentication: Ember.inject.service('authentication'),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    changedLanguage: Ember.computed('locale', function () {
      if (this.get('language') === 'pt') {
        this.set('intl.locale', [this.get('language'), 'en', 'de']);
      } else if (this.get('language') === 'de') {
        this.set('intl.locale', [this.get('language'), 'en', 'pt']);
      } else {
        this.set('intl.locale', [this.get('language'), 'de', 'pt']);
      }
    }),
    language: null,
    languages: ['en', 'de', 'pt'],
    init: function () {
      this._super(...arguments);

      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('locale', cookie.toString());
      } else {
        this.set('locale', 'en');
      }
    },
    actions: {
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      register: function () {},
      changeLanguage: function (newLocale) {
        this.cookies.write('locale', newLocale.toString());
        this.intl.set('locale', newLocale.toLowerCase());
        this.set('locale', newLocale);
      }
    }
  });

  _exports.default = _default;
});