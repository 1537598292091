define("E4E/components/users/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    oldPasswordValidity: Ember.computed('oldPassword', function () {
      if (this.get('oldPassword') != null && this.get('oldPassword') != "") {
        return false;
      } else {
        return true;
      }
    }),
    differentPasswords: Ember.computed('newPassword', 'confirmNewPassword', function () {
      if (this.get('newPassword') != null && this.get('newPassword') != "" || this.get('confirmNewPassword') != null && this.get('confirmNewPassword') != "") {
        if (this.get('newPassword') === this.get('confirmNewPassword')) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }),
    actions: {
      closeChangePasswordDialog: function () {
        this.closeChangePasswordDialog();
      },
      saveNewPassword: function () {
        if (this.newPassword.length <= 7) {
          this.set('errorInvalidPassword', true);
        } else {
          this.set('errorInvalidPassword', false);
          let save = {
            "newPassword": this.get('newPassword').toString(),
            "oldPassword": this.get('oldPassword').toString(),
            "confirmNewPassword": this.get('confirmNewPassword').toString()
          };
          this.saveNewPassword(save);
        }
      }
    }
  });

  _exports.default = _default;
});