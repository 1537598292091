define("E4E/controllers/organizations/job-seekers/index", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    jobSeekerParams: ["image", "coverimage", "curriculumvitae", "certificates", "conversations"],
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    userService: Ember.inject.service('user'),
    store: Ember.inject.service(),
    messengerService: Ember.inject.service('messenger-service'),
    init: function () {
      this._super(...arguments);

      this.set('model', {
        selectedJobSeeker: null
      });

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    searchJobSeekers: (0, _emberConcurrency.task)(function* (nextPage) {
      this.userService.filterJobSeekers(this.get('model.jobSeekerName'), this.get('model.jobSeekerCountryName').toLowerCase(), this.get('currentUserService.user.userOrganization.id'), this.get('model.jobSeekerFavorite'), this.get('model.city'), this.get('model.workExperience'), this.get('model.yearsExperience'), this.get('model.education'), this.get('model.educationScope'), this.get('model.educationNumber'), this.get('model.educationDateIssue'), this.get('model.languageName'), this.get('model.languageScope'), this.get('model.languageNumber'), this.get('model.languageDateIssue'), this.get('model.drivingLicenses'), this.get('model.competenceName'), this.get('model.outplacerId'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.jobSeekers').pushObjects(resp.users.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.jobSeekers', resp.users.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    findJobSeeker: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('user', id, {
        include: this.jobSeekerParams.toString()
      }).then(user => {
        this.set('model.selectedJobSeeker', user);
        $('#job_seeker-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          $('#search_container_job_seekers').addClass('hide');
          $('#job_seeker-container').addClass('show');
        }
      });
    }).restartable(),
    search: function () {
      this.set('model.jobSeekers', Ember.A([]));
      this.set('model.page', 0);
      this.set('model.meta', null);
      this.get('searchJobSeekers').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedJobSeeker') != null) {
          if ($('#search_container_job_seekers').hasClass('hide')) {
            $('#job_seeker-container').addClass('show');
          } else {
            $('#search_container_job_seekers').addClass('hide');
            $('#job_seeker-container').addClass('show');
          }
        } else {
          $('#search_container_job_seekers').removeClass('hide');
          $('#job_seeker-container').removeClass('show');
        }
      } else {
        $('#search_container_job_seekers').removeClass('hide');
        $('#job_seeker-container').removeClass('show');
      }
    },

    actions: {
      addToFavorites: function (favorite) {
        if (favorite != null) {
          favorite.set('isFavorite', true);
          favorite.save();
        } else {
          let flag = this.store.createRecord('flag');
          flag.set('userId', this.get('model.selectedJobSeeker.id'));
          flag.set('organizationId', this.currentUserService.get('user.userOrganization.id'));
          flag.set('isFavorite', true);
          flag.set('inEvidence', false);
          flag.save().then(resp => {
            this.currentUserService.get('user.userOrganization.flags').pushObject(resp);
          });
        }
      },
      removeFromFavorites: function (favorite) {
        favorite.set('isFavorite', false);
        favorite.save();
      },
      searchJobSeekers: function () {
        this.get('searchJobSeekers').perform();
      },
      selectJobSeeker: function (id) {
        this.findJobSeeker.perform(id);
      },
      updateJobSeekersName: function (value) {
        this.set('model.jobSeekerName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateWorkExperience: function (value) {
        this.set('model.workExperience', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducation: function (value) {
        this.set('model.education', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationScope: function (value) {
        if (value == null) {
          this.set('model.educationScope', "");
        } else {
          this.set('model.educationScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationNumber: function (value) {
        if (value == null) {
          this.set('model.educationNumber', "");
        } else {
          this.set('model.educationNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationDateIssue: function (value) {
        if (value == null) {
          this.set('model.educationDateIssue', "");
        } else {
          this.set('model.educationDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageScope: function (value) {
        if (value == null) {
          this.set('model.languageScope', "");
        } else {
          this.set('model.languageScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageNumber: function (value) {
        if (value == null) {
          this.set('model.languageNumber', "");
        } else {
          this.set('model.languageNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageDateIssue: function (value) {
        if (value == null) {
          this.set('model.languageDateIssue', "");
        } else {
          this.set('model.languageDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        if (value == null) {
          this.set('model.jobSeekerCountry', "");
        } else {
          this.set('model.jobSeekerCountry', value.iso3);
        }

        this.set('model.country', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCity: function (value) {
        this.set('model.city', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguage: function (value) {
        this.set('model.languageName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateYearsExperience: function (value) {
        if (value == null) {
          this.set('model.yearsExperience', "");
        } else {
          this.set('model.yearsExperience', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateDrivingLicense: function (value) {
        if (this.get('model.drivingLicenses').includes(value)) {
          this.get('model.drivingLicenses').removeObject(value);
        } else {
          this.get('model.drivingLicenses').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompetences: function (value) {
        this.set('model.competenceName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchJobSeekers').perform(true);
      },
      backToSearch: function () {},
      downloadCV: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      addConversation: function (receiverId) {
        var self = this;
        this.messengerService.addConversation(receiverId).then(resp => {
          self.findJobSeeker.perform(self.get("model.selectedJobSeeker.id"));
        });
      }
    }
  });

  _exports.default = _default;
});