define("E4E/routes/verify-account", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      token: null
    },
    beforeModel: function (transition) {
      this.set('token', transition.queryParams.token);
    },
    model: function () {
      return Ember.RSVP.hash({
        token: this.get('token')
      });
    },
    actions: {
      didTransition: function () {
        this.controller.send("checkPost");
      }
    }
  });

  _exports.default = _default;
});