define("E4E/components/users/delete-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deleteLanguage: function (language) {
        this.deleteLanguage(language);
      },
      cancelDeleteLanguage: function () {
        this.cancelDeleteLanguage();
      }
    }
  });

  _exports.default = _default;
});