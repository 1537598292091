define("E4E/routes/public-joboffer-profile", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    cookies: Ember.inject.service(),
    queryParams: {
      token: null
    },
    beforeModel: function (queryParams, transition) {
      let self = this;
      return this.get('store').queryRecord('joboffer', {
        shareableToken: {
          token: queryParams.queryParams.token.toString()
        }
      }).then(function (resp) {
        self.set('jobOffer', resp);
      }, function (error) {
        self.router.transitionTo('not-found');
      });
    },
    model: function (params) {
      return Ember.RSVP.hash({
        token: params.token,
        jobOffer: this.get('jobOffer')
      });
    }
  });

  _exports.default = _default;
});