define("E4E/components/jobapplications/competencesprofile-card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    socialCompetences: Ember.computed('jobApplication.{workExperienceJson.@each,qualificationTrainingJson.@each,skillsCompetencesJson.Communication.Competences.@each}', function () {
      let socialSkills = Ember.A([]);
      let uniqueSocial = Ember.A([]);

      if (this.get('jobApplication.workExperienceJson') != null) {
        this.get('jobApplication.workExperienceJson').forEach(function (workExperience) {
          if (workExperience.Competences != null) {
            let competences = workExperience.Competences.filterBy('category', 'SOCIAL_SKILLS');
            competences.forEach(function (competence) {
              if (competence.rating != null) {
                competence.rating.Organisation = workExperience.Employer;
                competence.rating.Position = workExperience.Position.Label;
              } else {
                competence.rating = {
                  value: 0,
                  additionalText: '',
                  Organisation: workExperience.Employer,
                  Position: workExperience.Position.Label
                };
              }
            });
            socialSkills.addObjects(competences);
          }
        });
      }

      if (this.get('jobApplication.qualificationTrainingJson') != null) {
        this.get('jobApplication.qualificationTrainingJson').forEach(function (education) {
          if (education.Competences != null) {
            let competences = education.Competences.filterBy('category', 'SOCIAL_SKILLS');
            competences.forEach(function (competence) {
              if (competence.rating != null) {
                competence.rating.Organisation = education.Organisation;
                competence.rating.Position = education.Title;
                competence.rating.Scope = education.Scope;
              } else {
                competence.rating = {
                  value: 0,
                  additionalText: '',
                  Organisation: education.Organisation,
                  Position: education.Title,
                  Scope: education.Scope
                };
              }
            });
            socialSkills.addObjects(competences.filterBy('category', 'SOCIAL_SKILLS'));
          }
        });
      }

      if (this.get('jobApplication.skillsCompetencesJson') != null) {
        if (this.get('jobApplication.skillsCompetencesJson.Communication') != null) {
          if (this.get('jobApplication.skillsCompetencesJson.Communication.Competences') != null) {
            socialSkills.addObjects(this.get('jobApplication.skillsCompetencesJson.Communication.Competences'));
          }
        }
      }

      let jsonArray = JSON.stringify(socialSkills);
      let arrayTemp = JSON.parse(jsonArray);
      let socialSkillsCopied = Ember.copy(arrayTemp, true);
      socialSkillsCopied.forEach(function (competence) {
        if (uniqueSocial.filterBy('name', competence.name).length == 0) {
          if (competence instanceof _emberData.default.Model) {
            let array = [];
            array.pushObject(competence.rating);
            competence.set('rating', array);
            uniqueSocial.pushObject(competence);
          } else {
            let array = [];
            array.pushObject(competence.rating);
            competence.rating = array;
            uniqueSocial.pushObject(competence);
          }
        } else {
          if (competence instanceof _emberData.default.Model) {
            let competenceTemp = uniqueSocial.filterBy('name', competence.name)[0];
            competenceTemp.rating.pushObject(competence.get('rating'));
          } else {
            let competenceTemp = uniqueSocial.filterBy('name', competence.name)[0];
            competenceTemp.rating.pushObject(competence.rating);
          }
        }
      });
      return uniqueSocial;
    }),
    organizationalCompetences: Ember.computed('jobApplication.{workExperienceJson.@each,qualificationTrainingJson.@each,skillsCompetencesJson.Organisational.Competences.@each}', function () {
      let organizationalSKills = Ember.A([]);
      let uniqueOrganizational = Ember.A([]);

      if (this.get('jobApplication.workExperienceJson') != null) {
        this.get('jobApplication.workExperienceJson').forEach(function (workExperience) {
          if (workExperience.Competences != null) {
            let competences = workExperience.Competences.filterBy('category', 'ORGANIZATIONAL_SKILLS');
            competences.forEach(function (competence) {
              if (competence.rating != null) {
                competence.rating.Organisation = workExperience.Employer;
                competence.rating.Position = workExperience.Position.Label;
              } else {
                competence.rating = {
                  value: 0,
                  additionalText: '',
                  Organisation: workExperience.Employer,
                  Position: workExperience.Position.Label
                };
              }
            });
            organizationalSKills.addObjects(competences);
          }
        });
      }

      if (this.get('jobApplication.qualificationTrainingJson') != null) {
        this.get('jobApplication.qualificationTrainingJson').forEach(function (education) {
          if (education.Competences != null) {
            let competences = education.Competences.filterBy('category', 'ORGANIZATIONAL_SKILLS');
            competences.forEach(function (competence) {
              if (competence.rating != null) {
                competence.rating.Organisation = education.Organisation;
                competence.rating.Position = education.Title;
                competence.rating.Scope = education.Scope;
              } else {
                competence.rating = {
                  value: 0,
                  additionalText: '',
                  Organisation: education.Organisation,
                  Position: education.Title,
                  Scope: education.Scope
                };
              }
            });
            organizationalSKills.addObjects(competences);
          }
        });
      }

      if (this.get('jobApplication.skillsCompetencesJson') != null) {
        if (this.get('jobApplication.skillsCompetencesJson.Organisational') != null) {
          if (this.get('jobApplication.skillsCompetencesJson.Organisational.Competences') != null) {
            organizationalSKills.addObjects(this.get('jobApplication.skillsCompetencesJson.Organisational.Competences'));
          }
        }
      }

      let jsonArray = JSON.stringify(organizationalSKills);
      let arrayTemp = JSON.parse(jsonArray);
      let organizationalSKillsCopied = Ember.copy(arrayTemp, true);
      organizationalSKillsCopied.forEach(function (competence) {
        if (uniqueOrganizational.filterBy('name', competence.name).length == 0) {
          if (competence instanceof _emberData.default.Model) {
            let array = [];
            array.pushObject(competence.rating);
            competence.set('rating', array);
            uniqueOrganizational.pushObject(competence);
          } else {
            let array = [];
            array.pushObject(competence.rating);
            competence.rating = array;
            uniqueOrganizational.pushObject(competence);
          }
        } else {
          let competenceTemp = uniqueOrganizational.filterBy('name', competence.name)[0];
          competenceTemp.rating.pushObject(competence.rating);
        }
      });
      return uniqueOrganizational;
    }),
    jobRelatedCompetences: Ember.computed('jobApplication.{workExperienceJson.@each,qualificationTrainingJson.@each,skillsCompetencesJson.JobRelated.Competences.@each}', function () {
      let jobRelatedSkills = Ember.A([]);
      let uniqueJobRelated = Ember.A([]);

      if (this.get('jobApplication.workExperienceJson') != null) {
        this.get('jobApplication.workExperienceJson').forEach(function (workExperience) {
          if (workExperience.Competences != null) {
            let competences = workExperience.Competences.filterBy('category', 'WORK_SKILLS');
            competences.forEach(function (competence) {
              if (competence.rating != null) {
                competence.rating.Organisation = workExperience.Employer;
                competence.rating.Position = workExperience.Position.Label;
              } else {
                competence.rating = {
                  value: 0,
                  additionalText: '',
                  Organisation: workExperience.Employer,
                  Position: workExperience.Position.Label
                };
              }
            });
            jobRelatedSkills.addObjects(competences);
          }
        });
      }

      if (this.get('jobApplication.qualificationTrainingJson') != null) {
        this.get('jobApplication.qualificationTrainingJson').forEach(function (education) {
          if (education.Competences != null) {
            let competences = education.Competences.filterBy('category', 'WORK_SKILLS');
            competences.forEach(function (competence) {
              if (competence.rating != null) {
                competence.rating.Organisation = education.Organisation;
                competence.rating.Position = education.Title;
                competence.rating.Scope = education.Scope;
              } else {
                competence.rating = {
                  value: 0,
                  additionalText: '',
                  Organisation: education.Organisation,
                  Position: education.Title,
                  Scope: education.Scope
                };
              }
            });
            jobRelatedSkills.addObjects(competences);
          }
        });
      }

      if (this.get('jobApplication.skillsCompetencesJson') != null) {
        if (this.get('jobApplication.skillsCompetencesJson.JobRelated') != null) {
          if (this.get('jobApplication.skillsCompetencesJson.JobRelated.Competences') != null) {
            jobRelatedSkills.addObjects(this.get('jobApplication.skillsCompetencesJson.JobRelated.Competences'));
          }
        }
      }

      let jsonArray = JSON.stringify(jobRelatedSkills);
      let arrayTemp = JSON.parse(jsonArray);
      let jobRelatedSkillsCopied = Ember.copy(arrayTemp, true);
      jobRelatedSkillsCopied.forEach(function (competence) {
        if (uniqueJobRelated.filterBy('name', competence.name).length == 0) {
          if (competence instanceof _emberData.default.Model) {
            let array = [];
            array.pushObject(competence.rating);
            competence.set('rating', array);
            uniqueJobRelated.pushObject(competence);
          } else {
            let array = [];
            array.pushObject(competence.rating);
            competence.rating = array;
            uniqueJobRelated.pushObject(competence);
          }
        } else {
          let competenceTemp = uniqueJobRelated.filterBy('name', competence.name)[0];
          competenceTemp.rating.pushObject(competence.rating);
        }
      });
      return uniqueJobRelated;
    }),
    actions: {
      displayRatings: function (competence) {
        this.set('showRating', true);
        this.set('competence', competence);
      }
    }
  });

  _exports.default = _default;
});