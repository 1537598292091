define("E4E/components/users/add-education", ["exports", "ember-concurrency", "pell"], function (_exports, _emberConcurrency, _pell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    Education: {
      Period: {
        From: {
          Year: "",
          Month: "",
          Day: ""
        },
        To: {
          Year: "",
          Month: "",
          Day: ""
        },
        Current: ""
      },
      Title: "",
      Organisation: {
        Name: "",
        ContactInfo: {
          Address: {
            Contact: {
              AddressLine: "",
              PostalCode: "",
              Municipality: "",
              Country: {
                Code: "",
                Label: ""
              }
            }
          },
          Website: {
            Contact: ""
          }
        }
      },
      Level: {
        Code: "",
        Label: ""
      },
      Field: {
        Code: "",
        Label: ""
      },
      Scope: {
        Label: "",
        Value: "",
        DateIssue: ""
      },
      Activities: "",
      ReferenceTo: [],
      Competences: Ember.A([])
    },
    validDateTo: Ember.computed('dayTo', 'monthTo', 'yearTo', 'ongoing', function () {
      if (this.get('ongoing')) {
        return false;
      } else {
        let dayTo = this.get('dayTo') != null && this.get('dayTo') != "";
        let monthTo = this.get('monthTo') != null && this.get('monthTo') != "";
        let yearTo = this.get('yearTo') != null && this.get('yearTo') != "";
        let date = moment.utc(moment.utc(this.get('yearTo') + "/" + this.get('monthTo') + "/" + this.get('dayTo')).format('YYYY/MM/DD'), 'YYYY/MM/DD');

        if (dayTo && monthTo && yearTo || !dayTo && monthTo && yearTo || !dayTo && !monthTo && yearTo) {
          if ((1 <= this.get('dayTo') && this.get('dayTo') <= 31 || !dayTo) && (1 <= this.get('monthTo') && this.get('monthTo') <= 12 || !monthTo) && 1930 <= this.get('yearTo') && this.get('yearTo') <= 2030) {
            if (dayTo && monthTo && yearTo) {
              if (date.year() == this.get('yearTo') && date.month() + 1 == this.get('monthTo') && date.date() == this.get('dayTo')) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    }),
    validDateFrom: Ember.computed('dayFrom', 'monthFrom', 'yearFrom', function () {
      let dayFrom = this.get('dayFrom') != null && this.get('dayFrom') != "";
      let monthFrom = this.get('monthFrom') != null && this.get('monthFrom') != "";
      let yearFrom = this.get('yearFrom') != null && this.get('yearFrom') != "";
      let date = moment.utc(moment.utc(this.get('yearFrom') + "/" + this.get('monthFrom') + "/" + this.get('dayFrom')).format('YYYY/MM/DD'), 'YYYY/MM/DD');

      if (dayFrom && monthFrom && yearFrom || !dayFrom && monthFrom && yearFrom || !dayFrom && !monthFrom && yearFrom) {
        if ((1 <= this.get('dayFrom') && this.get('dayFrom') <= 31 || !dayFrom) && (1 <= this.get('monthFrom') && this.get('monthFrom') <= 12 || !monthFrom) && 1930 <= this.get('yearFrom') && this.get('yearFrom') <= 2030) {
          if (dayFrom && monthFrom && yearFrom) {
            if (date.year() == this.get('yearFrom') && date.month() + 1 == this.get('monthFrom') && date.date() == this.get('dayFrom')) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }),
    validDates: Ember.computed('validDateTo', 'validDateFrom', 'education', function () {
      if (this.get('validDateTo') || this.get('validDateFrom') || this.get('education') == null) {
        return true;
      } else {
        return false;
      }
    }),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    page: 0,
    name: '',
    skillsAdded: Ember.A([]),
    allReferences: Ember.A([]),
    certificates: Ember.A([]),
    scopes: ["ECTS", "HOURS", "DAYS"],
    educationTypes: [{
      Code: 0,
      Label: "School"
    }, {
      Code: 1,
      Label: "AHS"
    }, {
      Code: 2,
      Label: "BHS (HAK, HTL, HLW...)"
    }, {
      Code: 3,
      Label: "BMS (HAS, VET college...)"
    }, {
      Code: 4,
      Label: "apprenticeship"
    }, {
      Code: 5,
      Label: "Bachelor's degree"
    }, {
      Code: 6,
      Label: "Master's degree"
    }, {
      Code: 7,
      Label: "Master's degree"
    }, {
      Code: 8,
      Label: "Doctoral degree"
    }, {
      Code: 9,
      Label: "Diploma Studies"
    }, {
      Code: 10,
      Label: "Seminar"
    }, {
      Code: 11,
      Label: "Course"
    }, {
      Code: 12,
      Label: "Course"
    }, {
      Code: 13,
      Label: "Further training"
    }],
    showAdditionalInformation: false,
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.educationTypeMatcher = this.educationTypeMatcher.bind(this);
      this.get('getCountries').perform();
      this.get('getEducations').perform();
      this.get('resetFields').perform();
      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    resetFields: (0, _emberConcurrency.task)(function* () {
      this.set('name', '');
      this.set('page', 0);
      this.set('skillsAdded', Ember.A([]));
      this.set('certificates', Ember.A([]));
    }),
    loadMore: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      this.set('page', this.get('page') + 1);
      let educations = yield this.store.query('education', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !educations.meta.page.last);
      this.get('educations').pushObjects(educations.toArray());
      this.set('isLoading', false);
    }).restartable(),
    getEducations: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      let educations = yield this.store.query('education', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !educations.meta.page.last);
      this.set('educations', educations.toArray());
      this.set('isLoading', false);
    }),
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    educationTypeMatcher: function (educationType, term) {
      const ret = `${this.get('intl').t('label.educationtypes.' + educationType.Code)}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    search: function () {
      this.set('educations', Ember.A([]));
      this.get('getEducations').perform();
    },
    actions: {
      addEducation: function (select, e) {
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          if (this.get('educations').filterBy('name', select.searchText)[0] == null) {
            let education = {
              name: select.searchText
            };
            this.set('education', education);
            this.get('educations').pushObject(education);
            select.actions.choose(education);
          } else {
            this.set('education', this.get('educations').filterBy('name', select.searchText)[0]);
          }
        }
      },
      closeAddEducationDialog: function () {
        this.closeAddEducationDialog();
      },
      saveEducation: function () {
        let education = Ember.copy(this.Education, true); // Setting Period of work experience

        education.Period.From.Day = this.get('dayFrom');
        education.Period.From.Month = this.get('monthFrom');
        education.Period.From.Year = this.get('yearFrom');
        education.Period.To.Day = this.get('dayTo');
        education.Period.To.Month = this.get('monthTo');
        education.Period.To.Year = this.get('yearTo');
        education.Period.Current = this.get('ongoing'); // Setting title of work experience

        if (this.get('education') != null) {
          education.Title = this.get('education');
        }

        if (this.get('educationType') != null) {
          education.Level.Code = this.get('educationType.Code');
          education.Level.Label = this.intl.t("label.educationtypes." + this.get('educationType.Code'));
        } // Setting Organization information of the education


        education.Organisation.Name = this.get('organizationName');
        education.Organisation.ContactInfo.Address.Contact.AddressLine = this.get('address');

        if (this.get('country') != null) {
          education.Organisation.ContactInfo.Address.Contact.Country.Label = this.get('country.name');
          education.Organisation.ContactInfo.Address.Contact.Country.Code = this.get('country.iso3');
        }

        education.Organisation.ContactInfo.Address.Contact.Municipality = this.get('city');
        education.Organisation.ContactInfo.Address.Contact.PostalCode = this.get('postalCode');
        education.Organisation.ContactInfo.Website.Contact = this.get('website'); // Competences

        education.Competences = this.get('skillsAdded').toArray(); // Scope

        education.Scope.Label = this.get('scope');
        education.Scope.Value = this.get('number');

        if (this.get('dateIssue') != undefined && this.get('dateIssue') != null && this.get('dateIssue') != "") {
          education.Scope.DateIssue = moment.utc(moment.utc(this.get('dateIssue')).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate();
        }

        education.Activities = this.get('activities');
        this.saveEducation(education, this.get('certificates'), Ember.A([]), null, true);
      },
      searchEducations: function (value) {
        this.set('name', value);
        this.set('page', 0);
        Ember.run.debounce(this, this.search, 1000);
      },
      addSkills: function () {
        this.set("addSkills", true);
      },
      addSkillsToEducation: function () {
        this.set('addSkills', false);
      },
      cancelAddSkills: function () {
        this.set("addSkills", false);
      },
      removeAddedSkills: function (value) {
        this.get("skillsAdded").removeObject(value);
      },
      didSelectFilesAttachment: function (files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          this.get('certificates').pushObject(files[0]);

          let done = function (url) {
            self.set('fileUrl', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      removeCertificate: function (certificate) {
        this.get('certificates').removeObject(certificate);
      },
      showAdditionalInfo: function () {
        this.set('showAdditionalInformation', true);
      },
      hideAdditionalInformation: function () {
        this.set('showAdditionalInformation', false);
      }
    }
  });

  _exports.default = _default;
});