define("E4E/serializers/reportblogitem", ["exports", "E4E/serializers/application", "ember-data", "E4E/mixins/keep-only-changed"], function (_exports, _application, _emberData, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _keepOnlyChanged.default, {
    attrs: {
      blogPostDto: {
        embedded: "always"
      },
      blogCommentDto: {
        embedded: "always"
      },
      userDto: {
        embedded: "always"
      }
    },
    extractMeta: function (store, typeClass, payload) {
      let meta = {};

      if (payload.page) {
        meta.page = payload.page;
        delete payload.page;
      }

      return meta;
    },
    normalizeSingleResponse: function (store, primaryModelClass, payload, id, requestType) {
      let modelKey = primaryModelClass.modelName;
      let aux = {};
      Object.keys(payload).forEach(key => {
        aux[key] = payload[key];
        delete payload[key];
      });
      payload[modelKey] = aux;
      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, false);
    },
    normalizeArrayResponse: function (store, primaryModelClass, payload, id, requestType) {
      let reportBlogsPayload = {};
      reportBlogsPayload.reportblogitems = payload;
      return this._normalizeResponse(store, primaryModelClass, reportBlogsPayload, id, requestType, false);
    },
    normalizeQueryResponse: function (store, primaryModelClass, payload, id, requestType) {
      let reportBlogsPayload = {};
      let page = {};
      reportBlogsPayload.reportblogitems = payload.content;
      page.totalPages = payload.totalPages;
      page.totalElements = payload.totalElements;
      page.last = payload.last;
      page.size = payload.size;
      page.number = payload.number;
      reportBlogsPayload.page = page;
      return this._normalizeResponse(store, primaryModelClass, reportBlogsPayload, id, requestType, false);
    }
  });

  _exports.default = _default;
});