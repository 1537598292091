define("E4E/components/dialogs/messenger/add-conversation", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    respMeta: null,
    respUsers: [],
    page: 0,
    lastGivenPage: 0,
    nameTerm: "",
    oldNameTerm: "",
    userService: Ember.inject.service('user'),
    store: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    selectedUser: null,
    numberOfElementsOnPage: 10,
    messengerService: Ember.inject.service(),
    wasSearch: false,
    init: function () {
      this._super(...arguments);
    },
    searchUsers: (0, _emberConcurrency.task)(function* (forcePageTo0) {
      if (this.get("nameTerm").toLowerCase() != this.get("oldNameTerm").toLowerCase() || forcePageTo0) {
        this.set("page", 0);
        this.set('oldNameTerm', this.get("nameTerm"));
      }

      yield this.userService.filterForMessenger(this.get("nameTerm"), this.get('page'), this.get('numberOfElementsOnPage')).then(resp => {
        this.set('wasSearch', true);
        this.set('respMeta', resp.meta);

        if (this.get("page") == 0) {
          this.set("respUsers", resp.users.toArray());
          return;
        }

        this.get("respUsers").pushObjects(resp.users.toArray());
      });
    }),

    scrollContainer(event) {
      if (Math.ceil($("#search_container_msg_users").scrollTop() + $("#search_container_msg_users").outerHeight()) == $("#search_container_msg_users")[0].scrollHeight) {
        if (!this.get('respMeta.last')) {
          this.nextPage();
        }
      }
    },

    nextPage: function () {
      this.set('page', this.get('page') + 1);
      this.get("searchUsers").perform();
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      $("#search_container_msg_users").on('scroll', this._boundScroll);
    },

    searchUsersForAdd: function () {
      $("#search_container_msg_users").scrollTop(0);
      this.get("searchUsers").perform(true);
    },
    actions: {
      searchUsers: function () {
        this.get("searchUsers").perform(false);
      },
      closeDialog: function () {
        this.set('wasSearch', false);
        this.closeDialog();
      },
      addConversation: function (receiverId, index) {
        let self = this;
        this.messengerService.addConversation(receiverId).then(resp => {
          $("#search_container_msg_users").scrollTop(0);
          self.findConversations();
          self.get("searchUsers").perform(true);
        });
      },
      findByName: function (value) {
        this.set('nameTerm', value);
        Ember.run.debounce(this, this.searchUsersForAdd, 1000);
      }
    }
  });

  _exports.default = _default;
});