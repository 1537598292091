define("E4E/validators/messages", ["exports", "ember-intl-cp-validations/validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _messages.default.extend({
    prefix: 'validationErrors'
  });

  _exports.default = _default;
});