define("E4E/templates/components/questions/preview/date-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/xJ2+dOX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-1\"],[9],[1,[27,\"t\",[\"label.date\"],null],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-7 margin-top-8\"],[9],[1,[23,[\"question\",\"questionText\"]],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-7 margin-top-8 margin-bottom-32\"],[9],[1,[23,[\"question\",\"explanation\"]],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/questions/preview/date-question.hbs"
    }
  });

  _exports.default = _default;
});