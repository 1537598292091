define("E4E/routes/recruiters/job-seekers/index", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    page: 0,
    drivingLicenses: [],

    model() {
      return Ember.RSVP.hash({
        selectedJobSeeker: null,
        country: null,
        city: '',
        jobSeekerCountryName: '',
        jobSeekerName: '',
        educationDateIssue: '',
        educationNumber: '',
        educationScope: '',
        language: [],
        languageName: [],
        languageScope: '',
        languageNumber: '',
        languageDateIssue: '',
        yearsExperience: '',
        competenceName: [],
        jobSeekerFavorite: false,
        workExperience: '',
        education: '',
        drivingLicenses: this.get('drivingLicenses'),
        outplacerId: '',
        page: this.get('page')
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("searchJobSeekers");
      }
    }
  });

  _exports.default = _default;
});