define("E4E/helpers/requests-applications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.requestsApplications = requestsApplications;

  function requestsApplications(params) {
    let requests = params[0];
    let outplacer = params[1];
    let jobOffer = params[2];
    let user = params[3];

    if (user.outplacerPermission != "ONE_PERMISSION" && user.outplacerPermission != "ONE_PERMISSION_EACH") {
      return "NO_PERMISSION";
    } else {
      if (user.outplacerId == outplacer.id) {
        let request = requests.filter(request => {
          return request.outplacerId == outplacer.id;
        });

        if (request != null) {
          if (request.length > 0) {
            if (user.outplacerPermission == "ONE_PERMISSION") {
              let temp = request.filter(req => {
                return req.status == "ACCEPTED";
              });

              if (temp != null) {
                if (temp.length > 0) {
                  return "ACCEPTED_REQUEST";
                } else {
                  let pending = request.filter(req => {
                    return req.status == "PENDING";
                  });

                  if (pending != null) {
                    if (pending.length > 0) {
                      return "PENDING_REQUEST";
                    } else {
                      return "NEED_PERMISSION";
                    }
                  } else {
                    return "NEED_PERMISSION";
                  }
                }
              } else {
                let pending = request.filter(req => {
                  return req.status == "PENDING";
                });

                if (pending != null) {
                  if (pending.length > 0) {
                    return "PENDING_REQUEST";
                  } else {
                    return "NEED_PERMISSION";
                  }
                } else {
                  return "NEED_PERMISSION";
                }
              }
            } else {
              let temp = request.filter(req => {
                return req.jobOfferId == jobOffer.id;
              });

              if (temp != null) {
                if (temp.length > 0) {
                  if (temp[0].status == "ACCEPTED") {
                    return "ACCEPTED_REQUEST";
                  } else if (temp[0].status == "PENDING") {
                    return "PENDING_REQUEST";
                  } else {
                    return "NEED_PERMISSION";
                  }
                } else {
                  return "NEED_PERMISSION";
                }
              } else {
                return "NEED_PERMISSION";
              }
            }
          } else {
            return "NEED_PERMISSION";
          }
        } else {
          return "NEED_PERMISSION";
        }
      } else {
        let request = requests.filter(request => {
          return request.outplacerId == outplacer.id;
        });

        if (request != null) {
          if (request.length > 0) {
            let temp = request.filter(req => {
              return req.jobOfferId == jobOffer.id;
            });

            if (temp != null) {
              if (temp.length > 0) {
                if (temp[0].status == "ACCEPTED") {
                  return "ACCEPTED_REQUEST";
                } else if (temp[0].status == "PENDING") {
                  return "PENDING_REQUEST";
                } else {
                  return "NEED_PERMISSION";
                }
              } else {
                return "NEED_PERMISSION";
              }
            } else {
              return "NEED_PERMISSION";
            }
          } else {
            return "NEED_PERMISSION";
          }
        } else {
          return "NEED_PERMISSION";
        }
      }
    }
  }

  var _default = Ember.Helper.helper(requestsApplications);

  _exports.default = _default;
});