define("E4E/components/no-access-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      goBack: function () {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});