define("E4E/components/questions/add/multiplechoice-question", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: Ember.A([]),
    store: Ember.inject.service(),
    clearFields: (0, _emberConcurrency.task)(function* () {
      yield this.options = Ember.A([]);
    }),
    init: function () {
      this._super(...arguments);

      this.clearFields.perform();
    },
    actions: {
      addOption: function () {
        this.get('options').pushObject({
          optionText: ""
        });
      },
      deleteOption: function (option) {
        this.get('options').removeObject(option);
      },
      saveQuestion: function () {
        let question = this.store.createRecord('question');
        question.set('json', JSON.stringify({
          options: this.get('options')
        }));
        this.addQuestions(question);
      },
      cancelQuestion: function () {
        this.cancelQuestion();
      }
    }
  });

  _exports.default = _default;
});