define("E4E/services/dialog-service", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @author estebes
  const DEFAULT_PROPS = {
    clickOutsideToClose: true
  };

  var _default = Ember.Service.extend({
    queue: Ember.A(),
    activeDialog: Ember.computed.reads('queue.firstObject'),

    // Open a new dialog
    open(inner, options) {
      let d = Ember.Object.create(Ember.assign({
        inner,
        show: true
      }, this.buildOptions(options)));
      this.get('queue').pushObject(d);
      return d;
    },

    // Close the dialog
    close(dialog) {
      dialog.set('show', false);

      if (this.get('activeDialog') === dialog) {
        Ember.run.later(() => {
          Ember.tryInvoke(dialog, 'onClose');
          this.get('queue').removeObject(dialog);
        }, 400);
      } else {
        Ember.tryInvoke(dialog, 'onClose');
        this.get('queue').removeObject(dialog);
      }
    },

    buildOptions(options) {
      let dialogOptions = {};

      if (_emberGetConfig.default['ember-paper'] && _emberGetConfig.default['ember-paper']['paper-dialog']) {
        dialogOptions = _emberGetConfig.default['ember-paper']['paper-dialog'];
      }

      return Ember.assign({}, DEFAULT_PROPS, dialogOptions, options);
    }

  });

  _exports.default = _default;
});