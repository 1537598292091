define("E4E/controllers/digitallibrary", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    guidelineDocuments: Ember.computed("documentItems", function () {
      return this.get('documentItems').filter(function (document) {
        return document.get('category') === "GUIDELINES";
      });
    }),
    learningDocuments: Ember.computed("documentItems", function () {
      return this.get('documentItems').filter(function (document) {
        return document.get('category') === "LEARNING_MATERIAL";
      });
    }),
    otherDocuments: Ember.computed("documentItems", function () {
      return this.get('documentItems').filter(function (document) {
        return document.get('category') === "OTHER_DOCUMENTS";
      });
    }),
    documentDeleteSuccessfull: false,
    documentDeleteFailure: false,
    documentItems: Ember.A([]),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    fileService: Ember.inject.service('file-service'),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    files: null,
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    init: function () {
      this._super(...arguments);
    },
    getDocuments: (0, _emberConcurrency.task)(function* () {
      let documentItems = yield this.store.findAll('document');
      this.set("documentItems", Ember.A([]));
      this.set('documentItems', documentItems);
    }),
    actions: {
      openUploadDialog: function () {
        this.set('openUploadDialogComponent', true);
      },
      closeUploadDocuments: function () {
        this.set('openUploadDialogComponent', false);
        this.set('files', null);
      },
      uploadDocument: function (files, category) {
        let self = this;
        this.fileService.uploadDocument.perform(files, category).then(document => {
          self.get("getDocuments").perform();
          self.set('openUploadDialogComponent', false);
          self.set('files', null);
        });
      },
      getDocuments: function () {
        this.get("getDocuments").perform();
      },
      downloadfile: function (originalFileName, file, mimeType) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(fileContent => self.saveFileAs(originalFileName, fileContent, mimeType));
        });
      },
      deleteDocument: function (document) {
        document.destroyRecord().then(() => {
          this.set("documentDeleteSuccessfull", true);
          this.get('getDocuments').perform();
        }).catch(function (error) {
          this.set('documentDeleteFailure', true);
        });
      },
      closeDocumentDeleteSuccessfull: function () {
        this.set("documentDeleteSuccessfull", false);
      },
      closeDocumentDeleteFailure: function () {
        this.set("documentDeleteFailure", false);
      }
    }
  });

  _exports.default = _default;
});