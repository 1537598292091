define("E4E/templates/components/digitalskills/selected-digital-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i1NddCDS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center padding-left-8 padding-right-8\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-1\"],[11,\"style\",\"line-height: inherit;\"],[9],[1,[21,\"option\"],false],[0,\" - \"],[1,[27,\"t\",[[27,\"concat\",[\"label.\",[23,[\"option\"]]],null]],null],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/digitalskills/selected-digital-level.hbs"
    }
  });

  _exports.default = _default;
});