define("E4E/models/jobapplication", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    created: attr('date'),
    modified: attr('date'),
    jAppJobOffer: belongsTo('joboffer', {
      async: false,
      inverse: null
    }),
    jAppJobSeeker: belongsTo('user', {
      async: false,
      inverse: null
    }),
    status: attr('string', {
      defaultValue: 'PENDING'
    }),
    motivationLetter: attr('string'),
    motive: attr('string'),
    permissionProfile: attr('boolean'),
    json: attr('string'),
    jsonEvaluation: attr('string'),
    jsonMap: Ember.computed('json', function () {
      if (this.get('json') != null) {
        let jsonMap = JSON.parse(this.get('json'));
        jsonMap.questions.forEach(element => {
          element.json = JSON.parse(element.json);
        });
        return jsonMap;
      }
    }),
    jsonEvaluationMap: Ember.computed('jsonEvaluation', function () {
      if (this.get('jsonEvaluation') != null) {
        let jsonMap = JSON.parse(this.get('jsonEvaluation'));
        jsonMap.questions.forEach(element => {
          element.json = JSON.parse(element.json);
        });
        return jsonMap;
      }
    }),
    videoId: attr('string'),
    jobSeekerId: attr('string'),
    jobOfferId: attr('string'),
    workExperience: attr('string'),
    workExperienceJson: Ember.computed('workExperience', function () {
      if (this.get('workExperience') != null && this.get('workExperience') != "") {
        let workExperienceJson = JSON.parse(this.get('workExperience'));

        if (workExperienceJson.workExperiences != null) {
          return workExperienceJson.workExperiences;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    qualificationTraining: _emberData.default.attr('string'),
    qualificationTrainingJson: Ember.computed('qualificationTraining', function () {
      if (this.get('qualificationTraining') != null && this.get('qualificationTraining') != "") {
        let educationJson = JSON.parse(this.get('qualificationTraining'));

        if (educationJson.educations != null) {
          return educationJson.educations;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    skillsCompetences: _emberData.default.attr('string'),
    skillsCompetencesJson: Ember.computed('skillsCompetences', function () {
      if (this.get('skillsCompetences') != null && this.get('skillsCompetences') != "") {
        let skills = JSON.parse(this.get('skillsCompetences'));

        if (skills.skills != null) {
          return skills.skills;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    availabiltyTravel: attr('boolean'),
    languages: attr('string'),
    drivingLicense: attr('string'),
    motivationalLetterId: attr('string'),
    matchingFactor: attr('number'),
    motivationalLetterFile: belongsTo('motivationalletter', {
      async: false
    }),
    jAppVideo: belongsTo('video', {
      async: false
    }),
    tags: hasMany('tag', {
      async: false,
      inverse: null
    }),
    links: _emberData.default.attr(),
    additionalInformation: _emberData.default.attr('string'),
    matchingCompetence: attr('string'),
    inEvidence: _emberData.default.attr('boolean'),
    acceptanceDate: attr('date'),
    rejectionDate: attr('date')
  });

  _exports.default = _default;
});