define("E4E/initializers/ember-data-change-tracker", ["exports", "ember-data-change-tracker"], function (_exports, _emberDataChangeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-data-change-tracker',
    after: 'ember-data',
    initialize: _emberDataChangeTracker.initializer
  };
  _exports.default = _default;
});