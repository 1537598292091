define("E4E/components/organizations/question-bundles/edit-questionbundle", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bundleName: '',
    selectedBundleType: null,
    addingQuestion: false,
    questionTypes: ["SINGLE_CHOICE", "MULTIPLE_CHOICE", "LIKERT_SCALE", "NUMERIC", "CERTIFICATE", "OPEN_TEXT", "DATE", "SLIDER"],
    questionsToBeRemoved: Ember.A([]),
    addedQuestions: Ember.A([]),
    clearFields: (0, _emberConcurrency.task)(function* () {
      this.set('questionType', null);
      this.set('questionText', '');
    }),
    init: function () {
      this._super(...arguments);

      this.set('bundleName', this.get('selectedQuestionBundle.name'));
      this.set('selectedBundleType', this.get('selectedQuestionBundle.bundleType'));
      this.set('addedQuestions', Ember.A([]));
      this.set('questionsToBeRemoved', Ember.A([]));
      this.get('addedQuestions').pushObjects(this.get('selectedQuestionBundle.questionsSorted'));
    },
    saveQuestions: (0, _emberConcurrency.task)(function* () {
      yield;

      for (var i = 0; i < this.get('addedQuestions').length; i++) {
        let question = this.get('addedQuestions')[i];

        if (question.get('questionType') === 'SINGLE_CHOICE') {
          question.set('json', JSON.stringify(question.get('singleChoiceJson')));
        } else if (question.get('questionType') === 'MULTIPLE_CHOICE') {
          question.set('json', JSON.stringify(question.get('multipleChoiceJson')));
        } else if (question.get('questionType') === 'LIKERT_SCALE') {
          question.set('json', JSON.stringify(question.get('likertScaleJson')));
        } else if (question.get('questionType') === 'LIKERT_SCALE_TWO') {
          question.set('json', JSON.stringify(question.get('likertScaleJson')));
        }

        question.set('orderNumber', i);
        question.save();
      }

      for (var i = 0; i < this.get('questionsToBeRemoved').length; i++) {
        let question = this.get('questionsToBeRemoved')[i];
        question.destroyRecord();
      }
    }),
    actions: {
      addQuestion: function () {
        this.set('addingQuestion', true);
      },
      saveQuestion: function () {},
      cancelQuestion: function () {
        this.set('addingQuestion', false);
        this.clearFields.perform();
      },
      addQuestions: function (question) {
        question.set('orderNumber', this.get('addedQuestions').length);
        question.set('questionText', this.get('questionText'));
        question.set('questionType', this.get('questionType'));
        question.set('questionBundleId', this.get('selectedQuestionBundle.id'));
        this.get('addedQuestions').pushObject(question);
        this.set('addingQuestion', false);
        this.clearFields.perform();
      },
      editQuestion: function (question) {},
      deleteQuestion: function (question) {
        this.get('addedQuestions').removeObject(question);
        this.get('questionsToBeRemoved').pushObject(question);
      },
      cancelBundle: function () {
        this.cancelBundle();
      },
      saveBundle: function () {
        let self = this;
        this.saveQuestions.perform().then(() => {
          self.set('selectedQuestionBundle.name', self.get('bundleName'));
          self.set('selectedQuestionBundle.bundleType', self.get('selectedBundleType'));
          self.set('selectedQuestionBundle.questions', Ember.A([]));
          self.updateQuestionBundle(self.get('selectedQuestionBundle'), self.get('addedQuestions'));
        });
      },
      dragEnd: function ({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
        /* , sourceArgs, targetArgs */

      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
      }
    }
  });

  _exports.default = _default;
});