define("E4E/controllers/registration-successfull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authentication: Ember.inject.service('authentication'),
    router: Ember.inject.service(),
    actions: {
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      openSelectionOptions: function () {
        this.set('registerOptions', true);
      },
      closeOptionSelection: function () {
        this.set('registerOptions', false);
      },
      goToLogin: function () {
        this.router.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});