define("E4E/models/cover", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    author: _emberData.default.belongsTo('user', {
      async: false
    }),
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    fileId: _emberData.default.attr('string'),
    authorId: _emberData.default.attr('string'),
    imageFile: _emberData.default.belongsTo('file', {
      async: false
    }),
    links: _emberData.default.attr()
  });

  _exports.default = _default;
});