define("E4E/adapters/store", ["exports", "ember-data", "E4E/app/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Store.extend({
    adapter: _application.default
  });

  _exports.default = _default;
});