define("E4E/helpers/is-safari", ["exports", "ember-browser-checker/helpers/is-safari"], function (_exports, _isSafari) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isSafari.default;
    }
  });
  Object.defineProperty(_exports, "isSafari", {
    enumerable: true,
    get: function () {
      return _isSafari.isSafari;
    }
  });
});