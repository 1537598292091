define("E4E/components/dialogs/jobapplications/edit-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dialogService: Ember.inject.service('dialog-service'),
    currentUserService: Ember.inject.service('current-user'),
    tagService: Ember.inject.service('tag'),
    jobApplicationService: Ember.inject.service('jobapplication'),
    store: Ember.inject.service(),
    tagName: '',
    colorSelected: null,
    colors: ["#f5a623", "#f8e71c", "#8b572a", "#417505", "#bd10e0", "#9013fe", "#4a90e2", "#50e3c2", "#fe877e", "#77f2c5", "#888888"],
    actions: {
      closeTagManagementDialog: function () {
        this.sendAction('closeDialog');
      },
      updateTags: function (value) {
        let jobApplication = this.get('params.jobApplication');
        this.store.findRecord('tag', value.id).then(tag => {
          jobApplication.get('tags').pushObject(tag);
          this.get('jobApplicationService').save(jobApplication, this.get('params').jobApplicationParams);
        });
      },
      removeTag: function (tag) {
        let jobApplication = this.get('params.jobApplication');
        jobApplication.get('tags').removeObject(tag);
        this.get('jobApplicationService').save(jobApplication, this.get('params').jobApplicationParams);
      },
      createNewTag: function () {
        this.set('addNewTag', true);
      },
      colorPickerUpdate: function () {
        Ember.$('#input-color_picker').on('click', function (event) {
          event.stopPropagation();
          Ember.$(this).unbind('click');
        }).trigger('click');
      },
      updateColor: function (color) {
        this.set('colorSelected', color);
      },
      saveNewTag: function () {
        let self = this;
        let tag = this.store.createRecord("tag");
        tag.set('title', this.get('tagName'));
        tag.set('color', this.get('colorSelected'));
        tag.set('organizationId', this.get('currentUserService.user.organizationId'));
        this.tagService.save(tag, []).then(newTag => {
          let jobApplication = self.get('params.jobApplication');
          jobApplication.get('tags').pushObject(newTag);
          self.get('jobApplicationService').save(jobApplication, self.get('params').jobApplicationParams).then(() => {
            self.set('tagName', "");
            self.set('colorSelected', null);
            self.set('addNewTag', false);
            self.searchTags();
          });
        });
      },
      cancelCreateNewTag: function () {
        this.set('tagName', "");
        this.set('colorSelected', null);
        this.set('addNewTag', false);
      }
    }
  });

  _exports.default = _default;
});