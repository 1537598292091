define("E4E/routes/job-seekers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    categories: [],
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUserService: Ember.inject.service("current-user"),
    controller: null,

    model() {
      return Ember.RSVP.hash({
        categories: Ember.A([])
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("getData");
      }
    }
  });

  _exports.default = _default;
});