define("E4E/components/users/video-library", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    video: null,
    actions: {
      cancelSelectVideo: function () {
        this.cancelSelectVideo();
      },
      selectVideo: function (video) {
        this.selectVideo(video);
      },
      chooseVideo: function (video) {
        this.set('videoSelected', video);
      }
    }
  });

  _exports.default = _default;
});