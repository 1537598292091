define("E4E/components/questions/edit/likertscaletwo-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
    },
    actions: {
      addOptionHorizontalOption: function () {
        this.get('question.likertScaleJson.options.horizontal').pushObject({
          optionText: ""
        });
      },
      addOptionVerticalOption: function () {
        this.get('question.likertScaleJson.options.vertical').pushObject({
          optionText: ""
        });
      },
      addQuestion: function () {
        this.get('question.likertScaleJson.questions').pushObject({
          questionText: ""
        });
      },
      deleteQuestion: function (question) {
        this.get('question.likertScaleJson.questions').removeObject(question);
      },
      deleteOptionVertical: function (option) {
        this.get('question.likertScaleJson.options.vertical').removeObject(option);
      },
      deleteOptionHorizontal: function (option) {
        this.get('question.likertScaleJson.options.horizontal').removeObject(option);
      }
    }
  });

  _exports.default = _default;
});