define("E4E/services/authentication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    authenticate: function (email, password) {
      this.get('session').authenticate('authenticator:oauth2', email, password).catch(() => {
        this.set('errorMessage', 'label.invalid.emailpassword');
      });
    }
  });

  _exports.default = _default;
});