define("E4E/services/questionbundle", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    save: function (questionBundle, questionBundleParams) {
      let self = this;
      return Ember.RSVP.resolve(questionBundle.save({
        include: questionBundleParams != null ? questionBundleParams.toString() : null
      })).then(questionBundle => {
        self.get('paperToaster').show(self.intl.t('label.questionbundle.saved'), {
          duration: 3000
        });
        return questionBundle;
      }).catch(error => {
        self.get('paperToaster').show(self.intl.t('label.questionbundle.error'), {
          duration: 3000
        });
        return error;
      });
    },

    load(id, questionBundleParams) {
      return this.store.findRecord('questionbundle', id, {
        include: questionBundleParams != null ? questionBundleParams.toString() : null
      });
    },

    delete(questionBundle) {
      let self = this;
      questionBundle.destroyRecord().then(() => {
        self.get('paperToaster').show(self.intl.t('label.questionbundle.deleted'), {
          duration: 3000
        });
      }).catch(error => {
        self.get('paperToaster').show(self.intl.t('label.questionbundle.deletederror'), {
          duration: 3000
        });
        return error;
      });
    },

    findByOrganization: function (id) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/questionbundles/findByOrganization/" + id)).then(resp => {
        return resp;
      });
    },

    addBundlesToJobOffer(id, questionBundlesTemp, questionBundlesTempEval) {
      this.removeBundlesFromJobOffer(id, "/jobofferquestionbundles/removefromjoboffer");
      this.removeBundlesFromJobOffer(id, "/jobofferevaluationschemebundles/removefromjoboffer");
      let self = this;
      let indexJobQuestionBundles = 0;
      let indexEvaluationSchemeBundles = 0;

      if (questionBundlesTemp != null) {
        questionBundlesTemp.forEach(function (bundle) {
          let jobOfferQuestionBundle = self.store.createRecord('jobofferquestionbundle');
          jobOfferQuestionBundle.set('jobOfferId', id);
          jobOfferQuestionBundle.set('questionBundleId', bundle.id);
          jobOfferQuestionBundle.set('orderNumber', indexJobQuestionBundles);
          jobOfferQuestionBundle.save();
          indexJobQuestionBundles++;
        });
      }

      if (questionBundlesTempEval != null) {
        questionBundlesTempEval.forEach(function (bundle) {
          let jobOfferEvaluationSchemeBundle = self.store.createRecord('jobofferevaluationschemebundle');
          jobOfferEvaluationSchemeBundle.set('jobOfferId', id);
          jobOfferEvaluationSchemeBundle.set('questionBundleId', bundle.id);
          jobOfferEvaluationSchemeBundle.set('orderNumber', indexEvaluationSchemeBundles);
          jobOfferEvaluationSchemeBundle.save();
          indexEvaluationSchemeBundles++;
        });
      }

      return Ember.RSVP.resolve(true);
    },

    filterCompanies: function (bundleName, bundleTypes, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/questionbundles/search/filterCompanies?" + "name=" + bundleName.toLowerCase() + "&organizationId=" + this.get('currentUserService.user.organizationId') + "&bundleType=" + bundleTypes.toString() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          questionBundles: null,
          meta: null
        };
        searchResponse.questionBundles = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    removeBundlesFromJobOffer: function (jobOfferId, url) {
      return Ember.$.ajax({
        type: "DELETE",
        headers: this.get('headers'),
        url: this.get('serverUrl') + url + "/" + jobOfferId,
        success: function (resp) {
          return resp;
        }
      });
    }
  });

  _exports.default = _default;
});