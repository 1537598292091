define("E4E/components/dialogs/jobapplications/reject-application", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dialogService: Ember.inject.service('dialog-service'),
    jobApplicationService: Ember.inject.service('jobapplication'),
    store: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      closeDialog: function () {
        this.closeRejectDialog();
      },
      rejectApplication: function () {
        let jobApplication = this.get('params.jobApplication');
        jobApplication.set("motive", this.get("motive"));
        jobApplication.set("status", "REJECTED");
        jobApplication.set('rejectionDate', new Date());
        this.jobApplicationService.rejectJobApplication(jobApplication, this.get("params.jobApplicationParams"));
        let notification = this.get('store').createRecord('notification');
        notification.set('senderId', this.get('params.jobApplication.jAppJobOffer.authorId'));
        notification.set('receiverId', this.get('params.jobApplication.jobSeekerId'));
        notification.set('notificationType', 'NOTIFICATION_APPLICATION_DENIED');
        notification.set('context', jobApplication.get('id'));
        notification.set('seenNotification', false);
        notification.save();

        if (this.get('params.jobApplication.jAppJobSeeker.activeEmail')) {
          let emailObject = {
            firstName: this.get('params.jobApplication.jAppJobSeeker.firstName'),
            lastName: this.get('params.jobApplication.jAppJobSeeker.lastName'),
            email: this.get('params.jobApplication.jAppJobSeeker.email'),
            notificationType: 'NOTIFICATION_APPLICATION_DENIED',
            context: this.get('params.jobApplication.id'),
            locale: self.get('i18n.locale'),
            jobOfferTitle: this.get('params.jobApplication.jAppJobOffer.title')
          };
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.APP.SPRING_HOST + "/api/notifications/sendNotification",
            data: JSON.stringify(emailObject),
            contentType: "application/json"
          }).then(function (response) {
            self.set('message', response.message);
          });
        }

        this.closeRejectDialog();
      }
    }
  });

  _exports.default = _default;
});