define("E4E/services/messenger-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    addConversation: function (receiverId) {
      let self = this;
      let conversation = this.store.createRecord('conversation');
      conversation.set('authorId', this.currentUserService.get('user.id'));
      conversation.set('receiverId', receiverId);
      return Ember.RSVP.resolve(conversation.save().then(function () {
        self.get('paperToaster').show(self.intl.t('label.messenger.contact-added'), {
          duration: 3000
        });
      }));
    }
  });

  _exports.default = _default;
});