define("E4E/templates/components/questions/edit/certificate-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EqT+++Z1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-start width100P\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"handle-sort margin-top-8 margin-right-16\"],[11,\"draggable\",\"true\"],[9],[0,\"\\n    \"],[1,[27,\"paper-icon\",[\"reorder\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start width100P margin-top-16\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-center width100P\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"text-16-type-1\"],[9],[1,[27,\"t\",[\"label.certificate\"],null],false],[10],[0,\"\\n\"],[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[true,[27,\"action\",[[22,0,[]],\"deleteQuestion\",[23,[\"question\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[27,\"paper-icon\",[\"delete\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[1,[27,\"paper-input\",null,[[\"class\",\"label\",\"value\",\"onChange\"],[\"width100P padding-0\",[27,\"t\",[\"label.questiontext\"],null],[23,[\"question\",\"questionText\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"question\",\"questionText\"]]],null]],null]]]],false],[0,\"\\n    \"],[1,[27,\"paper-input\",null,[[\"class\",\"label\",\"value\",\"onChange\"],[\"width100P padding-0\",[27,\"t\",[\"label.explanation\"],null],[23,[\"question\",\"explanation\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"question\",\"explanation\"]]],null]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/questions/edit/certificate-question.hbs"
    }
  });

  _exports.default = _default;
});