define("E4E/components/recruiters/job-offers/joboffer-overview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    hasEducation: Ember.computed('selectedJobApplication.qualificationTrainingJson.@each', function () {
      let formalEducation = this.get('selectedJobApplication.jAppJobOffer.formalEducationJson');
      let hasEducation = false;
      this.get('selectedJobApplication.qualificationTrainingJson').forEach(function (education) {
        if (formalEducation != null) {
          let educationTemp = formalEducation.filterBy('Title', education.Title);

          if (educationTemp.length > 0) {
            hasEducation = true;
          }
        }
      });
      return hasEducation;
    }),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      closeOverview: function () {
        this.closeOverview();
      },
      goToJobApplications: function (jobApplication) {
        let cookieService = this.get('cookies');
        cookieService.write('jobApplicationId', jobApplication.get('id'));
        cookieService.write('jobSeekerName', jobApplication.get('jAppJobSeeker.firstName') + " " + jobApplication.get('jAppJobSeeker.lastName'));
        this.get('router').transitionTo("recruiters.job-applications.index");
      }
    }
  });

  _exports.default = _default;
});