define("E4E/components/organizations/header-image-cropper", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _imageCropper.default.extend({
    //override default options of cropper
    dialogService: Ember.inject.service('dialog-service'),
    aspectRatio: 10 / 1.5,
    cropperContainer: '.cropper-container > img',
    croppedAvatar: null,
    responsive: true,
    actions: {
      setCropBoxData: function (data) {
        data.width = 1014;
        data.height = 154;
      },
      cropImage: function () {
        let self = this;
        var croppedImage = this.get('cropper').getCroppedCanvas({
          width: 1920,
          height: 288
        });
        croppedImage.toBlob(function (blob) {
          let url = URL.createObjectURL(blob);
          blob.url = url;
          blob.name = self.get('fileCover.name');
          self.set('cover', blob);
          self.uploadCompanyCover(self.get('cover'));
          self.closeImageDialog();
        });
      },
      closeCropper: function () {
        this.closeImageDialog();
      }
    }
  });

  _exports.default = _default;
});