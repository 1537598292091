define("E4E/models/blogpost", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    blocked: _emberData.default.attr('boolean'),
    authorId: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    comments: _emberData.default.hasMany("blogcomment", {
      async: false,
      inverse: null
    }),
    authorDto: _emberData.default.belongsTo('user', {
      async: false,
      inverse: null
    })
  });

  _exports.default = _default;
});