define("E4E/components/users/edit-organizationskill", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showMoreCompetences: false,
    store: Ember.inject.service(),
    competences: Ember.A([]),
    page: 0,
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.set('skillsAdded', this.get('organizationalSkill.Competences') != null ? this.get('organizationalSkill.Competences') : Ember.A([]));
      this.competencesMatcher = this.competencesMatcher.bind(this);
      this.get('getAllCompetences').perform();
      this.get('setRatingObject').perform();
    },
    setRatingObject: (0, _emberConcurrency.task)(function* () {
      this.get('skillsAdded').forEach(function (skill) {
        if (skill.rating == null) {
          skill.rating = {
            value: 0,
            additionalText: ''
          };
        }
      });
    }),
    getAllCompetences: (0, _emberConcurrency.task)(function* () {
      let competences = yield this.get('store').findAll('competence');
      this.set('competences', competences);
    }),
    competencesMatcher: function (competence, term) {
      const ret = `${this.get('intl').t('label.competencesnames.' + competence.code)}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      removeAddedSkills: function (skill) {
        this.get('skillsAdded').removeObject(skill);
      },
      addSkill: function (skill) {
        if (skill.id != null) {
          skill.set('rating', {
            value: 0,
            additionalText: ''
          });
        } else {
          skill.rating = {
            value: 0,
            additionalText: ''
          };
        }

        this.get('skillsAdded').pushObject(skill);
      },
      closeEditOrganizationSkillDialog: function () {
        this.closeEditOrganizationSkillDialog();
      },
      saveOrganizationSkill: function () {
        this.set('organizationalSkill.Competences', this.get('skillsAdded'));
        this.saveOrganizationSkill(this.get('organizationalSkill'));
      },
      showRatingStats: function () {
        this.set('showRating', true);
      },
      hideRatingsStats: function () {
        this.set('showRating', false);
      }
    }
  });

  _exports.default = _default;
});