define("E4E/helpers/jobapplications-workingyears", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    workExperiencesTitles: Ember.A([]),

    compute(params) {
      let self = this;
      let jobTitleDto = params[0].get('jobTitleDto');
      let jobApplication = params[1];
      let years = 0;
      jobApplication.get('workExperienceJson').forEach(function (workExperience) {
        self.get('store').query('jobtitle', {
          filter: {
            name: workExperience.Position.Label,
            language: self.get('intl.locale')[0],
            page: 0,
            size: 10
          }
        }).then(jobTitles => {
          let jobTitlesTemp = jobTitles.toArray();

          if (jobTitlesTemp.length > 0) {
            if (jobTitlesTemp != null) {
              let jobTitle = jobTitlesTemp.filterBy('name', workExperience.Position.Label);

              if (jobTitle.length > 0) {
                if (jobTitleDto != null) {
                  if (jobTitle[0].get('category') == jobTitleDto.get('category')) {
                    if (!self.workExperiencesTitles.includes(workExperience)) {
                      self.workExperiencesTitles.pushObject(workExperience);
                    }
                  }
                }
              }
            }
          }
        });
      });
      this.workExperiencesTitles.forEach(function (workExperience) {
        let dateFromA = new Date(workExperience.Period.From.Year, workExperience.Period.From.Month - 1, workExperience.Period.From.Day);
        let dateToB;

        if (workExperience.Period.Current) {
          dateToB = moment();
        } else {
          if (workExperience.Period.To.Day != null) {
            dateToB = moment(new Date(workExperience.Period.To.Year, workExperience.Period.To.Month - 1, workExperience.Period.To.Day));
          } else if (workExperience.Period.To.Month != null) {
            dateToB = moment(new Date(workExperience.Period.To.Year, workExperience.Period.To.Month - 1, 1));
          } else {
            dateToB = moment(new Date(workExperience.Period.To.Year, 1, 1));
          }
        }

        years = years + dateToB.diff(dateFromA, 'years');
      });
      return years;
    }

  });

  _exports.default = _default;
});