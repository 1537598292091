define("E4E/components/company-card", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    actions: {
      editOrganization: function () {
        this.sendAction('editOrganization');
      },
      changeImage: function () {
        this.sendAction('changeImage');
      }
    }
  });

  _exports.default = _default;
});