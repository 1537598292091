define("E4E/templates/components/jobapplications-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R4nnXzij",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[7,\"span\"],[11,\"class\",\"text-24-type-1\"],[9],[1,[27,\"t\",[\"label.joboffers\"],null],false],[10],[0,\"\\n\"],[4,\"paper-card\",null,[[\"class\"],[\"margin-0 maxWidth600 margin-top-16\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"padding-16\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/jobapplications-card.hbs"
    }
  });

  _exports.default = _default;
});