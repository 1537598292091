define("E4E/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    redirect: function () {
      switch (this.get('currentUserService.user.userRole.name')) {
        case "JOB_SEEKER":
          this.transitionTo('job-seekers.dashboard');
          break;

        case "ORGANIZATION_ADMIN":
          this.transitionTo('organizations.dashboard');
          break;

        case "ORGANIZATION_USER":
          this.transitionTo('users.profile', this.currentUserService.user.id);
          break;

        case "SUPER_ADMIN":
          this.transitionTo('superadmin.dashboard');
          break;

        case "OUTPLACER":
          this.transitionTo('outplacers.dashboard');
          break;

        case "PARTNER_MANAGER":
          this.transitionTo('partners.dashboard');
          break;

        case "RECRUITER":
          this.transitionTo('recruiters.dashboard');
          break;
      }
    }
  });

  _exports.default = _default;
});