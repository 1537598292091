define("E4E/components/dialogs/joboffers/edit-drivinglicense", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    Driving: {
      Description: Ember.A([]),
      ReferenceTo: Ember.A([])
    },
    init: function () {
      this._super(...arguments);

      this.get('setAlreadySelectedDrivingLicenses').perform();
    },
    setAlreadySelectedDrivingLicenses: (0, _emberConcurrency.task)(function* () {
      let self = this;

      if (this.get('drivingLicenses') != null) {
        this.get('drivingLicense.Description').forEach(function (value) {
          switch (value) {
            case "AM":
              self.set('drivingLicenseAM', true);
              break;

            case "A1":
              self.set('drivingLicenseA1', true);
              break;

            case "A2":
              self.set('drivingLicenseA2', true);
              break;

            case "A":
              self.set('drivingLicenseA', true);
              break;

            case "B1":
              self.set('drivingLicenseB1', true);
              break;

            case "B":
              self.set('drivingLicenseB', true);
              break;

            case "BE":
              self.set('drivingLicenseBE', true);
              break;

            case "C1":
              self.set('drivingLicenseC1', true);
              break;

            case "C1E":
              self.set('drivingLicenseC1E', true);
              break;

            case "C":
              self.set('drivingLicenseC', true);
              break;

            case "CE":
              self.set('drivingLicenseCE', true);
              break;

            case "D1":
              self.set('drivingLicenseD1', true);
              break;

            case "D1E":
              self.set('drivingLicenseD1E', true);
              break;

            case "D":
              self.set('drivingLicenseD', true);
              break;

            case "DE":
              self.set('drivingLicenseDE', true);
              break;
          }
        });
      }
    }),
    actions: {
      closeEditDrivingLicenseDialog: function () {
        this.closeEditDrivingLicenseDialog();
      },
      saveDrivingLicenseToJobOffer: function () {
        let driving = Ember.copy(this.Driving, true);
        let am = this.get('drivingLicenseAM'),
            a1 = this.get('drivingLicenseA1'),
            a2 = this.get('drivingLicenseA2'),
            a = this.get('drivingLicenseA'),
            b1 = this.get('drivingLicenseB1'),
            b = this.get('drivingLicenseB'),
            be = this.get('drivingLicenseBE'),
            c1 = this.get('drivingLicenseC1'),
            c1e = this.get('drivingLicenseC1E'),
            c = this.get('drivingLicenseC'),
            ce = this.get('drivingLicenseCE'),
            d1 = this.get('drivingLicenseD1'),
            d1e = this.get('drivingLicenseD1E'),
            d = this.get('drivingLicenseD'),
            de = this.get('drivingLicenseDE');

        if (am) {
          driving.Description.push("AM");
        }

        if (a1) {
          driving.Description.push("A1");
        }

        if (a2) {
          driving.Description.push("A2");
        }

        if (a) {
          driving.Description.push("A");
        }

        if (b1) {
          driving.Description.push("B1");
        }

        if (b) {
          driving.Description.push("B");
        }

        if (be) {
          driving.Description.push("BE");
        }

        if (c1) {
          driving.Description.push("C1");
        }

        if (c1e) {
          driving.Description.push("C1E");
        }

        if (c) {
          driving.Description.push("C");
        }

        if (ce) {
          driving.Description.push("CE");
        }

        if (d1) {
          driving.Description.push("D1");
        }

        if (d1e) {
          driving.Description.push("D1E");
        }

        if (d) {
          driving.Description.push("D");
        }

        if (de) {
          driving.Description.push("DE");
        }

        if (this.get('selectedTeamMember.skillsJson') != null) {
          if (this.get('selectedTeamMember.skillsJson.Driving') != null) {
            driving.ReferenceTo = this.get('selectedTeamMember.skillsJson.Driving.ReferenceTo') != null ? this.get('selectedTeamMember.skillsJson.Driving.ReferenceTo') : Ember.A([]);
          }
        }

        this.saveDrivingLicenseToJobOffer(driving);
      }
    }
  });

  _exports.default = _default;
});