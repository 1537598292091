define("E4E/components/superadmin/reports/results-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scrollContainer(event) {
      if (Math.ceil($("#search_container_reports_blog").scrollTop() + $("#search_container_reports_blog").outerHeight()) == $("#search_container_reports_blog")[0].scrollHeight) {
        if (!this.get('meta.last')) {
          this.nextPage();
        }
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      $("#search_container_reports_blog").on('scroll', this._boundScroll);
      Ember.run.debounce(this, 'scrollcontent', 1000);
    },

    scrollcontent() {
      if ($("#search_container_reports_blog").outerHeight() == $("#search_container_reports_blog")[0].scrollHeight) {
        if (!this.get('meta.last')) {
          this.nextPage();
          Ember.run.debounce(this, 'scrollcontent', 500);
        }
      }
    },

    actions: {
      selectReport: function (value) {
        this.selectReport(value);
      }
    }
  });

  _exports.default = _default;
});