define("E4E/controllers/job-seekers/dashboard", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    lastApplications: Ember.A([]),
    myJobOffers: Ember.A([]),
    jobSuggestions: Ember.A([]),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    jobApplicationService: Ember.inject.service("jobapplication"),
    jobOfferService: Ember.inject.service("joboffer"),
    currentUserService: Ember.inject.service("current-user"),
    categories: Ember.computed('currentUserService', function () {
      console.log(this.currentUserService);

      if (this.currentUserService.workExperience != null && this.currentUserService.user.workExperience != "") {
        let workExperienceJson = JSON.parse(this.currentUserService.user.workExperience);

        if (workExperienceJson.workExperiences != null) {
          this.getCategoriesTask.perform(workExperienceJson.workExperiences);
        }
      }
    }),
    status: ['ACCEPTED', 'PENDING', 'REJECTED'],
    types: ["FULL_TIME", "PART_TIME", "FULL_TIME_PART_TIME", "FREELANCER", "NONE"],
    statusJobOffer: ["ACTIVE", "FINISHED", "NONE"],
    init: function () {
      this._super(...arguments);
    },
    getMyApplications: (0, _emberConcurrency.task)(function* () {
      yield this.jobApplicationService.filterJobSeekers('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', this.get('status'), // need status or not?
      false, 0, 7).then(resp => {
        this.set('lastApplications', resp.jobApplications.toArray());
        this.set('lastApplicationsMeta', resp.meta);
      });
    }).enqueue(),
    getMyJobs: (0, _emberConcurrency.task)(function* () {
      yield this.jobOfferService.filter('', '', '', this.get('types'), '', '', '', '', '', '', '', '', '', '', '', '', '', '', true, this.currentUserService.get('user.id'), 0, 7).then(resp => {
        this.get('myJobOffers').pushObjects(resp.jobOffers.toArray());
        this.set('myJobOffersMeta', resp.meta);
      });
    }).enqueue(),
    getMySuggestions: (0, _emberConcurrency.task)(function* () {
      yield this.jobOfferService.filter('', '', '', this.get('types'), '', '', '', '', '', '', '', '', '', '', '', '', '', this.get('model.categories'), false, this.currentUserService.get('user.id'), 0, 7).then(resp => {
        this.get('jobSuggestions').pushObjects(resp.jobOffers.toArray());
        this.set('jobSuggestionsMeta', resp.meta);
      });
    }).restartable(),
    getCategories: (0, _emberConcurrency.task)(function* () {
      if (this.currentUserService.get('user.workExperience') != null && this.currentUserService.get('user.workExperience') != "") {
        let workExperienceJson = JSON.parse(this.currentUserService.get('user.workExperience'));

        if (workExperienceJson.workExperiences != null) {
          yield this.getCategoriesTask.perform(workExperienceJson.workExperiences);
        }
      }

      return this.get('model.categories');
    }).restartable(),
    getCategoriesTask: (0, _emberConcurrency.task)(function* (workExperiences) {
      let self = this;
      yield workExperiences.forEach(function (workExperience, index) {
        self.store.query('jobtitle', {
          filter: {
            name: workExperience.Position.Label,
            language: self.get('intl.locale')[0],
            page: 0,
            size: 1
          }
        }).then(resp => {
          if (resp != null && !Ember.isEmpty(resp)) {
            self.get('model.categories').push(resp.toArray()[0].category);
          }

          if (workExperiences.length === index + 1) {
            self.get('getMySuggestions').perform();
          }
        });
      });
    }).enqueue(),
    actions: {
      getData: function () {
        this.set('lastApplications', Ember.A([]));
        this.set('lastApplicationsMeta', null);
        this.set('jobSuggestions', Ember.A([]));
        this.set('jobSuggestionsMeta', null);
        this.set('myJobOffers', Ember.A([]));
        this.set('myJobOffersMeta', null);
        this.get('getMyApplications').perform();
        this.get('getMyJobs').perform();
        this.get('getCategories').perform();
      },
      goToJobApplications: function () {
        this.get('router').transitionTo("job-seekers.job-applications");
      },
      goToJobOffers: function () {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferFavourite', true);
        this.get('router').transitionTo("job-seekers.job-offers");
      },
      goToJobOffersCategory: function () {
        let cookieService = this.get('cookies');
        cookieService.write('categories', true);
        this.get('router').transitionTo("job-seekers.job-offers");
      },
      goToJobOffer: function (jobOffer) {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferId', jobOffer.id);
        cookieService.write('jobOfferTitle', jobOffer.title);
        this.get('router').transitionTo("job-seekers.job-offers.index");
        this.set('closeSwiper', true);
      }
    }
  });

  _exports.default = _default;
});