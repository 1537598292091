define("E4E/controllers/organizations/job-offers/index", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    jobOfferService: Ember.inject.service("joboffer"),
    jobOfferParams: ["image", "organization", "jobtitle", "jobapplications", "jobOfferQuestionBundles", "jobOfferEvaluationSchemeBundles", "publiclink", "contactcompany", "jobofferpdf"],
    dialogService: Ember.inject.service('dialog-service'),
    fileService: Ember.inject.service('file-service'),
    paperToaster: Ember.inject.service(),
    questionBundleService: Ember.inject.service('questionbundle'),
    session: Ember.inject.service(),
    showOverview: false,
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    deleteJobOfferDialog: false,
    init: function () {
      this._super(...arguments);

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      this.set('addJobOffer', false);
      this.set('editJobOffer', false);
      this.set('showOverview', false);
      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.jobOffers', Ember.A([]));
      this.set('model.meta', null);
      this.get('searchJobOffers').perform();
      this.set('addJobOffer', false);
      this.set('editJobOffer', false);
      this.set('showOverview', false);
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedJobOffer') != null || this.get('addJobOffer') || this.get('editJobOffer')) {
          if (Ember.$('#search_container_job_offers').hasClass('hide')) {
            Ember.$('#job-offer-container').addClass('show');
          } else {
            Ember.$('#search_container_job_offers').addClass('hide');
            Ember.$('#job-offer-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_job_offers').removeClass('hide');
          Ember.$('#job-offer-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_job_offers').removeClass('hide');
        Ember.$('#job-offer-container').removeClass('show');
      }
    },

    findJobOffer: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('joboffer', id, {
        include: this.jobOfferParams.toString()
      }).then(jobOffer => {
        this.set('addJobOffer', false);
        this.set('editJobOffer', false);
        this.set('model.selectedJobOffer', jobOffer);
        Ember.$('#job-offer-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').addClass('hide');
          Ember.$('#job-offer-container').addClass('show');
        }

        if (jobOffer.get('publicLink') != null) {
          this.set('model.publicLink', jobOffer.get('publicLink'));
          this.set('link', "https://" + window.location.host + '/public-joboffer-profile?token=' + jobOffer.get('publicLink.token'));
        }
      });
    }).restartable(),
    searchJobOffers: (0, _emberConcurrency.task)(function* (nextPage) {
      yield;
      this.jobOfferService.filterCompanies(this.get('model.jobOfferName'), this.get('model.countryName'), this.get('model.types'), this.get('model.city'), this.get('model.workExperience'), this.get('model.yearsExperience'), this.get('model.education'), this.get('model.languageName'), this.get('model.drivingLicenses'), this.get('model.competenceName'), this.get('model.status'), this.currentUserService.get('user.organizationId'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.jobOffers').pushObjects(resp.jobOffers.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.jobOffers', resp.jobOffers.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    actions: {
      resetFilters: function () {
        this.set('model.jobOfferName', '');
        this.set('model.jobSeeker', '');
        this.set('model.status', ['ACCEPTED', 'PENDING', 'REJECTED', 'NONE']);
        this.set('model.tagName', '');
        this.set('model.questionId', '');
        Ember.run.debounce(this, this.search, 1000);
      },
      updateJobOffer: function (value) {
        this.set('model.jobOfferName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCity: function (value) {
        this.set('model.city', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateWorkExperience: function (value) {
        this.set('model.workExperience', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducation: function (value) {
        this.set('model.education', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        if (value == null) {
          this.set('model.jobSeekerCountry', "");
        } else {
          this.set('model.jobSeekerCountry', value.iso3);
        }

        this.set('model.country', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguage: function (value) {
        this.set('model.languageName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateYearsExperience: function (value) {
        if (value == null) {
          this.set('model.yearsExperience', "");
        } else {
          this.set('model.yearsExperience', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateDrivingLicense: function (value) {
        if (this.get('model.drivingLicenses').includes(value)) {
          this.get('model.drivingLicenses').removeObject(value);
        } else {
          this.get('model.drivingLicenses').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompetences: function (value) {
        this.set('model.competenceName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateStatus: function (value) {
        if (this.get('model.status').includes(value)) {
          this.get('model.status').removeObject(value);
        } else {
          this.get('model.status').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateTypes: function (value) {
        if (this.get('model.types').includes(value)) {
          this.get('model.types').removeObject(value);
        } else {
          this.get('model.types').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      searchJobOffers: function () {
        this.get("searchJobOffers").perform();
      },
      searchAndFindJobOffer: function (id) {
        this.get("searchJobOffers").perform();
        this.get("findJobOffer").perform(this.get('model.jobOfferId'));
      },
      selectJobOffer: function (jobOfferId) {
        Ember.$('#job-offer-container').scrollTop(0);
        this.get("findJobOffer").perform(jobOfferId);
        this.set('showOverview', false);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchJobOffers').perform(true);
      },
      backToSearch: function () {
        this.set('addJobOffer', false);
        this.set('editJobOffer', false);
        this.set('model.selectedJobOffer', null);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').removeClass('hide');
          Ember.$('#job-offer-container').removeClass('show');
        }
      },
      addJobOffer: function () {
        this.set('addJobOffer', true);
        this.set('editJobOffer', false);
        Ember.$('#job-offer-container').scrollTop(0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').addClass('hide');
          Ember.$('#job-offer-container').addClass('show');
        }
      },
      editJobOffer: function () {
        this.set('addJobOffer', false);
        this.set('editJobOffer', true);
        Ember.$('#job-offer-container').scrollTop(0);
        let params = {
          jobOfferTitle: this.get('model.selectedJobOffer.title'),
          profilePicture: this.get('model.selectedJobOffer.profilePicture'),
          jobTitle: this.get('model.selectedJobOffer.jobTitleDto'),
          deadline: moment.utc(moment.utc(this.get('model.selectedJobOffer.deadline')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString(),
          description: this.get('model.selectedJobOffer.description'),
          qualifications: this.get('model.selectedJobOffer.qualificationsJson'),
          skillsAdded: this.get('model.selectedJobOffer.skillsJson') != null ? this.get('model.selectedJobOffer.skillsJson') : Ember.A([]),
          yearsExperience: this.get('model.selectedJobOffer.yearsExperience'),
          addedLanguages: this.get('model.selectedJobOffer.languagesJson'),
          drivingLicenses: this.get('model.selectedJobOffer.drivingLicenseJson'),
          travel: this.get('model.selectedJobOffer.travel'),
          etype: this.get('model.selectedJobOffer.etype'),
          city: this.get('model.selectedJobOffer.city'),
          country: this.get('model.selectedJobOffer.country'),
          hoursWeek: this.get('model.selectedJobOffer.hoursWeek'),
          startDate: moment.utc(moment.utc(this.get('model.selectedJobOffer.startDate')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString(),
          salary: this.get('model.selectedJobOffer.salary'),
          duration: this.get('model.selectedJobOffer.contractDuration'),
          formalEducation: this.get('model.selectedJobOffer.formalEducationJson'),
          questionBundlesTemp: this.get('model.selectedJobOffer.questionBundlesTemp'),
          questionBundlesTempEval: this.get('model.selectedJobOffer.questionBundlesTempEval'),
          showCompanyFor: this.get('model.selectedJobOffer.showCompanyFor'),
          companyName: this.get('model.selectedJobOffer.companyName'),
          forCompany: this.get('model.selectedJobOffer.companyName') != null ? true : false,
          deletedJobOfferPdf: false,
          jobOfferPdfFile: this.get('model.selectedJobOffer.jobOfferPdfFile'),
          healthStatus: this.get('model.selectedJobOffer.healthStatus'),
          specialConditions: this.get('model.selectedJobOffer.specialConditions')
        };
        this.set('params', params);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').addClass('hide');
          Ember.$('#job-offer-container').addClass('show');
        }
      },
      saveJobOffer: function (params) {
        let self = this;
        let jobOffer = this.store.createRecord('joboffer');
        jobOffer.set('title', params.title);
        jobOffer.set('jobTitleId', params.jobTitleId);
        jobOffer.set('deadline', moment.utc(moment.utc(params.deadline).format('YYYY/MM/DD HH:mm'), 'YYYY/MM/DD HH:mm').endOf('day').toDate());
        jobOffer.set('description', params.description);
        jobOffer.set('qualifications', params.qualifications);
        jobOffer.set('formalEducation', params.formalEducation);
        jobOffer.set('skills', params.skills);
        jobOffer.set('yearsExperience', params.yearsExperience);
        jobOffer.set('drivingLicense', params.drivingLicense);
        jobOffer.set('languages', params.languages);
        jobOffer.set('etype', params.etype);
        jobOffer.set('city', params.city);
        jobOffer.set('country', params.country);
        jobOffer.set('hoursWeek', params.hoursWeek);
        jobOffer.set('travel', params.travel);
        jobOffer.set('startDate', params.startDate != null ? moment.utc(moment.utc(params.startDate).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate() : null);
        jobOffer.set('salary', params.salary);
        jobOffer.set('contractDuration', params.contractDuration);
        jobOffer.set('authorId', this.get('currentUserService.user.id'));
        jobOffer.set('organizationId', this.get('currentUserService.user.organizationId'));
        jobOffer.set('privateJobOffer', false);
        jobOffer.set('healthStatus', params.healthStatus);
        jobOffer.set('specialConditions', params.specialConditions);

        if (params.forCompany) {
          jobOffer.set('showCompanyFor', params.showCompanyFor);
          jobOffer.set('companyName', params.companyName);
          jobOffer.set('forCompany', true);
        } else {
          jobOffer.set('forCompany', false);
        }

        if (params.jobOfferPdfFile != null) {
          this.fileService.get('uploadJobOfferPdf').perform(params.jobOfferPdfFile).then(pdf => {
            jobOffer.set('jobOfferPdfId', pdf.get('id'));
            self.jobOfferService.save(jobOffer, self.jobOfferParams).then(() => {
              self.questionBundleService.addBundlesToJobOffer(jobOffer.id, params.questionBundlesTemp, params.questionBundlesTempEval);

              if (params.image != null) {
                self.fileService.get('uploadJobOfferImage').perform(params.image, jobOffer);
              }

              self.set('model.page', 0);
              self.get("searchJobOffers").perform();
              self.send('cancelJobOffer');
              Ember.$('#job-offer-container').scrollTop(0);
            });
          });
        } else {
          self.jobOfferService.save(jobOffer, self.jobOfferParams).then(() => {
            self.questionBundleService.addBundlesToJobOffer(jobOffer.id, params.questionBundlesTemp, params.questionBundlesTempEval);

            if (params.image != null) {
              self.fileService.get('uploadJobOfferImage').perform(params.image, jobOffer);
            }

            self.set('model.page', 0);
            self.get("searchJobOffers").perform();
            self.send('cancelJobOffer');
            Ember.$('#job-offer-container').scrollTop(0);
          });
        }
      },
      saveEditJobOffer: function (params) {
        let self = this;
        this.set('model.selectedJobOffer.title', params.title);
        this.set('model.selectedJobOffer.jobTitleId', params.jobTitleId);
        this.set('model.selectedJobOffer.deadline', moment.utc(moment.utc(params.deadline).format('YYYY/MM/DD HH:mm'), 'YYYY/MM/DD HH:mm').endOf('day').toDate());
        this.set('model.selectedJobOffer.description', params.description);
        this.set('model.selectedJobOffer.qualifications', params.qualifications);
        this.set('model.selectedJobOffer.formalEducation', params.formalEducation);
        this.set('model.selectedJobOffer.skills', params.skills.toString());
        this.set('model.selectedJobOffer.yearsExperience', params.yearsExperience);
        this.set('model.selectedJobOffer.drivingLicense', params.drivingLicense.toString());
        this.set('model.selectedJobOffer.languages', params.languages);
        this.set('model.selectedJobOffer.etype', params.etype);
        this.set('model.selectedJobOffer.city', params.city);
        this.set('model.selectedJobOffer.country', params.country);
        this.set('model.selectedJobOffer.hoursWeek', params.hoursWeek);
        this.set('model.selectedJobOffer.travel', params.travel);
        this.set('model.selectedJobOffer.startDate', params.startDate != null ? moment.utc(moment.utc(params.startDate).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate() : null);
        this.set('model.selectedJobOffer.salary', params.salary);
        this.set('model.selectedJobOffer.contractDuration', params.contractDuration);
        this.set('model.selectedJobOffer.privateJobOffer', false);
        this.set('model.selectedJobOffer.healthStatus', params.healthStatus);
        this.set('model.selectedJobOffer.specialConditions', params.specialConditions);

        if (params.forCompany) {
          this.set('model.selectedJobOffer.showCompanyFor', params.showCompanyFor);
          this.set('model.selectedJobOffer.companyName', params.companyName);
          this.set('model.selectedJobOffer.forCompany', true);
        } else {
          this.set('model.selectedJobOffer.forCompany', false);
        }

        if (params.deletedJobOfferPdf) {
          let jobOfferPdfDelete = this.get("model.selectedJobOffer.jobOfferPdfId");

          if (params.jobOfferPdfFile != null) {
            this.fileService.get('uploadJobOfferPdf').perform(params.jobOfferPdfFile).then(pdf => {
              self.set('model.selectedJobOffer.jobOfferPdfId', pdf.get('id'));
              self.jobOfferService.save(self.get('model.selectedJobOffer'), self.jobOfferParams).then(() => {
                self.questionBundleService.addBundlesToJobOffer(self.get('model.selectedJobOffer.id'), params.questionBundlesTemp, params.questionBundlesTempEval);

                if (params.image != null) {
                  self.fileService.get('uploadJobOfferImage').perform(params.image, self.get('model.selectedJobOffer'));
                }

                if (jobOfferPdfDelete != null) {
                  self.store.findRecord('jobofferpdf', jobOfferPdfDelete).then(pdfOld => {
                    pdfOld.destroyRecord();
                  });
                }

                self.set('model.page', 0);
                self.get("searchJobOffers").perform();
                self.send('cancelJobOffer');
                Ember.$('#job-offer-container').scrollTop(0);
              });
            });
          } else {
            self.set('model.selectedJobOffer.jobOfferPdfId', null);
            self.jobOfferService.save(this.get('model.selectedJobOffer'), self.jobOfferParams).then(() => {
              self.questionBundleService.addBundlesToJobOffer(self.get('model.selectedJobOffer.id'), params.questionBundlesTemp, params.questionBundlesTempEval);

              if (params.image != null) {
                self.fileService.get('uploadJobOfferImage').perform(params.image, self.get('model.selectedJobOffer'));
              }

              if (jobOfferPdfDelete != null) {
                self.store.findRecord('jobofferpdf', jobOfferPdfDelete).then(pdfOld => {
                  pdfOld.destroyRecord();
                });
              }

              self.set('model.page', 0);
              self.get("searchJobOffers").perform();
              self.send('cancelJobOffer');
              Ember.$('#job-offer-container').scrollTop(0);
            });
          }
        } else {
          this.jobOfferService.save(this.get('model.selectedJobOffer'), this.jobOfferParams).then(() => {
            this.questionBundleService.addBundlesToJobOffer(this.get('model.selectedJobOffer.id'), params.questionBundlesTemp, params.questionBundlesTempEval).then(() => {
              this.get("findJobOffer").perform(this.get('model.selectedJobOffer.id'));
            });

            if (params.image != null) {
              this.fileService.get('uploadJobOfferImage').perform(params.image, this.get('model.selectedJobOffer'));
            }

            this.set('model.page', 0);
            this.get("searchJobOffers").perform();
            this.send('cancelJobOffer');
            Ember.$('#job-offer-container').scrollTop(0);
          });
        }
      },
      cancelJobOffer: function () {
        this.set('addJobOffer', false);
        this.set('editJobOffer', false);
        Ember.$('#job-offer-container').scrollTop(0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').removeClass('hide');
          Ember.$('#job-offer-container').removeClass('show');
        }
      },
      generatePublicLink: function () {
        this.set('generatePublicLinkDialog', true);
      },
      generateLink: function (expires) {
        let self = this;
        let expiresNew = moment.utc(moment.utc(expires).format('YYYY/MM/DD'), 'YYYY/MM/DD').add({
          'hours': 23,
          'minuts': 59,
          'seconds': 59
        }).toDate();
        Ember.$.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: this.get('serverUrl') + "/joboffers/generate/shareable/profile/token",
          contentType: "application/json",
          data: JSON.stringify({
            jobOfferId: this.get('model.selectedJobOffer.id'),
            expires: expiresNew
          }),
          success: function (resp) {
            self.set('model.publicLink', resp);
            self.set('link', "https://" + window.location.host + '/public-joboffer-profile?token=' + resp);
          }
        });
      },
      closeGeneratePublicLinkDialog: function () {
        this.set('generatePublicLinkDialog', false);
      },
      goToOverview: function () {
        Ember.$('#job-offer-container').scrollTop(0);
        this.set('showOverview', true);
      },
      closeOverview: function () {
        Ember.$('#job-offer-container').scrollTop(0);
        this.set('showOverview', false);
      },
      openDeleteJobOfferDialog: function () {
        this.set('deleteJobOfferDialog', true);
      },
      closeDeleteJobOfferDialog: function () {
        this.set('deleteJobOfferDialog', false);
      },
      deleteJobOffer: function () {
        let jobOffer = this.get('model.selectedJobOffer');
        jobOffer.destroyRecord().then(() => {
          this.get('paperToaster').show(this.intl.t('label.jobofferdelete.success'), {
            duration: 3000
          });
          this.set('deleteJobOfferDialog', false);
          this.set('model.selectedJobOffer', null);
          this.get("searchJobOffers").perform();
          Ember.$('#job-offer-container').scrollTop(0);
        });
      },
      downloadJobOfferPdf: function () {
        let self = this;
        this.get('store').findRecord('file', this.get('model.selectedJobOffer.jobOfferPdfFile.fileId')).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(self.get('model.selectedJobOffer.jobOfferPdfFile.originalFileName'), pdfContent, 'application/pdf'));
        });
      }
    }
  });

  _exports.default = _default;
});