define("E4E/controllers/public-joboffer-profile", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    init: function () {
      this._super(...arguments);

      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('locale', cookie.toString());
      } else {
        this.set('locale', 'en');
      }
    },
    competences: Ember.computed('model.jobOffer.skillsJson.@each', 'model.jobOffer.formalEducationJson.@each', function () {
      let competencesFinal = Ember.A([]);

      if (this.get('model.jobOffer.skillsJson') != null) {
        this.get('model.jobOffer.skillsJson').forEach(function (competence) {
          competencesFinal.pushObject(competence);
        });
      }

      if (this.get('model.jobOffer.formalEducationJson')) {
        this.get('model.jobOffer.formalEducationJson').forEach(function (education) {
          let competences = education.Competences;
          competences.forEach(function (competence) {
            let temp = competencesFinal.filter(function (comp) {
              if (comp != undefined) {
                return comp.name == competence.name;
              }
            });

            if (temp == null || temp.length <= 0) {
              competencesFinal.pushObject(competence);
            }
          });
        });
      }

      return competencesFinal;
    }),
    actions: {
      register: function () {},
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },
      changeLanguage: function (newLocale) {
        this.cookies.write('locale', newLocale.toString());
        this.intl.set('locale', newLocale.toLowerCase());
        this.set('locale', newLocale);
      }
    }
  });

  _exports.default = _default;
});