define("E4E/components/users/change-partner", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    requests: Ember.computed('user.requests.@each', function () {
      let requests = this.get('user.requests').filter(function (request) {
        return request.get('requestType') == "REQUEST_JOB_SEEKER_PARTNER" && request.get('status') == "PENDING";
      });
      return requests;
    }),
    actions: {
      requestNewPartner: function (partner) {
        this.requestNewPartner(partner);
      },
      cancelChangePartner: function () {
        this.cancelChangePartner();
      }
    }
  });

  _exports.default = _default;
});