define("E4E/controllers/organizations/question-bundles/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    questionBundleParams: ["questions", "joboffers"],
    screen: Ember.inject.service('screen'),
    questionBundleService: Ember.inject.service('questionbundle'),
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.questionBundles', Ember.A([]));
      this.set('model.page', 0);
      this.set('model.meta', null);
      this.get('searchQuestionBundles').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedQuestionBundle') != null || this.get('addQuestionBundle') || this.get('editQuestionBundle')) {
          if (Ember.$('#search_container_question_bundles').hasClass('hide')) {
            Ember.$('#question_bundle-container').addClass('show');
          } else {
            Ember.$('#search_container_question_bundles').addClass('hide');
            Ember.$('#question_bundle-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_question_bundles').removeClass('hide');
          Ember.$('#question_bundle-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_question_bundles').removeClass('hide');
        Ember.$('#question_bundle-container').removeClass('show');
      }
    },

    searchQuestionBundles: (0, _emberConcurrency.task)(function* (nextPage) {
      this.questionBundleService.filterCompanies(this.get('model.bundleName'), this.get('model.bundleTypes'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.questionBundles').pushObjects(resp.questionBundles.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.questionBundles', resp.questionBundles.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    findQuestionBundle: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('questionbundle', id, {
        include: this.questionBundleParams.toString()
      }).then(questionBundle => {
        this.set('addQuestionBundle', false);
        this.set('editQuestionBundle', false);
        this.set('model.selectedQuestionBundle', questionBundle);
        Ember.$('#question_bundle-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_question_bundles').addClass('hide');
          Ember.$('#question_bundle-container').addClass('show');
        }
      });
    }).restartable(),
    actions: {
      searchQuestionBundles: function () {
        this.get("searchQuestionBundles").perform();
      },
      updateBundle: function (value) {
        this.set('model.bundleName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateTypes: function (value) {
        if (this.get('model.bundleTypes').includes(value)) {
          this.get('model.bundleTypes').removeObject(value);
        } else {
          this.get('model.bundleTypes').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      selectQuestionBundle: function (id) {
        this.get('findQuestionBundle').perform(id);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchQuestionBundles').perform(true);
      },
      backToSearch: function () {
        this.set('addQuestionBundle', false);
        this.set('editQuestionBundle', false);
        this.set('model.selectedQuestionBundle', null);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_question_bundles').removeClass('hide');
          Ember.$('#question_bundle-container').removeClass('show');
        }
      },
      editQuestionBundle: function () {
        this.set('addQuestionBundle', false);
        this.set('editQuestionBundle', true);
      },
      addNewBundle: function () {
        this.set('addQuestionBundle', true);
        this.set('editQuestionBundle', false);
      },
      saveQuestionBundle: function (params) {
        let self = this;
        let questionBundle = this.store.createRecord('questionbundle');
        questionBundle.set('name', params.name);
        questionBundle.set('bundleType', params.bundleType);
        questionBundle.set('organizationId', this.get('currentUserService.user.organizationId'));
        this.questionBundleService.save(questionBundle, null).then(questionBundleNew => {
          params.questions.forEach(function (question) {
            question.set('questionBundleId', questionBundleNew.get('id'));
            question.save();
          });
          Ember.run.debounce(self, self.search, 1000);
          self.send('cancelQuestionBundle');
        });
      },
      updateQuestionBundle: function (questionBundle) {
        let self = this;
        this.questionBundleService.save(questionBundle, this.get('questionBundleParams')).then(() => {
          Ember.run.debounce(self, self.search, 1000);
          self.set('editQuestionBundle', false);
          self.get('findQuestionBundle').perform(questionBundle.get('id'));
        });
      },
      addQuestion: function (params) {},
      cancelQuestionBundle: function () {
        this.set('addQuestionBundle', false);
        this.set('editQuestionBundle', false);
      },
      deleteQuestionBundle: function () {
        this.questionBundleService.delete(this.get('model.selectedQuestionBundle'));
        this.set('model.selectedQuestionBundle', null);
        this.set('addQuestionBundle', false);
        this.set('editQuestionBundle', false);
        Ember.run.debounce(this, this.search, 1000);
      }
    }
  });

  _exports.default = _default;
});