define("E4E/components/superadmin/reports/search-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reportsName: "",
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      updateReports: function (value) {
        this.set("reportsName", value);
        this.updateReports(value);
      }
    }
  });

  _exports.default = _default;
});