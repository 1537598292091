define("E4E/templates/organizations/job-offers/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhmRyF68",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/organizations/job-offers/overview.hbs"
    }
  });

  _exports.default = _default;
});