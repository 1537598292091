define("E4E/components/dialogs/joboffers/add-language", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ForeignLanguage: {
      Description: {
        Code: "",
        Label: ""
      },
      ProficiencyLevel: {
        Listening: "",
        Reading: "",
        SpokenInteraction: "",
        SpokenProduction: "",
        Writing: ""
      },
      Scope: {
        Label: "",
        Value: "",
        DateIssue: ""
      },
      ReferenceTo: Ember.A([])
    },
    MotherToungue: {
      Description: {
        Code: "",
        Label: ""
      },
      ReferenceTo: Ember.A([])
    },
    motherTongue: false,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    allReferences: Ember.A([]),
    certificates: Ember.A([]),
    language: null,
    listeningLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    readingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenInteractionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenProductionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    writingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    scopes: ["ECTS", "HOURS", "DAYS"],
    init: function () {
      this._super(...arguments);

      this.languageMatcher = this.languageMatcher.bind(this);
      this.get('getLanguages').perform();
    },
    getLanguages: (0, _emberConcurrency.task)(function* () {
      let languages = yield $.getJSON("/json-components/languages/languages_en.json");
      this.set('languages', languages);
    }),
    languageMatcher: function (language, term) {
      const ret = `${this.get('intl').t('label.languagesnames.' + language.Code.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      cancelAddLanguage: function () {
        this.cancelAddLanguage();
      },
      saveLanguageToJobOffer: function () {
        if (this.get('motherTongue')) {
          let motherTongue = Ember.copy(this.MotherToungue, true);
          motherTongue.Description.Label = this.get('language.Name');
          motherTongue.Description.Code = this.get('language.Code');
          this.saveLanguageToJobOffer(motherTongue, this.get('motherTongue'), null);
        } else {
          let foreignLanguage = Ember.copy(this.ForeignLanguage, true);
          foreignLanguage.Description.Label = this.get('language.Name');
          foreignLanguage.Description.Code = this.get('language.Code');
          foreignLanguage.ProficiencyLevel.Listening = this.get('listeningLevel');
          foreignLanguage.ProficiencyLevel.Reading = this.get('readingLevel');
          foreignLanguage.ProficiencyLevel.SpokenInteraction = this.get('spokenInteractionLevel');
          foreignLanguage.ProficiencyLevel.SpokenProduction = this.get('spokenProductionLevel');
          foreignLanguage.ProficiencyLevel.Writing = this.get('writingLevel');
          foreignLanguage.Scope.Label = this.get('scope');
          foreignLanguage.Scope.Value = this.get('number');

          if (this.get('dateIssue') != undefined && this.get('dateIssue') != null && this.get('dateIssue') != "") {
            foreignLanguage.Scope.DateIssue = moment.utc(moment.utc(this.get('dateIssue')).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate();
          }

          this.saveLanguageToJobOffer(foreignLanguage, this.get('motherTongue'), null);
        }
      }
    }
  });

  _exports.default = _default;
});