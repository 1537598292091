define("E4E/models/new-company-member", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    // User section
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      }), (0, _emberCpValidations.validator)('email-available')]
    },
    emailConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'email',
      message: 'Email addresses do not match'
    }),
    password: {
      description: 'Password',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 8
      })]
    },
    passwordConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    })
  }, {
    debounce: 500
  });

  var _default = _emberData.default.Model.extend(Validations, {
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    emailConfirmation: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('date'),
    website: _emberData.default.attr('string'),
    profession: _emberData.default.attr('string'),
    organization: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    profileCompanies: false,
    videoCompanies: false,
    editCompanyProfile: true,
    createJobOffers: true,
    editOwnJobOffers: true,
    editAllJobOffers: true,
    acceptOwnJobApplications: true,
    acceptAllJobApplications: true,
    denyOwnJobApplications: true,
    denyAllJobApplications: true,
    locale: _emberData.default.attr('string'),
    host: _emberData.default.attr('string'),
    partnerId: _emberData.default.attr('string')
  });

  _exports.default = _default;
});