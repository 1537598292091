define("E4E/helpers/active-joboffers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activeJobOffers = activeJobOffers;
  _exports.default = void 0;

  function activeJobOffers(params) {
    return params[0].filter(item => Date.parse(item.deadline) >= new Date()).length;
  }

  var _default = Ember.Helper.helper(activeJobOffers);

  _exports.default = _default;
});