define("E4E/components/curriculumvitaes/find-certificates", ["exports", "E4E/config/environment", "ember-concurrency", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _emberConcurrency, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fileSaver.default, {
    store: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    referenceTo: Ember.A([]),
    certificates: Ember.A([]),
    showAttachments: false,
    certificatesArray: Ember.computed('referenceTo.@each', 'certificates.@each', function () {
      let certificatesArray = Ember.A([]);

      if (this.get('referenceTo') != null) {
        this.get('referenceTo').forEach(element => {
          if (this.get('certificates') != null) {
            this.get('certificates').filter(function (certificate) {
              if (certificate.get('europassCertificateId') == element.idref) {
                certificatesArray.pushObject(certificate);
              }
            });
          }
        });
        return certificatesArray;
      }
    }),
    init: function () {
      this._super(...arguments);

      this.get('getCertificates').perform();
    },
    getCertificates: (0, _emberConcurrency.task)(function* () {
      let certificatesArray = Ember.A([]);

      if (this.get('referenceTo') != null) {
        this.get('referenceTo').forEach(element => {
          if (this.get('certificates') != null) {
            this.get('certificates').filter(function (certificate) {
              if (certificate.get('europassCertificateId') === element.idref) {
                certificatesArray.pushObject(certificate);
              }
            });
          }
        });
        this.set('certificatesArray', certificatesArray);
      }
    }),

    didUpdateAttrs() {
      this.get('getCertificates').perform();
    },

    actions: {
      downloadCV: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, record.get('mimeType')));
        });
      },
      showAttachments: function () {
        this.set('showAttachments', true);
      }
    }
  });

  _exports.default = _default;
});