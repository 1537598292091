define("E4E/components/users/edit-digitalskills", ["exports", "ember-concurrency", "ember-data"], function (_exports, _emberConcurrency, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    allReferences: Ember.A([]),
    certificates: Ember.computed('selectedTeamMember.skillsJson.Computer.ReferenceTo.@each', 'selectedTeamMember.certificates.@each', function () {
      let certificatesArray = Ember.A([]);

      if (this.get('selectedTeamMember.skillsJson.Computer.ReferenceTo') != null) {
        this.get('selectedTeamMember.skillsJson.Computer.ReferenceTo').forEach(element => {
          this.get('selectedTeamMember.certificates').filter(function (certificate) {
            if (certificate.get('europassCertificateId') == element.idref) {
              certificatesArray.pushObject(certificate);
            }
          });
        });
        return certificatesArray;
      }
    }),
    informationProccessLevels: ["A", "B", "C"],
    communicationLevels: ["A", "B", "C"],
    contentCreationLevels: ["A", "B", "C"],
    securityLevels: ["A", "B", "C"],
    problemSolvingLevels: ["A", "B", "C"],
    Computer: {
      ProficiencyLevel: {
        Information: "",
        Communication: "",
        ContentCreation: "",
        Safety: "",
        ProblemSolving: ""
      },
      ReferenceTo: Ember.A([])
    },
    deletedCertificates: Ember.A([]),
    init: function () {
      this._super(...arguments);

      this.get('setData').perform();
    },
    setData: (0, _emberConcurrency.task)(function* () {
      this.set('deletedCertificates', Ember.A([]));
    }),
    actions: {
      closeEditDigitalSkillsDialog: function () {
        this.closeEditDigitalSkillsDialog();
      },
      saveDigitalSkills: function () {
        let computer = Ember.copy(this.Computer, true);
        computer.ProficiencyLevel.Information = this.get('informationProccessLevel');
        computer.ProficiencyLevel.Communication = this.get('communicationLevel');
        computer.ProficiencyLevel.ContentCreation = this.get('contentCreationLevel');
        computer.ProficiencyLevel.Safety = this.get('securityLevel');
        computer.ProficiencyLevel.ProblemSolving = this.get('problemSolvingLevel');

        if (this.get('selectedTeamMember.skillsJson') != null) {
          if (this.get('selectedTeamMember.skillsJson.Computer') != null) {
            computer.ReferenceTo = this.get('selectedTeamMember.skillsJson.Computer.ReferenceTo') != null ? this.get('selectedTeamMember.skillsJson.Computer.ReferenceTo') : Ember.A([]);
          }
        }

        this.saveDigitalSkills(computer, this.certificates, this.deletedCertificates);
      },
      didSelectFilesAttachment: function (files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          files[0].originalFileName = files[0].name;
          this.get('certificates').pushObject(files[0]);

          let done = function (url) {
            self.set('fileUrl', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      removeCertificate: function (certificate) {
        if (certificate instanceof Model) {
          this.get('certificates').removeObject(certificate);
          this.get('deletedCertificates').pushObject(certificate);
        } else {
          this.get('certificates').removeObject(certificate);
        }
      }
    }
  });

  _exports.default = _default;
});