define("E4E/components/job-seekers/job-offers/joboffer-preview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    store: Ember.inject.service(),
    hasFavorite: Ember.computed('selectedJobOffer', 'currentUserService.user.flags.@each', function () {
      let self = this;

      if (this.get('currentUserService.user.flags') != null) {
        return this.get('currentUserService.user.flags').filter(function (flag) {
          return flag.jobOfferId == self.get('selectedJobOffer.id');
        });
      } else {
        return false;
      }
    }),
    hasApplication: Ember.computed('selectedJobOffer', 'currentUserService.user', function () {
      let self = this;
      let jobApplication = this.currentUserService.get('user.jobApplications').filter(function (jobApplication) {
        return jobApplication.jobOfferId == self.get('selectedJobOffer.id');
      });

      if (jobApplication.length > 0) {
        return true;
      } else {
        return false;
      }
    }),
    competences: Ember.computed('selectedJobOffer.skillsJson.@each', 'selectedJobOffer.formalEducationJson.@each', function () {
      let competencesFinal = Ember.A([]);
      this.get('selectedJobOffer.skillsJson').forEach(function (competence) {
        competencesFinal.pushObject(competence);
      });
      this.get('selectedJobOffer.formalEducationJson').forEach(function (education) {
        let competences = education.Competences;
        competences.forEach(function (competence) {
          let temp = competencesFinal.filter(function (comp) {
            if (comp != undefined) {
              return comp.name == competence.name;
            }
          });

          if (temp == null || temp.length <= 0) {
            competencesFinal.pushObject(competence);
          }
        });
      });
      return competencesFinal;
    }),
    actions: {
      backToSearch: function () {
        this.backToSearch();
      },
      applyJobSeeker: function () {
        this.applyJobSeeker();
      },
      addToFavorite: function () {
        this.addToFavorite();
      },
      removeFromFavorite: function () {
        this.removeFromFavorite(this.hasFavorite);
      }
    }
  });

  _exports.default = _default;
});