define("E4E/services/user", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    filterJobSeekersFromProject: function (partnerId, name, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/users/search/filterJobSeekersFromProject?" + "name=" + name.toLowerCase() + "&partnerId=" + partnerId + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterJobSeekersOfPartner: function (partnerId, name, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/users/search/filterJobSeekersOfPartner?" + "name=" + name.toLowerCase() + "&partnerId=" + partnerId + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterOutplacersOfPartner: function (partnerId, name, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/users/search/filterOutplacersOfPartner?" + "name=" + name.toLowerCase() + "&partnerId=" + partnerId + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterSuperAdmin: function (name, organization, countryName, roles, status, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/users/search/filterSuperAdmin?" + "name=" + name.toLowerCase() + "&organization=" + organization.toLowerCase() + "&role=" + roles.toString() + "&status=" + status.toString() + "&country=" + countryName + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filter: function (name, roles, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/users/search/filter?" + "name=" + name.toLowerCase() + "&organizationId=" + this.get('currentUserService.user.organizationId') + "&role=" + roles.toString() + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterJobSeekers: function (name, country, organizationId, favorite, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, outplacerId, page, size) {
      return Ember.RSVP.resolve(this.authorizedGet("/users/search/filterCompanies?" + "name=" + name.toLowerCase() + "&country=" + country + "&organizationId=" + organizationId + "&favorite=" + favorite + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&outplacerId=" + outplacerId + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterForJobSeekers: function (name, country, city, workExperience, education, languageName, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/users/search/filterForJobSeekers?" + "name=" + name.toLowerCase() + "&country=" + country + "&city=" + city + "&workExperience=" + workExperience.toString() + "&education=" + education.toString() + "&languageName=" + languageName.toString() + "&userId=" + this.currentUserService.get('user.id') + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    save: function (user, userParams) {
      let self = this;
      return Ember.RSVP.resolve(user.save({
        include: userParams.toString()
      })).then(() => {
        self.get('paperToaster').show(self.intl.t('label.user.saved'), {
          duration: 3000
        });
      }).catch(error => {
        self.get('paperToaster').show(self.intl.t('label.user.error'), {
          duration: 3000
        });
        return error;
      });
    },

    load(id, userParams) {
      return this.store.findRecord('user', id, {
        include: userParams.toString()
      });
    },

    checkCanDelete: function (userId) {
      return Ember.RSVP.resolve(this.authorizedGet("/users/candelete/" + userId)).then(resp => {
        return resp;
      }).catch(error => {
        return error;
      });
    },
    filterForMessenger: function (name, page, size) {
      return Ember.RSVP.resolve(this.authorizedGet("/users/search/filterForMessenger?" + "nameTerm=" + name.toLowerCase() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          users: null,
          meta: null
        };
        searchResponse.users = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    }
  });

  _exports.default = _default;
});