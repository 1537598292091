define("E4E/templates/components/blog/blog-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "efWSx/TF",
    "block": "{\"symbols\":[\"blogPost\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"blogPosts\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"blog/post-card\",null,[[\"link\",\"blogPost\",\"currentUserService\",\"addPost\",\"deletePost\",\"saveReport\"],[true,[22,1,[]],[23,[\"currentUserService\"]],[27,\"action\",[[22,0,[]],\"addPost\"],null],[27,\"action\",[[22,0,[]],\"deletePost\"],null],[27,\"action\",[[22,0,[]],\"saveReport\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/blog/blog-scroll.hbs"
    }
  });

  _exports.default = _default;
});