define("E4E/components/dialog-handler", ["exports", "E4E/templates/components/dialog-handler"], function (_exports, _dialogHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dialogHandler.default,
    tagName: '',
    dialogService: Ember.inject.service(),
    activeDialog: Ember.computed.reads('dialogService.activeDialog'),
    onClose: function (dialog) {
      this.get('dialogService').close(dialog);
    }
  });

  _exports.default = _default;
});