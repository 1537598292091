define("E4E/templates/components/upload-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UGg7rOAW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"md-toast-text margin-right-8\"],[9],[1,[27,\"t\",[\"label.uploading\"],null],false],[0,\" \"],[1,[23,[\"fileManager\",\"file\",\"name\"]],false],[10],[0,\"\\n  \"],[1,[27,\"paper-progress-circular\",null,[[\"value\",\"diameter\"],[[23,[\"fileManager\",\"progress\"]],30]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/upload-toast.hbs"
    }
  });

  _exports.default = _default;
});