define("E4E/helpers/jobapplications-haslanguages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      let self = this;
      let motherTongue = params[1].get('skillsCompetencesJson.Linguistic.MotherTongue');
      let foreignLanguage = params[1].get('skillsCompetencesJson.Linguistic.ForeignLanguage');
      let languages = Ember.A([]);
      languages.pushObjects(params[0].get('languagesJson.MotherTongue'));
      languages.pushObjects(params[0].get('languagesJson.ForeignLanguage'));
      let languagesUsed = Ember.A([]);
      languages.forEach(function (language) {
        if (motherTongue != null) {
          let languageTemp = motherTongue.filterBy('Description.Label', language.Description.Label);

          if (languageTemp.length > 0) {
            let languageNew = languageTemp[0];
            languageNew.Native = true;
            languagesUsed.pushObject(languageNew);
          }
        }

        if (foreignLanguage != null) {
          let languageTemp2 = foreignLanguage.filterBy('Description.Label', language.Description.Label);

          if (languageTemp2.length > 0) {
            let languageNew = languageTemp2[0];
            let avaliation = 0;
            let listeningNumber = self.avaliationNumber(languageNew.ProficiencyLevel.Listening);
            let readingNumber = self.avaliationNumber(languageNew.ProficiencyLevel.Reading);
            let spokenIntNumber = self.avaliationNumber(languageNew.ProficiencyLevel.SpokenInteraction);
            let spokenProdNumber = self.avaliationNumber(languageNew.ProficiencyLevel.SpokenProduction);
            let writingNumber = self.avaliationNumber(languageNew.ProficiencyLevel.Writing);
            avaliation = (listeningNumber + readingNumber + spokenIntNumber + spokenProdNumber + writingNumber) / 5;

            switch (Math.round(avaliation)) {
              case 1:
                languageNew.Avaliation = "A1";
                break;

              case 2:
                languageNew.Avaliation = "A2";
                break;

              case 3:
                languageNew.Avaliation = "B1";
                break;

              case 4:
                languageNew.Avaliation = "B2";
                break;

              case 5:
                languageNew.Avaliation = "C1";
                break;

              case 6:
                languageNew.Avaliation = "C2";
                break;

              default:
                languageNew.Avaliation = "None";
            }

            languagesUsed.pushObject(languageNew);
          }
        }
      });
      return languagesUsed;
    },

    avaliationNumber: function (avaliation) {
      switch (avaliation) {
        case "A1":
          return 1;

        case "A2":
          return 2;

        case "B1":
          return 3;

        case "B2":
          return 4;

        case "C1":
          return 5;

        case "C2":
          return 6;

        default:
          return 0;
      }
    }
  });

  _exports.default = _default;
});