define("E4E/components/dialogs/questionbundles/add-bundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    questions: Ember.A([]),
    addQuestionContainer: false,
    questionTypes: ["CERTIFICATE", "NUMERIC", "DATE", "LIKERT_SCALE", "OPEN_TEXT", "MULTIPLE_CHOICE", "SINGLE_CHOICE"],
    init: function () {
      this._super(...arguments);
    },
    actions: {
      closeAddBundleDialog: function () {},
      addQuestion: function () {
        this.set('addQuestionContainer', true);
      }
    }
  });

  _exports.default = _default;
});