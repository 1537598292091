define("E4E/components/blog/post-comment", ["exports", "pell", "E4E/config/environment", "ember-concurrency"], function (_exports, _pell, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    comment: null,
    comments: [],
    showAddComment: false,
    showDialogComment: false,
    showDialogReport: false,
    commentContent: "",
    page: 0,
    size: 10,
    morePage: false,
    ReportPostSuccessDialog: false,
    blogPostParams: ["comments", "author"],
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    canSave: Ember.computed('commentContent', function () {
      if (this.get('commentContent') != null && this.get('commentContent') != '' && this.get('commentContent') != '<div><br></div>' && this.get('commentContent') != '<div></div>') {
        return false;
      } else {
        return true;
      }
    }),
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    updateComments: (0, _emberConcurrency.task)(function* () {
      let self = this;
      yield this.store.query('blogcomment', {
        filter: {
          id: self.get("comment.id"),
          page: self.get("page"),
          size: self.get("size")
        }
      }).then(function (resp) {
        if (resp.meta.page.last) {
          self.set("morePage", false);
        } else {
          self.set("morePage", true);
        }

        self.set("comments", null);
        self.set("respMeta", resp.meta);

        if (self.get("comments") == null || self.get("page") == 0) {
          self.set("comments", resp.toArray());
          return;
        }

        self.get('comments').pushObjects(resp.toArray());
      });
    }),
    actions: {
      showAddComment(comment) {
        this.set("showAddComment", true);
        this.set("comment", comment);
        this.set("commentContent", "");
      },

      closeAddComment() {
        this.set("showDialogComment", false);
        this.set("showAddComment", false);
      },

      addComment: function (comment) {
        if (comment != null) {
          this.set("comment", comment);
          this.set("commentContent", comment.get("content"));
          this.set("showDialogComment", true);
        }
      },
      saveComment: function (obj) {
        let self = this;

        if (obj != null) {
          if (obj.constructor.modelName == "blogpost") {
            let comment = this.store.createRecord('blogcomment');
            comment.set('content', this.get("commentContent"));
            comment.set('blogPostId', obj.id);
            comment.set('blogCommentId', null);
            comment.set('authorId', this.get("currentUserService.user.id"));
            comment.save().then(() => {
              self.set("showAddComment", false);
              self.set("commentContent", false);
              self.refreshPage();
              self.get("updateComments").perform();
            });
          } else if (obj.constructor.modelName == "blogcomment") {
            let comment = this.store.createRecord('blogcomment');
            comment.set('content', this.get("commentContent"));
            comment.set('blogPostId', null);
            comment.set('blogCommentId', obj.id);
            comment.set('authorId', this.get("currentUserService.user.id"));
            comment.save().then(() => {
              self.set("showAddComment", false);
              self.set("commentContent", false);
              self.refreshPage();
              self.get("updateComments").perform();
            });
          } else {
            let comment = this.get("comment");
            comment.set('content', obj);
            comment.save().then(() => {
              self.set("showAddComment", false);
              self.set("commentContent", false);
              self.set("showDialogComment", false);
              self.refreshPage();
            });
          }
        }
      },
      deleteComment: function (comment, index) {
        let self = this;
        comment.destroyRecord().then(() => {
          self.set("postDeleteSuccessfull", true);
          self.removeComment(comment);
        }).catch(function (error) {
          self.set('postDeleteFailure', true);
        });
      },
      removeComment: function (comment) {
        this.get("comments").removeObject(comment);
      },
      updateComments: function () {
        this.get("updateComments").perform();
      },
      moreAnswers: function () {
        this.set("page", this.get("page") + 1);
        this.get("updateComments").perform();
      },
      reportComment: function (comment) {
        this.set("showDialogReport", true);
        this.set("comment", comment);
      },
      closeDialogReport: function () {
        this.set("showDialogReport", false);
      },
      closeReportPostSuccessDialog: function () {
        this.set("ReportPostSuccessDialog", false);
      },
      saveReport: function (content) {
        let self = this;
        let reportBlogItem = this.store.createRecord('reportblogitem');
        reportBlogItem.set('reasonReport', content);
        reportBlogItem.set('blogPostId', null);
        reportBlogItem.set('blogCommentId', this.get("comment.id"));
        reportBlogItem.save().then(() => {
          self.set("content", "");
          self.set("showDialogReport", false);
          self.set("ReportPostSuccessDialog", true);
        });
      },
      closeDialogReportUser: function () {
        this.set("showReportUser", false);
      },
      saveReportUser: function (content) {
        let self = this;
        let reportBlogItem = this.store.createRecord('reportblogitem');
        reportBlogItem.set('reasonReport', content);
        reportBlogItem.set('blogPostId', null);
        reportBlogItem.set('blogCommentId', null);
        reportBlogItem.set('userId', this.get("blogPost.authorId"));
        reportBlogItem.save().then(() => {
          self.set("content", "");
          self.set("showReportUser", false);
          self.set("ReportUserSuccessDialog", true);
        });
      },
      closeReportUserSuccessDialog: function () {
        this.set("ReportUserSuccessDialog", false);
      },
      reportUser: function (comment) {
        this.set("showReportUser", true);
        this.set("comment", comment);
      },
      closeDialogReportUser: function () {
        this.set("showReportUser", false);
      },
      saveReportUser: function (content) {
        let self = this;
        let reportBlogItem = this.store.createRecord('reportblogitem');
        reportBlogItem.set('reasonReport', content);
        reportBlogItem.set('blogPostId', null);
        reportBlogItem.set('blogCommentId', null);
        reportBlogItem.set('userId', this.get("comment.authorId"));
        reportBlogItem.save().then(() => {
          self.set("content", "");
          self.set("showReportUser", false);
          self.set("ReportUserSuccessDialog", true);
        });
      },
      closeReportUserSuccessDialog: function () {
        this.set("ReportUserSuccessDialog", false);
      },
      refreshPage: function () {
        this.updateComments.perform();
      }
    }
  });

  _exports.default = _default;
});