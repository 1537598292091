define("E4E/templates/components/public-link/organizationalskills-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQcog/l+",
    "block": "{\"symbols\":[\"competence\"],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-center width100P margin-bottom-9\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-20-type-2\"],[9],[1,[27,\"t\",[\"label.organizationalskills\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center layout-wrap margin-top-8\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"not-eq\",[[23,[\"organizationalSkill\",\"Competences\",\"length\"]],0],null],[27,\"not-eq\",[[23,[\"organizationalSkill\",\"Competences\"]],null],null],[27,\"not-eq\",[[23,[\"organizationalSkill\",\"Competences\"]],[26]],null]],null]],null,{\"statements\":[[4,\"each\",[[23,[\"organizationalSkill\",\"Competences\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-center chipContainer\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"text-14-type-1\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[27,\"t\",[\"label.nocompetencesinsection\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/public-link/organizationalskills-card.hbs"
    }
  });

  _exports.default = _default;
});