define("E4E/serializers/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      users: {
        embedded: "always"
      }
    },
    extractMeta: function (store, typeClass, payload) {
      let meta = {};

      if (payload.page) {
        meta.page = payload.page;
        delete payload.page;
      }

      return meta;
    },
    normalizeArrayResponse: function (store, primaryModelClass, payload, id, requestType) {
      let rolesPayload = {};
      rolesPayload.roles = payload;
      return this._normalizeResponse(store, primaryModelClass, rolesPayload, id, requestType, false);
    },
    normalizeSingleResponse: function (store, primaryModelClass, payload, id, requestType) {
      let modelKey = primaryModelClass.modelName;
      let aux = {};
      Object.keys(payload).forEach(key => {
        aux[key] = payload[key];
        delete payload[key];
      });
      payload[modelKey] = aux;
      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, true);
    }
  });

  _exports.default = _default;
});