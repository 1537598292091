define("E4E/routes/superadmin/organizations/index", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    companyName: '',
    sectorName: '',
    countryName: '',
    status: ["ACCEPTED", "PENDING", "REJECTED", "NONE"],
    page: 0,
    selectedOrganization: null,
    sector: null,

    model() {
      return Ember.RSVP.hash({
        selectedOrganization: null,
        sectors: this.store.findAll("sector"),
        companyName: this.get('companyName'),
        countryName: this.get('countryName'),
        sector: this.get("sector"),
        sectorName: this.get("sectorName"),
        status: this.get('status'),
        page: this.get('page')
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("searchOrganizations");
      }
    }
  });

  _exports.default = _default;
});