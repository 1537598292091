define("E4E/controllers/superadmin/dashboard", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    documentDeleteSuccessfull: false,
    documentDeleteFailure: false,
    documentItems: Ember.A([]),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    options: {
      title: {
        display: true
      },
      responsive: true,
      legend: {
        display: false
      }
    },
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    init: function () {
      this._super(...arguments);
    },
    setData: (0, _emberConcurrency.task)(function* () {
      let jobSeekers = yield this.authorizedGet('/users/search/countRole?role=JOB_SEEKER');
      let organizationUsers = yield this.authorizedGet('/users/search/countRole?role=ORGANIZATION_USER');
      let organizationAdmin = yield this.authorizedGet('/users/search/countRole?role=ORGANIZATION_ADMIN');
      let countryManagers = yield this.authorizedGet('/users/search/countRole?role=PARTNER_MANAGER');
      console.log(jobSeekers);
      let accountsData = {
        title: "# of Accounts",
        labels: ["Job Seekers", "Company Admins", "Company Users", "Country Managers"],
        datasets: [{
          data: [jobSeekers, organizationAdmin, organizationUsers, countryManagers],
          backgroundColor: ['#ff5722', '#00bcd4', '#ffc107', '#f44336'],
          borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
          borderWidth: 1
        }]
      };
      this.set('model.accountsData', accountsData);
      this.set('model.showDiagrams', true);
    }).enqueue(),
    getDocuments: (0, _emberConcurrency.task)(function* () {
      let documentItems = yield this.store.findAll('document');
      this.set("documentItems", Ember.A([]));
      this.set('documentItems', documentItems);
    }),
    actions: {
      showDiagrams: function () {
        this.get('setData').perform();
      },
      getDocuments: function () {
        this.get("getDocuments").perform();
      },
      downloadfile: function (originalFileName, file, mimeType) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(fileContent => self.saveFileAs(originalFileName, fileContent, mimeType));
        });
      },
      deleteDocument: function (document) {
        document.destroyRecord().then(() => {
          this.set("documentDeleteSuccessfull", true);
          this.get('getDocuments').perform();
        }).catch(function (error) {
          this.set('documentDeleteFailure', true);
        });
      },
      closeDocumentDeleteSuccessfull: function () {
        this.set("documentDeleteSuccessfull", false);
      },
      closeDocumentDeleteFailure: function () {
        this.set("documentDeleteFailure", false);
      }
    }
  });

  _exports.default = _default;
});