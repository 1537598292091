define("E4E/components/dialogs/videos/edit-video", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    init: function () {
      this._super(...arguments);

      this.setEditableInfo.perform();
    },
    setEditableInfo: (0, _emberConcurrency.task)(function* () {
      this.set('title', this.get('video.title'));
      this.set('description', this.get('video.description'));
    }),
    actions: {
      cancelEditVideo: function () {
        this.set('video.title', null);
        this.set('video.description', null);
        this.cancelEditVideo();
      },
      saveEditVideo: function () {
        this.set('video.title', this.get('title'));
        this.set('video.description', this.get('description'));
        this.saveEditVideo(this.get('video'));
      }
    }
  });

  _exports.default = _default;
});