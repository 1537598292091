define("E4E/components/dialogs/files/upload-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disableSave: Ember.computed("files", "category", function () {
      if (this.get("files") != null && this.get("category") != null) {
        return false;
      } else {
        return true;
      }
    }),
    category: null,
    categories: ["GUIDELINES", "LEARNING_MATERIAL", "OTHER_DOCUMENTS"],
    init: function () {
      this._super(...arguments);
    },
    actions: {
      closeUploadDocuments: function () {
        this.closeUploadDocuments();
      },
      uploadDocument: function () {
        this.uploadDocument(this.get("files"), this.get("category"));
      },
      documentSelect: function (files) {
        this.set("files", files[0]);
      },
      removeFiles: function () {
        this.set('files', null);
      }
    }
  });

  _exports.default = _default;
});