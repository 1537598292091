define("E4E/adapters/application", ["exports", "ember-data", "E4E/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-cli-file-saver/mixins/adapter-arraybuffer-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin, _adapterArraybufferMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ApplicationAdapter = _emberData.default.RESTAdapter.reopen(_dataAdapterMixin.default, _adapterArraybufferMixin.default, {
    host: _environment.default.APP.SPRING_HOST,
    authorizer: 'authorizer:oauth2'
  });

  var _default = ApplicationAdapter.extend({
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),

    createRecord(store, type, snapshot) {
      type = type || snapshot && snapshot.type;
      let serializer = store.serializerFor(type.modelName);
      let data = {};
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      data = data[type.modelName];
      let id = snapshot.id;
      let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'POST', {
        data
      });
    },

    updateRecord(store, type, snapshot) {
      type = type || snapshot && snapshot.type;
      let serializer = store.serializerFor(type.modelName);
      let data = {};
      serializer.serializeIntoHash(data, type, snapshot);
      data = data[type.modelName];
      let id = snapshot.id;
      let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      if (snapshot.include != null) {
        url = url + "?include=" + snapshot.include.toString();
        return this.ajax(url, 'PATCH', {
          data
        });
      } else {
        return this.ajax(url, 'PATCH', {
          data
        });
      }
    },

    queryRecord(store, type, query) {
      let modelName = type.modelName === 'current-user' ? 'user' : type.modelName;
      let url = this.buildURL(modelName, null, null, 'queryRecord', query);

      if (query.me) {
        return this.ajax(url + '/search/me', 'GET', null);
      }

      if (query.shareableToken) {
        return this.ajax(url + '/search/shareableToken?' + Ember.$.param(query.shareableToken), 'GET', {
          headers: {
            Authorization: null
          }
        });
      }

      if (query.getTEKIProfile) {
        return this.ajax(url + '/get/' + query.getTEKIProfile.code, 'GET', {
          headers: {
            Authorization: null
          }
        });
      }

      if (query.getProfessionBundles) {
        url = this.buildURL("teki", null, null, 'queryRecord', query);
        return this.ajax(url + '/get/questions/' + query.getProfessionBundles.code + '?' + Ember.$.param(query.getProfessionBundles), 'GET', {
          headers: {
            Authorization: null
          }
        });
      }

      if (query.loginByCode) {
        return this.ajax(url + '/login/' + query.loginByCode.code + '?' + 'captchaHash=' + query.loginByCode.captchaHash + "&email=" + query.loginByCode.email + "&locale=" + query.loginByCode.locale, 'GET', {
          headers: {
            Authorization: null
          }
        });
      }

      return this.ajax(url, 'GET', {
        data: query
      });
    },

    findAll(store, type, query) {
      let url = this.buildURL(type.modelName, null, null, 'findAll', query);
      return this.ajax(url + '/search/all', 'GET', null);
    },

    query(store, type, query) {
      let url = this.buildURL(type.modelName, null, null, 'query', query);

      if (query.filter) {
        return this.ajax(url + '/search/filter?' + Ember.$.param(query.filter), 'GET', null);
      }

      if (query.filterCompanies) {
        return this.ajax(url + '/search/filterCompanies?' + Ember.$.param(query.filterCompanies), 'GET', null);
      }

      if (query.filterPicturesJobOffer) {
        return this.ajax(url + '/search/filterPicturesJobOffer?' + Ember.$.param(query.filterPicturesJobOffer), 'GET', null);
      }

      if (query.filterPicturesAuthor) {
        return this.ajax(url + '/search/filterPicturesAuthor?' + Ember.$.param(query.filterPicturesAuthor), 'GET', null);
      }

      if (query.filterPicturesOrganization) {
        return this.ajax(url + '/search/filterPicturesOrganization?' + Ember.$.param(query.filterPicturesOrganization), 'GET', null);
      }

      if (query.filterJobApplicationsOfOrganization) {
        return this.ajax(url + '/search/filterJobApplicationsOfOrganization?' + Ember.$.param(query.filterJobApplicationsOfOrganization), 'GET', null);
      }

      if (query.filterJobApplicationsOutplacers) {
        return this.ajax(url + '/search/filterJobApplicationsOutplacers?' + Ember.$.param(query.filterJobApplicationsOutplacers), 'GET', null);
      }

      if (query.filterByCategory) {
        return this.ajax(url + '/search/filterByCategory?' + Ember.$.param(query.filterByCategory), 'GET', null);
      }

      if (query.countStatus) {
        return this.ajax(url + '/search/countStatus?' + Ember.$.param(query.countStatus), 'GET', null);
      }

      if (query.filterSuperAdmin) {
        return this.ajax(url + '/search/filterSuperAdmin?' + Ember.$.param(query.filterSuperAdmin), 'GET', null);
      }

      if (query.filterRequests) {
        return this.ajax(url + '/search/filterRequests?' + Ember.$.param(query.filterRequests), 'GET', null);
      }

      if (query.filterOutplacersByPartner) {
        return this.ajax(url + '/search/filterOutplacersByPartner?' + Ember.$.param(query.filterOutplacersByPartner), 'GET', null);
      }

      if (query.getMyNotifications) {
        return this.ajax(url + '/search/me?' + Ember.$.param(query.getMyNotifications), 'GET', null);
      }

      if (query.filterApplicationRequests) {
        return this.ajax(url + '/search/filterApplicationRequests?' + Ember.$.param(query.filterApplicationRequests), 'GET', null);
      }

      if (query.findByOrganizationId) {
        return this.ajax(url + '/search/company/' + query.findByOrganizationId.organizationId, 'GET', null);
      }

      if (query.findByOrganizationId) {
        return this.ajax(url + '/search/filterDocumentsAuthor/' + Ember.$.param(query.filterDocumentsAuthor), 'GET', null);
      }
    }

  });

  _exports.default = _default;
});