define("E4E/components/users/delete-education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasOrganisation: Ember.computed('education', function () {
      if (this.get('education.Organisation') != null) {
        console.log(this.get('education.Organisation.Name'));

        if (this.get('education.Organisation.Name') === undefined || this.get('education.Organisation.Name') === null || this.get('education.Organisation.Name') === "") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }),
    actions: {
      deleteEducation: function (education) {
        this.deleteEducation(education);
      },
      cancelDeleteEducation: function () {
        this.cancelDeleteEducation();
      }
    }
  });

  _exports.default = _default;
});