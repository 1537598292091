define("E4E/components/questions/edit/date-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
    },
    actions: {
      deleteQuestion: function (question) {
        this.deleteQuestion(question);
      }
    }
  });

  _exports.default = _default;
});