define("E4E/components/dialogs/joboffers/add-education", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    Education: {
      Period: {
        From: {
          Year: "",
          Month: "",
          Day: ""
        },
        To: {
          Year: "",
          Month: "",
          Day: ""
        },
        Current: ""
      },
      Title: "",
      Organisation: {
        Name: "",
        ContactInfo: {
          Address: {
            Contact: {
              AddressLine: "",
              PostalCode: "",
              Municipality: "",
              Country: {
                Code: "",
                Label: ""
              }
            }
          },
          Website: {
            Contact: ""
          }
        }
      },
      Level: {
        Code: "",
        Label: ""
      },
      Field: {
        Code: "",
        Label: ""
      },
      Scope: {
        Label: "",
        Value: "",
        DateIssue: ""
      },
      ReferenceTo: [],
      Competences: Ember.A([])
    },
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    page: 0,
    name: '',
    skillsAdded: Ember.A([]),
    scopes: ["ECTS", "HOURS", "DAYS"],
    educationTypes: [{
      Code: 0,
      Label: "School"
    }, {
      Code: 1,
      Label: "AHS"
    }, {
      Code: 2,
      Label: "BHS (HAK, HTL, HLW...)"
    }, {
      Code: 3,
      Label: "BMS (HAS, VET college...)"
    }, {
      Code: 4,
      Label: "apprenticeship"
    }, {
      Code: 5,
      Label: "Bachelor's degree"
    }, {
      Code: 6,
      Label: "Master's degree"
    }, {
      Code: 7,
      Label: "Master's degree"
    }, {
      Code: 8,
      Label: "Doctoral degree"
    }, {
      Code: 9,
      Label: "Diploma Studies"
    }, {
      Code: 10,
      Label: "Seminar"
    }, {
      Code: 11,
      Label: "Course"
    }, {
      Code: 12,
      Label: "Course"
    }, {
      Code: 13,
      Label: "Further training"
    }],
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
      this.get('getEducations').perform();
      this.get('resetFields').perform();
    },
    resetFields: (0, _emberConcurrency.task)(function* () {
      this.set('name', '');
      this.set('page', 0);
      this.set('skillsAdded', Ember.A([]));
    }),
    loadMore: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      this.set('page', this.get('page') + 1);
      let educations = yield this.store.query('education', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !educations.meta.page.last);
      this.get('educations').pushObjects(educations.toArray());
      this.set('isLoading', false);
    }).restartable(),
    getEducations: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      let educations = yield this.store.query('education', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !educations.meta.page.last);
      this.set('educations', educations.toArray());
      this.set('isLoading', false);
    }),
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    search: function () {
      this.set('educations', Ember.A([]));
      this.get('getEducations').perform();
    },
    actions: {
      addEducation: function (select, e) {
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          if (this.get('educations').filterBy('name', select.searchText)[0] == null) {
            let education = {
              name: select.searchText
            };
            this.set('education', education);
            this.get('educations').pushObject(education);
            select.actions.choose(education);
          } else {
            this.set('education', this.get('educations').filterBy('name', select.searchText)[0]);
          }
        }
      },
      cancelAddEducation: function () {
        this.cancelAddEducation();
      },
      saveEducationToJobOffer: function () {
        let education = Ember.copy(this.Education, true); // Setting title of work experience

        if (this.get('education') != null) {
          education.Title = this.get('education');
        }

        if (this.get('educationType') != null) {
          education.Level.Code = this.get('educationType.Code');
          education.Level.Label = this.intl.t("label.educationtypes." + this.get('educationType.Code'));
        } // Competences


        education.Competences = this.get('skillsAdded').toArray(); // Scope

        education.Scope.Label = this.get('scope');
        education.Scope.Value = this.get('number');

        if (this.get('dateIssue') != undefined && this.get('dateIssue') != null && this.get('dateIssue') != "") {
          education.Scope.DateIssue = moment.utc(moment.utc(this.get('dateIssue')).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate();
        }

        this.saveEducationToJobOffer(education, null);
      },
      searchEducations: function (value) {
        this.set('name', value);
        this.set('page', 0);
        Ember.run.debounce(this, this.search, 1000);
      },
      addSkills: function () {
        this.set("addSkills", true);
      },
      addSkillsToEducation: function () {
        this.set('addSkills', false);
      },
      cancelAddSkills: function () {
        this.set("addSkills", false);
      },
      removeAddedSkills: function (value) {
        this.get("skillsAdded").removeObject(value);
      }
    }
  });

  _exports.default = _default;
});