define("E4E/controllers/recruiters/team-members/index", ["exports", "ember-concurrency", "ember-cli-file-saver/mixins/file-saver", "E4E/config/environment"], function (_exports, _emberConcurrency, _fileSaver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    teamMembersParams: ["joboffers", "image", "organization", "coverimage", "curriculumvitae", "certificates"],
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    screen: Ember.inject.service('screen'),
    userService: Ember.inject.service('user'),
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    fileService: Ember.inject.service('file-service'),
    addTeamMember: false,
    editTeamMember: false,
    intl: Ember.inject.service(),
    pdfJs: Ember.inject.service('pdf-js'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    session: Ember.inject.service(),
    Attachment: {
      Id: "",
      Name: "",
      Data: "",
      MimeType: "",
      Description: "",
      Metadata: []
    },
    socialSkills: {
      Description: '',
      Competences: Ember.A([])
    },
    organizationalSkills: {
      Description: '',
      Competences: Ember.A([])
    },
    professionalSkills: {
      Description: '',
      Competences: Ember.A([])
    },
    init: function () {
      this._super(...arguments);

      this.set('model', {
        selectedTeamMember: null
      });

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    authorizedGetJSON: function (url, data, done) {
      return Ember.$.ajax({
        type: 'POST',
        url: _environment.default.APP.SPRING_HOST + url,
        contentType: 'application/json',
        data: JSON.stringify(data),
        headers: this.get('headers'),
        success: done
      });
    },
    authorizedPost: function (url, content, success) {
      return Ember.$.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        data: JSON.stringify(content),
        contentType: "application/json",
        success: success
      });
    },
    search: function () {
      this.set('model.teamMembers', Ember.A([]));
      this.set('model.page', 0);
      this.set('model.meta', null);
      this.get('searchTeamMembers').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedTeamMember') != null || this.get('addTeamMember') || this.get('editTeamMember')) {
          if (Ember.$('#search_container_team_members').hasClass('hide')) {
            Ember.$('#team_member-container').addClass('show');
          } else {
            Ember.$('#search_container_team_members').addClass('hide');
            Ember.$('#team_member-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_team_members').removeClass('hide');
          Ember.$('#team_member-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_team_members').removeClass('hide');
        Ember.$('#team_member-container').removeClass('show');
      }
    },

    searchTeamMembers: (0, _emberConcurrency.task)(function* (nextPage) {
      this.userService.filter(this.get('model.memberName'), this.get('model.memberRoles'), this.get('model.city'), this.get('model.workExperience'), this.get('model.yearsExperience'), this.get('model.education'), this.get('model.educationScope'), this.get('model.educationNumber'), this.get('model.educationDateIssue'), this.get('model.languageName'), this.get('model.languageScope'), this.get('model.languageNumber'), this.get('model.languageDateIssue'), this.get('model.drivingLicenses'), this.get('model.competenceName'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.teamMembers').pushObjects(resp.users.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.teamMembers', resp.users.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    findTeamMember: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('user', id, {
        include: this.teamMembersParams.toString()
      }).then(user => {
        this.set('addTeamMember', false);
        this.set('editTeamMember', false);
        this.set('model.selectedTeamMember', user);
        Ember.$('#team_member-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_team_members').addClass('hide');
          Ember.$('#team_member-container').addClass('show');
        }
      });
    }).restartable(),
    generateNewCV: (0, _emberConcurrency.task)(function* () {
      let self = this;
      this.set('waitingCvDialog', true);
      self.authorizedGetJSON('/rest/files/curriculum/generate?userId=' + self.get('model.selectedTeamMember.id') + '&locale=' + self.intl.get('locale'), '', function (respTemp) {
        self.set('waitingCvDialog', false);

        if (respTemp) {
          self.set('successGenerationCV', true);
          self.get('userService').load(self.get('model.selectedTeamMember.id'), self.get('teamMembersParams'));
        } else {
          self.set('failureGenerationCV', true);
        }
      });
    }),
    sortWorkExperienceByDate: (0, _emberConcurrency.task)(function* () {
      let workExperience = this.get('model.selectedTeamMember.workExperienceJson').sort(function (a, b) {
        var dateFromA = new Date(a.Period.From.Year, a.Period.From.Month - 1, a.Period.From.Day);
        var dateFromB = new Date(b.Period.From.Year, b.Period.From.Month - 1, b.Period.From.Day);

        if (a.Period.Current && b.Period.Current) {
          return dateFromB - dateFromA;
        } else if (a.Period.Current && !b.Period.Current) {
          return -1;
        } else if (!a.Period.Current && b.Period.Current) {
          return 1;
        } else {
          if (a.Period.To.Day != null) {
            var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1, a.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1);
            } else {
              var dateToA = new Date(a.Period.To.Year);
            }
          }

          if (b.Period.To.Day != null) {
            var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1, b.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1);
            } else {
              var dateToB = new Date(b.Period.To.Year);
            }
          }

          return dateToB - dateToA;
        }
      });
      this.set('model.selectedTeamMember.workExperience', JSON.stringify({
        workExperiences: workExperience
      }));
    }),
    sortEducationByDate: (0, _emberConcurrency.task)(function* () {
      let education = this.get('model.selectedTeamMember.educationJson').sort(function (a, b) {
        var dateFromA = new Date(a.Period.From.Year, a.Period.From.Month - 1, a.Period.From.Day);
        var dateFromB = new Date(b.Period.From.Year, b.Period.From.Month - 1, b.Period.From.Day);

        if (a.Period.Current && b.Period.Current) {
          return dateFromB - dateFromA;
        } else if (a.Period.Current && !b.Period.Current) {
          return -1;
        } else if (!a.Period.Current && b.Period.Current) {
          return 1;
        } else {
          if (a.Period.To.Day != null) {
            var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1, a.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1);
            } else {
              var dateToA = new Date(a.Period.To.Year);
            }
          }

          if (b.Period.To.Day != null) {
            var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1, b.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1);
            } else {
              var dateToB = new Date(b.Period.To.Year);
            }
          }

          return dateToB - dateToA;
        }
      });
      this.set('model.selectedTeamMember.education', JSON.stringify({
        educations: education
      }));
    }),
    actions: {
      closeFailureDialog: function () {
        this.set('failureGenerationCV', false);
      },
      closeSuccessDialog: function () {
        this.set('successGenerationCV', false);
      },
      searchTeamMembers: function () {
        this.get("searchTeamMembers").perform();
      },
      updateMembers: function (value) {
        this.set('model.memberName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateRoles: function (value) {
        if (this.get('model.memberRoles').includes(value)) {
          this.get('model.memberRoles').removeObject(value);
        } else {
          this.get('model.memberRoles').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateWorkExperience: function (value) {
        this.set('model.workExperience', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducation: function (value) {
        this.set('model.education', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationScope: function (value) {
        if (value == null) {
          this.set('model.educationScope', "");
        } else {
          this.set('model.educationScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationNumber: function (value) {
        if (value == null) {
          this.set('model.educationNumber', "");
        } else {
          this.set('model.educationNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationDateIssue: function (value) {
        if (value == null) {
          this.set('model.educationDateIssue', "");
        } else {
          this.set('model.educationDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageScope: function (value) {
        if (value == null) {
          this.set('model.languageScope', "");
        } else {
          this.set('model.languageScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageNumber: function (value) {
        if (value == null) {
          this.set('model.languageNumber', "");
        } else {
          this.set('model.languageNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageDateIssue: function (value) {
        if (value == null) {
          this.set('model.languageDateIssue', "");
        } else {
          this.set('model.languageDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        if (value == null) {
          this.set('model.jobSeekerCountry', "");
        } else {
          this.set('model.jobSeekerCountry', value.iso3);
        }

        this.set('model.country', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCity: function (value) {
        this.set('model.city', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguage: function (value) {
        this.set('model.languageName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateYearsExperience: function (value) {
        if (value == null) {
          this.set('model.yearsExperience', "");
        } else {
          this.set('model.yearsExperience', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateDrivingLicense: function (value) {
        if (this.get('model.drivingLicenses').includes(value)) {
          this.get('model.drivingLicenses').removeObject(value);
        } else {
          this.get('model.drivingLicenses').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompetences: function (value) {
        this.set('model.competenceName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      selectTeamMember: function (id) {
        this.get('findTeamMember').perform(id);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchTeamMembers').perform(true);
      },
      backToSearch: function () {
        this.set('addTeamMember', false);
        this.set('editTeamMember', false);
        this.set('model.selectedTeamMember', null);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_team_members').removeClass('hide');
          Ember.$('#team_member-container').removeClass('show');
        }
      },
      editTeamMember: function () {
        this.set('addTeamMember', false);
        this.set('editTeamMember', true);
        let params = {
          firstName: this.get('model.selectedTeamMember.firstName'),
          lastName: this.get('model.selectedTeamMember.lastName'),
          address: this.get('model.selectedTeamMember.address'),
          postalCode: this.get('model.selectedTeamMember.postalCode'),
          city: this.get('model.selectedTeamMember.city'),
          country: this.get('model.selectedTeamMember.country'),
          birthDate: moment.utc(moment.utc(this.get('model.selectedTeamMember.birthDate')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString(),
          profilePicture: this.get('model.selectedTeamMember.profilePicture'),
          coverPicture: this.get('model.selectedTeamMember.coverPicture'),
          introduction: this.get('model.selectedTeamMember.introduction'),
          profession: this.get('model.selectedTeamMember.profession'),
          phoneNumber: this.get('model.selectedTeamMember.phoneNumber'),
          website: this.get('model.selectedTeamMember.website')
        };
        this.set('params', params);
      },
      saveEditBasicInformation: function (params) {
        let user = this.get('model.selectedTeamMember');
        user.set('firstName', params.firstName);
        user.set('lastName', params.lastName);
        user.set('address', params.address);
        user.set('postalCode', params.postalCode);
        user.set('city', params.city);
        user.set('country', params.country);
        user.set('birthDate', moment.utc(moment.utc(params.birthDate).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate());
        user.set('introduction', params.introduction);
        user.set('profession', params.profession);
        user.set('phoneNumber', params.phoneNumber);
        user.set('website', params.website);
        this.userService.save(user, this.get('teamMembersParams'));
      },
      addNewMember: function () {
        this.set('addTeamMember', true);
        this.set('editTeamMember', false);
      },
      uploadUserAvatar: function (avatar) {
        let self = this;
        this.fileService.get('uploadUserAvatarImage').perform(avatar, this.get('model.selectedTeamMember')).then(() => {
          self.get("searchTeamMembers").perform(false);
        });
      },
      uploadUserCover: function (cover) {
        let self = this;
        this.fileService.get('uploadUserCoverImage').perform(cover, this.get('model.selectedTeamMember')).then(() => {
          self.get("searchTeamMembers").perform();
        });
      },
      deleteTeamMember: function () {
        this.set('deleteAccountStep', true);
      },
      deleteAccount: function () {
        let self = this;
        let id = this.get('model.selectedTeamMember.id');
        this.get('model.selectedTeamMember').destroyRecord().then(resp => {
          if (id === self.currentUserService.get('user.id')) {
            self.set('showByeDialog', true);
          } else {
            self.set('deleteSuccessToast', true);
            self.set('addTeamMember', false);
            self.set('editTeamMember', false);
            self.set('model.selectedTeamMember', null);
            Ember.run.debounce(self, self.search, 1000);
          }
        }).catch(() => {
          self.set('deleteFailureToast', true);
        });
      },
      closeDeleteStepDialog: function () {
        this.set('deleteAccountStep', false);
      },
      uploadCV: function (file) {
        let self = this;
        this.fileService.get('uploadCV').perform(file, this.get('model.selectedTeamMember')).then(() => {
          self.userService.save(self.get('model.selectedTeamMember'), self.get('teamMembersParams'));
        });
      },
      downloadCV: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      generateCV: function () {
        this.generateNewCV.perform();
      },
      saveWorkExperience: function (workExperience, certificates, deletedCertificates, index) {
        deletedCertificates.forEach(function (certificate) {
          workExperience.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              workExperience.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.selectedTeamMember')).then(referencesTo => {
          if (referencesTo != null || referencesTo.length > 0) {
            workExperience.ReferenceTo.pushObjects(referencesTo);
          }

          if (this.get('model.selectedTeamMember.workExperienceJson') != null) {
            let workExperienceJson = this.get('model.selectedTeamMember.workExperienceJson');

            if (index != null) {
              workExperienceJson[index] = workExperience;
            } else {
              workExperienceJson.push(workExperience);
            }

            this.set('model.selectedTeamMember.workExperience', JSON.stringify({
              workExperiences: workExperienceJson
            }));
          } else {
            let workExperienceArray = Ember.A([]);
            workExperienceArray.push(workExperience);
            this.set('model.selectedTeamMember.workExperience', JSON.stringify({
              workExperiences: workExperienceArray
            }));
          }

          this.sortWorkExperienceByDate.perform().then(() => {
            this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
          });
        });
      },
      deleteWorkExperience: function (workExperience) {
        let self = this;
        workExperience.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.selectedTeamMember.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.selectedTeamMember.workExperienceJson').removeObject(workExperience);
        this.set('model.selectedTeamMember.workExperience', JSON.stringify({
          workExperiences: this.get('model.selectedTeamMember.workExperienceJson')
        }));
        this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
      },
      saveEducation: function (education, certificates, deletedCertificates, index) {
        deletedCertificates.forEach(function (certificate) {
          education.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              education.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.selectedTeamMember')).then(referencesTo => {
          if (referencesTo != null || referencesTo.length > 0) {
            education.ReferenceTo.pushObjects(referencesTo);
          }

          if (this.get('model.selectedTeamMember.educationJson') != null) {
            let educationJson = this.get('model.selectedTeamMember.educationJson');

            if (index != null) {
              educationJson[index] = education;
            } else {
              educationJson.push(education);
            }

            this.set('model.selectedTeamMember.education', JSON.stringify({
              educations: educationJson
            }));
          } else {
            let educationArray = Ember.A([]);
            educationArray.push(education);
            this.set('model.selectedTeamMember.education', JSON.stringify({
              educations: educationArray
            }));
          }

          this.sortEducationByDate.perform().then(() => {
            this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
          });
        });
      },
      deleteEducation: function (education) {
        let self = this;
        education.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.selectedTeamMember.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.selectedTeamMember.educationJson').removeObject(education);
        this.set('model.selectedTeamMember.education', JSON.stringify({
          educations: this.get('model.selectedTeamMember.educationJson')
        }));
        this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
      },
      saveLanguage: function (language, certificates, motherTongue, deletedCertificates, index) {
        deletedCertificates.forEach(function (certificate) {
          language.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              language.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.selectedTeamMember')).then(referencesTo => {
          if (referencesTo != null || referencesTo.length > 0) {
            language.ReferenceTo.pushObjects(referencesTo);
          }

          if (this.get('model.selectedTeamMember.skillsJson') != null) {
            if (this.get('model.selectedTeamMember.skillsJson.Linguistic') != null) {
              if (motherTongue) {
                if (this.get('model.selectedTeamMember.skillsJson.Linguistic.MotherTongue') != null) {
                  let skills = this.get('model.selectedTeamMember.skillsJson');
                  skills.Linguistic.MotherTongue.push(language);
                  this.set('model.selectedTeamMember.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
                } else {
                  let MotherTongue = Ember.A([]);
                  MotherTongue.push(language);
                  let skills = this.get('model.selectedTeamMember.skillsJson');
                  skills.Linguistic.MotherTongue = MotherTongue;
                  this.set('model.selectedTeamMember.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
                }
              } else {
                if (this.get('model.selectedTeamMember.skillsJson.Linguistic.ForeignLanguage') != null) {
                  let skills = this.get('model.selectedTeamMember.skillsJson');

                  if (index != null) {
                    skills.Linguistic.ForeignLanguage[index] = language;
                  } else {
                    skills.Linguistic.ForeignLanguage.push(language);
                  }

                  this.set('model.selectedTeamMember.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
                } else {
                  let ForeignLanguage = Ember.A([]);
                  ForeignLanguage.push(language);
                  let skills = this.get('model.selectedTeamMember.skillsJson');
                  skills.Linguistic.ForeignLanguage = ForeignLanguage;
                  this.set('model.selectedTeamMember.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
                }
              }
            } else {
              if (motherTongue) {
                let skills = this.get('model.selectedTeamMember.skillsJson');
                skills.Linguistic = {
                  "MotherTongue": [language]
                };
                this.set('model.selectedTeamMember.skills', JSON.stringify({
                  skills: skills
                }));
                this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
              } else {
                let skills = this.get('model.selectedTeamMember.skillsJson');
                skills.Linguistic = {
                  "ForeignLanguage": [language]
                };
                this.set('model.selectedTeamMember.skills', JSON.stringify({
                  skills: skills
                }));
                this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
              }
            }
          } else {
            if (motherTongue) {
              let skills = {
                "Linguistic": {
                  "MotherTongue": [language]
                }
              };
              this.set('model.selectedTeamMember.skills', JSON.stringify({
                skills: skills
              }));
              this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
            } else {
              let skills = {
                "Linguistic": {
                  "ForeignLanguage": [language]
                }
              };
              this.set('model.selectedTeamMember.skills', JSON.stringify({
                skills: skills
              }));
              this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
            }
          }
        });
      },
      deleteForeignLanguage: function (foreignLanguage) {
        let self = this;
        foreignLanguage.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.selectedTeamMember.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.selectedTeamMember.skillsJson.Linguistic.ForeignLanguage').removeObject(foreignLanguage);
        this.set('model.selectedTeamMember.skills', JSON.stringify({
          skills: this.get('model.selectedTeamMember.skillsJson')
        }));
        this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
      },
      removeMotherLanguage: function (motherTongue) {
        let self = this;
        motherTongue.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.selectedTeamMember.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.selectedTeamMember.skillsJson.Linguistic.MotherTongue').removeObject(motherTongue);
        this.set('model.selectedTeamMember.skills', JSON.stringify({
          skills: this.get('model.selectedTeamMember.skillsJson')
        }));
        this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
      },
      saveDigitalSkills: function (digitalSkill, certificates, deletedCertificates) {
        deletedCertificates.forEach(function (certificate) {
          digitalSkill.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              digitalSkill.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.selectedTeamMember')).then(referencesTo => {
          digitalSkill.ReferenceTo.pushObjects(referencesTo);

          if (this.get('model.selectedTeamMember.skillsJson') != null) {
            this.set('model.selectedTeamMember.skillsJson.Computer', digitalSkill);
            this.set('model.selectedTeamMember.skills', JSON.stringify({
              skills: this.get('model.selectedTeamMember.skillsJson')
            }));
            this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
          } else {
            let skills = {
              Computer: digitalSkill
            };
            this.set('model.selectedTeamMember.skills', JSON.stringify({
              skills: skills
            }));
            this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
          }
        });
      },
      saveDrivingLicense: function (drivingLicense, certificates, deletedCertificates) {
        deletedCertificates.forEach(function (certificate) {
          drivingLicense.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              drivingLicense.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.selectedTeamMember')).then(referencesTo => {
          drivingLicense.ReferenceTo.pushObjects(referencesTo);

          if (this.get('model.selectedTeamMember.skillsJson') != null) {
            this.set('model.selectedTeamMember.skillsJson.Driving', drivingLicense);
            this.set('model.selectedTeamMember.skills', JSON.stringify({
              skills: this.get('model.selectedTeamMember.skillsJson')
            }));
            this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
          } else {
            let skills = {
              Driving: drivingLicense
            };
            this.set('model.selectedTeamMember.skills', JSON.stringify({
              skills: skills
            }));
            this.userService.save(this.get('model.selectedTeamMember'), this.teamMembersParams);
          }
        });
      },
      saveTeamMember: function (data) {
        Ember.$.ajax({
          type: "POST",
          url: _environment.default.APP.SPRING_HOST + "/api/registration/registerCompanyAdminUser",
          data: JSON.stringify(data),
          contentType: "application/json"
        }).then(() => {
          this.set('addTeamMember', false);
          this.set('editTeamMember', false);
          this.set('successAdd', true);
        }).catch(adapterError => {
          this.set('insuccessAdd', true);
        });
      },
      cancelTeamMember: function () {
        this.set('addTeamMember', false);
        this.set('editTeamMember', false);
      },
      closeToast: function () {
        this.set('successAdd', false);
        this.set('insuccessAdd', false);
        this.set('deleteFailureToast', false);
        this.set('deleteSuccessToast', false);
      },
      updateScope: function (value) {
        this.set('model.scope', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateNumber: function (value) {
        this.set('model.number', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateDateIssue: function (value) {
        this.set('model.dateIssue', value.toString());
        Ember.run.debounce(this, this.search, 1000);
      },
      downloadCVWord: function (originalFileName, id) {
        let self = this;
        this.get('store').findRecord('file', id).then(function (record) {
          record.download().then(wordContent => self.saveFileAs(originalFileName + ".docx", wordContent, 'application/msword'));
        });
      }
    }
  });

  _exports.default = _default;
});