define("E4E/controllers/superadmin/organizations/index", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    organizationService: Ember.inject.service("organization"),
    dialogService: Ember.inject.service('dialog-service'),
    paperToaster: Ember.inject.service(),
    deleteCompanyDialog: false,
    waitingDeleteAccount: false,
    tekiCodeDialog: false,
    organizationParams: ["joboffers", "jobapplications", "image", "sectors", "payablefunctionalities", "coverimage", "selfPartner", "tekicodes"],
    init: function () {
      this._super(...arguments);

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.organizations', Ember.A([]));
      this.set('model.meta', null);
      this.get('searchOrganizations').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedOrganization') != null) {
          if (Ember.$('#search_container_organizations').hasClass('hide')) {
            Ember.$('#organizations-container').addClass('show');
          } else {
            Ember.$('#search_container_organizations').addClass('hide');
            Ember.$('#organizations-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_organizations').removeClass('hide');
          Ember.$('#organizations-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_organizations').removeClass('hide');
        Ember.$('#organizations-container').removeClass('show');
      }
    },

    findOrganization: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('organization', id, {
        include: this.organizationParams.toString()
      }).then(organization => {
        this.set('model.selectedOrganization', organization);
        Ember.$('#organizations-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_organizations').addClass('hide');
          Ember.$('#organizations-container').addClass('show');
        }
      });
    }).restartable(),
    searchOrganizations: (0, _emberConcurrency.task)(function* (nextPage) {
      yield this.organizationService.filter(this.get('model.companyName'), this.get('model.sectorName'), this.get('model.countryName'), this.get('model.status'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.organizations').pushObjects(resp.organizations.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.organizations', resp.organizations.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    actions: {
      resetFilters: function () {
        this.set('model.countryName', '');
        this.set('model.country', null);
        this.set('model.companyName', '');
        this.set('model.sectorName', '');
        this.set('model.sector', null);
        this.set('model.page', 0);
        this.set('model.status', ['ACCEPTED', 'PENDING', 'REJECTED', 'NONE']);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompany: function (value) {
        if (value) {
          this.set('model.companyName', value);
        } else {
          this.set('model.companyName', '');
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateSector: function (value) {
        this.set('model.sector', value);

        if (value) {
          this.set('model.sectorName', value.get('name'));
        } else {
          this.set('model.sectorName', '');
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        this.set('model.country', value);

        if (value) {
          this.set('model.countryName', value.iso3);
        } else {
          this.set('model.countryName', '');
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateStatus: function (value) {
        if (this.get('model.status').includes(value)) {
          this.get('model.status').removeObject(value);
        } else {
          this.get('model.status').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      searchOrganizations: function () {
        this.get("searchOrganizations").perform();
      },
      selectOrganization: function (organizationId) {
        this.get("findOrganization").perform(organizationId);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchOrganizations').perform(true);
      },
      acceptCompany: function () {
        let self = this;
        this.set('waitingDialog', true);
        Ember.$.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: _environment.default.APP.SPRING_HOST + "/organizations/accept/" + this.get('model.selectedOrganization.id'),
          success: function (resp) {
            if (resp) {
              self.get('paperToaster').show(self.intl.t('label.organization.saved'), {
                duration: 3000
              });
              self.get("findOrganization").perform(self.get('model.selectedOrganization.id'));
            } else {
              self.get('paperToaster').show(self.intl.t('label.organization.error'), {
                duration: 3000
              });
            }
          },
          error: function () {
            self.get('paperToaster').show(self.intl.t('label.organization.error'), {
              duration: 3000
            });
          }
        });
      },
      rejectCompany: function () {
        let self = this;
        this.set('waitingDialog', true);
        Ember.$.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: _environment.default.APP.SPRING_HOST + "/organizations/reject/" + this.get('model.selectedOrganization.id'),
          success: function (resp) {
            if (resp) {
              self.get('paperToaster').show(self.intl.t('label.organization.saved'), {
                duration: 3000
              });
              self.get("findOrganization").perform(self.get('model.selectedOrganization.id'));
            } else {
              self.get('paperToaster').show(self.intl.t('label.organization.error'), {
                duration: 3000
              });
            }
          },
          error: function () {
            self.get('paperToaster').show(self.intl.t('label.organization.error'), {
              duration: 3000
            });
          }
        });
      },
      openDeleteDialog: function () {
        this.set('deleteCompanyDialog', true);
      },
      deleteOrganization: function () {
        let self = this;
        this.set('deleteCompanyDialog', false);
        this.set('waitingDeleteAccount', true);
        this.get('model.selectedOrganization').destroyRecord().then(resp => {
          self.set('waitingDeleteAccount', false);
          self.set('deleteSuccessfull', true);
          self.set('model.selectedOrganization', null);
          self.get('searchOrganizations').perform();
        }).catch(function (error) {
          self.set('waitingDeleteAccount', false);
          self.set('deleteFailure', true);
        });
      },
      cancelDeleteOrganization: function () {
        this.set('deleteCompanyDialog', false);
      },
      closeDeleteAccountToast: function () {
        this.set('deleteSuccessfull', false);
        this.set('deleteFailure', false);
      }
    }
  });

  _exports.default = _default;
});