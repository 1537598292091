define("E4E/helpers/check-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkProgress = checkProgress;
  _exports.default = void 0;

  function checkProgress(params
  /*, hash*/
  ) {}

  var _default = Ember.Helper.helper(checkProgress);

  _exports.default = _default;
});