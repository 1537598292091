define("E4E/services/screen", ["exports", "ember-screen"], function (_exports, _emberScreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberScreen.default.extend({
    //isMobile: breakpoint(`(max-width: 96px)`),
    //isDesktop: breakpoint(`(min-width: 100px)`),
    is959: (0, _emberScreen.breakpoint)('(max-width: 959px)')
  });

  _exports.default = _default;
});