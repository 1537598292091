define("E4E/components/dialogs/joboffers/edit-language", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ForeignLanguage: {
      Description: {
        Code: "",
        Label: ""
      },
      ProficiencyLevel: {
        Listening: "",
        Reading: "",
        SpokenInteraction: "",
        SpokenProduction: "",
        Writing: ""
      },
      Scope: {
        Label: "",
        Value: "",
        DateIssue: ""
      },
      ReferenceTo: Ember.A([])
    },
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    scopes: ["ECTS", "HOURS", "DAYS"],
    language: null,
    listeningLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    readingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenInteractionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenProductionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    writingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    init: function () {
      this._super(...arguments);

      this.languageMatcher = this.languageMatcher.bind(this);
      this.get('getLanguages').perform();
    },
    getLanguages: (0, _emberConcurrency.task)(function* () {
      let languages = yield $.getJSON("/json-components/languages/languages_en.json");
      this.set('languages', languages);
      this.get('setDataToEdit').perform();
    }),
    setDataToEdit: (0, _emberConcurrency.task)(function* () {
      this.set('language', yield this.get('languages').filterBy('Code', this.get('foreignLanguage.Description.Code'))[0]);
      this.set('listeningLevel', this.get('foreignLanguage.ProficiencyLevel.Listening'));
      this.set('readingLevel', this.get('foreignLanguage.ProficiencyLevel.Reading'));
      this.set('spokenInteractionLevel', this.get('foreignLanguage.ProficiencyLevel.SpokenInteraction'));
      this.set('spokenProductionLevel', this.get('foreignLanguage.ProficiencyLevel.SpokenProduction'));
      this.set('writingLevel', this.get('foreignLanguage.ProficiencyLevel.Writing'));
      this.set('scope', this.get('foreignLanguage.Scope.Label'));
      this.set('number', this.get('foreignLanguage.Scope.Value'));
      this.set('dateIssue', this.get('foreignLanguage.Scope.DateIssue') != '' ? moment.utc(moment.utc(this.get('foreignLanguage.Scope.DateIssue')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString() : null);
    }),
    languageMatcher: function (language, term) {
      const ret = `${this.get('intl').t('label.languagesnames.' + language.Code.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      cancelEditLanguage: function () {
        this.cancelEditLanguage();
      },
      saveLanguageToJobOffer: function () {
        let foreignLanguage = Ember.copy(this.ForeignLanguage, true);
        foreignLanguage.Description.Label = this.get('language.Name');
        foreignLanguage.Description.Code = this.get('language.Code');
        foreignLanguage.ProficiencyLevel.Listening = this.get('listeningLevel');
        foreignLanguage.ProficiencyLevel.Reading = this.get('readingLevel');
        foreignLanguage.ProficiencyLevel.SpokenInteraction = this.get('spokenInteractionLevel');
        foreignLanguage.ProficiencyLevel.SpokenProduction = this.get('spokenProductionLevel');
        foreignLanguage.ProficiencyLevel.Writing = this.get('writingLevel');
        foreignLanguage.Scope.Label = this.get('scope');
        foreignLanguage.Scope.Value = this.get('number');

        if (this.get('dateIssue') != undefined && this.get('dateIssue') != null && this.get('dateIssue') != "") {
          foreignLanguage.Scope.DateIssue = moment.utc(moment.utc(this.get('dateIssue')).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate();
        }

        this.saveLanguageToJobOffer(foreignLanguage, false, this.get('index'));
      }
    }
  });

  _exports.default = _default;
});