define("E4E/routes/superadmin/users/index", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    memberName: '',
    page: 0,
    roles: ["RECRUITER", "PARTNER_MANAGER", "JOB_SEEKER", "ORGANIZATION_ADMIN", "ORGANIZATION_USER", "OUTPLACER"],
    status: ["ACCEPTED", "PENDING", "REJECTED", "NONE"],

    redirect(model, transition) {
      if (this.currentUserService.get('user.userRole.name') !== "SUPER_ADMIN") {
        this.transitionTo('noaccess');
      }
    },

    model() {
      return Ember.RSVP.hash({
        selectedUser: null,
        name: '',
        countryName: '',
        country: null,
        organization: '',
        roles: this.get('roles'),
        status: this.get('status'),
        page: this.get('page')
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("searchUsers");
      }
    }
  });

  _exports.default = _default;
});