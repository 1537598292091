define("E4E/components/users/edit-foreignlanguage", ["exports", "ember-concurrency", "ember-data"], function (_exports, _emberConcurrency, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Ember.Component.extend({
    ForeignLanguage: {
      Description: {
        Code: "",
        Label: ""
      },
      ProficiencyLevel: {
        Listening: "",
        Reading: "",
        SpokenInteraction: "",
        SpokenProduction: "",
        Writing: ""
      },
      Scope: {
        Label: "",
        Value: "",
        DateIssue: ""
      },
      ReferenceTo: Ember.A([])
    },
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    allReferences: Ember.A([]),
    scopes: ["ECTS", "HOURS", "DAYS"],
    certificates: Ember.computed('foreignLanguage.ReferenceTo.@each', 'selectedTeamMember.certificates.@each', function () {
      let certificatesArray = Ember.A([]);

      if (this.get('foreignLanguage.ReferenceTo') != null) {
        this.get('foreignLanguage.ReferenceTo').forEach(element => {
          this.get('selectedTeamMember.certificates').filter(function (certificate) {
            if (certificate.get('europassCertificateId') == element.idref) {
              certificatesArray.pushObject(certificate);
            }
          });
        });
      }

      return certificatesArray;
    }),
    deletedCertificates: Ember.A([]),
    language: null,
    listeningLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    readingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenInteractionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenProductionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    writingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    init: function () {
      this._super(...arguments);

      this.languageMatcher = this.languageMatcher.bind(this);
      this.get('getLanguages').perform();
    },
    getLanguages: (0, _emberConcurrency.task)(function* () {
      let languages = yield $.getJSON("/json-components/languages/languages_en.json");
      this.set('languages', languages);
      this.get('setDataToEdit').perform();
    }),
    setDataToEdit: (0, _emberConcurrency.task)(function* () {
      this.set('deletedCertificates', Ember.A([]));
      this.set('language', yield this.get('languages').filterBy('Code', this.get('foreignLanguage.Description.Code'))[0]);
      this.set('listeningLevel', this.get('foreignLanguage.ProficiencyLevel.Listening'));
      this.set('readingLevel', this.get('foreignLanguage.ProficiencyLevel.Reading'));
      this.set('spokenInteractionLevel', this.get('foreignLanguage.ProficiencyLevel.SpokenInteraction'));
      this.set('spokenProductionLevel', this.get('foreignLanguage.ProficiencyLevel.SpokenProduction'));
      this.set('writingLevel', this.get('foreignLanguage.ProficiencyLevel.Writing'));
      this.set('scope', this.get('foreignLanguage.Scope.Label'));
      this.set('number', this.get('foreignLanguage.Scope.Value'));
      this.set('dateIssue', this.get('foreignLanguage.Scope.DateIssue') != '' ? moment.utc(moment.utc(this.get('foreignLanguage.Scope.DateIssue')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString() : null);
    }),
    languageMatcher: function (language, term) {
      const ret = `${this.get('intl').t('label.languagesnames.' + language.Code.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      closeEditForeignLanguageDialog: function () {
        this.closeEditForeignLanguageDialog();
      },
      saveLanguage: function () {
        let foreignLanguage = Ember.copy(this.ForeignLanguage, true);
        foreignLanguage.Description.Label = this.get('intl').t('label.languagesnames.' + this.get('language.Code').toLowerCase());
        foreignLanguage.Description.Code = this.get('language.Code');
        foreignLanguage.ProficiencyLevel.Listening = this.get('listeningLevel');
        foreignLanguage.ProficiencyLevel.Reading = this.get('readingLevel');
        foreignLanguage.ProficiencyLevel.SpokenInteraction = this.get('spokenInteractionLevel');
        foreignLanguage.ProficiencyLevel.SpokenProduction = this.get('spokenProductionLevel');
        foreignLanguage.ProficiencyLevel.Writing = this.get('writingLevel');
        foreignLanguage.Scope.Label = this.get('scope');
        foreignLanguage.Scope.Value = this.get('number');

        if (this.get('dateIssue') != undefined && this.get('dateIssue') != null && this.get('dateIssue') != "") {
          foreignLanguage.Scope.DateIssue = moment.utc(moment.utc(this.get('dateIssue')).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate();
        }

        foreignLanguage.ReferenceTo = this.get('foreignLanguage.ReferenceTo');
        this.saveLanguage(foreignLanguage, this.get('certificates'), false, this.deletedCertificates, this.get('index'));
      },
      didSelectFilesAttachment: function (files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          files[0].originalFileName = files[0].name;
          this.get('certificates').pushObject(files[0]);

          let done = function (url) {
            self.set('fileUrl', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      removeCertificate: function (certificate) {
        if (certificate instanceof Model) {
          this.get('certificates').removeObject(certificate);
          this.get('deletedCertificates').pushObject(certificate);
        } else {
          this.get('certificates').removeObject(certificate);
        }
      }
    }
  });

  _exports.default = _default;
});