define("E4E/components/users/add-socialmedia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    socialMediaCopy: {
      name: "",
      link: ""
    },
    disableSave: Ember.computed('socialMediaName', 'socialMediaTemp', 'socialMediaLink', function () {
      if (this.get('socialMediaTemp') == null) {
        return true;
      } else {
        if (this.get('socialMediaLink') == null) {
          return true;
        } else {
          if (this.get('socialMediaTemp.name') === "Other") {
            if (this.get('socialMediaName') == null) {
              return true;
            } else {
              return false;
            }
          }

          return false;
        }
      }
    }),
    socialMedias: [{
      name: "Facebook"
    }, {
      name: "Instagram"
    }, {
      name: "Twitter"
    }, {
      name: "Linkedin"
    }, {
      name: "Other"
    }],
    init: function () {
      this._super(...arguments);

      this.set('socialMediaTemp', null);
      this.set('socialMediaName', null);
      this.set('socialMediaLink', null);
    },
    actions: {
      saveAddSocialMedia: function () {
        let socialMedia = Ember.copy(this.socialMediaCopy, true);
        socialMedia.link = this.get('socialMediaLink');

        if (this.get('socialMediaTemp.name') === "Other") {
          socialMedia.name = this.get('socialMediaName');
        } else {
          socialMedia.name = this.get('socialMediaTemp.name');
        }

        this.saveAddSocialMedia(socialMedia);
      },
      closeAddSocialMedia: function () {
        this.closeAddSocialMedia();
      }
    }
  });

  _exports.default = _default;
});