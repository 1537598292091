define("E4E/components/superadmin/menu-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    displaySubItem: false,
    actions: {
      openSubMenu: function () {
        this.set('displaySubItem', !this.get('displaySubItem'));
      }
    }
  });

  _exports.default = _default;
});