define("E4E/controllers/recruiters/dashboard", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    router: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      goToJobOffers: function () {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferStatus', ["ACTIVE"].toString());
        this.get('router').transitionTo("organizations.job-offers");
      },
      goToJobApplications: function () {
        let cookieService = this.get('cookies');
        cookieService.write('jobApplicationStatus', ["PENDING"]);
        this.get('router').transitionTo("organizations.job-applications");
      },
      goToJobOffer: function (jobOffer) {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferId', jobOffer.id);
        cookieService.write('jobOfferTitle', jobOffer.title);
        this.get('router').transitionTo("recruiters.job-offers.index");
      }
    }
  });

  _exports.default = _default;
});