define("E4E/controllers/superadmin/job-offers/index", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    jobOfferService: Ember.inject.service("joboffer"),
    jobOfferParams: ["image", "organization", "jobtitle", "jobapplications", "jobOfferQuestionBundles", "jobOfferEvaluationSchemeBundles", "onlypartner", "contactcompany", "jobofferpdf"],
    dialogService: Ember.inject.service('dialog-service'),
    fileService: Ember.inject.service('file-service'),
    jobSeekerMotivationalLetter: null,
    paperToaster: Ember.inject.service(),
    category: '',
    init: function () {
      this._super(...arguments);

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.jobOffers', Ember.A([]));
      this.set('model.suggestions', Ember.A([]));
      this.set('model.meta', null);
      this.set('model.meta2', null);
      this.get('searchJobOffers').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedJobOffer') != null || this.get('addJobOffer') || this.get('editJobOffer')) {
          if (Ember.$('#search_container_job_offers').hasClass('hide')) {
            Ember.$('#job-offer-container').addClass('show');
          } else {
            Ember.$('#search_container_job_offers').addClass('hide');
            Ember.$('#job-offer-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_job_offers').removeClass('hide');
          Ember.$('#job-offer-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_job_offers').removeClass('hide');
        Ember.$('#job-offer-container').removeClass('show');
      }
    },

    findJobOffer: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('joboffer', id, {
        include: this.jobOfferParams.toString()
      }).then(jobOffer => {
        this.set('model.selectedJobOffer', jobOffer);
        Ember.$('#job-offer-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').addClass('hide');
          Ember.$('#job-offer-container').addClass('show');
        }
      });
    }).restartable(),
    searchJobOffers: (0, _emberConcurrency.task)(function* (nextPage) {
      yield this.jobOfferService.filter(this.get('model.jobOfferName'), this.get('model.company'), this.get('model.jobSeekerCountry'), this.get('model.types'), this.get('model.city'), this.get('model.workExperience'), this.get('model.yearsExperience'), this.get('model.education'), this.get('model.educationScope'), this.get('model.educationNumber'), this.get('model.educationDateIssue'), this.get('model.languageName'), this.get('model.languageScope'), this.get('model.languageNumber'), this.get('model.languageDateIssue'), this.get('model.drivingLicenses'), this.get('model.competenceName'), this.get('model.category'), this.get('model.isFavorite'), null, this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.jobOffers').pushObjects(resp.jobOffers.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.jobOffers', resp.jobOffers.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    actions: {
      resetFilters: function () {
        this.set('model.jobOfferName', '');
        Ember.run.debounce(this, this.search, 1000);
      },
      updateJobOffer: function (value) {
        this.set('model.jobOfferName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateStatus: function (value) {
        /*this.set('model.jobOfferName', value);
        debounce(this, this.search, 1000);*/
      },
      updateWorkExperience: function (value) {
        this.set('model.workExperience', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducation: function (value) {
        this.set('model.education', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateTypes: function (value) {
        if (this.get('model.types').includes(value)) {
          this.get('model.types').removeObject(value);
        } else {
          this.get('model.types').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      searchAndFindJobOffer: function () {
        this.get("searchJobOffers").perform();
        this.get("findJobOffer").perform(this.get('model.jobOfferId'));
      },
      updateEducationScope: function (value) {
        if (value == null) {
          this.set('model.educationScope', "");
        } else {
          this.set('model.educationScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationNumber: function (value) {
        if (value == null) {
          this.set('model.educationNumber', "");
        } else {
          this.set('model.educationNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationDateIssue: function (value) {
        if (value == null) {
          this.set('model.educationDateIssue', "");
        } else {
          this.set('model.educationDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageScope: function (value) {
        if (value == null) {
          this.set('model.languageScope', "");
        } else {
          this.set('model.languageScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageNumber: function (value) {
        if (value == null) {
          this.set('model.languageNumber', "");
        } else {
          this.set('model.languageNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageDateIssue: function (value) {
        if (value == null) {
          this.set('model.languageDateIssue', "");
        } else {
          this.set('model.languageDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        if (value == null) {
          this.set('model.jobSeekerCountry', "");
        } else {
          this.set('model.jobSeekerCountry', value.iso3);
        }

        this.set('model.country', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCity: function (value) {
        this.set('model.city', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompany: function (value) {
        this.set('model.company', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguage: function (value) {
        this.set('model.languageName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateYearsExperience: function (value) {
        if (value == null) {
          this.set('model.yearsExperience', "");
        } else {
          this.set('model.yearsExperience', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateDrivingLicense: function (value) {
        if (this.get('model.drivingLicenses').includes(value)) {
          this.get('model.drivingLicenses').removeObject(value);
        } else {
          this.get('model.drivingLicenses').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompetences: function (value) {
        this.set('model.competenceName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateFavorite: function () {
        this.set('model.isFavorite', !this.get('model.isFavorite'));
        Ember.run.debounce(this, this.search, 1000);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchJobOffers').perform(true);
      },
      nextPage2: function () {
        this.set('model.page2', this.get('model.page2') + 1);
        this.get('searchSuggestions').perform(true);
      },
      searchJobOffers: function () {
        this.get("searchJobOffers").perform();
      },
      selectJobOffer: function (jobOfferId) {
        this.get("findJobOffer").perform(jobOfferId);
      },
      backToSearch: function () {
        this.set('addJobOffer', false);
        this.set('editJobOffer', false);
        this.set('model.selectedJobOffer', null);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_offers').removeClass('hide');
          Ember.$('#job-offer-container').removeClass('show');
        }
      },
      applyJobSeeker: function () {
        this.set('jobSeeker', null);
        this.set('jobSeeker', this.store.findRecord('user', this.currentUserService.get('user.id'), {
          include: ["certificates", "videos", "image", "sectors"].toString()
        })).then(() => {
          this.set('applyJobSeekerDialog', true);
        });
      },
      cancelApplyJobSeekers: function () {
        this.set('applyJobSeekerDialog', false);
      },
      applyJobSeekers: function (jobApplicationTemp) {
        let self = this;
        let jobApplication = this.store.createRecord('jobapplication');
        jobApplication.set('jobOfferId', this.get('model.selectedJobOffer.id'));
        jobApplication.set('jobSeekerId', this.currentUserService.get('user.id'));
        jobApplication.set('motivationLetter', jobApplicationTemp.motivationalLetter);
        jobApplication.set('availabiltyTravel', jobApplicationTemp.availableTravel == "YES" ? true : false);
        jobApplication.set('workExperience', jobApplicationTemp.workExperienceJson);
        jobApplication.set('qualificationTraining', jobApplicationTemp.qualificationTraining);
        jobApplication.set('skillsCompetences', jobApplicationTemp.skillsCompetences);

        if (jobApplicationTemp.jsonTemp != null) {
          jobApplication.set('json', JSON.stringify(jobApplicationTemp.jsonTemp));
        }

        if (this.get('jobSeekerMotivationalLetter') != null) {
          this.fileService.uploadMotivationalLetter.perform(this.get('jobSeekerMotivationalLetter')).then(motivationalLetter => {
            jobApplication.set('motivationalLetterId', motivationalLetter.get('id'));

            if (jobApplicationTemp.videoSelected != null) {
              jobApplication.set('videoId', jobApplicationTemp.videoSelected.get('id'));
              jobApplication.save().then(() => {
                self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
                  duration: 3000
                });
                self.set('applyJobSeekerDialog', false);
                Ember.run.debounce(self, self.search, 1000);
                self.get("findJobOffer").perform(self.get('model.selectedJobOffer.id'));
                self.currentUserService.load();
              });
            } else {
              if (jobApplicationTemp.videoUploaded != null) {
                self.fileService.uploadUserVideo.perform(jobApplicationTemp.videoUploaded).then(video => {
                  jobApplication.set('videoId', video.get('id'));
                  jobApplication.save().then(() => {
                    self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
                      duration: 3000
                    });
                    self.set('applyJobSeekerDialog', false);
                    Ember.run.debounce(self, self.search, 1000);
                    self.get("findJobOffer").perform(self.get('model.selectedJobOffer.id'));
                    self.currentUserService.load();
                  });
                });
              } else {
                jobApplication.save().then(() => {
                  self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
                    duration: 3000
                  });
                  self.set('applyJobSeekerDialog', false);
                  Ember.run.debounce(self, self.search, 1000);
                  self.get("findJobOffer").perform(self.get('model.selectedJobOffer.id'));
                  self.currentUserService.load();
                });
              }
            }
          });
        } else {
          if (jobApplicationTemp.videoSelected != null) {
            jobApplication.set('videoId', jobApplicationTemp.videoSelected.get('id'));
            jobApplication.save().then(() => {
              self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
                duration: 3000
              });
              self.set('applyJobSeekerDialog', false);
              Ember.run.debounce(self, self.search, 1000);
              self.get("findJobOffer").perform(self.get('model.selectedJobOffer.id'));
              self.currentUserService.load();
            });
          } else {
            if (jobApplicationTemp.videoUploaded != null) {
              self.fileService.uploadUserVideo.perform(jobApplicationTemp.videoUploaded).then(video => {
                jobApplication.set('videoId', video.get('id'));
                jobApplication.save().then(() => {
                  self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
                    duration: 3000
                  });
                  self.set('applyJobSeekerDialog', false);
                  Ember.run.debounce(self, self.search, 1000);
                  self.get("findJobOffer").perform(self.get('model.selectedJobOffer.id'));
                  self.currentUserService.load();
                });
              });
            } else {
              jobApplication.save().then(() => {
                self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
                  duration: 3000
                });
                self.set('applyJobSeekerDialog', false);
                Ember.run.debounce(self, self.search, 1000);
                self.get("findJobOffer").perform(self.get('model.selectedJobOffer.id'));
                self.currentUserService.load();
              });
            }
          }
        }
      },
      updateCategories: function (category) {
        this.set('model.category', category);
        Ember.run.debounce(this, this.search, 1000);
      },
      removeFromFavorite: function (hasFavorite) {
        let self = this;
        this.store.findRecord('flag', hasFavorite[0].id).then(flag => {
          if (flag.get('jobOfferId') === this.get('model.selectedJobOffer.id')) {
            flag.destroyRecord().then(() => {
              Ember.run.debounce(self, self.search, 100);
              self.currentUserService.load();
            });
          }
        });
      },
      addToFavorite: function () {
        let self = this;
        let flag = this.store.createRecord('flag');
        flag.set('isFavorite', true);
        flag.set('userId', this.currentUserService.get('user.id'));
        flag.set('jobOfferId', this.get('model.selectedJobOffer.id'));
        flag.save().then(() => {
          Ember.run.debounce(self, self.search, 100);
          self.currentUserService.load();
        });
      }
    }
  });

  _exports.default = _default;
});