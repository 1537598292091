define("E4E/components/jobapplications/delete-jobapplication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
    },
    actions: {
      closeDeleteApplicationDialog: function () {
        this.closeDeleteApplicationDialog();
      },
      deleteJobApplication: function () {
        this.deleteJobApplication();
      }
    }
  });

  _exports.default = _default;
});