define("E4E/templates/superadmin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GtNwviu3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start width100P maxHeight100VH\"],[9],[0,\"\\n  \"],[1,[27,\"page-base-layout\",null,[[\"leftSideBarOpen2\",\"class\"],[false,\"width100P\"]]],false],[0,\"\\n  \\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/superadmin.hbs"
    }
  });

  _exports.default = _default;
});