define("E4E/services/tag", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    save: function (tag, tagParams) {
      let self = this;
      return Ember.RSVP.resolve(tag.save()).then(() => {
        self.get('paperToaster').show(self.intl.t('label.tags.saved'), {
          duration: 3000
        });
        return this.load(tag.get('id'), tagParams);
      }).catch(error => {
        self.get('paperToaster').show(self.intl.t('label.tags.error'), {
          duration: 3000
        });
        return error;
      });
    },

    load(id, tagParams) {
      return this.store.findRecord('tag', id, {
        include: tagParams.toString()
      });
    },

    findByOrganizationId: function (organizationId) {
      return $.ajax({
        type: "GET",
        headers: this.get('headers'),
        url: this.get('serverUrl') + "/tags/search/findByOrganizationId/" + organizationId,
        contentType: "application/json",
        success: function (resp) {
          return resp;
        }
      });
    },

    addTagsToApplication(id, tagsTemp) {
      this.removeTagsFromApplication(id, "/tags/removefromapplication");
      let self = this;
      let indexJobQuestionBundles = 0;
      let indexEvaluationSchemeBundles = 0;
      /*
      if(questionBundlesTemp != null){
          questionBundlesTemp.forEach(function(bundle){
              let jobOfferQuestionBundle = self.store.createRecord('jobofferquestionbundle');
              jobOfferQuestionBundle.set('jobOfferId', id);
              jobOfferQuestionBundle.set('questionBundleId', bundle.id);
              jobOfferQuestionBundle.set('orderNumber', indexJobQuestionBundles);
              jobOfferQuestionBundle.save(); 
              indexJobQuestionBundles++;
          });
      }
      if(questionBundlesTempEval != null){
          questionBundlesTempEval.forEach(function(bundle){
              let jobOfferEvaluationSchemeBundle = self.store.createRecord('jobofferevaluationschemebundle');
              jobOfferEvaluationSchemeBundle.set('jobOfferId', id);
              jobOfferEvaluationSchemeBundle.set('questionBundleId', bundle.id);
              jobOfferEvaluationSchemeBundle.set('orderNumber', indexEvaluationSchemeBundles);
              jobOfferEvaluationSchemeBundle.save();
              indexEvaluationSchemeBundles++;
          });
      }
      */

      return Ember.RSVP.resolve(true);
    },

    removeTagsFromApplication: function (applicationId, url) {
      return $.ajax({
        type: "DELETE",
        headers: this.get('headers'),
        url: this.get('serverUrl') + url + "/" + applicationId,
        success: function (resp) {
          return resp;
        }
      });
    }
  });

  _exports.default = _default;
});