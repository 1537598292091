define("E4E/components/superadmin/users/search-container", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
    },
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },

    scrollContainer(event) {
      if (Math.ceil($("#search_container_users").scrollTop() + $("#search_container_users").outerHeight()) == $("#search_container_users")[0].scrollHeight) {
        if (!this.get('meta.last')) {
          this.nextPage();
        }
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      $("#search_container_users").on('scroll', this._boundScroll);
    },

    actions: {
      updateStatus: function (value) {
        this.updateStatus(value);
      },
      updateCountry: function (value) {
        this.updateCountry(value);
      },
      updateCompany: function (value) {
        this.updateCompany(value);
      },
      updateName: function (value) {
        this.updateName(value);
      },
      updateRoles: function (value) {
        this.updateRoles(value);
      },
      selectUser: function (id) {
        this.selectUser(id);
      },
      showFilters: function () {
        this.set('showAllFilters', true);
      },
      hideFilters: function () {
        this.set('showAllFilters', false);
      }
    }
  });

  _exports.default = _default;
});