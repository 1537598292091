define("E4E/controllers/forgot-password", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    authentication: Ember.inject.service('authentication'),
    init: function () {
      this._super(...arguments);

      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('locale', cookie.toString());
        this.set('intl.locale', cookie.toString());
      } else {
        this.set('locale', 'en');
        this.set('intl.locale', 'en');
      }

      this.set('emailValidation', [{
        message: this.intl.t('label.invalidformat'),
        validate: inputValue => {
          let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }]);
    },
    ajaxPost: function (content) {
      let self = this;
      self.set('showWaitingDialog', true);
      return Ember.$.ajax({
        type: "POST",
        url: _environment.default.APP.SPRING_HOST + "/api/registration/recoverPassword",
        data: 'email=' + content.email + '&locale=' + content.locale,
        success: function () {
          self.set('showWaitingDialog', false);
          self.set('successSentEmail', true);
          self.set('errorSentEmail', false);
        },
        error: function () {
          self.set('showWaitingDialog', false);
          self.set('successSentEmail', false);
          self.set('errorSentEmail', true);
        }
      }).then(function (response) {
        self.set('showWaitingDialog', false);
        self.set('message', response.message);
      });
    },
    changedLanguage: Ember.computed('locale', function () {
      if (this.get('language') === 'pt') {
        this.set('intl.locale', [this.get('language'), 'en', 'de']);
      } else if (this.get('language') === 'de') {
        this.set('intl.locale', [this.get('language'), 'en', 'pt']);
      } else {
        this.set('intl.locale', [this.get('language'), 'de', 'pt']);
      }
    }),
    language: null,
    languages: ['en', 'de', 'pt'],
    actions: {
      requestNewPassword: function () {
        let locale = this.get('intl.locale');
        var content = {
          email: this.get('model.email'),
          locale: locale
        };
        this.ajaxPost(content);
      },
      closeSuccessDialog: function () {
        this.set('showSuccessDialog', false);
      },
      closeErrorDialog: function () {
        this.set('showErrorDialog', false);
      },
      goToLoginPage: function () {
        this.router.transitionTo('login');
      },
      changeLanguage: function (newLocale) {
        this.cookies.write('locale', newLocale.toString());
        this.intl.set('locale', newLocale.toLowerCase());
        this.set('locale', newLocale);
      },
      openSelectionOptions: function () {
        this.set('registerOptions', true);
      },
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      closeOptionSelection: function () {
        this.set('registerOptions', false);
      }
    }
  });

  _exports.default = _default;
});