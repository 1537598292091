define("E4E/controllers/recruiters/organization/profile", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    fileService: Ember.inject.service('file-service'),
    organizationService: Ember.inject.service('organization'),
    advancedInformationOpened: false,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    sectorService: Ember.inject.service('sector'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    totalJobApplications: 0,
    hiredApplications: 0,
    pendingApplications: 0,
    deniedApplications: 0,
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    authorizedGet: function (url) {
      return $.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    init: function () {
      this._super(...arguments);

      this.set('model', {
        organization: null,
        topSectors: null
      });
    },
    findOrganization: (0, _emberConcurrency.task)(function* () {
      let organization = yield this.organizationService.load(this.get('model.id'), this.get('model.organizationParams'));
      this.set('model.organization', organization);
    }).enqueue(),
    getTopSectors: (0, _emberConcurrency.task)(function* () {
      let topSectors = yield this.authorizedGet('/sectors/search/jobOfferSectorsOrganization?organization=' + this.get('model.id'));
      this.set('model.topSectors', topSectors.toArray());
    }).enqueue(),
    currentUserService: Ember.inject.service('current-user'),
    actions: {
      findOrganization: function () {
        this.get('findOrganization').perform();
      },
      getTopSectors: function () {
        this.get('getTopSectors').perform();
      },
      goToJobOffers: function (status) {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferStatus', [status].toString());
        this.get('router').transitionTo("organizations.job-offers");
      },
      goToApplications: function (status) {
        let cookieService = this.get('cookies');

        if (status != null) {
          cookieService.write('jobApplicationStatus', [status]);
          this.get('router').transitionTo("organizations.job-applications");
        } else {
          this.get('router').transitionTo("organizations.job-applications");
        }
      },
      openAdvancedInfo: function () {
        this.set('advancedInformationOpened', true);
      },
      closeAdvancedInfo: function () {
        this.set('advancedInformationOpened', false);
      }
    }
  });

  _exports.default = _default;
});