define("E4E/services/store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Store.extend({
    unloadAll: function (model) {
      var self = this;
      let entries = self.peekAll(model).content;

      for (let i = 0; i < entries.length; i++) {
        self.unloadRecord(entries[i]);
      }
    }
  });

  _exports.default = _default;
});