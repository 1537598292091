define("E4E/components/questions/add/slider-question", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    minValue: null,
    maxValue: null,
    store: Ember.inject.service(),
    clearFields: (0, _emberConcurrency.task)(function* () {
      yield this.set('minValue', null);
      yield this.set('maxValue', null);
    }),
    init: function () {
      this._super(...arguments);

      this.clearFields.perform();
    },
    actions: {
      saveQuestion: function () {
        let question = this.store.createRecord('question');
        question.set('json', JSON.stringify({
          maxValue: this.get('maxValue'),
          minValue: this.get('minValue')
        }));
        this.addQuestions(question);
      },
      cancelQuestion: function () {
        this.cancelQuestion();
      }
    }
  });

  _exports.default = _default;
});