define("E4E/components/users/drivinglicense-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAttachments: false,
    currentUserService: Ember.inject.service('current-user'),
    actions: {
      showAttachments: function () {
        this.set('showAttachments', true);
      },
      editDrivingLicense: function (event) {
        this.editDrivingLicense(event);
      }
    }
  });

  _exports.default = _default;
});