define("E4E/controllers/company-registration", ["exports", "E4E/config/environment", "pell", "ember-concurrency"], function (_exports, _environment, _pell, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: '',
    password: '',
    authentication: Ember.inject.service('authentication'),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    uploadCvRegistration: false,
    registrationManual: false,
    router: Ember.inject.service(),
    seenPrivacy: false,
    companyEmail: '',
    companyName: '',
    country: null,
    companyCity: '',
    companyPhoneNumber: '',
    companyAddress: '',
    companyType: null,
    companyTypes: ["COMPANY", "PRIVATE_HOUSEHOLD", "PUBLIC_ORGANIZATION", "NON_PROFIT_ORGANIZATION", "OTHER"],
    validitySave: Ember.computed('companyType', 'companyEmail', 'companyName', 'country', 'companyCity', 'companyPhoneNumber', 'companyAddress', 'email', 'confirmEmail', 'firstName', 'lastName', 'password', 'confirmPassword', function () {
      if (this.get('email') != '' && this.get('confirmEmail') != '' && this.get('firstName') != '' && this.get('lastName') != '' && this.get('password') != '' && this.get('confirmPassword') != '' && !this.get('errorPasswordNotSame') && !this.get('errorEmailNotSame') && !this.get('errorSameCompanyEmail') && !this.get('errorSameEmail') && this.get('companyEmail') != '' && this.get('companyName') != '' && this.get('country') != null && this.get('companyCity') != '' && this.get('companyPhoneNumber') != '' && this.get('companyAddress') != '' && this.get('companyType') != null) {
        return false;
      } else {
        return true;
      }
    }),
    emailValidation: null,
    options: null,
    step1: true,
    init: function () {
      this._super(...arguments);

      this.set('model', null);
      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('intl.locale', cookie.toString());
      } else {
        this.set('intl.locale', 'en');
      }

      let message = this.intl.t("label.invalidformat");
      this.set('emailValidation', [{
        message: message,
        validate: inputValue => {
          let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }]);
      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield Ember.$.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      openSelectionOptions: function () {
        this.set('registerOptions', true);
      },
      closeOptionSelection: function () {
        this.set('registerOptions', false);
      },
      checkCompanyEmail: function (value) {
        this.set('companyEmail', value);
        Ember.$.get(_environment.default.APP.SPRING_HOST + "/users/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
          if (response === false) {
            return Ember.$.get(_environment.default.APP.SPRING_HOST + "/organizations/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
              if (response === false) {
                this.set('errorCompanyEmail', false);
              } else {
                this.set('errorCompanyEmail', true);
              }
            });
          } else {
            this.set('errorCompanyEmail', true);
          }
        });

        if (value === this.get('email')) {
          this.set('errorSameCompanyEmail', true);
          this.set('errorSameEmail', true);
        } else {
          this.set('errorSameCompanyEmail', false);
          this.set('errorSameEmail', false);
        }
      },
      checkEmail: function (value) {
        this.set('email', value);
        Ember.$.get(_environment.default.APP.SPRING_HOST + "/users/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
          if (response === false) {
            return Ember.$.get(_environment.default.APP.SPRING_HOST + "/organizations/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
              if (response === false) {
                this.set('errorEmail', false);
              } else {
                this.set('errorEmail', true);
              }
            });
          } else {
            this.set('errorEmail', true);
          }
        });

        if (value === this.get('confirmEmail')) {
          this.set('errorEmailNotSame', false);
        } else {
          if (this.get('confirmEmail') != '') {
            this.set('errorEmailNotSame', true);
          }
        }

        if (value === this.get('companyEmail')) {
          this.set('errorSameCompanyEmail', true);
          this.set('errorSameEmail', true);
        } else {
          this.set('errorSameCompanyEmail', false);
          this.set('errorSameEmail', false);
        }
      },
      checkConfirmEmail: function (value) {
        this.set('confirmEmail', value);

        if (value !== this.get('email')) {
          this.set('errorEmailNotSame', true);
        } else {
          this.set('errorEmailNotSame', false);
        }
      },
      checkPassword: function (value) {
        this.set('password', value);

        if (value === this.get('confirmPassword')) {
          this.set('errorPasswordNotSame', false);
        } else {
          if (this.get('confirmPassword') != '') {
            this.set('errorPasswordNotSame', true);
          }
        }
      },
      checkConfirmPassword: function (value) {
        this.set('confirmPassword', value);

        if (value !== this.get('password')) {
          this.set('errorPasswordNotSame', true);
        } else {
          this.set('errorPasswordNotSame', false);
        }
      },
      registerCompany: function () {
        let self = this;

        if (this.password.length <= 7) {
          self.set('errorInvalidPassword', true);
        } else {
          this.set('showWaitingDialog', true);
          self.set('errorInvalidPassword', false);
          let params = {
            firstName: this.get('firstName'),
            lastName: this.get('lastName'),
            email: this.get('email'),
            emailConfirmation: this.get('confirmEmail'),
            password: this.get('password'),
            passwordConfirmation: this.get('confirmPassword'),
            companyName: this.get('companyName'),
            companyDescription: this.get('description'),
            companyEmail: this.get('companyEmail'),
            phoneNumber: this.get('companyPhoneNumber'),
            city: this.get('companyCity'),
            country: this.get('country.iso3'),
            address: this.get('companyAddress'),
            locale: this.get('intl.locale')[0],
            companyType: this.get('companyType')
          };
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.APP.SPRING_HOST + "/api/registration/registerCompany",
            data: JSON.stringify(params),
            contentType: "application/json",
            success: function () {
              self.set('showWaitingDialog', false);
              self.router.transitionTo('registration-company-successfull');
            },
            error: function () {
              self.set('showWaitingDialog', false);
              self.set('showErrorMessage', true);
            }
          });
        }
      },
      closeFailureRegistrationToast: function () {
        this.set('showErrorMessage', false);
      },
      nextStep: function () {
        this.set('step1', false);
        this.set('step2', true);
      },
      previousStep: function () {
        this.set('step2', false);
        this.set('step1', true);
      }
    }
  });

  _exports.default = _default;
});