define("E4E/components/generic/generate-public-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeGeneratePublicLinkDialog: function () {
        this.closeGeneratePublicLinkDialog();
      },
      generateLink: function () {
        this.generateLink(this.get('expires'));
      }
    }
  });

  _exports.default = _default;
});