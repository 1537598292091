define("E4E/components/organizations/theme-changes", ["exports", "ember-paper/utils/palettes"], function (_exports, _palettes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paperTheme: Ember.inject.service('paper-theme'),
    palettes: Ember.computed(function () {
      return Object.keys(_palettes.default).map(key => {
        let palette = _palettes.default[key];
        return {
          name: key,
          palette
        };
      });
    }),
    actions: {
      installTheme: function () {
        this.set('params', {
          primary: this.get('primary'),
          accent: this.get('accent'),
          warn: this.get('warn')
        });
        this.sendAction('install', this.get('params'));
      }
    }
  });

  _exports.default = _default;
});