define("E4E/controllers/users/profile", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    currentUserService: Ember.inject.service('current-user'),
    fileService: Ember.inject.service('file-service'),
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    cropingCoverImage: false,
    cropingAvatarImage: false,
    socialSkills: {
      Description: '',
      Competences: Ember.A([])
    },
    organizationalSkills: {
      Description: '',
      Competences: Ember.A([])
    },
    professionalSkills: {
      Description: '',
      Competences: Ember.A([])
    },
    partner: null,
    partners: null,
    intl: Ember.inject.service(),
    link: '',
    paperToaster: Ember.inject.service(),
    authorizedPost: function (url, content, success) {
      return $.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        data: JSON.stringify(content),
        contentType: "application/json",
        success: success
      });
    },
    authorizedGetJSON: function (url, data, done) {
      return $.ajax({
        type: 'POST',
        url: _environment.default.APP.SPRING_HOST + url,
        contentType: 'application/json',
        data: JSON.stringify(data),
        headers: this.get('headers'),
        success: done
      });
    },
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    init: function () {
      this._super(...arguments);
    },
    findUser: (0, _emberConcurrency.task)(function* () {
      yield this.store.findRecord('user', this.get('model.id'), {
        include: this.get('model.userParams').toString()
      }).then(user => {
        this.set('addTeamMember', false);
        this.set('editTeamMember', false);
        this.set('model.user', user);

        if (user.get('publicLink') != null) {
          this.set('link', window.location.host + '/public-profile?token=' + user.get('publicLink.token'));
          console.log(this.get('link'));
        }
      });
      this.createLink.perform();
    }).restartable(),
    sortWorkExperienceByDate: (0, _emberConcurrency.task)(function* () {
      let workExperience = this.get('model.user.workExperienceJson').sort(function (a, b) {
        var dateFromA = new Date(a.Period.From.Year, a.Period.From.Month - 1, a.Period.From.Day);
        var dateFromB = new Date(b.Period.From.Year, b.Period.From.Month - 1, b.Period.From.Day);

        if (a.Period.Current && b.Period.Current) {
          return dateFromB - dateFromA;
        } else if (a.Period.Current && !b.Period.Current) {
          return -1;
        } else if (!a.Period.Current && b.Period.Current) {
          return 1;
        } else {
          if (a.Period.To.Day != null) {
            var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1, a.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1);
            } else {
              var dateToA = new Date(a.Period.To.Year);
            }
          }

          if (b.Period.To.Day != null) {
            var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1, b.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1);
            } else {
              var dateToB = new Date(b.Period.To.Year);
            }
          }

          return dateToB - dateToA;
        }
      });
      this.set('model.user.workExperience', JSON.stringify({
        workExperiences: workExperience
      }));
    }),
    sortEducationByDate: (0, _emberConcurrency.task)(function* () {
      let education = this.get('model.user.educationJson').sort(function (a, b) {
        var dateFromA = new Date(a.Period.From.Year, a.Period.From.Month - 1, a.Period.From.Day);
        var dateFromB = new Date(b.Period.From.Year, b.Period.From.Month - 1, b.Period.From.Day);

        if (a.Period.Current && b.Period.Current) {
          return dateFromB - dateFromA;
        } else if (a.Period.Current && !b.Period.Current) {
          return -1;
        } else if (!a.Period.Current && b.Period.Current) {
          return 1;
        } else {
          if (a.Period.To.Day != null) {
            var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1, a.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToA = new Date(a.Period.To.Year, a.Period.To.Month - 1);
            } else {
              var dateToA = new Date(a.Period.To.Year);
            }
          }

          if (b.Period.To.Day != null) {
            var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1, b.Period.To.Day);
          } else {
            if (a.Period.To.Month != null) {
              var dateToB = new Date(b.Period.To.Year, b.Period.To.Month - 1);
            } else {
              var dateToB = new Date(b.Period.To.Year);
            }
          }

          return dateToB - dateToA;
        }
      });
      this.set('model.user.education', JSON.stringify({
        educations: education
      }));
    }),
    generateNewCV: (0, _emberConcurrency.task)(function* () {
      let self = this;
      this.set('waitingCvDialog', true);
      self.authorizedGetJSON('/rest/files/curriculum/generate?userId=' + self.get('model.user.id') + '&locale=' + self.intl.get('locale'), '', function (respTemp) {
        self.set('waitingCvDialog', false);

        if (respTemp) {
          self.set('successGenerationCV', true);
          self.get('userService').load(self.get('model.user.id'), self.get('model.userParams'));
        } else {
          self.set('failureGenerationCV', true);
        }
      });
    }),
    createLink: (0, _emberConcurrency.task)(function* () {
      if (this.get('model.user.publicLink') != null) {
        this.set('publicLink', this.get('model.user.publicLink'));
        this.set('link', "https://" + window.location.host + '/public-profile?token=' + this.get('model.user.publicLink.token'));
      }
    }).restartable(),
    deleteNormalCompanyUser: (0, _emberConcurrency.task)(function* (userId) {
      let self = this;
      $.ajax({
        type: "POST",
        headers: this.get('headers'),
        url: _environment.default.APP.SPRING_HOST + "/users/companyaccount/delete",
        data: {
          userDeactiveId: userId,
          substituteId: self.get('toSubstituteUser') != null ? self.get('toSubstituteUser.id') : null
        },
        success: function (resp) {
          if (resp) {
            if (self.get('model.user.id') == self.currentUserService.get('user.id')) {
              self.set('showByeDialog', true);
              self.set('model.selectedTeamMember', null);
            } else {
              self.get('paperToaster').show(self.intl.t('label.user.deleted'), {
                duration: 3000
              });
              self.set('model.user', null);
            }

            debounce(self, self.search, 1000);
          } else {
            self.get('paperToaster').show(self.intl.t('label.user.deletederror'), {
              duration: 3000
            });
          }
        },
        error: function () {
          self.get('paperToaster').show(self.intl.t('label.user.deletederror'), {
            duration: 3000
          });
        }
      });
    }),
    getTeamMembers: (0, _emberConcurrency.task)(function* (userRole) {
      this.userService.filter('', [userRole], '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 1000).then(resp => {
        this.set('teamMembers', resp.users.toArray());
      });
    }),
    actions: {
      createLink: function () {
        this.createLink.perform();
      },
      closeFailureDialog: function () {
        this.set('failureGenerationCV', false);
      },
      closeSuccessDialog: function () {
        this.set('successGenerationCV', false);
      },
      deleteTeamMember: function () {
        this.set('deleteAccountStep', true);
      },
      deleteAccount: function () {
        let self = this;
        this.set('waitingDialog', true);
        $.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: _environment.default.APP.SPRING_HOST + "/users/normal/delete",
          data: {
            userDelete: this.get('model.user.id')
          },
          success: function (resp) {
            if (resp) {
              this.set('waitingDialog', false);
              self.set('showByeDialog', true);
            } else {
              this.set('waitingDialog', false);
              self.set('deleteFailureToast', true);
            }
          },
          error: function () {
            this.set('waitingDialog', false);
            self.set('showByeDialog', false);
          }
        }).then(function (response) {
          if (response) {
            self.set('waitingDialog', false);
            self.set('showByeDialog', true);
          } else {
            self.set('waitingDialog', false);
            self.set('showByeDialog', false);
          }
        });
      },
      findUser: function () {
        this.get('findUser').perform();
      },
      closeCoverImageDialog: function () {
        this.set('cropingCoverImage', false);
      },
      didSelectFilesHeader: function (files) {
        let self = this;
        this.set('cropingCoverImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileCover', files[0]);

          let done = function (url) {
            self.set('fileCoverSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      closeAvatarImageDialog: function () {
        this.set('cropingAvatarImage', false);
      },
      didSelectFilesAvatar: function (files) {
        let self = this;
        this.set('cropingAvatarImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileAvatar', files[0]);

          let done = function (url) {
            self.set('fileAvatarSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      didSelectFilesVideos: function (files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          this.fileService.uploadUserVideo.perform(files[0]).then(video => {
            self.get("findUser").perform();
          });
          this.set('videoUploaded', files[0]);

          let done = function (url) {
            self.set('videoUrl', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      uploadUserAvatar: function (avatar) {
        let self = this;
        this.fileService.get('uploadUserAvatarImage').perform(avatar, this.get('model.user')).then(() => {
          self.get("findUser").perform();
        });
      },
      uploadUserCover: function (cover) {
        let self = this;
        this.fileService.get('uploadUserCoverImage').perform(cover, this.get('model.user')).then(() => {
          self.get("findUser").perform();
        });
      },
      editBasicInformation: function () {
        let basicInformation = {
          firstName: this.get('model.user.firstName'),
          lastName: this.get('model.user.lastName'),
          address: this.get('model.user.address'),
          postalCode: this.get('model.user.postalCode'),
          city: this.get('model.user.city'),
          country: this.get('model.user.country'),
          birthDate: moment.utc(moment.utc(this.get('model.user.birthDate')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString(),
          profilePicture: this.get('model.user.profilePicture'),
          introduction: this.get('model.user.introduction'),
          profession: this.get('model.user.profession'),
          phoneNumber: this.get('model.user.phoneNumber'),
          website: this.get('model.user.website'),
          role: this.get('model.user.userRole'),
          editCv: this.get('model.user.outplacerEditCv'),
          editProfile: this.get('model.user.outplacerEditProfile'),
          profileCompanies: this.get('model.user.profileCompanies'),
          birthPlace: this.get('model.user.birthPlace'),
          citizenship: this.get('model.user.citizenship'),
          maritalStatus: this.get('model.user.maritalStatus'),
          socialMedia: this.get('model.user.socialMediaJson'),
          jobSeekersPool: this.get('model.user.jobSeekersPool'),
          careExperience: this.get('model.user.careExperience')
        };
        this.set('basicInformation', basicInformation);
        this.set('editBasicInformation', true);
      },
      saveEditBasicInformation: function () {
        let params = {
          firstName: this.get('basicInformation.firstName'),
          lastName: this.get('basicInformation.lastName'),
          address: this.get('basicInformation.address'),
          postalCode: this.get('basicInformation.postalCode'),
          city: this.get('basicInformation.city'),
          country: this.get('basicInformation.country') != null ? this.get('basicInformation.country') : "",
          birthDate: this.get('basicInformation.birthDate'),
          introduction: this.get('basicInformation.introduction'),
          profession: this.get('basicInformation.profession'),
          phoneNumber: this.get('basicInformation.phoneNumber'),
          website: this.get('basicInformation.website'),
          editCv: this.get('basicInformation.editCv'),
          editProfile: this.get('basicInformation.editProfile'),
          outplacerId: this.get('basicInformation.outplacerId'),
          partnerId: this.get('basicInformation.partnerId'),
          outplacerPermission: this.get('basicInformation.wantOutplacer') ? this.get('basicInformation.outplacerPermission') != null ? this.get('basicInformation.outplacerPermission') : "NO_PERMISSION" : null,
          profileCompanies: this.get('basicInformation.profileCompanies'),
          birthPlace: this.get('basicInformation.birthPlace'),
          citizenship: this.get('basicInformation.citizenship'),
          maritalStatus: this.get('basicInformation.maritalStatus'),
          wantOutplacer: this.get('basicInformation.wantOutplacer'),
          socialMedia: this.get('basicInformation.socialMedia'),
          jobSeekersPool: this.get('basicInformation.jobSeekersPool'),
          careExperience: this.get('basicInformation.careExperience')
        };
        console.log('params - ', params);
        this.set('model.user.firstName', params.firstName);
        this.set('model.user.lastName', params.lastName);
        this.set('model.user.address', params.address);
        this.set('model.user.postalCode', params.postalCode);
        this.set('model.user.city', params.city);
        this.set('model.user.country', params.country);

        if (params.birthDate != null && params.birthDate != undefined && params.birthDate != "") {
          this.set('model.user.birthDate', moment.utc(moment.utc(params.birthDate).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate());
        }

        this.set('model.user.introduction', params.introduction);
        this.set('model.user.profession', params.profession);
        this.set('model.user.phoneNumber', params.phoneNumber);
        this.set('model.user.website', params.website); // Outplacement functionalities

        this.set('model.user.outplacerEditCv', params.editCv);
        this.set('model.user.outplacerEditProfile', params.editProfile);
        this.set('model.user.outplacerId', params.outplacerId);
        this.set('model.user.outplacerPermission', params.outplacerPermission);
        this.set('model.user.profileCompanies', params.profileCompanies);
        this.set('model.user.wantOutplacer', params.wantOutplacer);
        this.set('model.user.partnerId', params.partnerId);
        this.set('model.user.birthPlace', params.birthPlace);
        this.set('model.user.citizenship', params.citizenship);
        this.set('model.user.maritalStatus', params.maritalStatus);
        this.set('model.user.socialMedia', JSON.stringify(params.socialMedia));
        this.set('model.user.jobSeekersPool', params.jobSeekersPool);
        this.set('model.user.careExperience', params.careExperience);
        this.userService.save(this.get('model.user'), this.get('model.userParams')).then(() => {
          this.set('currentUserService.user.outplacerId', params.outplacerId);
          this.set('basicInformation', null);
          this.set('editBasicInformation', false);
        });
      },
      closeEditBasicInformationDialog: function () {
        this.set('basicInformation', null);
        this.set('editBasicInformation', false);
      },
      downloadCV: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      generateCV: function () {
        this.generateNewCV.perform();
      },
      didSelectFilesCV: function (files, resetInput) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          self.fileService.get('uploadCV').perform(files[0], this.get('model.user')).then(() => {
            self.userService.save(self.get('model.user'), self.get('model.userParams'));
          });
        }

        resetInput();
      },
      addWorkExperience: function () {
        window.scrollTo(0, 0);
        this.set('addWorkExperience', true);
      },
      closeAddWorkExperienceDialog: function () {
        this.set('addWorkExperience', false);
      },
      closeEditWorkExperienceDialog: function () {
        this.set('editWorkExperienceDialog', false);
      },
      closeEditEducationDialog: function () {
        this.set('editEducationDialog', false);
      },
      editWorkExperience: function (workExperience, index) {
        window.scrollTo(0, 0);
        this.set('workExperience', workExperience);
        this.set('index', index);
        this.set('editWorkExperienceDialog', true);
      },
      editEducation: function (education, index) {
        window.scrollTo(0, 0);
        this.set('education', education);
        this.set('index', index);
        this.set('editEducationDialog', true);
      },
      saveWorkExperience: function (workExperience, certificates, deletedCertificates, index) {
        deletedCertificates.forEach(function (certificate) {
          workExperience.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              workExperience.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.user')).then(referencesTo => {
          if (referencesTo != null || referencesTo.length > 0) {
            workExperience.ReferenceTo.pushObjects(referencesTo);
          }

          if (this.get('model.user.workExperienceJson') != null) {
            let workExperienceJson = this.get('model.user.workExperienceJson');

            if (index != null) {
              workExperienceJson[index] = workExperience;
            } else {
              workExperienceJson.push(workExperience);
            }

            this.set('model.user.workExperience', JSON.stringify({
              workExperiences: workExperienceJson
            }));
          } else {
            let workExperienceArray = Ember.A([]);
            workExperienceArray.push(workExperience);
            this.set('model.user.workExperience', JSON.stringify({
              workExperiences: workExperienceArray
            }));
          }

          this.sortWorkExperienceByDate.perform().then(() => {
            this.userService.save(this.get('model.user'), this.get('model.userParams'));
          });
        });
        this.set('addWorkExperience', false);
        this.set('editWorkExperienceDialog', false);
      },
      addEducation: function () {
        window.scrollTo(0, 0);
        this.set('addEducation', true);
      },
      closeAddEducationDialog: function () {
        this.set('addEducation', false);
      },
      saveEducation: function (education, certificates, deletedCertificates, index, outdated) {
        deletedCertificates.forEach(function (certificate) {
          education.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              education.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.user')).then(referencesTo => {
          if (referencesTo != null || referencesTo.length > 0) {
            education.ReferenceTo.pushObjects(referencesTo);
          }

          if (this.get('model.user.educationJson') != null) {
            let educationJson = this.get('model.user.educationJson');

            if (index != null) {
              educationJson[index] = education;
            } else {
              educationJson.push(education);
            }

            this.set('model.user.education', JSON.stringify({
              educations: educationJson
            }));
          } else {
            let educationArray = Ember.A([]);
            educationArray.push(education);
            this.set('model.user.education', JSON.stringify({
              educations: educationArray
            }));
          }

          this.sortEducationByDate.perform().then(() => {
            this.userService.save(this.get('model.user'), this.get('model.userParams'));
          });
        });
        this.set('addEducation', false);
        this.set('editEducationDialog', false);
      },
      addLanguage: function () {
        window.scrollTo(0, 0);
        this.set('addLanguage', true);
      },
      editForeignLanguage: function (foreignLanguage, index) {
        window.scrollTo(0, 0);
        this.set('foreignLanguage', foreignLanguage);
        this.set('index', index);
        this.set('editForeignLanguage', true);
      },
      closeEditForeignLanguageDialog: function () {
        this.set('editForeignLanguage', false);
      },
      closeAddLanguageDialog: function () {
        this.set('addLanguage', false);
      },
      saveLanguage: function (language, certificates, motherTongue, deletedCertificates, index) {
        deletedCertificates.forEach(function (certificate) {
          language.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              language.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.user')).then(referencesTo => {
          if (referencesTo != null || referencesTo.length > 0) {
            language.ReferenceTo.pushObjects(referencesTo);
          }

          if (this.get('model.user.skillsJson') != null) {
            if (this.get('model.user.skillsJson.Linguistic') != null) {
              if (motherTongue) {
                if (this.get('model.user.skillsJson.Linguistic.MotherTongue') != null) {
                  let skills = this.get('model.user.skillsJson');
                  skills.Linguistic.MotherTongue.push(language);
                  this.set('model.user.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.user'), this.get('model.userParams'));
                } else {
                  let MotherTongue = Ember.A([]);
                  MotherTongue.push(language);
                  let skills = this.get('model.user.skillsJson');
                  skills.Linguistic.MotherTongue = MotherTongue;
                  this.set('model.user.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.user'), this.get('model.userParams'));
                }
              } else {
                if (this.get('model.user.skillsJson.Linguistic.ForeignLanguage') != null) {
                  let skills = this.get('model.user.skillsJson');

                  if (index != null) {
                    skills.Linguistic.ForeignLanguage[index] = language;
                  } else {
                    skills.Linguistic.ForeignLanguage.push(language);
                  }

                  this.set('model.user.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.user'), this.get('model.userParams'));
                } else {
                  let ForeignLanguage = Ember.A([]);
                  ForeignLanguage.push(language);
                  let skills = this.get('model.user.skillsJson');
                  skills.Linguistic.ForeignLanguage = ForeignLanguage;
                  this.set('model.user.skills', JSON.stringify({
                    skills: skills
                  }));
                  this.userService.save(this.get('model.user'), this.get('model.userParams'));
                }
              }
            } else {
              if (motherTongue) {
                let skills = this.get('model.user.skillsJson');
                skills.Linguistic = {
                  "MotherTongue": [language]
                };
                this.set('model.user.skills', JSON.stringify({
                  skills: skills
                }));
                this.userService.save(this.get('model.user'), this.get('model.userParams'));
              } else {
                let skills = this.get('model.user.skillsJson');
                skills.Linguistic = {
                  "ForeignLanguage": [language]
                };
                this.set('model.user.skills', JSON.stringify({
                  skills: skills
                }));
                this.userService.save(this.get('model.user'), this.get('model.userParams'));
              }
            }
          } else {
            if (motherTongue) {
              let skills = {
                "Linguistic": {
                  "MotherTongue": [language]
                }
              };
              this.set('model.user.skills', JSON.stringify({
                skills: skills
              }));
              this.userService.save(this.get('model.user'), this.get('model.userParams'));
            } else {
              let skills = {
                "Linguistic": {
                  "ForeignLanguage": [language]
                }
              };
              this.set('model.user.skills', JSON.stringify({
                skills: skills
              }));
              this.userService.save(this.get('model.user'), this.get('model.userParams'));
            }
          }
        });
        this.set('addLanguage', false);
        this.set('editForeignLanguage', false);
      },
      deleteForeignLanguage: function (foreignLanguage) {
        let self = this;
        foreignLanguage.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.user.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.user.skillsJson.Linguistic.ForeignLanguage').removeObject(foreignLanguage);
        this.set('model.user.skills', JSON.stringify({
          skills: this.get('model.user.skillsJson')
        }));
        this.userService.save(this.get('model.user'), this.get('model.userParams'));
      },
      removeMotherLanguage: function (motherTongue) {
        let self = this;
        motherTongue.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.user.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.user.skillsJson.Linguistic.MotherTongue').removeObject(motherTongue);
        this.set('model.user.skills', JSON.stringify({
          skills: this.get('model.user.skillsJson')
        }));
        this.userService.save(this.get('model.user'), this.get('model.userParams'));
      },
      deleteWorkExperience: function (workExperience) {
        let self = this;
        workExperience.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.user.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.user.workExperienceJson').removeObject(workExperience);
        this.set('model.user.workExperience', JSON.stringify({
          workExperiences: this.get('model.user.workExperienceJson')
        }));
        this.userService.save(this.get('model.user'), this.get('model.userParams'));
      },
      deleteEducation: function (education) {
        let self = this;
        education.ReferenceTo.forEach(function (referenceTo) {
          let certificate = self.get('model.user.certificates').findBy('europassCertificateId', referenceTo.idref);

          if (certificate != null) {
            certificate.destroyRecord();
          }
        });
        this.get('model.user.educationJson').removeObject(education);
        this.set('model.user.education', JSON.stringify({
          educations: this.get('model.user.educationJson')
        }));
        this.userService.save(this.get('model.user'), this.get('model.userParams'));
      },
      editDigitalSkills: function () {
        window.scrollTo(0, 0);

        if (this.get('model.user.skillsJson') != null) {
          if (this.get('model.user.skillsJson.Computer') != null) {
            this.set('digitalSkill', {
              ProficiencyLevel: {
                Information: this.get('model.user.skillsJson.Computer.ProficiencyLevel.Information'),
                Communication: this.get('model.user.skillsJson.Computer.ProficiencyLevel.Communication'),
                ContentCreation: this.get('model.user.skillsJson.Computer.ProficiencyLevel.ContentCreation'),
                Safety: this.get('model.user.skillsJson.Computer.ProficiencyLevel.Safety'),
                ProblemSolving: this.get('model.user.skillsJson.Computer.ProficiencyLevel.ProblemSolving')
              },
              ReferenceTo: this.get('model.user.skillsJson.Computer.ReferenceTo')
            });
          } else {
            this.set('digitalSkill', {
              ProficiencyLevel: {
                Information: null,
                Communication: null,
                ContentCreation: null,
                Safety: null,
                ProblemSolving: null
              },
              ReferenceTo: Ember.A([])
            });
          }
        } else {
          this.set('digitalSkill', {
            ProficiencyLevel: {
              Information: null,
              Communication: null,
              ContentCreation: null,
              Safety: null,
              ProblemSolving: null
            },
            ReferenceTo: Ember.A([])
          });
        }

        this.set('editDigitalSkills', true);
      },
      closeEditDigitalSkillsDialog: function () {
        this.set('editDigitalSkills', false);
      },
      saveDigitalSkills: function (digitalSkill, certificates, deletedCertificates) {
        deletedCertificates.forEach(function (certificate) {
          digitalSkill.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              digitalSkill.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.user')).then(referencesTo => {
          digitalSkill.ReferenceTo.pushObjects(referencesTo);

          if (this.get('model.user.skillsJson') != null) {
            this.set('model.user.skillsJson.Computer', digitalSkill);
            this.set('model.user.skills', JSON.stringify({
              skills: this.get('model.user.skillsJson')
            }));
            this.userService.save(this.get('model.user'), this.get('model.userParams'));
          } else {
            let skills = {
              Computer: digitalSkill
            };
            this.set('model.user.skills', JSON.stringify({
              skills: skills
            }));
            this.userService.save(this.get('model.user'), this.get('model.userParams'));
          }
        });
        this.set('editDigitalSkills', false);
      },
      closeEditDrivingLicenseDialog: function () {
        this.set('editDrivingLicense', false);
      },
      saveDrivingLicense: function (drivingLicense, certificates, deletedCertificates) {
        deletedCertificates.forEach(function (certificate) {
          drivingLicense.ReferenceTo.forEach(function (attachment) {
            if (attachment.idref === certificate.get('europassCertificateId')) {
              drivingLicense.ReferenceTo.removeObject(attachment);
            }
          });
          certificate.destroyRecord();
        });
        this.fileService.uploadCertificates.perform(certificates, this.get('model.user')).then(referencesTo => {
          drivingLicense.ReferenceTo.pushObjects(referencesTo);

          if (this.get('model.user.skillsJson') != null) {
            this.set('model.user.skillsJson.Driving', drivingLicense);
            this.set('model.user.skills', JSON.stringify({
              skills: this.get('model.user.skillsJson')
            }));
            this.userService.save(this.get('model.user'), this.get('model.userParams'));
          } else {
            let skills = {
              Driving: drivingLicense
            };
            this.set('model.user.skills', JSON.stringify({
              skills: skills
            }));
            this.userService.save(this.get('model.user'), this.get('model.userParams')).then(() => {
              console.log(this.get('dialogEvent'));
            });
          }
        });
        this.set('editDrivingLicense', false);
      },
      editDrivingLicense: function (event) {
        this.set('dialogEvent', event);
        window.scrollTo(0, 0);

        if (this.get('model.user.skillsJson') != null) {
          if (this.get('model.user.skillsJson.Driving') != null) {
            this.set('drivingLicense', {
              Description: this.get('model.user.skillsJson.Driving.Description'),
              ReferenceTo: this.get('model.user.skillsJson.Driving.ReferenceTo')
            });
          } else {
            this.set('drivingLicense', {
              Description: Ember.A([]),
              ReferenceTo: Ember.A([])
            });
          }
        } else {
          this.set('drivingLicense', {
            Description: Ember.A([]),
            ReferenceTo: Ember.A([])
          });
        }

        this.set('editDrivingLicense', true);
      },
      editSocialSkills: function (socialSkill) {
        window.scrollTo(0, 0);

        if (socialSkill != null && socialSkill != undefined) {
          this.set('socialSkill', Ember.copy(socialSkill, true));
        } else {
          this.set('socialSkill', Ember.copy(this.socialSkills, true));
        }

        this.set('editSocialSkill', true);
      },
      editOrganizationSkills: function (organizationalSkill) {
        window.scrollTo(0, 0);

        if (organizationalSkill != null && organizationalSkill != undefined) {
          this.set('organizationalSkill', Ember.copy(organizationalSkill, true));
        } else {
          this.set('organizationalSkill', Ember.copy(this.organizationalSkills, true));
        }

        this.set('editOrganizationalSkill', true);
      },
      editProfessionalSkills: function (professionalSkill) {
        window.scrollTo(0, 0);

        if (professionalSkill != null && professionalSkill != undefined) {
          this.set('professionalSkill', Ember.copy(professionalSkill, true));
        } else {
          this.set('professionalSkill', Ember.copy(this.professionalSkills, true));
        }

        this.set('editProfessionalSkill', true);
      },
      saveSocialSkill: function (socialSkill) {
        if (this.get('model.user.skillsJson') != null) {
          this.set('model.user.skillsJson.Communication', socialSkill);
          this.set('model.user.skills', JSON.stringify({
            skills: this.get('model.user.skillsJson')
          }));
          this.userService.save(this.get('model.user'), this.get('model.userParams'));
        } else {
          let Communication = {
            Competences: socialSkill.Competences
          };
          this.set('model.user.skills', JSON.stringify({
            skills: Communication
          }));
          this.userService.save(this.get('model.user'), this.get('model.userParams'));
        }

        this.set('editSocialSkill', false);
      },
      closeEditSocialSkillDialog: function () {
        this.set('editSocialSkill', false);
      },
      closeEditOrganizationSkillDialog: function () {
        this.set('editOrganizationalSkill', false);
      },
      saveOrganizationSkill: function (organizationalSkill) {
        if (this.get('model.user.skillsJson') != null) {
          this.set('model.user.skillsJson.Organisational', organizationalSkill);
          this.set('model.user.skills', JSON.stringify({
            skills: this.get('model.user.skillsJson')
          }));
          this.userService.save(this.get('model.user'), this.get('model.userParams'));
        } else {
          let Organisational = {
            Competences: organizationalSkill.Competences
          };
          this.set('model.user.skills', JSON.stringify({
            skills: Organisational
          }));
          this.userService.save(this.get('model.user'), this.get('model.userParams'));
        }

        this.set('editOrganizationalSkill', false);
      },
      closeEditProfessionalSkillDialog: function () {
        this.set('editProfessionalSkill', false);
      },
      saveProfessionalSkill: function (professionalSkill) {
        if (this.get('model.user.skillsJson') != null) {
          this.set('model.user.skillsJson.JobRelated', professionalSkill);
          this.set('model.user.skills', JSON.stringify({
            skills: this.get('model.user.skillsJson')
          }));
          this.userService.save(this.get('model.user'), this.get('model.userParams'));
        } else {
          let JobRelated = {
            Competences: professionalSkill.Competences
          };
          this.set('model.user.skills', JSON.stringify({
            skills: JobRelated
          }));
          this.userService.save(this.get('model.user'), this.get('model.userParams'));
        }

        this.set('editProfessionalSkill', false);
      },
      changePassword: function () {
        this.set('changePasswordDialog', true);
      },
      closeChangePasswordDialog: function () {
        this.set('changePasswordDialog', false);
      },
      saveNewPassword: function (params) {
        let self = this;
        this.authorizedPost(_environment.default.APP.SPRING_HOST + '/users/' + this.get('model.user.id') + '/change/password', params, null).then(resp => {
          if (resp) {
            self.set('successChangePassword', true);
            this.set('changePasswordDialog', false);
          } else {
            self.set('failureChangePassword', true);
          }
        });
      },
      closeChangePasswordToast: function () {
        this.set('successChangePassword', false);
        this.set('failureChangePassword', false);
      },
      closeToast: function () {
        this.set('deleteFailureToast', false);
        this.set('deleteSuccessToast', false);
      },
      downloadCVWord: function (originalFileName, id) {
        let self = this;
        this.get('store').findRecord('file', id).then(function (record) {
          record.download().then(wordContent => self.saveFileAs(originalFileName + ".docx", wordContent, 'application/msword'));
        });
      },
      changePartner: function (partners, partner) {
        this.set('partners', partners);
        this.set('partner', partner);
        this.set('changePartnerDialog', true);
      },
      cancelChangePartner: function () {
        this.set('changePartnerDialog', false);
      },
      requestNewPartner: function (newPartner) {
        this.partnerService.requestPartner(newPartner.get('id'), this.currentUserService.get('user.id'));
        this.userService.load(this.get('model.user.id'), this.get('model.userParams'));
        this.set('changePartnerDialog', false);
      },
      generatePublicLink: function () {
        this.set('generatePublicLinkDialog', true);
      },
      closeGeneratePublicLinkDialog: function () {
        this.set('generatePublicLinkDialog', false);
      },
      generateLink: function (expires) {
        let self = this;
        let expiresNew = moment.utc(moment.utc(expires).format('YYYY/MM/DD'), 'YYYY/MM/DD').add({
          'hours': 23,
          'minuts': 59,
          'seconds': 59
        }).toDate();
        $.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: this.get('serverUrl') + "/api/user/generate/shareable/profile/token",
          data: {
            userId: this.get('model.user.id'),
            expires: expiresNew
          },
          success: function (resp) {
            self.set('publicLink', true);
            self.set('link', "https://" + window.location.host + '/public-profile?token=' + resp);
          }
        });
      },
      removeVideo: function (video) {
        this.set('deleteVideoDialog', true);
        this.set('deletingVideo', video);
      },
      deleteVideo: function (video) {
        let self = this;
        video.destroyRecord().then(() => {
          self.set('deleteVideoDialog', false);
          self.get('findUser').perform();
          self.set('toastVideoDeleted', true);
        });
      },
      cancelDelete: function () {
        this.set('deleteVideoDialog', false);
        this.set('deletingVideo', null);
      },
      chooseVideo: function (video) {
        this.set('videoSelected', video);
      },
      closeToastDeleteVideo: function () {
        this.set('toastVideoDeleted', false);
      },
      removePartner: function (partner) {
        this.set('editBasicInformation', false);
        this.set('selectedPartner', partner);
        this.set('leavePartnerDialog', true);
      },
      cancelLeavePartner: function () {
        this.set('leavePartnerDialog', false);
        this.set('editBasicInformation', true);
      },
      leavePartner: function () {
        let user = this.get('model.user');
        user.set('partnerId', null);
        user.set('outplacerId', null);
        this.userService.save(user, this.get('model.userParams')).then(() => {
          this.set('leavePartnerDialog', false);
          this.currentUserService.load();
        });
      },
      deleteOrganizationUser: function () {
        this.userService.checkCanDelete(this.get('model.user.id')).then(resp => {
          if (resp) {
            this.set('toDeleteUser', this.get('model.user'));
            this.set('toSubstituteUser', null);
            this.set('deleteAccountStep', true);
          } else {
            this.set('chooseTeamMember', true);
          }
        });
      },
      closeDeleteStepDialog: function () {
        this.set('toDeleteUser', null);
        this.set('toSubstituteUser', null);
        this.set('deleteAccountStep', false);
      },
      closeChooseTeamMemberDialog: function () {
        this.set('chooseTeamMember', false);
      },
      saveChooseTeamMemberDialog: function (user) {
        this.set('toDeleteUser', this.get('model.user'));
        this.set('toSubstituteUser', user);
        this.set('deleteAccountStepOrganizations', true);
        this.set('chooseTeamMember', false);
      },
      deleteTeamMemberAccount: function (id) {
        this.deleteNormalCompanyUser.perform(id);
        this.set('deleteAccountStepOrganizations', false);
      },
      openAddSocialMedia: function () {
        this.set('editBasicInformation', false);
        this.set('addSocialMediaDialog', true);
      },
      openEditSocialMedia: function (socialMedia, index) {
        this.set('socialMedia', socialMedia);
        this.set('index', index);
        this.set('editBasicInformation', false);
        this.set('editSocialMediaDialog', true);
      },
      closeAddSocialMedia: function () {
        this.set('addSocialMediaDialog', false);
        this.set('editBasicInformation', true);
      },
      saveAddSocialMedia: function (socialMedia) {
        if (this.get('basicInformation.socialMedia') != null) {
          this.get('basicInformation.socialMedia').push(socialMedia);
        } else {
          this.set('basicInformation.socialMedia', [socialMedia]);
        }

        this.set('addSocialMediaDialog', false);
        this.set('editBasicInformation', true);
      },
      closeEditSocialMedia: function () {
        this.set('editSocialMediaDialog', false);
        this.set('editBasicInformation', true);
      },
      saveEditSocialMedia: function (socialMedia) {
        this.get('basicInformation.socialMedia')[this.get('index')] = socialMedia;
        this.set('editSocialMediaDialog', false);
        this.set('editBasicInformation', true);
      },
      removeSocialMedia: function (socialMedia, index) {
        this.get('basicInformation.socialMedia').removeObject(socialMedia);
      }
    }
  });

  _exports.default = _default;
});