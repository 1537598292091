define("E4E/serializers/job-offer-shareable-link", ["exports", "ember-data", "E4E/mixins/keep-only-changed"], function (_exports, _emberData, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _keepOnlyChanged.default, {
    extractMeta: function (store, typeClass, payload) {
      let meta = {};
      let links;

      if (payload.page) {
        meta.page = payload.page;
        delete payload.page;
      }

      return meta;
    },
    normalizeSingleResponse: function (store, primaryModelClass, payload, id, requestType) {
      let links;

      if (payload._links) {
        links = {};
        Object.keys(payload._links).forEach(link => {
          if (link === 'self') {
            links[link] = payload._links.self.href.replace('http://portal.cuvid.eu', 'https://portal.cuvid.eu/api');
          } else {
            links[link] = payload._links.self.href.replace('http://portal.cuvid.eu', 'https://portal.cuvid.eu/api') + '/' + link;
          }
        });
        delete payload._links;
        payload.links = links;
      }

      let modelKey = primaryModelClass.modelName;
      let aux = {};
      Object.keys(payload).forEach(key => {
        aux[key] = payload[key];
        delete payload[key];
      });
      payload[modelKey] = aux;
      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, true);
    },
    normalizeArrayResponse: function (store, primaryModelClass, payload, id, requestType) {
      let shareableLinksPayload = {};
      shareableLinksPayload.joboffershareableprofilelinks = payload;
      return this._normalizeResponse(store, primaryModelClass, shareableLinksPayload, id, requestType, false);
    },
    normalizeQueryResponse: function (store, primaryModelClass, payload, id, requestType) {
      let shareableLinksPayload = {};
      let page = {};
      shareableLinksPayload.joboffershareableprofilelinks = payload.content;
      page.totalPages = payload.totalPages;
      page.totalElements = payload.totalElements;
      page.size = payload.size;
      page.number = payload.number;
      shareableLinksPayload.page = page;
      return this._normalizeResponse(store, primaryModelClass, shareableLinksPayload, id, requestType, false);
    }
  });

  _exports.default = _default;
});