define("E4E/controllers/reset-password", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    authentication: Ember.inject.service('authentication'),
    password: "",
    confirmPassword: "",
    init: function () {
      this._super(...arguments);

      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('locale', cookie.toString());
      } else {
        this.set('locale', 'en');
      }
    },
    ajaxPost: function (model) {
      let self = this;
      $.ajax({
        type: "POST",
        url: _environment.default.APP.SPRING_HOST + "/api/registration/changePassword",
        data: JSON.stringify(model),
        contentType: "application/json",
        success: function () {
          self.set('showWaitingDialog', false);
          self.set('successChangePassword', true);
          self.set('errorChangePassword', false);
          self.set('password', "");
          self.set('confirmPassword', "");
        },
        error: function () {
          self.set('showWaitingDialog', false);
          self.set('errorChangePassword', true);
          self.set('successChangePassword', false);
        }
      }).then(function (response) {
        self.set('message', response.message);
      });
    },
    actions: {
      goToLogin: function () {
        this.get('router').transitionTo('login');
      },
      changePassword: function () {
        let model = {
          token: this.get('model.token'),
          locale: this.intl.get('locale')[0],
          password: this.get('password'),
          passwordConfirmation: this.get('confirmPassword')
        };
        this.set('showWaitingDialog', true);
        this.ajaxPost(model);
      },
      openWaitingDialog: function () {
        this.set('showWaitingDialog', true);
      },
      closeWaitingDialog: function () {
        this.set('showWaitingDialog', false);
      },
      closeSuccessDialog: function () {
        this.set('showWaitingDialog', false);
      },
      closeErrorDialog: function () {
        this.set('showErrorDialog', false);
      },
      goToLoginPage: function () {
        this.router.transitionTo('login');
      },
      changeLanguage: function (newLocale) {
        this.cookies.write('locale', newLocale.toString());
        this.intl.set('locale', newLocale.toLowerCase());
        this.set('locale', newLocale);
      },
      openSelectionOptions: function () {
        this.set('registerOptions', true);
      },
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      closeOptionSelection: function () {
        this.set('registerOptions', false);
      },
      checkPassword: function (value) {
        this.set('password', value);

        if (value === this.get('confirmPassword')) {
          this.set('errorPasswordNotSame', false);
        } else {
          if (this.get('confirmPassword') != '') {
            this.set('errorPasswordNotSame', true);
          }
        }
      },
      checkConfirmPassword: function (value) {
        this.set('confirmPassword', value);

        if (value !== this.get('password')) {
          this.set('errorPasswordNotSame', true);
        } else {
          this.set('errorPasswordNotSame', false);
        }
      }
    }
  });

  _exports.default = _default;
});