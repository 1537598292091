define("E4E/models/reportblogitem", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    blogPostId: _emberData.default.attr('string'),
    blogCommentId: _emberData.default.attr('string'),
    reasonReport: _emberData.default.attr('string'),
    validated: _emberData.default.attr('boolean'),
    userId: _emberData.default.attr('string'),
    blogPostDto: _emberData.default.belongsTo('blogpost', {
      async: false,
      inverse: null
    }),
    blogCommentDto: _emberData.default.belongsTo('blogcomment', {
      async: false,
      inverse: null
    }),
    userDto: _emberData.default.belongsTo('user', {
      async: false,
      inverse: null
    })
  });

  _exports.default = _default;
});