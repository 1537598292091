define("E4E/components/questions/add/certificate-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      saveQuestion: function () {
        let question = this.store.createRecord('question');
        question.set('json', JSON.stringify({}));
        this.addQuestions(question);
      },
      cancelQuestion: function () {
        this.cancelQuestion();
      }
    }
  });

  _exports.default = _default;
});