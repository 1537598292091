define("E4E/models/competence", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('number'),
    links: _emberData.default.attr(),
    category: _emberData.default.attr('string'),
    rating: _emberData.default.attr()
  });

  _exports.default = _default;
});