define("E4E/components/users/attachments-card", ["exports", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fileSaver.default, {
    store: Ember.inject.service(),
    actions: {
      downloadCertificate: function (originalFileName, file) {
        let self = this;
        this.store.findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      }
    }
  });

  _exports.default = _default;
});