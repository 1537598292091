define("E4E/components/page-base-layout", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    leftSideBarLockedOpen: false,
    currentUserService: Ember.inject.service('current-user'),
    router: Ember.inject.service(),
    menuState: Ember.inject.service('menu-state'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    socketConnection: Ember.inject.service('socket-connection'),
    paperToaster: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    changedLanguage: Ember.computed('locale', function () {
      if (this.get('language') === 'pt') {
        this.set('intl.locale', [this.get('language'), 'en', 'de']);
      } else if (this.get('language') === 'de') {
        this.set('intl.locale', [this.get('language'), 'en', 'pt']);
      } else {
        this.set('intl.locale', [this.get('language'), 'de', 'pt']);
      }
    }),
    language: null,
    languages: ['en', 'de', 'pt'],
    page: 0,
    moment: Ember.inject.service(),
    notificationsNotSeen: Ember.computed.filterBy('model.notifications', 'seenNotification', false),
    init: function () {
      this._super(...arguments);

      this.set('model', {
        notifications: Ember.A([])
      });
      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');
      this.set('locale', cookie.toString());
      this.get('fetchData').perform();
      let self = this;

      if (this.get('socketConnection').connected) {
        this.wsSubscibe();
      } else {
        let interval = setInterval(() => {
          if (self.get('socketConnection').connected) {
            clearInterval(interval);
            self.wsSubscibe();
          }
        }, 2000);
      }
    },
    wsSubscibe: function () {
      var self = this;
      this.get('socketConnection').stompClient.subscribe('/user/topic/notifications', function () {
        self.get('fetchData').perform();
        self.get('paperToaster').show(self.intl.t('label.newnotification'), {
          duration: 3000
        });
      });
    },
    fetchData: (0, _emberConcurrency.task)(function* () {
      let self = this;
      $.ajax({
        type: "GET",
        url: _environment.default.APP.SPRING_HOST + "/notifications/search/me",
        contentType: "application/json",
        headers: this.get('headers'),
        data: {
          page: self.get('page'),
          size: 10
        }
      }).then(data => {
        self.set('model', {
          notifications: Ember.A([])
        });
        self.get('model.notifications').pushObjects(data.content.toArray());
      });
    }).restartable(),
    actions: {
      openMenu: function () {
        this.get('menuState').openMenu();
      },
      closeMenu: function () {
        this.get('menuState').closeMenu();
      },
      logout: function () {
        this.socketConnection.disconnectNotifier();
        this.get('session').invalidate();
      },
      changeLanguage: function (newLocale) {
        let cookies = this.cookies;
        this.intl.set('locale', newLocale.toLowerCase());
        this.set('locale', newLocale);
        this.get('moment').setLocale(newLocale.toLowerCase());
        cookies.write('locale', newLocale.toString());
      },
      openNotificationMenu: function () {
        if (this.get("showNotificationDialog")) {
          this.set('showNotificationDialog', false);
        } else {
          this.set('showNotificationDialog', true);
        }
      },
      clickNotification: function (notification) {
        if (notification.notificationType === "EDIT_PROFILE") {
          if (!notification.seenNotification) {
            this.store.findRecord('notification', notification.id).then(not => {
              not.set('seenNotification', true);
              not.save().then(() => {
                location.href = "/users/profile/" + notification.context;
              });
            });
          } else {
            location.href = "/users/profile/" + notification.context;
          }
        } else if (notification.notificationType === "SUGGESTION_JOB_OFFER") {
          let cookieService = this.get('cookies');
          cookieService.write('jobOfferId', notification.jobOfferDto.id);
          cookieService.write('jobOfferTitle', notification.jobOfferDto.title);

          if (!notification.seenNotification) {
            this.store.findRecord('notification', notification.id).then(not => {
              not.set('seenNotification', true);
              not.save().then(() => {
                location.href = "/job-seekers/job-offers";
              });
            });
          } else {
            location.href = "/job-seekers/job-offers";
          }
        }
      }
    }
  });

  _exports.default = _default;
});