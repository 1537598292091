define("E4E/controllers/blog/index", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    postShowSuccessDialog: false,
    postDeleteSuccessfull: false,
    postDeleteFailure: false,
    respMeta: null,
    name: "",
    page: 0,
    size: 10,
    blogPost: null,
    blogPosts: null,
    addPost: false,
    title: "",
    content: "",
    postId: 0,
    postNew: true,
    searchpost: "",
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    init: function () {
      this._super(...arguments);

      let self = this;
    },
    search: function () {
      this.set('blogPosts', Ember.A([]));
      this.set('respMeta', null);
      this.set("page", 0);
      this.get("getBlog").perform();
    },
    getBlog: (0, _emberConcurrency.task)(function* () {
      let self = this;
      yield this.store.query('blogpost', {
        filter: {
          titleTerm: self.get("searchpost").toLowerCase(),
          page: self.get("page"),
          size: self.get("size")
        }
      }).then(function (resp) {
        self.set("respMeta", resp.meta);

        if (self.get("blogPosts") == null || self.get("page") == 0) {
          self.set("blogPosts", resp.toArray());
          return;
        }

        self.get('blogPosts').pushObjects(resp.toArray());
      });
    }),
    actions: {
      getBlog: function () {
        this.get("getBlog").perform();
      },
      nextPage: function () {
        this.set("page", this.get("page") + 1);
        this.get("getBlog").perform();
      },
      addPost: function (blogPost) {
        if (blogPost != null) {
          this.set("blogPost", blogPost);
          this.set("postId", blogPost.get("id"));
          this.set("title", blogPost.get("title"));
          this.set("content", blogPost.get("content"));
        }

        this.set("addPost", true);
      },
      closeAddPost: function () {
        this.set("blogPost", null);
        this.set("postId", 0);
        this.set("title", "");
        this.set("content", "");
        this.set("addPost", false);
      },
      deletePost: function (blogPost) {
        blogPost.destroyRecord().then(() => {
          this.set("blogPost", null);
          this.set('page', 0);
          this.get("getBlog").perform();
          this.set("postDeleteSuccessfull", true);
        }).catch(function (error) {
          this.set('postDeleteFailure', true);
        });
      },
      savePost: function (title, content) {
        let self = this;

        if (this.get("blogPost") == null) {
          let blogpost = this.store.createRecord('blogpost');
          blogpost.set('authorId', this.get("currentUserService.user.id"));
          blogpost.set('title', title);
          blogpost.set('content', content);
          blogpost.save().then(() => {
            self.set('page', 0);
            self.get("getBlog").perform();
            self.set("addPost", false);
            self.set("title", "");
            self.set("content", "");
            self.set("postShowSuccessDialog", true);
          });
        } else {
          let blogpost = this.get("blogPost");
          blogpost.set('title', title);
          blogpost.set('content', content);
          blogpost.save().then(() => {
            self.set("addPost", false);
            self.set("blogPost", null);
            self.set('page', 0);
            self.get("getBlog").perform();
            self.set("title", "");
            self.set("content", "");
            self.set("postShowSuccessDialog", true);
          });
        }
      },
      saveReport: function (content, id) {},
      searchBlog: function (value) {
        this.set("searchpost", value);
        Ember.run.debounce(this, this.search, 1000);
      },
      closePostSuccessDialog: function () {
        this.set("postShowSuccessDialog", false);
      },
      closePostDeleteSuccessfull: function () {
        this.set("postDeleteSuccessfull", false);
      },
      closePostDeleteFailure: function () {
        this.set("postDeleteFailure", false);
      }
    }
  });

  _exports.default = _default;
});