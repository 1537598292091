define("E4E/components/job-seekers/job-applications/search-container", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 0,
    page2: 0,
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
    },
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      updateJobOffer: function (value) {
        this.updateJobOffer(value);
      },
      updateCity: function (value) {
        this.updateCity(value);
      },
      updateCompany: function (value) {
        this.updateCompany(value);
      },
      updateCountry: function (value) {
        this.updateCountry(value);
      },
      updateStatus: function (value) {
        this.updateStatus(value);
      },
      showFilters: function () {
        this.set('showAllFilters', true);
      },
      hideFilters: function () {
        this.set('showAllFilters', false);
      },
      updateInEvidence: function (value) {
        this.updateInEvidence(value);
      }
    }
  });

  _exports.default = _default;
});