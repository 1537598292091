define("E4E/routes/job-seekers/job-applications/index", ["exports", "ember-simple-auth/mixins/application-route-mixin", "E4E/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    jobOfferName: '',
    sectorName: '',
    countryName: '',
    drivingLicenses: [],
    page: 0,
    questionId: '',
    tagName: '',
    jobSeeker: '',
    status: ["ACCEPTED", "REJECTED", "PENDING"],
    selectedJobOffer: null,
    sector: null,
    authorId: '',
    inEvidence: false,

    model() {
      return Ember.RSVP.hash({
        selectedJobApplication: null,
        country: null,
        companyName: '',
        city: '',
        countryName: '',
        jobOfferName: '',
        educationDateIssue: '',
        educationNumber: '',
        educationScope: '',
        language: [],
        languageName: [],
        languageScope: '',
        languageNumber: '',
        languageDateIssue: '',
        yearsExperience: '',
        competenceName: [],
        workExperience: '',
        education: '',
        jobApplications: null,
        status: this.get('status'),
        drivingLicenses: this.get('drivingLicenses'),
        inEvidence: this.get('inEvidence'),
        page: this.get('page')
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("searchJobApplications");
      }
    }
  });

  _exports.default = _default;
});