define("E4E/templates/components/joboffers/drivinglicense-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rr6t43+W",
    "block": "{\"symbols\":[\"driving\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-center width100P\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-3 margin-bottom-9\"],[9],[1,[27,\"t\",[\"label.drivinglicense\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"actionsEnabled\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[true,[27,\"action\",[[22,0,[]],\"editDrivingLicenses\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"paper-icon\",[\"create\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"not-eq\",[[23,[\"drivingLicense\",\"Description\",\"length\"]],0],null],[27,\"not-eq\",[[23,[\"drivingLicense\",\"Description\"]],[26]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"drivingLicense\",\"Description\"]]],null,{\"statements\":[[0,\"      \"],[4,\"if\",[[27,\"or\",[[27,\"eq\",[[22,1,[]],\"Excavator\"],null],[27,\"eq\",[[22,1,[]],\"Forklift\"],null],[27,\"eq\",[[22,1,[]],\"Crane\"],null]],null]],null,{\"statements\":[[1,[27,\"t\",[[27,\"concat\",[\"label.\",[27,\"lowercase\",[[22,1,[]]],null]],null]],null],false]],\"parameters\":[]},{\"statements\":[[1,[22,1,[]],false]],\"parameters\":[]}],[4,\"if\",[[27,\"not-eq\",[[22,2,[]],[27,\"sub\",[[23,[\"drivingLicense\",\"Description\",\"length\"]],1],null]],null]],null,{\"statements\":[[0,\",\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"text-16-type-19\"],[9],[1,[27,\"t\",[\"label.nodrivinglicense\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/joboffers/drivinglicense-card.hbs"
    }
  });

  _exports.default = _default;
});