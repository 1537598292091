define("E4E/components/users/change-role", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedRole: null,
    selectSubRole: null,
    roles: Ember.A([]),
    userTypes: ["EMPLOYEE", "EXTERNAL"],
    rolesTemp: Ember.computed('roles.@each', 'selectedUser', function () {
      let self = this;

      if (this.get('roles').length > 0) {
        return this.get('roles').filter(function (role) {
          return role.get('name') != "SUPER_ADMIN" && role.get('name') != "JOB_SEEKER" && role.get('name') != "EMS_AUTHORITY";
        });
      }
    }),
    disableChangeRole: Ember.computed('selectedRole', 'selectSubRole', function () {
      if (this.get('selectedRole') != null) {
        if (this.get('selectedRole.name') === "ORGANIZATION_USER") {
          if (this.get('selectSubRole') != null) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
    init: function () {
      this._super(...arguments);

      this.findAllRoles.perform();
    },
    findAllRoles: (0, _emberConcurrency.task)(function* () {
      let roles = yield this.store.findAll('role');
      this.set('roles', roles);
    }),
    actions: {
      cancelNewRole: function () {
        this.cancelNewRole();
      },
      saveNewRole: function () {
        this.saveNewRole(this.get('selectedRole'), this.get('selectSubRole'));
      }
    }
  });

  _exports.default = _default;
});