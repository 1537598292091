define("E4E/models/questionbundle", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    questions: _emberData.default.hasMany('question', {
      async: false
    }),
    questionsSorted: Ember.computed('questions', function () {
      return this.get('questions').sortBy('orderNumber');
    }),
    jobOfferQuestionBundles: _emberData.default.hasMany('jobofferquestionbundle'),
    jobOfferEvaluationSchemeBundles: _emberData.default.hasMany('jobofferevaluationschemebundle'),
    jobOffers: _emberData.default.hasMany('joboffer', {
      async: false
    }),
    bundleType: _emberData.default.attr('string'),
    links: _emberData.default.attr(),
    organizationId: _emberData.default.attr('string'),
    questionCount: _emberData.default.attr('number'),
    subTitle: _emberData.default.attr('string')
  });

  _exports.default = _default;
});