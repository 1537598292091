define("E4E/components/recruiters/question-bundles/add-questionbundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addedQuestions: Ember.A([]),
    addingQuestion: false,
    questionTypes: ["SINGLE_CHOICE", "MULTIPLE_CHOICE", "LIKERT_SCALE", "NUMERIC", "CERTIFICATE", "OPEN_TEXT", "DATE"],
    init: function () {
      this._super(...arguments);

      this.set('addedQuestions', Ember.A([]));
    },
    actions: {
      addQuestion: function () {
        this.set('addingQuestion', true);
      },
      saveQuestion: function () {},
      cancelQuestion: function () {
        this.set('questionType', null);
        this.set('questionText', '');
        this.set('addingQuestion', false);
      },
      addQuestions: function (question) {
        question.set('orderNumber', this.get('addedQuestions').length);
        question.set('questionText', this.get('questionText'));
        question.set('questionType', this.get('questionType'));
        this.get('addedQuestions').pushObject(question);
        this.set('addingQuestion', false);
        this.set('questionType', null);
        this.set('questionText', '');
      },
      editQuestion: function (question) {},
      deleteQuestion: function (question) {
        this.get('addedQuestions').removeObject(question);
      },
      cancelBundle: function () {
        this.cancelBundle();
      },
      saveBundle: function () {
        for (var i = 0; i < this.get('addedQuestions').length; i++) {
          let question = this.get('addedQuestions')[i];
          question.set('orderNumber', i);
        }

        let params = {
          name: this.get('bundleName'),
          bundleType: this.get('selectedBundleType'),
          questions: this.get('addedQuestions')
        };
        this.saveBundle(params);
      },
      dragEnd: function ({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
        /* , sourceArgs, targetArgs */

      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
      }
    }
  });

  _exports.default = _default;
});