define("E4E/components/blog/blog-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.resizeBlog();
    },

    scrollContainer(event) {
      if ($("#blogscroll")[0].scrollHeight - Math.ceil($("#blogscroll").scrollTop() + $("#blogscroll").outerHeight()) < 1) {
        if (!this.get('respMeta.last')) {
          this.nextPage();
        }
      }
    },

    resizeBlog() {
      $("#blogscroll").height($(window).height() - $("#blogscroll").offset().top - 2);
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      this._resizeBlog = () => Ember.run.debounce(this, 'resizeBlog', 0);

      $("#blogscroll").on('scroll', this._boundScroll);
      window.addEventListener('resize', this._resizeBlog, false);
    },

    nextPage: function () {
      this.nextPageReceived();
    },
    actions: {
      nextPage: function () {
        this.nextPage();
      },
      addPost: function (blogPost) {
        this.addPost(blogPost);
      },
      deletePost: function (blogPost) {
        this.deletePost(blogPost);
      },
      saveReport: function (content, id) {
        this.saveReport(content, id);
      }
    }
  });

  _exports.default = _default;
});