define("E4E/models/motivationalletter", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    jobApplication: _emberData.default.belongsTo('jobapplication', {
      async: false
    }),
    file: _emberData.default.belongsTo('file'),
    originalFileName: _emberData.default.attr('string'),
    links: _emberData.default.attr(),
    ownerId: _emberData.default.attr('string'),
    fileId: _emberData.default.attr('string')
  });

  _exports.default = _default;
});