define("E4E/components/organizations/job-offers/edit-joboffer", ["exports", "pell", "ember-concurrency", "E4E/config/environment"], function (_exports, _pell, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    screen: Ember.inject.service('screen'),
    currentUserService: Ember.inject.service('current-user'),
    questionBundleService: Ember.inject.service("questionbundle"),
    languages: Ember.computed('intl.locale', function () {
      return $.getJSON('/json-components/languages/languages_' + this.get('intl.locale') + '.json');
    }),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    cannotSave: Ember.computed('params.jobOfferTitle', 'params.description', 'params.etype', 'country', 'params.deadline', function () {
      if (this.get('params.jobOfferTitle') != null && this.get('params.jobOfferTitle') != '' && this.get('params.description') != null && this.get('params.description') != '' && this.get('params.etype') != null && this.get('country') != null && this.get('params.deadline') != null) {
        return false;
      } else {
        return true;
      }
    }),
    listeningLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    readingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenInteractionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    spokenProductionLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    writingLevels: ["A1", "A2", "B1", "B2", "C1", "C2"],
    qualifications: Ember.A([]),
    skills: Ember.A([]),
    drivingLicenses: Ember.A([]),
    addedLanguages: Ember.A([]),
    availabilityTravel: ["YES", "NO"],
    offerTypes: ["FULL_TIME", "PART_TIME", "FULL_TIME_PART_TIME", "FREELANCER"],
    canLoadMore: true,
    isLoading: false,
    page: 0,
    addBundle: false,
    dialogService: Ember.inject.service('dialog-service'),
    addSkills: false,
    skillsAdded: Ember.A([]),
    image: null,
    file: null,
    cropingImage: false,
    showCompanyFor: false,
    forCompany: false,
    company: null,
    companyTemp: null,
    jobOfferPdfFile: null,
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    jobOfferQuestionBundles: Ember.computed('questionBundles.@each', function () {
      if (this.get('questionBundles') != null) {
        return this.get('questionBundles').filter(function (questionBundle) {
          return questionBundle.bundleType === "QUESTION_BUNDLE";
        });
      }
    }),
    evaluationQuestionBundles: Ember.computed('questionBundles.@each', function () {
      if (this.get('questionBundles') != null) {
        return this.get('questionBundles').filter(function (questionBundle) {
          return questionBundle.bundleType === "EVALUATION_SCHEME_BUNDLE";
        });
      }
    }),
    init: function () {
      this._super(...arguments);

      this.get('getCountries').perform();
      this.countryMatcher = this.countryMatcher.bind(this);
      this.jobTitlesMatcher = this.jobTitlesMatcher.bind(this);
      this.get('getLanguages').perform();
      this.get('getJobTitles').perform();
      this.get('getQuestionBundles').perform();
      this.get('setRatingObject').perform();
      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    setRatingObject: (0, _emberConcurrency.task)(function* () {
      this.get('params.skillsAdded').forEach(function (skill) {
        if (skill.rating == null) {
          skill.rating = {
            value: 0,
            additionalText: ''
          };
        }
      });
    }),
    getQuestionBundles: (0, _emberConcurrency.task)(function* () {
      this.questionBundleService.findByOrganization(this.get("currentUserService.user.organizationId")).then(resp => {
        this.set('questionBundles', resp.toArray());
      });
    }),
    getLanguages: (0, _emberConcurrency.task)(function* () {
      let languages = yield $.getJSON('/json-components/languages/languages_' + this.get('intl.locale')[0] + '.json');
      this.set('languages', languages);
    }),
    loadMore: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      this.set('page', this.get('page') + 1);
      let jobTitles = yield this.store.query('jobtitle', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !jobTitles.meta.page.last);
      this.get('jobTitles').pushObjects(jobTitles.toArray());
      this.set('isLoading', false);
    }).restartable(),
    getJobTitles: (0, _emberConcurrency.task)(function* () {
      let jobTitles = yield this.store.findAll('jobtitle');
      this.set('jobTitles', jobTitles);
    }).restartable(),
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
      this.set('country', this.get('countries').findBy("iso3", this.get('params.country').toUpperCase()));
    }),
    addLanguage: false,
    jobTitlesMatcher: function (jobTitle, term) {
      const ret = `${this.get('intl').t('label.jobtitlesnames.' + jobTitle.code)}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    search: function () {
      this.set('jobTitles', Ember.A([]));
      this.get('getJobTitles').perform();
    },
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      saveJobOffer: function () {
        let params = {
          title: this.get('params.jobOfferTitle'),
          jobTitleId: this.get('params.jobTitle.id'),
          deadline: this.get('params.deadline'),
          description: this.get('params.description'),
          formalEducation: JSON.stringify({
            educations: this.get('params.formalEducation')
          }),
          skills: JSON.stringify({
            competences: this.get('params.skillsAdded')
          }),
          yearsExperience: this.get('params.yearsExperience'),
          languages: JSON.stringify({
            languages: this.get('params.addedLanguages')
          }),
          drivingLicense: JSON.stringify({
            drivingLicenses: this.get('params.drivingLicenses')
          }),
          etype: this.get('params.etype'),
          city: this.get('params.city'),
          country: this.get('country.iso3').toLowerCase(),
          hoursWeek: this.get('params.hoursWeek'),
          startDate: this.get('params.startDate'),
          salary: this.get('params.salary'),
          contractDuration: this.get('params.duration'),
          questionBundles: this.get('params.questionBundlesTemp'),
          image: this.get('image'),
          travel: this.get('params.travel'),
          showCompanyFor: this.get('params.showCompanyFor'),
          questionBundlesTemp: this.get('params.questionBundlesTemp'),
          questionBundlesTempEval: this.get('params.questionBundlesTempEval'),
          companyName: this.get('params.companyName'),
          forCompany: this.get('params.forCompany'),
          deletedJobOfferPdf: this.get('params.deletedJobOfferPdf'),
          jobOfferPdfFile: this.get('params.deletedJobOfferPdf') ? this.get('jobOfferPdfFile') : this.get('params.jobOfferPdfFile'),
          healthStatus: this.get('params.healthStatus'),
          specialConditions: this.get('params.specialConditions')
        };
        this.saveEditJobOffer(params);
      },
      cancelJobOffer: function () {
        this.sendAction("cancelJobOffer");
      },
      addSkills: function () {
        this.set("addSkills", true);
      },
      cancelAddSkills: function () {
        this.set("addSkills", false);
      },
      removeAddedSkills: function (value) {
        this.get("skillsAdded").removeObject(value);
      },
      addLanguage: function () {
        this.set('addLanguage', true);
      },
      saveLanguageToJobOffer: function (language, motherTongue, index) {
        if (this.get('params.addedLanguages') != null) {
          if (motherTongue) {
            if (this.get('params.addedLanguages.MotherTongue') != null) {
              let skills = this.get('params.addedLanguages');
              skills.MotherTongue.pushObject(language);
              this.set('params.addedLanguages', skills);
            } else {
              let MotherTongue = Ember.A([]);
              MotherTongue.pushObject(language);
              let skills = this.get('params.addedLanguages');
              skills.MotherTongue = MotherTongue;
              this.set('params.addedLanguages', skills);
            }
          } else {
            if (this.get('params.addedLanguages.ForeignLanguage') != null) {
              let skills = this.get('params.addedLanguages');

              if (index != null) {
                skills.ForeignLanguage.removeAt(index);
                skills.ForeignLanguage.insertAt(index, language);
              } else {
                skills.ForeignLanguage.pushObject(language);
              }

              this.set('params.addedLanguages', skills);
            } else {
              let ForeignLanguage = Ember.A([]);
              ForeignLanguage.pushObject(language);
              let skills = this.get('params.addedLanguages');
              skills.ForeignLanguage = ForeignLanguage;
              this.set('params.addedLanguages', skills);
            }
          }
        } else {
          if (motherTongue) {
            let skills = this.get('params.addedLanguages');
            skills = {
              "MotherTongue": [language]
            };
            this.set('params.addedLanguages', skills);
          } else {
            let skills = this.get('params.addedLanguages');
            skills = {
              "ForeignLanguage": [language]
            };
            this.set('params.addedLanguages', skills);
          }
        }

        this.set('addLanguage', false);
        this.set('editLanguage', false);
      },
      cancelAddLanguage: function () {
        this.set('addLanguage', false);
      },
      closeEditDrivingLicenseDialog: function () {
        this.set('editDrivingLicenses', false);
      },
      editDrivingLicenses: function () {
        this.set('drivingLicense', this.get('params.drivingLicenses'));
        this.set('editDrivingLicenses', true);
      },
      saveDrivingLicenseToJobOffer: function (drivingLicense) {
        if (this.get('params.drivingLicenses') != null) {
          this.set('params.drivingLicenses', drivingLicense);
        } else {
          this.set('params.drivingLicenses', drivingLicense);
        }

        this.set('editDrivingLicenses', false);
      },
      searchJobTitles: function (value) {
        this.set('name', value);
        this.set('page', 0);
        Ember.run.debounce(this, this.search, 1000);
      },
      backToSearch: function () {
        this.sendAction("backToSearch");
      },
      addBundle: function () {
        this.set("addBundle", true);
      },
      addSkillsToJobOffer: function () {
        this.set('addSkills', false);
      },

      didSelectFiles(files) {
        let self = this;
        this.set('cropingImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('file', files[0]);

          let done = function (url) {
            self.set('fileSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },

      closeImageDialog: function () {
        this.set('cropingImage', false);
      },
      editEducation: function (education, index) {
        this.set('education', education);
        this.set('index', index);
        this.set('editEducation', true);
      },
      deleteEducation: function (education) {
        this.get('params.formalEducation').removeObject(education);
      },
      addEducation: function () {
        this.set('addEducation', true);
      },
      saveEducationToJobOffer: function (education, index) {
        if (this.get('params.formalEducation') != null) {
          let educationJson = this.get('params.formalEducation');

          if (index != null) {
            educationJson[index] = education;
          } else {
            educationJson.pushObject(education);
          }

          this.set('params.formalEducation', educationJson);
        } else {
          let educationArray = Ember.A([]);
          educationArray.pushObject(education);
          this.set('params.formalEducation', educationArray);
        }

        this.set('addEducation', false);
        this.set('editEducation', false);
      },
      cancelAddEducation: function () {
        this.set('addEducation', false);
      },
      cancelEditEducation: function () {
        this.set('editEducation', false);
      },
      cancelEditLanguage: function () {
        this.set('editLanguage', false);
      },
      removeMotherLanguage: function (motherTongue) {
        this.get('params.addedLanguages.MotherTongue').removeObject(motherTongue);
      },
      deleteForeignLanguage: function (foreignLanguage) {
        this.get('params.addedLanguages.ForeignLanguage').removeObject(foreignLanguage);
      },
      editLanguage: function (language, index) {
        this.set('language', language);
        this.set('index', index);
        this.set('editLanguage', true);
      },
      removeJobOfferPdfFile: function () {
        this.set('params.jobOfferPdfFile', null);
        this.set('params.deletedJobOfferPdf', true);
      },
      removeJobOfferPdf: function () {
        this.set('jobOfferPdfFile', null);
      },
      didSelectFilesAttachment: function (files) {
        this.set('jobOfferPdfFile', files[0]);
        this.set('params.deletedJobOfferPdf', true);
      }
    }
  });

  _exports.default = _default;
});