define("E4E/services/menu-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    language: null,

    openMenu() {
      this.set('menuOpened', true);
    },

    closeMenu() {
      this.set('menuOpened', false);
    }

  });

  _exports.default = _default;
});