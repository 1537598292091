define("E4E/components/dialogs/educations/add-skills", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showMoreCompetences: false,
    store: Ember.inject.service(),
    competences: Ember.A([]),
    init: function () {
      this._super(...arguments);

      this.get('getAllCompetences').perform();
    },
    getAllCompetences: (0, _emberConcurrency.task)(function* () {
      let competences = yield this.get('store').findAll('competence');
      this.set('competences', competences);
    }),
    actions: {
      closeAddSkillsDialog: function () {
        this.cancelAddSkills();
      },
      showLess: function () {
        this.set('showMoreCompetences', false);
      },
      showMore: function () {
        this.set('showMoreCompetences', true);
      },
      removeAddedSkills: function (skill) {
        this.get('skillsAdded').removeObject(skill);
        this.get('skills').pushObject(skill);
      },
      addSkill: function (skill) {
        let self = this;

        if (skill.id != null) {
          this.store.findRecord('competence', skill.id).then(function (competence) {
            competence.set('rating', {
              value: 0,
              additionalText: ''
            });
            self.get('skillsAdded').pushObject(competence);
            self.get('skills').removeObject(skill);
          });
        } else {
          skill.rating = {
            value: 0,
            additionalText: ''
          };
          self.get('skillsAdded').pushObject(skill);
        }
      },
      addToOffer: function () {
        this.addToJobOffer();
      },
      showRatingStats: function () {
        this.set('showRating', true);
      },
      hideRatingsStats: function () {
        this.set('showRating', false);
      }
    }
  });

  _exports.default = _default;
});