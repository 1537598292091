define("E4E/templates/components/questions/preview/singlechoice-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MoykR7y6",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-1\"],[9],[1,[27,\"t\",[\"label.single_choice\"],null],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-7 margin-top-8\"],[9],[1,[23,[\"question\",\"questionText\"]],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-7 margin-top-8 margin-bottom-32\"],[9],[1,[23,[\"question\",\"explanation\"]],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start width100P\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"question\",\"singleChoiceJson\",\"options\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"paper-input\",null,[[\"class\",\"label\",\"value\",\"disabled\",\"onChange\"],[\"width100P\",[27,\"t\",[\"label.option\"],null],[22,1,[\"optionText\"]],true,null]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/questions/preview/singlechoice-question.hbs"
    }
  });

  _exports.default = _default;
});