define("E4E/models/file", ["exports", "@ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    contentLength: _emberData.default.attr('string'),
    mimeType: _emberData.default.attr('string'),
    video: _emberData.default.belongsTo('video'),
    image: _emberData.default.belongsTo('picture'),
    coverImage: _emberData.default.belongsTo('cover'),
    curriculumVitae: _emberData.default.belongsTo('curriculum-vitae'),
    motivationalLetter: _emberData.default.belongsTo('motivationalletter'),
    links: _emberData.default.attr(),
    sizeString: Ember.computed('contentLength', function () {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (this.get("contentLength") == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(this.get("contentLength")) / Math.log(1024)));
      return Math.round(this.get("contentLength") / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }),
    download: (0, _emberApiActions.memberAction)({
      path: ' ',
      type: 'GET',
      ajaxOptions: {
        arraybuffer: true
      }
    })
  });

  _exports.default = _default;
});