define("E4E/services/file-service", ["exports", "E4E/config/environment", "ember-concurrency", "ember-uploader/uploaders/uploader", "ember-data"], function (_exports, _environment, _emberConcurrency, _uploader, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Ember.Service.extend({
    ReferenceTo: {
      idref: null
    },
    Attachment: {
      Id: "",
      Name: "",
      Data: "",
      MimeType: "",
      Description: "",
      Metadata: []
    },
    store: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    pictureService: Ember.inject.service('picture'),
    coverService: Ember.inject.service('cover'),
    userService: Ember.inject.service('user'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    authorizedGet: function (url, done) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers'),
        done: done
      });
    },
    authorizedPost: function (url, content, done) {
      return Ember.$.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        data: JSON.stringify(content),
        contentType: "application/json",
        done: done
      });
    },
    authorizedDelete: function (fileId) {
      Ember.$.ajax({
        type: 'DELETE',
        url: _environment.default.APP.SPRING_HOST + "/files/" + fileId,
        headers: this.get('headers'),
        error: function (error) {
          return true;
        },
        success: function (resp) {
          return resp;
        }
      });
    },
    uploadCertificateQuestions: (0, _emberConcurrency.task)(function* (file, question) {
      let self = this; // Reset vars

      this.set('file', file);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(file);

      if (question.get('certificateResponse.file') != null) {
        this.authorizedDelete(question.get('certificateResponse.file'));
        question.set('certificateResponse.file', ret.id);
      } else {
        question.set('certificateResponse.file', ret.id);
      }

      self.get('paperToaster').cancelToast(toast);
    }),
    uploadJobOfferImage: (0, _emberConcurrency.task)(function* (file, jobOffer) {
      let self = this; // Reset vars

      this.set('file', file);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(file);
      let image = {
        jobOfferId: jobOffer.get('id'),
        fileId: ret.id
      };
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/pictures', image);
      var images = yield this.pictureService.filterPicturesJobOffer(jobOffer.get('id'), 0, 100);
      var imagesUser = images.toArray();
      self.get('forEachImagesRemoval').perform(imagesUser, final);
      self.get('paperToaster').cancelToast(toast);
      let picture = yield this.get('store').findRecord('picture', final.id);
      jobOffer.set('profilePicture', picture);
    }).enqueue(),
    uploadUserAvatarImage: (0, _emberConcurrency.task)(function* (avatar, user) {
      let self = this; // Reset vars

      this.set('file', avatar);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(avatar);
      let image = {
        authorId: user.get('id'),
        fileId: ret.id
      };
      var images = yield this.pictureService.filterPicturesAuthor(user.get('id'), 0, 100);
      var imagesUser = images.toArray();
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/pictures', image);
      yield self.get('forEachImagesRemoval').perform(imagesUser, final);
      self.get('paperToaster').cancelToast(toast);
      let picture = yield this.get('store').findRecord('picture', final.id);
      user.set('profilePicture', picture);

      if (this.currentUserService.get('user.id') === user.get('id')) {
        this.currentUserService.set('user.profilePicture', picture);
      }
    }),
    uploadUserCoverImage: (0, _emberConcurrency.task)(function* (cover, user) {
      let self = this; // Reset vars

      this.set('file', cover);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(cover);
      let image = {
        authorId: user.get('id'),
        fileId: ret.id
      };
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/covers', image);
      var images = yield this.coverService.filterPicturesAuthor(user.get('id'), 0, 100);
      var imagesUser = images.toArray();
      yield self.get('forEachImagesRemoval').perform(imagesUser, final);
      self.get('paperToaster').cancelToast(toast);
      let coverFinal = yield this.get('store').findRecord('cover', final.id);
      user.set('coverImage', coverFinal);

      if (this.currentUserService.get('user.id') === user.get('id')) {
        this.currentUserService.set('user.coverImage', coverFinal);
      }
    }),
    uploadCompanyCoverImage: (0, _emberConcurrency.task)(function* (cover, organization) {
      let self = this; // Reset vars

      this.set('file', cover);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(cover);
      let image = {
        organizationId: organization.get('id'),
        fileId: ret.id
      };
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/covers', image);
      var images = yield this.coverService.filterPicturesOrganization(organization.get('id'), 0, 100);
      var imagesOrganization = images.toArray();
      self.get('forEachImagesRemoval').perform(imagesOrganization, final);
      self.get('paperToaster').cancelToast(toast);
      let coverFinal = yield this.get('store').findRecord('cover', final.id);
      organization.set('coverImage', coverFinal);

      if (this.currentUserService.get('user.userOrganization.id') === organization.get('id')) {
        this.currentUserService.set('user.userOrganization.coverImage', coverFinal);
      }
    }),
    uploadCompanyAvatar: (0, _emberConcurrency.task)(function* (avatar, organization) {
      let self = this; // Reset vars

      this.set('file', avatar);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(avatar);
      let image = {
        organizationId: organization.get('id'),
        fileId: ret.id
      };
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/pictures', image);
      var images = yield this.pictureService.filterPicturesOrganization(organization.get('id'), 0, 100);
      var imagesOrganization = images.toArray();
      self.get('forEachImagesRemoval').perform(imagesOrganization, final);
      self.get('paperToaster').cancelToast(toast);
      let picture = yield this.get('store').findRecord('picture', final.id);
      organization.set('profilePicture', picture);

      if (this.currentUserService.get('user.userOrganization.id') === organization.get('id')) {
        this.currentUserService.set('user.userOrganization.profilePicture', picture);
      }
    }),
    uploadCV: (0, _emberConcurrency.task)(function* (file, user) {
      let self = this; // Reset vars

      this.set('file', file);
      this.set('progress', 0);
      this.set('newAttachment', null); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(file);
      let textFile = {
        fileId: ret.id,
        originalFileName: file.name,
        authorId: user.get('id')
      };

      if (user.get('curriculumVitae') != null) {
        yield user.get('curriculumVitae').destroyRecord();
        let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/curriculumVitaes', textFile, null);
        let curriculumJson = yield self.authorizedGet('/rest/files/generate/curriculum/user?userId=' + user.get('id'), null);

        if (curriculumJson.SkillsPassport != null) {
          if (curriculumJson.SkillsPassport.LearnerInfo.WorkExperience != null) {
            user.set('workExperience', JSON.stringify({
              workExperiences: curriculumJson.SkillsPassport.LearnerInfo.WorkExperience
            }));
          } else {
            user.set('workExperience', JSON.stringify({
              workExperiences: []
            }));
          }

          if (curriculumJson.SkillsPassport.LearnerInfo.Education != null) {
            user.set('education', JSON.stringify({
              educations: curriculumJson.SkillsPassport.LearnerInfo.Education
            }));
          } else {
            user.set('education', JSON.stringify({
              educations: []
            }));
          }

          if (curriculumJson.SkillsPassport.LearnerInfo.Skills != null) {
            user.set('skills', JSON.stringify({
              skills: curriculumJson.SkillsPassport.LearnerInfo.Skills
            }));
          } else {
            user.set('skills', JSON.stringify({
              skills: []
            }));
          }
        } else {
          user.set('workExperience', JSON.stringify({
            workExperiences: []
          }));
          user.set('education', JSON.stringify({
            educations: []
          }));
          user.set('skills', JSON.stringify({
            skills: []
          }));
        }

        self.get('paperToaster').cancelToast(toast);
      } else {
        let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/curriculumVitaes', textFile, null);
        let curriculumJson = yield self.authorizedGet('/rest/files/generate/curriculum/user?userId=' + user.get('id'), null);

        if (curriculumJson.SkillsPassport != null) {
          if (curriculumJson.SkillsPassport.LearnerInfo.WorkExperience != null) {
            user.set('workExperience', JSON.stringify({
              workExperiences: curriculumJson.SkillsPassport.LearnerInfo.WorkExperience
            }));
          } else {
            user.set('workExperience', JSON.stringify({
              workExperiences: []
            }));
          }

          if (curriculumJson.SkillsPassport.LearnerInfo.Education != null) {
            user.set('education', JSON.stringify({
              educations: curriculumJson.SkillsPassport.LearnerInfo.Education
            }));
          } else {
            user.set('education', JSON.stringify({
              educations: []
            }));
          }

          if (curriculumJson.SkillsPassport.LearnerInfo.Skills != null) {
            user.set('skills', JSON.stringify({
              skills: curriculumJson.SkillsPassport.LearnerInfo.Skills
            }));
          } else {
            user.set('skills', JSON.stringify({
              skills: []
            }));
          }
        } else {
          user.set('workExperience', JSON.stringify({
            workExperiences: []
          }));
          user.set('education', JSON.stringify({
            educations: []
          }));
          user.set('skills', JSON.stringify({
            skills: []
          }));
        }

        self.get('paperToaster').cancelToast(toast);
      }

      self.get('paperToaster').show(self.intl.t("label.curriculumuploaded") + ' - ' + file.name, {
        duration: 5000
      });
    }).enqueue(),
    uploadCompanyVideo: (0, _emberConcurrency.task)(function* (video, organization, organizationParams) {
      let self = this; // Reset vars

      this.set('file', video.file);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(video.file);
      let videoTemp = this.store.createRecord('video');
      videoTemp.set('title', video.title);
      videoTemp.set('description', video.description);
      videoTemp.set('fileId', ret.id);
      yield videoTemp.save();
      organization.set('videoId', videoTemp.get('id'));
      yield organization.save({
        include: organizationParams
      });
      organization.set('videoDto', videoTemp);
      self.get('paperToaster').cancelToast(toast);
    }).enqueue(),
    uploadUserVideo: (0, _emberConcurrency.task)(function* (video) {
      let self = this; // Reset vars

      this.set('file', video);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(video);
      let videoTemp = this.store.createRecord('video');
      videoTemp.set('title', video.name);
      videoTemp.set('fileId', ret.id);
      videoTemp.set('authorId', this.currentUserService.get('user.id'));
      yield videoTemp.save();
      self.get('paperToaster').cancelToast(toast);
      return videoTemp;
    }).enqueue(),
    uploadMotivationalLetter: (0, _emberConcurrency.task)(function* (motivationalLetter) {
      let self = this; // Reset vars

      this.set('file', motivationalLetter);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(motivationalLetter);
      let motivationTemp = this.store.createRecord('motivationalletter');
      motivationTemp.set('fileId', ret.id);
      motivationTemp.set('ownerId', this.currentUserService.get('user.id'));
      motivationTemp.set('originalFileName', motivationalLetter.name);
      yield motivationTemp.save();
      self.get('paperToaster').cancelToast(toast);
      return motivationTemp;
    }).enqueue(),
    uploadCertificates: (0, _emberConcurrency.task)(function* (files, user) {
      if (files != null) {
        let referenceTos = Ember.A([]);
        let self = this;

        for (let i = 0; i < files.length; i++) {
          if (!(files[i] instanceof Model)) {
            let referenceTo = yield self.uploadCertificate.perform(files[i], user);
            referenceTos.pushObject(referenceTo);
          }
        }

        return referenceTos;
      }

      return Ember.A([]);
    }).enqueue(),
    uploadPrivateDocuments: (0, _emberConcurrency.task)(function* (document) {
      let self = this; // Reset vars

      this.set('file', document);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(document);
      let documentTemp = this.store.createRecord('privatedocument');
      documentTemp.set('originalFileName', document.name);
      documentTemp.set('fileId', ret.id);
      documentTemp.set('authorId', this.currentUserService.get('user.id'));
      yield documentTemp.save();
      self.get('paperToaster').cancelToast(toast);
      return documentTemp;
    }),
    forEachImagesRemoval: (0, _emberConcurrency.task)(function* (imagesUser, final) {
      let self = this;

      for (var i = 0; i < imagesUser.length; i++) {
        if (imagesUser[i].get('id') != final.id) {
          let imageTemp = imagesUser[i];
          let file = imagesUser[i].get('imageFile');
          yield Ember.$.get(_environment.default.APP.SPRING_HOST + "/files/" + file.get('id'), function () {
            imageTemp.destroyRecord();
            self.authorizedDelete(file.get('id'));
          }).fail(function () {
            imageTemp.destroyRecord();
          });
        }
      }
    }).enqueue(),
    uploadCertificate: (0, _emberConcurrency.task)(function* (file, user) {
      let self = this;
      let retTemp = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {});

      let uploaderTemp = _uploader.default.create({
        ajaxSettings: {
          headers: self.get('headers')
        },
        url: retTemp._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      });

      yield uploaderTemp.upload(file);
      let textFile = {
        fileId: retTemp.id,
        originalFileName: file.name,
        ownerId: user.get('id'),
        curriculumVitaeId: this.get('user.curriculumVitae') != null ? this.get('user.curriculumVitae.id') : null,
        europassCertificateId: "ATT_" + retTemp.id
      };
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/certificates', textFile, null);
      let certificate = yield self.store.findRecord('certificate', final.id);
      let referenceTo = Ember.copy(this.get('ReferenceTo'), true);
      referenceTo.idref = certificate.get('europassCertificateId');
      return referenceTo;
    }).enqueue(),
    uploadTekiAvatarImage: (0, _emberConcurrency.task)(function* (avatar, oldImage) {
      let self = this; // Reset vars

      this.set('file', avatar);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(avatar);
      let image = {
        fileId: ret.id
      };
      let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/pictures', image);
      yield Ember.$.get(_environment.default.APP.SPRING_HOST + "/files/" + oldImage.get('fileId'), function () {
        oldImage.destroyRecord();
        self.authorizedDelete(oldImage.get('fileId'));
      }).fail(function () {
        oldImage.destroyRecord();
      });
      self.get('paperToaster').cancelToast(toast);
      let picture = yield this.get('store').findRecord('picture', final.id);
      return picture;
    }),
    uploadTekiReportImage: (0, _emberConcurrency.task)(function* (avatar, user) {
      if (avatar != null && avatar != undefined && avatar != 'removed') {
        let self = this; // Reset vars

        this.set('file', avatar);
        this.set('progress', 0); // Open toast

        let toast = this.get('paperToaster').showComponent('upload-toast', {
          duration: false
        }); // Create file entry

        let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

        const uploader = _uploader.default.create({
          ajaxSettings: {
            headers: this.get('headers')
          },
          url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
          method: 'PUT',
          attributeBindings: ['accept']
        }); // On upload progress update the progress tracker


        uploader.on('progress', event => {
          self.set('progress', event.percent);
        }); // On upload completed create the parent object

        uploader.on('didUpload', event => {}); // Run the uploader

        yield uploader.upload(avatar);
        let image = {
          fileId: ret.id
        }; // var images = yield this.pictureService.filterPicturesAuthor(user.get('id'),0, 100);
        // var imagesUser = images.toArray();

        let final = yield self.authorizedPost(_environment.default.APP.SPRING_HOST + '/pictures', image); // yield self.get('forEachImagesRemoval').perform(imagesUser, final);

        self.get('paperToaster').cancelToast(toast);
        let picture = yield this.get('store').findRecord('picture', final.id);
        return picture.get('id'); // user.set('profilePicture', picture);
        // if(this.currentUserService.get('user.id') === user.get('id')){
        //     this.currentUserService.set('user.profilePicture', picture);
        // }
      } else {
        return null;
      }
    }),
    uploadDocument: (0, _emberConcurrency.task)(function* (document, category) {
      let self = this; // Reset vars

      this.set('file', document);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(document);
      let documentTemp = this.store.createRecord('document');
      documentTemp.set('originalFileName', document.name);
      documentTemp.set('fileId', ret.id);
      documentTemp.set('authorId', this.currentUserService.get('user.id'));
      documentTemp.set('category', category);
      yield documentTemp.save();
      self.get('paperToaster').cancelToast(toast); //return documentTemp;
    }).enqueue(),
    uploadJobOfferPdf: (0, _emberConcurrency.task)(function* (document) {
      let self = this; // Reset vars

      this.set('file', document);
      this.set('progress', 0); // Open toast

      let toast = this.get('paperToaster').showComponent('upload-toast', {
        duration: false
      }); // Create file entry

      let ret = yield this.authorizedPost(_environment.default.APP.SPRING_HOST + '/files', {}); // Create uploader based on the file entry

      const uploader = _uploader.default.create({
        ajaxSettings: {
          headers: this.get('headers')
        },
        url: ret._links.self.href.replace('https://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://talentsatwork.at', 'https://talentsatwork.at/api').replace('http://127.0.0.1:8080', 'https://talentsatwork.at/api').replace('https://e4etest.future-balloons.eu', 'https://e4etest.future-balloons.eu/api'),
        method: 'PUT',
        attributeBindings: ['accept']
      }); // On upload progress update the progress tracker


      uploader.on('progress', event => {
        self.set('progress', event.percent);
      }); // On upload completed create the parent object

      uploader.on('didUpload', event => {}); // Run the uploader

      yield uploader.upload(document);
      let documentTemp = this.store.createRecord('jobofferpdf');
      documentTemp.set('originalFileName', document.name);
      documentTemp.set('fileId', ret.id);
      yield documentTemp.save();
      self.get('paperToaster').cancelToast(toast);
      return documentTemp;
    }).enqueue()
  });

  _exports.default = _default;
});