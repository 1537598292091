define("E4E/helpers/is-explorer", ["exports", "ember-browser-checker/helpers/is-explorer"], function (_exports, _isExplorer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isExplorer.default;
    }
  });
  Object.defineProperty(_exports, "isExplorer", {
    enumerable: true,
    get: function () {
      return _isExplorer.isExplorer;
    }
  });
});