define("E4E/routes/superadmin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    beforeModel: function () {
      let self = this;

      if (this.currentUserService.get('user.userRole.name') !== "SUPER_ADMIN") {
        if (this.currentUserService.get('user.userRole.name') === "JOB_SEEKER") {
          switch (this.get('currentUserService.user.userRole.name')) {
            case "JOB_SEEKER":
              self.transitionTo('job-seekers.dashboard');
              break;

            case "ORGANIZATION_ADMIN":
              self.transitionTo('organizations.dashboard');
              break;

            case "ORGANIZATION_USER":
              self.transitionTo('users.profile', this.currentUserService.user.id);
              break;

            case "SUPER_ADMIN":
              self.transitionTo('superadmin.dashboard');
              break;

            case "OUTPLACER":
              self.transitionTo('outplacers.dashboard');
              break;

            case "PARTNER_MANAGER":
              self.transitionTo('partners.dashboard');
              break;

            case "RECRUITER":
              self.transitionTo('recruiters.dashboard');
              break;
          }
        }
      }
    }
  });

  _exports.default = _default;
});