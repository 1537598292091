define("E4E/controllers/superadmin/reports/index", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    blockPostSuccessDialog: false,
    unlockPostSuccessDialog: false,
    dismissReportSave: false,
    dismissReportFailure: false,
    rememberReportSave: false,
    blockUserSuccessDialog: false,
    reportBlogItem: null,
    reportBlogItems: null,
    reportblogitemSelect: null,
    reportsParams: ["blogpost", "blogcomment", "user"],
    reportsName: "",
    morePage: false,
    reportService: Ember.inject.service("report"),
    page: 10,
    size: 10,
    respMeta: null,
    screen: Ember.inject.service("screen"),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedReport') != null) {
          if (Ember.$('#search_container_reports_blog').hasClass('hide')) {
            Ember.$('#reports_blog_container').addClass('show');
          } else {
            Ember.$('#search_container_reports_blog').addClass('hide');
            Ember.$('#search_container_reports').addClass('hide');
            Ember.$('#reports_blog_container').addClass('show');
          }
        } else {
          Ember.$('#search_container_reports_blog').removeClass('hide');
          Ember.$('#search_container_reports').removeClass('hide');
          Ember.$('#reports_blog_container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_reports_blog').removeClass('hide');
        Ember.$('#search_container_reports').removeClass('hide');
        Ember.$('#reports_blog_container').removeClass('show');
      }
    },

    getReports: (0, _emberConcurrency.task)(function* () {
      let self = this;
      yield this.reportService.filter(self.get("model.title").toLowerCase(), self.get("model.page"), self.get("size")).then(function (resp) {
        if (resp.meta.last) {
          self.set("morePage", false);
        } else {
          self.set("morePage", true);
        }

        self.set("respMeta", resp.meta);

        if (self.get("reportBlogItems") == null || self.get("model.page") == 0) {
          self.set("reportBlogItems", resp.reportblogitem.toArray());
          return;
        }

        self.get('reportBlogItems').pushObjects(resp.reportblogitem.toArray());
      });
    }),
    findReportBlogItem: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('reportblogitem', id, {
        include: this.reportsParams.toString()
      }).then(reportblog => {
        this.set('model.selectedReport', reportblog);
        Ember.$('#reports_blog_container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_reports').addClass('hide');
          Ember.$('#search_container_reports_blog').addClass('hide');
          Ember.$('#reports_blog_container').addClass('show');
        }
      });
    }).restartable(),
    search: function () {
      this.set("respMeta", null);
      this.set('model.page', 0);
      this.set("reportBlogItems", []);
      this.get('getReports').perform();
    },
    actions: {
      getReports: function () {
        this.get("getReports").perform();
      },
      nextPage: function () {
        this.set("model.page", this.get("model.page") + 1);
        this.get("getReports").perform();
      },
      updateReports: function (value) {
        this.set('model.title', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      selectReport: function (value) {
        this.get('findReportBlogItem').perform(value);
      },
      blockPost: function (reportBlogItem) {
        let self = this;
        let blogPostDtoblocked = reportBlogItem.blogPostDto;
        blogPostDtoblocked.set("blocked", true);
        blogPostDtoblocked.save().then(() => {
          self.set("blockPostSuccessDialog", true);
          self.get('findReportBlogItem').perform(reportBlogItem.id);
        });
      },
      unlockPost: function (reportBlogItem) {
        let self = this;
        let blogPostDtoblocked = reportBlogItem.blogPostDto;
        blogPostDtoblocked.set("blocked", false);
        blogPostDtoblocked.save().then(() => {
          self.set("unlockPostSuccessDialog", true);
          self.get('findReportBlogItem').perform(reportBlogItem.id);
        });
      },
      blockComment: function (reportBlogItem) {
        let self = this;
        let blogCommentDtoblocked = reportBlogItem.blogCommentDto;
        blogCommentDtoblocked.set("blocked", true);
        blogCommentDtoblocked.save().then(() => {
          self.set("blockPostSuccessDialog", true);
          self.get('findReportBlogItem').perform(reportBlogItem.id);
        });
      },
      unlockComment: function (reportBlogItem) {
        let self = this;
        let blogCommentDtoblocked = reportBlogItem.blogCommentDto;
        blogCommentDtoblocked.set("blocked", false);
        blogCommentDtoblocked.save().then(() => {
          self.set("unlockPostSuccessDialog", true);
          self.get('findReportBlogItem').perform(reportBlogItem.id);
        });
      },
      dismissReport: function (reportBlogItem) {
        reportBlogItem.destroyRecord().then(() => {
          this.set("model.selectedReport", null);
          this.set('page', 0);
          this.get("getReports").perform();
          this.set("dismissReportSave", true);
        }).catch(function (error) {
          this.set('dismissReportFailure', true);
        });
      },
      rememberReport: function (reportBlogItem) {
        let self = this;
        let blogCommentDtoblocked = reportBlogItem;
        blogCommentDtoblocked.set("validated", true);
        blogCommentDtoblocked.save().then(() => {
          self.set("rememberReportSave", true);
          self.get('findReportBlogItem').perform(reportBlogItem.id);
        });
      },
      blockUser: function (reportBlogItem) {
        let self = this;
        let userDto = reportBlogItem.get("userDto");
        userDto.set("blogBlocked", true);
        userDto.save().then(() => {
          self.set("blockUserSuccessDialog", true);
          self.get('findReportBlogItem').perform(reportBlogItem.id);
        });
      },
      closeBlockUserSuccessDialog: function () {
        this.set("blockUserSuccessDialog", false);
      },
      closerememberReportSave: function () {
        this.set("rememberReportSave", false);
      },
      closeBlockPostSuccessDialog: function () {
        this.set("blockPostSuccessDialog", false);
      },
      closeunlockPostSuccessDialog: function () {
        this.set("unlockPostSuccessDialog", false);
      },
      closeDismissReportSave: function () {
        this.set("dismissReportSave", false);
      },
      closeDismissReportFailure: function () {
        this.set("dismissReportFailure", false);
      }
    }
  });

  _exports.default = _default;
});