define("E4E/controllers/job-seekers/learning-monitoring", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    education: null,
    showIntershipInfo: false,
    showCompetenceInfo: false,
    selectedBasicTab: 0,
    init: function () {
      this._super(...arguments);

      this.get('getEducation').perform();
    },
    displayExtraInfo: Ember.A([]),
    getEducation: (0, _emberConcurrency.task)(function* () {
      let education = yield $.getJSON('/json-components/education_temp.json');
      this.set('education', education);
    }),
    actions: {
      displayExtraInfo: function (indexC) {
        this.get('displayExtraInfo').pushObject('showExtraInfo' + indexC);
      },
      closeExtraInfo: function (indexC) {
        this.get('displayExtraInfo').removeObject('showExtraInfo' + indexC);
      },
      openInternshipInfo: function (internship) {
        this.set('internshipTemp', internship);
        this.set('showIntershipInfo', true);
      },
      openCompetenceInfo: function (competence) {
        this.set('competence', competence);
        this.set('showCompetenceInfo', true);
      },
      closeInfo: function () {
        this.set('internshipTemp', null);
        this.set('showIntershipInfo', false);
      },
      closeCompetenceInfo: function () {
        this.set('competence', null);
        this.set('showCompetenceInfo', false);
      }
    }
  });

  _exports.default = _default;
});