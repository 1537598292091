define("E4E/components/blog/post-card", ["exports", "E4E/config/environment", "pell"], function (_exports, _environment, _pell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAddComment: false,
    ReportPostSuccessDialog: false,
    showReportUser: false,
    ReportUserSuccessDialog: false,
    notbutton: true,
    commentContent: "",
    blogPost: null,
    canSave: Ember.computed('commentContent', function () {
      if (this.get('commentContent') != null && this.get('commentContent') != '' && this.get('commentContent') != '<div><br></div>' && this.get('commentContent') != '<div></div>') {
        return false;
      } else {
        return true;
      }
    }),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    actions: {
      getPost(id) {
        location.href = "/blog/post/" + id;
      },

      changeBooleanProp(which, newValue) {
        this.set('booleanProp' + which, newValue);

        if (newValue) {
          this.set("booleanProp1Text", "Active");
        } else {
          this.set("booleanProp1Text", "Not Active");
        }
      },

      addPost(blogPost) {
        this.addPost(blogPost);
      },

      deletePost(blogPost) {
        this.deletePost(blogPost);
      },

      showAddComment() {
        this.set("showAddComment", true);
      },

      closeAddComment() {
        this.set("showAddComment", false);
      },

      saveComment: function (blogPost) {
        this.saveComment(this.get("commentContent"), blogPost);
        this.set("commentContent", "");
        this.set("showAddComment", false);
      },
      reportUser: function (blogPost) {
        this.set("showReportUser", true);
        this.set("blogPost", blogPost);
      },
      closeDialogReportUser: function () {
        this.set("showReportUser", false);
      },
      saveReportUser: function (content) {
        let self = this;
        let reportBlogItem = this.store.createRecord('reportblogitem');
        reportBlogItem.set('reasonReport', content);
        reportBlogItem.set('blogPostId', null);
        reportBlogItem.set('blogCommentId', null);
        reportBlogItem.set('userId', this.get("blogPost.authorId"));
        reportBlogItem.save().then(() => {
          self.set("content", "");
          self.set("showReportUser", false);
          self.set("ReportUserSuccessDialog", true);
        });
      },
      closeReportUserSuccessDialog: function () {
        this.set("ReportUserSuccessDialog", false);
      },
      reportComment: function (blogPost) {
        this.set("showDialogReport", true);
        this.set("blogPost", blogPost);
      },
      closeDialogReport: function () {
        this.set("showDialogReport", false);
      },
      closeReportPostSuccessDialog: function () {
        this.set("ReportPostSuccessDialog", false);
      },
      saveReport: function (content) {
        let self = this;
        let reportBlogItem = this.store.createRecord('reportblogitem');
        reportBlogItem.set('reasonReport', content);
        reportBlogItem.set('blogPostId', this.get("blogPost.id"));
        reportBlogItem.set('blogCommentId', null);
        reportBlogItem.save().then(() => {
          self.set("content", "");
          self.set("showDialogReport", false);
          self.set("ReportPostSuccessDialog", true);
        });
      }
    }
  });

  _exports.default = _default;
});