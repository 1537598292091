define("E4E/templates/components/questionbundles/search-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZIbwPcWV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-start\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-1\"],[9],[1,[23,[\"questionBundle\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"questionBundle\",\"bundleType\"]],\"QUESTION_BUNDLE\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[27,\"t\",[\"label.jobofferbundles\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[27,\"t\",[\"label.evalutaionschemebundle\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"span\"],[11,\"class\",\"text-14-type-11\"],[9],[1,[27,\"t\",[\"label.numberofquestions\"],[[\"numberOfQuestions\"],[[23,[\"questionBundle\",\"questionCount\"]]]]],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/questionbundles/search-card.hbs"
    }
  });

  _exports.default = _default;
});