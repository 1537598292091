define("E4E/controllers/job-seekers/job-applications/index", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    jobApplicationService: Ember.inject.service("jobapplication"),
    jobApplicationParams: ["jobseeker", "joboffer", "video", "motivationalletter", "request"],
    dialogService: Ember.inject.service('dialog-service'),
    fileService: Ember.inject.service('file-service'),
    paperToaster: Ember.inject.service(),
    showAnswersDialog: false,
    showRevokeDialog: false,
    deleteApplicationDialog: false,
    init: function () {
      this._super(...arguments);

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.jobApplications', Ember.A([]));
      this.set('model.meta', null);
      this.get('searchJobApplications').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedJobApplication') != null) {
          if (Ember.$('#search_container_job_appications').hasClass('hide')) {
            Ember.$('#job-application-container').addClass('show');
          } else {
            Ember.$('#search_container_job_appications').addClass('hide');
            Ember.$('#job-application-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_job_appications').removeClass('hide');
          Ember.$('#job-application-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_job_appications').removeClass('hide');
        Ember.$('#job-application-container').removeClass('show');
      }
    },

    findJobApplication: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('jobapplication', id, {
        include: this.jobApplicationParams.toString()
      }).then(jobApplication => {
        this.set('model.selectedJobApplication', jobApplication);
        Ember.$('#job-application-container').scrollTop(0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_appications').addClass('hide');
          Ember.$('#job-application-container').addClass('show');
        }
      });
    }).restartable(),
    searchJobApplications: (0, _emberConcurrency.task)(function* (nextPage) {
      this.jobApplicationService.filterJobSeekers(this.get('model.jobOfferName'), this.get('model.companyName'), this.get('model.countryName'), this.get('model.city'), this.get('model.workExperience'), this.get('model.yearsExperience'), this.get('model.education'), this.get('model.educationScope'), this.get('model.educationNumber'), this.get('model.educationDateIssue'), this.get('model.languageName'), this.get('model.languageScope'), this.get('model.languageNumber'), this.get('model.languageDateIssue'), this.get('model.drivingLicenses'), this.get('model.competenceName'), this.get('model.status'), this.get('model.inEvidence'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.jobApplications').pushObjects(resp.jobApplications.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.jobApplications', resp.jobApplications.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    actions: {
      resetFilters: function () {
        this.set('model.jobOfferName', '');
        Ember.run.debounce(this, this.search, 1000);
      },
      updateJobOffer: function (value) {
        this.set('model.jobOfferName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCity: function (value) {
        this.set('model.city', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompany: function (value) {
        this.set('model.companyName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateStatus: function (value) {
        if (this.get('model.status').includes(value)) {
          this.get('model.status').removeObject(value);
        } else {
          this.get('model.status').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        if (value == null) {
          this.set('model.countryName', "");
        } else {
          this.set('model.countryName', value.iso3);
        }

        this.set('model.country', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchJobApplications').perform(true);
      },
      searchJobApplications: function () {
        this.get("searchJobApplications").perform();
      },
      selectJobApplication: function (jobApplicationId) {
        this.get("findJobApplication").perform(jobApplicationId);
      },
      backToSearch: function () {
        this.set('model.selectedJobApplication', null);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_appications').removeClass('hide');
          Ember.$('#job-application-container').removeClass('show');
        }
      },
      showAnswers: function () {
        this.set('showAnswersDialog', true);
      },
      closeAnswersDialog: function () {
        this.set('showAnswersDialog', false);
      },
      downloadMotivationalLetter: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      updateInEvidence: function (value) {
        this.set('model.inEvidence', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      openRevokeInEvidence: function () {
        this.set('showRevokeDialog', true);
      },
      closeInEvidenceDialog: function () {
        this.set('showRevokeDialog', false);
      },
      revokeInEvidence: function () {
        let jobApplication = this.get('model.selectedJobApplication');
        jobApplication.set('inEvidence', false);
        jobApplication.save().then(() => {
          this.get('paperToaster').show(this.intl.t('label.inevidencerevokedresp'), {
            duration: 3000
          });
          this.set('showRevokeDialog', false);
          this.get("findJobApplication").perform(this.get('model.selectedJobApplication.id'));
        });
      },
      openDeleteApplicationDialog: function () {
        this.set('deleteApplicationDialog', true);
      },
      closeDeleteApplicationDialog: function () {
        this.set('deleteApplicationDialog', false);
      },
      deleteJobApplication: function () {
        let jobApplication = this.get('model.selectedJobApplication');
        jobApplication.destroyRecord().then(() => {
          this.set('deleteApplicationDialog', false);
          this.get('paperToaster').show(this.intl.t('label.jobapplicationdeleted.success'), {
            duration: 3000
          });
          this.set('model.selectedJobApplication', null);
          this.get("searchJobApplications").perform();
        });
      }
    }
  });

  _exports.default = _default;
});