define("E4E/routes/recruiters/organization/profile", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    organizationService: Ember.inject.service('organization'),
    organizationParams: ["joboffers", "jobapplications", "image", "sectors", "payablefunctionalities", "coverimage", "videos"],
    cookies: Ember.inject.service(),

    beforeModel(transition) {
      console.log();
      this.set('id', this.currentUserService.get('user.userOrganization.id'));
    },

    model() {
      return Ember.RSVP.hash({
        id: this.get('id'),
        organizationParams: this.get('organizationParams')
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("findOrganization");
        this.controller.send("getTopSectors");
      }
    }
  });

  _exports.default = _default;
});