define("E4E/components/superadmin/reports/report-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      rememberReport: function (value) {
        this.rememberReport(value);
      },
      dismissReport: function (value) {
        this.dismissReport(value);
      },
      blockPost: function (value) {
        this.blockPost(value);
      },
      blockComment: function (value) {
        this.blockComment(value);
      },
      unlockPost: function (value) {
        this.unlockPost(value);
      },
      unlockComment: function (value) {
        this.unlockComment(value);
      },
      blockUser: function (value) {
        this.blockUser(value);
      }
    }
  });

  _exports.default = _default;
});