define("E4E/controllers/job-seekers", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    closeSwiper: false,
    init: function () {
      this._super(...arguments);
    },
    actions: {
      closeSwiper: function () {
        this.set('closeSwiper', true);
      }
    }
  });

  _exports.default = _default;
});