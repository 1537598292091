define("E4E/controllers/blog/post", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    postShowSuccessDialog: false,
    postDeleteSuccessfull: false,
    postDeleteFailure: false,
    addPost: false,
    addComment: false,
    respMeta: null,
    page: 0,
    size: 10,
    blogPost: null,
    comment: null,
    id: 0,
    title: "",
    content: "",
    postId: 0,
    commentId: 0,
    blogPostParams: ["comments", "author"],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    init: function () {
      this._super(...arguments);

      let self = this;
    },
    search: function () {},
    getPost: (0, _emberConcurrency.task)(function* () {
      let self = this;
      yield this.store.findRecord('blogpost', this.get("model.id"), {
        include: this.blogPostParams.toString()
      }).then(blogPost => {
        this.set('model.blogPost', blogPost);
      });
    }),
    actions: {
      getPost: function () {
        this.get("getPost").perform();
      },
      addComment: function (comment) {
        if (comment != null) {
          this.set("comment", comment);
          this.set("commentId", comment.get("id"));
          this.set("content", comment.get("content"));
        }

        this.set("addComment", true);
      },
      saveComment: function (content, obj) {
        let self = this;

        if (obj != null) {
          if (obj.constructor.modelName == "blogpost") {
            let comment = this.store.createRecord('blogcomment');
            comment.set('content', content);
            comment.set('blogPostId', obj.id);
            comment.set('blogCommentId', null);
            comment.set('authorId', this.get("currentUserService.user.id"));
            comment.save().then(() => {
              self.get("getPost").perform();
              self.set("content", "");
              self.set("postShowSuccessDialog", true);
            });
          }

          if (obj.constructor.modelName == "blogcomment") {
            let comment = this.store.createRecord('blogcomment');
            comment.set('content', content);
            comment.set('blogPostId', null);
            comment.set('blogCommentId', obj.id);
            comment.set('authorId', this.get("currentUserService.user.id"));
            comment.save().then(() => {
              self.get("getPost").perform();
              self.set("content", "");
              self.set("postShowSuccessDialog", true);
            });
          }
        } else {
          let comment = this.get("comment");
          comment.set('content', content);
          comment.save().then(() => {
            self.get("getPost").perform();
            self.set("content", "");
            this.set("addComment", false);
            self.set("postShowSuccessDialog", true);
          });
        }
      },
      deleteComment: function (comment) {
        comment.destroyRecord().then(() => {
          this.set("postDeleteSuccessfull", true);
          this.get("getPost").perform();
        }).catch(function (error) {
          this.set('postDeleteFailure', true);
        });
      },
      closeAddComment: function () {
        this.set("comment", null);
        this.set("content", "");
        this.set("addComment", false);
      },
      addPost: function (blogPost) {
        if (blogPost != null) {
          this.set("blogPost", blogPost);
          this.set("postId", blogPost.get("id"));
          this.set("title", blogPost.get("title"));
          this.set("content", blogPost.get("content"));
          this.set("addPost", true);
        }
      },
      closeAddPost: function () {
        this.set("blogPost", null);
        this.set("title", "");
        this.set("content", "");
        this.set("addPost", false);
      },
      savePost: function (title, content) {
        let self = this;

        if (this.get("blogPost") != null) {
          let blogpost = this.get("blogPost");
          blogpost.set('title', title);
          blogpost.set('content', content);
          blogpost.save().then(() => {
            self.set("addPost", false);
            self.set("blogPost", null);
            self.set('page', 0);
            self.get("getPost").perform();
            self.set("title", "");
            self.set("content", "");
            self.set("postShowSuccessDialog", true);
          });
        }
      },
      deletePost: function (blogPost) {
        blogPost.destroyRecord().then(() => {
          this.set("postDeleteSuccessfull", true);
          location.href = "/blog";
        }).catch(function (error) {
          this.set('postDeleteFailure', true);
        });
      },
      closePostSuccessDialog: function () {
        this.set("postShowSuccessDialog", false);
      },
      closePostDeleteSuccessfull: function () {
        this.set("postDeleteSuccessfull", false);
      },
      closePostDeleteFailure: function () {
        this.set("postDeleteFailure", false);
      },
      refreshPage: function () {
        this.get("getPost").perform();
      }
    }
  });

  _exports.default = _default;
});