define("E4E/components/recruiters/team-members/teammember-preview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    currentUserService: Ember.inject.service('current-user'),
    dialogService: Ember.inject.service('dialog-service'),
    addWorkExperience: false,
    actions: {
      deleteTeamMember: function () {
        this.deleteTeamMember();
      },
      editTeamMember: function () {
        this.editTeamMember();
      },
      editBasicInformation: function () {
        let basicInformation = {
          firstName: this.get('selectedTeamMember.firstName'),
          lastName: this.get('selectedTeamMember.lastName'),
          address: this.get('selectedTeamMember.address'),
          postalCode: this.get('selectedTeamMember.postalCode'),
          city: this.get('selectedTeamMember.city'),
          country: this.get('selectedTeamMember.country'),
          birthDate: moment.utc(moment.utc(this.get('selectedTeamMember.birthDate')).format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day')._i.toString(),
          profilePicture: this.get('selectedTeamMember.profilePicture'),
          introduction: this.get('selectedTeamMember.introduction'),
          profession: this.get('selectedTeamMember.profession'),
          phoneNumber: this.get('selectedTeamMember.phoneNumber'),
          website: this.get('selectedTeamMember.website')
        };
        this.set('basicInformation', basicInformation);
        this.set('editBasicInformation', true);
      },
      saveEditBasicInformation: function () {
        let params = {
          firstName: this.get('basicInformation.firstName'),
          lastName: this.get('basicInformation.lastName'),
          address: this.get('basicInformation.address'),
          postalCode: this.get('basicInformation.postalCode'),
          city: this.get('basicInformation.city'),
          country: this.get('basicInformation.country.iso3'),
          birthDate: this.get('basicInformation.birthDate'),
          introduction: this.get('basicInformation.introduction'),
          profession: this.get('basicInformation.profession'),
          phoneNumber: this.get('basicInformation.phoneNumber'),
          website: this.get('basicInformation.website')
        };
        this.saveEditBasicInformation(params);
        this.send('closeEditBasicInformationDialog');
      },
      closeEditBasicInformationDialog: function () {
        this.set('basicInformation', null);
        this.set('editBasicInformation', false);
      },
      changeAvatar: function () {},
      didSelectFilesHeader: function (files) {
        let self = this;
        this.set('cropingCoverImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileCover', files[0]);

          let done = function (url) {
            self.set('fileCoverSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      didSelectFilesAvatar: function (files) {
        let self = this;
        this.set('cropingAvatarImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileAvatar', files[0]);

          let done = function (url) {
            self.set('fileAvatarSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      didSelectFilesCV: function (files, resetInput) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          self.uploadCV(files[0]);
        }

        resetInput();
      },
      downloadCV: function () {
        this.downloadCV(this.get('selectedTeamMember.curriculumVitae.originalFileName'), this.get('selectedTeamMember.curriculumVitae.fileId'));
      },
      generateCV: function () {
        this.generateCV();
      },
      addWorkExperience: function () {
        this.set('addWorkExperience', true);
      },
      closeAddWorkExperienceDialog: function () {
        this.set('addWorkExperience', false);
      },
      closeEditWorkExperienceDialog: function () {
        this.set('editWorkExperienceDialog', false);
      },
      closeEditEducationDialog: function () {
        this.set('editEducationDialog', false);
      },
      editWorkExperience: function (workExperience, index) {
        this.set('workExperience', workExperience);
        this.set('index', index);
        this.set('editWorkExperienceDialog', true);
      },
      editEducation: function (education, index) {
        this.set('education', education);
        this.set('index', index);
        this.set('editEducationDialog', true);
      },
      saveWorkExperience: function (workExperience, certificates, deletedCertificates, index) {
        this.saveWorkExperience(workExperience, certificates, deletedCertificates, index);
        this.set('addWorkExperience', false);
        this.set('editWorkExperienceDialog', false);
      },
      addEducation: function () {
        this.set('addEducation', true);
      },
      closeAddEducationDialog: function () {
        this.set('addEducation', false);
      },
      saveEducation: function (education, certificates, deletedCertificates, index, outdated) {
        this.saveEducation(education, certificates, deletedCertificates, index, outdated);
        this.set('addEducation', false);
        this.set('editEducationDialog', false);
      },
      addLanguage: function () {
        this.set('addLanguage', true);
      },
      editForeignLanguage: function (foreignLanguage, index) {
        this.set('foreignLanguage', foreignLanguage);
        this.set('index', index);
        this.set('editForeignLanguage', true);
      },
      closeEditForeignLanguageDialog: function () {
        this.set('editForeignLanguage', false);
      },
      closeAddLanguageDialog: function () {
        this.set('addLanguage', false);
      },
      saveLanguage: function (language, certificates, motherTongue, deletedCertificates, index) {
        this.saveLanguage(language, certificates, motherTongue, deletedCertificates, index);
        this.set('addLanguage', false);
        this.set('editForeignLanguage', false);
      },
      deleteForeignLanguage: function (foreignLanguage) {
        this.deleteForeignLanguage(foreignLanguage);
      },
      removeMotherLanguage: function (motherTongue) {
        this.removeMotherLanguage(motherTongue);
      },
      deleteWorkExperience: function (workExperience) {
        this.deleteWorkExperience(workExperience);
      },
      deleteEducation: function (education) {
        this.deleteEducation(education);
      },
      editSocialSkills: function (socialSkill) {
        if (socialSkill != null && socialSkill != undefined) {
          this.set('socialSkill', socialSkill);
        } else {
          this.set('socialSkill', Ember.copy(this.socialSkills, true));
        }

        this.set('editSocialSkill', true);
      },
      editOrganizationSkills: function (organizationalSkill) {
        if (organizationalSkill != null && organizationalSkill != undefined) {
          this.set('organizationalSkill', organizationalSkill);
        } else {
          this.set('organizationalSkill', Ember.copy(this.organizationalSkills, true));
        }

        this.set('editOrganizationalSkill', true);
      },
      editProfessionalSkills: function (professionalSkill) {
        if (professionalSkill != null && professionalSkill != undefined) {
          this.set('professionalSkill', professionalSkill);
        } else {
          this.set('professionalSkill', Ember.copy(this.professionalSkills, true));
        }

        this.set('editProfessionalSkill', true);
      },
      saveSocialSkill: function (socialSkill) {
        if (this.get('selectedTeamMember.skillsJson') != null) {
          this.set('selectedTeamMember.skillsJson.Communication', socialSkill);
          this.set('selectedTeamMember.skills', JSON.stringify({
            skills: this.get('selectedTeamMember.skillsJson')
          }));
          this.userService.save(this.get('selectedTeamMember'), this.get('selectedTeamMemberParams'));
        } else {
          let Communication = {
            Competences: socialSkill.Competences
          };
          this.set('selectedTeamMember.skills', JSON.stringify({
            skills: Communication
          }));
          this.userService.save(this.get('selectedTeamMember'), this.get('selectedTeamMemberParams'));
        }

        this.set('editSocialSkill', false);
      },
      closeEditSocialSkillDialog: function () {
        this.set('editSocialSkill', false);
      },
      closeEditOrganizationSkillDialog: function () {
        this.set('editOrganizationalSkill', false);
      },
      saveOrganizationSkill: function (organizationalSkill) {
        if (this.get('selectedTeamMember.skillsJson') != null) {
          this.set('selectedTeamMember.skillsJson.Organisational', organizationalSkill);
          this.set('selectedTeamMember.skills', JSON.stringify({
            skills: this.get('selectedTeamMember.skillsJson')
          }));
          this.userService.save(this.get('selectedTeamMember'), this.get('selectedTeamMemberParams'));
        } else {
          let Organisational = {
            Competences: organizationalSkill.Competences
          };
          this.set('selectedTeamMember.skills', JSON.stringify({
            skills: Organisational
          }));
          this.userService.save(this.get('selectedTeamMember'), this.get('selectedTeamMemberParams'));
        }

        this.set('editOrganizationalSkill', false);
      },
      closeEditProfessionalSkillDialog: function () {
        this.set('editProfessionalSkill', false);
      },
      saveProfessionalSkill: function (professionalSkill) {
        if (this.get('selectedTeamMember.skillsJson') != null) {
          this.set('selectedTeamMember.skillsJson.JobRelated', professionalSkill);
          this.set('selectedTeamMember.skills', JSON.stringify({
            skills: this.get('selectedTeamMember.skillsJson')
          }));
          this.userService.save(this.get('selectedTeamMember'), this.get('selectedTeamMemberParams'));
        } else {
          let JobRelated = {
            Competences: professionalSkill.Competences
          };
          this.set('selectedTeamMember.skills', JSON.stringify({
            skills: JobRelated
          }));
          this.userService.save(this.get('selectedTeamMember'), this.get('selectedTeamMemberParams'));
        }

        this.set('editProfessionalSkill', false);
      },
      editDigitalSkills: function () {
        if (this.get('selectedTeamMember.skillsJson') != null) {
          if (this.get('selectedTeamMember.skillsJson.Computer') != null) {
            this.set('digitalSkill', {
              ProficiencyLevel: {
                Information: this.get('selectedTeamMember.skillsJson.Computer.ProficiencyLevel.Information'),
                Communication: this.get('selectedTeamMember.skillsJson.Computer.ProficiencyLevel.Communication'),
                ContentCreation: this.get('selectedTeamMember.skillsJson.Computer.ProficiencyLevel.ContentCreation'),
                Safety: this.get('selectedTeamMember.skillsJson.Computer.ProficiencyLevel.Safety'),
                ProblemSolving: this.get('selectedTeamMember.skillsJson.Computer.ProficiencyLevel.ProblemSolving')
              },
              ReferenceTo: this.get('selectedTeamMember.skillsJson.Computer.ReferenceTo')
            });
          } else {
            this.set('digitalSkill', {
              ProficiencyLevel: {
                Information: null,
                Communication: null,
                ContentCreation: null,
                Safety: null,
                ProblemSolving: null
              },
              ReferenceTo: Ember.A([])
            });
          }
        } else {
          this.set('digitalSkill', {
            ProficiencyLevel: {
              Information: null,
              Communication: null,
              ContentCreation: null,
              Safety: null,
              ProblemSolving: null
            },
            ReferenceTo: Ember.A([])
          });
        }

        this.set('editDigitalSkills', true);
      },
      closeEditDigitalSkillsDialog: function () {
        this.set('editDigitalSkills', false);
      },
      saveDigitalSkills: function (digitalSkill, certificates, deletedCertificates) {
        this.saveDigitalSkills(digitalSkill, certificates, deletedCertificates);
        this.set('editDigitalSkills', false);
      },
      closeEditDrivingLicenseDialog: function () {
        this.set('editDrivingLicense', false);
      },
      saveDrivingLicense: function (drivingLicense, certificates, deletedCertificates) {
        this.saveDrivingLicense(drivingLicense, certificates, deletedCertificates);
        this.set('editDrivingLicense', false);
      },
      editDrivingLicense: function () {
        if (this.get('selectedTeamMember.skillsJson') != null) {
          if (this.get('selectedTeamMember.skillsJson.Driving') != null) {
            this.set('drivingLicense', {
              Description: this.get('selectedTeamMember.skillsJson.Driving.Description'),
              ReferenceTo: this.get('selectedTeamMember.skillsJson.Driving.ReferenceTo')
            });
          } else {
            this.set('drivingLicense', {
              Description: Ember.A([]),
              ReferenceTo: Ember.A([])
            });
          }
        } else {
          this.set('drivingLicense', {
            Description: Ember.A([]),
            ReferenceTo: Ember.A([])
          });
        }

        this.set('editDrivingLicense', true);
      },
      closeCoverImageDialog: function () {
        this.set('cropingCoverImage', false);
      },
      closeAvatarImageDialog: function () {
        this.set('cropingAvatarImage', false);
      },
      uploadUserAvatar: function (avatar) {
        this.uploadUserAvatar(avatar);
      },
      uploadUserCover: function (cover) {
        this.uploadUserCover(cover);
      },
      changePassword: function () {
        this.set('changePasswordDialog', true);
      },
      closeChangePasswordDialog: function () {
        this.set('changePasswordDialog', false);
      },
      saveNewPassword: function (params) {
        let self = this;
        this.authorizedPost(_environment.default.APP.SPRING_HOST + '/users/' + this.get('selectedTeamMember.id') + '/change/password', params, null).then(resp => {
          if (resp) {
            self.set('successChangePassword', true);
            this.set('changePasswordDialog', false);
          } else {
            self.set('failureChangePassword', true);
          }
        });
      },
      closeChangePasswordToast: function () {
        this.set('successChangePassword', false);
        this.set('failureChangePassword', false);
      },
      downloadCVWord: function (originalFileName, id) {
        this.downloadCVWord(originalFileName, id);
      }
    }
  });

  _exports.default = _default;
});