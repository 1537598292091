define("E4E/components/pagination-links", ["exports", "ember-pagination-links/components/pagination-links"], function (_exports, _paginationLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paginationLinks.default;
    }
  });
});