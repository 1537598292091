define("E4E/routes/organizations/job-offers", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    /*
    currentUserService: service('current-user'),
    cookies: service(),
    jobOfferName: '',
    sectorName: '',
    countryName:'',
    types: [
        "FULL_TIME",
        "PART_TIME",
        "FULL_TIME_PART_TIME",
        "FREELANCER",
        "NONE"
    ],
    status: [
        "ACTIVE",
        "FINISHED",
        "NONE"
    ],
    jobOffersIds: [],
    page:0,
    selectedJobOffer: null,
    sector: null,
    authorId: '',
    beforeModel: function(){
        let cookieService = this.get('cookies');
        let jobOfferStatus = cookieService.read('jobOfferStatus');
        if(jobOfferStatus != undefined){
            this.set('status', ["ACTIVE", "NONE"]);
            cookieService.clear('jobOfferStatus');
        }
    },
    model(){
        return hash({
            selectedJobOffer: null,
            sectors: this.store.findAll("sector"),
            sector: this.get("sector"),
            types: this.get("types"),
            status: this.get("status"),
            jobOffersIds: this.get('jobOffersIds'),
            jobOfferName: this.get("jobOfferName"),
            sectorName: this.get("sectorName"),
            countryName: this.get("countryName"),
            authorId: this.get('authorId'),
            page: this.get('page')
        })
    },
    actions: {
        didTransition:function(){
            this.controller.send("searchJobOffers");
        }
    }
    */
  });

  _exports.default = _default;
});