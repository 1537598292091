define("E4E/components/recruiters/team-members/edit-teammember", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: {
      actions: [{
        name: 'bold',
        title: 'Bold',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
      }, {
        name: 'italic',
        title: 'Italic',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
      }, {
        name: 'underline',
        title: 'Underline',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
      }, {
        name: 'ulist',
        title: 'Unordered List',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
      }, {
        name: 'olist',
        title: 'Ordered List',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
      }, {
        name: 'indent',
        title: 'Increase Identation',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
        result: () => exec('indent')
      }, {
        name: 'outdent',
        title: 'Decrease Identation',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
        result: () => exec('outdent')
      }, {
        name: 'link',
        title: 'Add link',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
        result: () => {
          const url = window.prompt('Enter the link URL');
          if (url) exec('createLink', url);
        }
      }, {
        name: 'unlink',
        title: 'Remove Link',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
        result: () => exec('unlink')
      }, {
        name: 'subscript',
        title: 'Subscript',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
        result: () => exec('subscript')
      }, {
        name: 'superscript',
        title: 'Superscript',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
        result: () => exec('superscript')
      }]
    },
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    cover: null,
    avatar: null,
    fileCover: null,
    fileAvatar: null,
    cropingImage: false,
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
    },
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
      this.set('country', this.get('countries').findBy("iso3", this.get('params.country').toUpperCase()));
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      changeAvatar: function () {},
      didSelectFilesHeader: function (files) {
        let self = this;
        this.set('cropingCoverImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileCover', files[0]);

          let done = function (url) {
            self.set('fileCoverSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      didSelectFilesAvatar: function (files) {
        let self = this;
        this.set('cropingAvatarImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileAvatar', files[0]);

          let done = function (url) {
            self.set('fileAvatarSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      closeCoverImageDialog: function () {
        this.set('cropingCoverImage', false);
      },
      closeAvatarImageDialog: function () {
        this.set('cropingAvatarImage', false);

        if (this.get('avatar') != null) {
          this.set('params.profilePicture', null);
        }
      }
    }
  });

  _exports.default = _default;
});