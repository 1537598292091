define("E4E/mixins/keep-only-changed", ["exports", "ember-data-change-tracker/mixins/keep-only-changed"], function (_exports, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _keepOnlyChanged.default.reopen({
    keepValue(record, key) {
      if (record._internalModel.modelName === "user") {
        if (key === "outplacerId") {
          return true;
        }

        if (key === "partnerId") {
          return true;
        }
      }

      if (record._internalModel.modelName === "joboffer") {
        if (key === "startDate") {
          return true;
        }

        if (key === "jobOfferPdfId") {
          return true;
        }
      }

      if (record._internalModel.modelName === "jobapplication") {
        if (key === "tags") {
          return true;
        }
      }

      if (key === "forCompany") {
        return true;
      } else {
        return record.get('isNew') || record.didChange(key);
      }
    },

    serializeAttribute: function (snapshot, json, key) {
      if (this.keepValue(snapshot.record, key)) {
        return this._super(...arguments);
      }
    }
    /*
    serializeBelongsTo: function(snapshot, json, relationship) {
        if (this.keepValue(snapshot.record, relationship.key)) {
            let key = relationship.key;
            
            if (this._canSerialize(key)) {
                let belongsToId = snapshot.belongsTo(key, { id: true });
                 let payloadKey = this._getMappedKey(key, snapshot.type);
                if (payloadKey === key && this.keyForRelationship) {
                    payloadKey = this.keyForRelationship(key, "belongsTo", "serialize");
                }
                 if (isNone(belongsToId)) {
                    json[payloadKey] = null;
                } else {
                    json[payloadKey] = snapshot.belongsTo(key, { id: false })._attributes.links.self;
                }
                 if (relationship.options.polymorphic) {
                    this.serializePolymorphicType(snapshot, json, relationship);
                }
            }
        }
    },
    
    serializeHasMany: function(snapshot, json, relationship) {
        if (this.keepValue(snapshot.record, relationship.key)) {
            let key = relationship.key;
            let hasMany = snapshot.hasMany(key, { ids: true });
            if (hasMany !== undefined) {
                let payloadKey = this._getMappedKey(key, snapshot.type);
                if (payloadKey === key && this.keyForRelationship) {
                    payloadKey = this.keyForRelationship(key, "hasMany", "serialize");
                }
                let lul = snapshot.hasMany(key, { ids: false });
                let lel = [];
                lul.forEach(function(snapshot) {
                    lel.push(snapshot._attributes.hasManyRelationships);
                });
                json[payloadKey] = lel;
            }
        }
    }
    */

  });

  _exports.default = _default;
});