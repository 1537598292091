define("E4E/templates/registration-successfull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hBsBY7s+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-center width100P\"],[9],[0,\"\\n  \"],[1,[27,\"login-header\",null,[[\"authenticate\",\"class\",\"openSelectionOptions\"],[[27,\"action\",[[22,0,[]],\"authenticate\"],null],\"width100P layout-row layout-align-space-between-center height56\",[27,\"action\",[[22,0,[]],\"openSelectionOptions\"],null]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-center width100P heightViewAnonymous\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"margin-top-48\"],[11,\"src\",\"/pix/register_complete.svg\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-60-type-4 margin-top-32\"],[9],[1,[27,\"t\",[\"label.registration.successfulltitle\"],null],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-16-type-8 margin-top-24\"],[9],[1,[27,\"html-safe\",[[27,\"t\",[\"label.registration.successfullmessage\"],null]],null],false],[10],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"primary\",\"raised\",\"onClick\"],[\"margin-top-48\",true,true,[27,\"action\",[[22,0,[]],\"goToLogin\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"label.exploree4e\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/registration-successfull.hbs"
    }
  });

  _exports.default = _default;
});