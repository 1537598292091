define("E4E/models/comment", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    content: _emberData.default.attr('string'),
    userId: _emberData.default.attr('string'),
    author: _emberData.default.belongsTo('user', {
      async: false
    }),
    video: _emberData.default.belongsTo('video', {
      async: true
    }),
    videoId: _emberData.default.attr('string'),
    privateComment: _emberData.default.attr('boolean'),
    links: _emberData.default.attr()
  });

  _exports.default = _default;
});