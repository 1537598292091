define("E4E/models/document", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    fileId: _emberData.default.attr('string'),
    authorId: _emberData.default.attr('string'),
    originalFileName: _emberData.default.attr('string'),
    fileDto: _emberData.default.belongsTo('file', {
      async: false,
      inverse: null
    }),
    category: _emberData.default.attr('string')
  });

  _exports.default = _default;
});