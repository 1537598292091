define("E4E/models/education", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('number'),
    jobOffers: _emberData.default.hasMany('joboffer', {
      async: false
    }),
    competences: _emberData.default.attr('string'),
    competencesMap: Ember.computed('competences', function () {
      if (this.get('competences') != null) {
        return JSON.parse(this.get('competences'));
      }
    }),
    competencesArray: Ember.computed('competences', function () {
      if (this.get('competences') != null) {
        let array = [];
        let competences = JSON.parse(this.get('competences'));
        this.extractArray(competences, array);
        return Array.from(new Set(array));
      }
    }),
    extractArray: function (array, pushArray) {
      array.map(competence => {
        this.extractArray(competence.competences, pushArray);
        pushArray.pushObject({
          name: competence.name,
          id: competence.id
        });
      });
    },
    links: _emberData.default.attr()
  });

  _exports.default = _default;
});