define("E4E/controllers/public-profile", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    authentication: Ember.inject.service('authentication'),
    router: Ember.inject.service('router'),
    actions: {
      register: function () {},
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },
      downloadCV: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      downloadCVWord: function (originalFileName) {
        let self = this;
        this.set('waitingCvDialog', true);
        this.authorizedGet('/rest/files/curriculum/generate/xml?userId=' + this.get('model.user.id')).then(function (record) {
          $.ajaxTransport("+binary", function (options, originalOptions, jqXHR) {
            // check for conditions and support for blob / arraybuffer response type
            if (window.FormData && (options.dataType && options.dataType == 'binary' || options.data && (window.ArrayBuffer && options.data instanceof ArrayBuffer || window.Blob && options.data instanceof Blob))) {
              return {
                // create new XMLHttpRequest
                send: function (headers, callback) {
                  // setup all variables
                  var xhr = new XMLHttpRequest(),
                      url = options.url,
                      type = options.type,
                      async = options.async || true,
                      // blob or arraybuffer. Default is blob
                  dataType = options.responseType || "blob",
                      data = options.data || null,
                      username = options.username || null,
                      password = options.password || null;
                  xhr.addEventListener('load', function () {
                    var data = {};
                    data[options.dataType] = xhr.response; // make callback and send data

                    callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
                  });
                  xhr.open(type, url, async, username, password); // setup custom headers

                  for (var i in headers) {
                    xhr.setRequestHeader(i, headers[i]);
                  }

                  xhr.responseType = dataType;
                  xhr.send(data);
                },
                abort: function () {
                  jqXHR.abort();
                }
              };
            }
          });
          $.ajax({
            type: "POST",
            url: "https://europass.cedefop.europa.eu/rest/v1/document/to/word",
            headers: {
              'Content-Type': 'application/xml',
              'Accept-Language': 'de'
            },
            data: record,
            dataType: 'binary',
            processData: false,
            success: function (data) {
              self.set('waitingCvDialog', false); //var data = new Uint8Array(response);

              var filename = originalFileName + ".doc";
              var blob = new Blob([data], {
                type: 'application/msword'
              });

              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
              } else {
                var elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blob);
                elem.download = filename;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
              }
            },
            error: function (error) {
              self.set('waitingCvDialog', false);
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});