define("E4E/components/superadmin/users/users-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
    },
    actions: {
      rejectUser: function () {
        this.rejectUser();
      },
      acceptUser: function () {
        this.acceptUser();
      },
      showAdviceDelete: function () {
        this.showAdviceDelete();
      },
      downloadCV: function (originalFileName, fileId) {
        this.downloadCV(originalFileName, fileId);
      },
      downloadCVWord: function (originalFileName, fileId) {
        this.downloadCVWord(originalFileName, fileId);
      },
      blockUserinBlog: function (user) {
        this.blockUserinBlog(user);
      },
      unblockUserinBlog: function (user) {
        this.unblockUserinBlog(user);
      }
    }
  });

  _exports.default = _default;
});