define("E4E/components/delete-account-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    actions: {
      logoutUser: function () {
        this.get('session').invalidate();
      }
    }
  });

  _exports.default = _default;
});