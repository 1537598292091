define("E4E/services/blogcomment", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    save: function (content, obj) {
      let self = this;

      if (obj != null) {
        console.log("obj ! null");

        if (obj.constructor.modelName == "blogpost") {
          let comment = this.store.createRecord('blogcomment');
          comment.set('content', content);
          comment.set('blogPostId', obj.id);
          comment.set('blogCommentId', null);
          comment.set('authorId', this.get("currentUserService.user.id"));
          comment.save().then(() => {
            self.get("getPost").perform();
            self.set("content", "");
            self.set("postShowSuccessDialog", true);
          });
        }

        if (obj.constructor.modelName == "blogcomment") {
          console.log("blogcomment"); // console.log(content)

          let comment = this.store.createRecord('blogcomment');
          comment.set('content', content);
          comment.set('blogPostId', null);
          comment.set('blogCommentId', obj.id);
          comment.set('authorId', this.get("currentUserService.user.id"));
          comment.save().then(() => {
            self.get("getPost").perform();
            self.set("content", "");
            self.set("postShowSuccessDialog", true);
          });
        }
      } else {
        let comment = this.get("comment");
        comment.set('content', content);
        comment.save().then(() => {
          self.get("getPost").perform();
          self.set("content", "");
          this.set("comment", null);
          this.set("addComment", false);
          self.set("postShowSuccessDialog", true);
        });
      }
    }
  });

  _exports.default = _default;
});