define("E4E/components/pdf-js", ["exports", "ember-pdf-js/components/pdf-js"], function (_exports, _pdfJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pdfJs.default;
    }
  });
});