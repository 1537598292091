define("E4E/helpers/type-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeFile = typeFile;

  function typeFile(params
  /*, hash*/
  ) {
    if (typeof params[0] != "undefined" && params[0].length > 0) {
      if (params[0].match(/video/)) {
        return "video_file";
      } else if (params[0].match(/image/)) {
        return "image";
      } else if (params[0].match(/pdf/)) {
        return "picture_as_pdf";
      }

      return "description";
    } else return "description";
  }

  var _default = Ember.Helper.helper(typeFile);

  _exports.default = _default;
});