define("E4E/controllers/verify-account", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUserService: Ember.inject.service('current-user'),
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    postCode: (0, _emberConcurrency.task)(function* (token) {
      let response = yield this.ajaxPost(token);
      this.set('response', response);
    }).restartable(),
    router: Ember.inject.service(),
    ajaxPost: function (token) {
      return $.ajax({
        type: "POST",
        url: _environment.default.APP.SPRING_HOST + "/api/registration/verifyAccount",
        data: 'token=' + token
      });
    },
    authorizedPost: function (url, content) {
      let self = this;
      return $.ajax({
        type: 'POST',
        url: url,
        data: 'tokenOld=' + content.tokenOld + '&locale=' + content.locale,
        success: function () {
          self.set('showWaitingDialog', false);
          self.set('showSuccessDialog', true);
        },
        error: function () {
          self.set('showWaitingDialog', false);
          self.set('showErrorDialog', true);
        }
      }).then(function (response) {
        self.set('message', response.message);
      });
    },
    authorizedPostNewEmail: function (url, content) {
      let self = this;
      return $.ajax({
        type: 'POST',
        url: url,
        data: 'email=' + content.email + '&locale=' + content.locale,
        success: function () {
          self.set('showWaitingDialog', false);
          self.set('showSuccessDialog', true);
        },
        error: function () {
          self.set('showWaitingDialog', false);
          self.set('showErrorDialog', true);
        }
      }).then(function (response) {
        self.set('message', response.message);
      });
    },
    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: inputValue => {
        let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(inputValue);
      }
    }],
    actions: {
      goToLogin: function () {
        this.get('router').transitionTo('login');
      },
      resendVerificationToken: function () {
        let locale = this.get('intl.locale');
        var content = {
          tokenOld: this.get('token'),
          locale: locale
        };
        let response = this.authorizedPost(_environment.default.APP.SPRING_HOST + "/api/registration/resendConfirmationEmail", content);
      },
      closeSuccessDialog: function () {
        this.set('showSuccessDialog', false);
      },
      closeErrorDialog: function () {
        this.set('showErrorDialog', false);
      },
      checkPost: function () {
        this.get('postCode').perform(this.get('model.token'));
      },
      generateNewVerificationEmail: function () {
        let locale = this.get('intl.locale');
        var content = {
          email: this.get('model.email'),
          locale: locale
        };
        let response = this.authorizedPostNewEmail(_environment.default.APP.SPRING_HOST + "/api/registration/resendVerificationCode", content);
      }
    }
  });

  _exports.default = _default;
});