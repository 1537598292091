define("E4E/validators/email-available", ["exports", "ember-cp-validations/validators/base", "E4E/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const EmailAvailable = _base.default.extend({
    intl: Ember.inject.service(),
    message: 'Something is wrong',
    validate: function (value) {
      let self = this;
      return Ember.$.get(_environment.default.APP.SPRING_HOST + "/users/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
        if (response === false) {
          return Ember.$.get(_environment.default.APP.SPRING_HOST + "/organizations/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
            if (response === false) {
              return true;
            } else {
              return self.get('intl').t('label.emailinuse');
            }
          });
        } else {
          return self.get('intl').t('label.emailinuse').string;
        }
      });
    }
  });

  EmailAvailable.reopenClass({
    getDependentsFor() {
      return [];
    }

  });
  var _default = EmailAvailable;
  _exports.default = _default;
});