define("E4E/routes/superadmin/job-offers/index", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    types: ["FULL_TIME", "PART_TIME", "FULL_TIME_PART_TIME", "FREELANCER", "NONE"],
    status: ["ACTIVE", "FINISHED", "NONE"],
    jobOffersIds: [],
    page: 0,
    drivingLicenses: [],
    isFavorite: false,
    beforeModel: function () {
      let cookieService = this.get('cookies');
      let jobOfferStatus = cookieService.read('jobOfferStatus');
      let jobOfferId = cookieService.read('jobOfferId');
      let jobOfferTitle = cookieService.read('jobOfferTitle');
      let jobOfferFavourite = cookieService.read('jobOfferFavourite');

      if (jobOfferStatus != undefined) {
        this.set('status', ["ACTIVE", "NONE"]);
        cookieService.clear('jobOfferStatus');
      }

      if (jobOfferId != undefined) {
        this.set('jobOfferId', jobOfferId);
        cookieService.clear('jobOfferId');
      } else {
        this.set('jobOfferId', null);
      }

      if (jobOfferTitle != undefined) {
        this.set('jobOfferName', jobOfferTitle);
        cookieService.clear('jobOfferTitle');
      } else {
        this.set('jobOfferName', '');
      }

      if (jobOfferFavourite != undefined) {
        this.set('isFavorite', jobOfferFavourite);
        cookieService.clear('jobOfferFavourite');
      }
    },

    model() {
      return Ember.RSVP.hash({
        selectedJobOffer: null,
        country: null,
        city: '',
        jobSeekerCountry: '',
        jobOfferName: this.get('jobOfferName'),
        educationDateIssue: '',
        educationNumber: '',
        educationScope: '',
        language: [],
        languageName: [],
        languageScope: '',
        languageNumber: '',
        languageDateIssue: '',
        yearsExperience: '',
        competenceName: [],
        workExperience: '',
        education: '',
        category: '',
        company: '',
        types: this.get('types'),
        drivingLicenses: this.get('drivingLicenses'),
        page: this.get('page'),
        jobOfferId: this.get('jobOfferId'),
        isFavorite: this.get('isFavorite')
      });
    },

    actions: {
      didTransition: function () {
        if (this.get('jobOfferId') != null) {
          this.controller.send("searchAndFindJobOffer");
        } else {
          this.controller.send("searchJobOffers");
        }
      }
    }
  });

  _exports.default = _default;
});