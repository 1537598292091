define("E4E/components/power-select/lazy-options", ["exports", "ember-power-select/components/power-select/options"], function (_exports, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const OptionsComponent = _options.default.extend({
    canLoadMore: false,

    init() {
      this._super(...arguments); // BEGIN Move to initializer


      Ember.$.fn.checkInView = function (partial = false) {
        const elem = this;

        if (!elem.length) {
          return false;
        }

        const container = elem.parent();
        const contHeight = container.height();
        const contTop = container.scrollTop();
        const contBottom = contTop + contHeight;
        const elemTop = elem.offset().top - container.offset().top;
        const elemBottom = elemTop + elem.height();
        const isTotal = elemTop >= 0 && elemBottom <= contHeight;
        const isPart = (elemTop < 0 && elemBottom > 0 || elemTop > 0 && elemTop <= container.height()) && partial;
        return isTotal || isPart;
      }; // END


      (true && !(Ember.get(this, 'loadMore') && typeof Ember.get(this, 'loadMore') === 'function') && Ember.assert('<component:infinite-options>: You must provide a closure action name `loadMore`', Ember.get(this, 'loadMore') && typeof Ember.get(this, 'loadMore') === 'function'));
    },

    didInsertElement() {
      this._super(...arguments);

      const checkVisibility = () => {
        if (Ember.$(this.element).find('.ember-power-select-option--load-more').checkInView(true)) {
          const loadMore = Ember.get(this, 'loadMore');
          return loadMore();
        }
      };

      Ember.$(this.element).on('scroll', () => checkVisibility());
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.$(this.element).off('scroll');
    }

  });

  var _default = OptionsComponent;
  _exports.default = _default;
});