define("E4E/components/users/languages-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAttachments: false,
    currentUserService: Ember.inject.service('current-user'),
    actions: {
      showAttachments: function () {
        this.set('showAttachments', true);
      },
      editForeignLanguage: function (foreignLanguage, index) {
        this.editForeignLanguage(foreignLanguage, index);
      },
      deleteForeignLanguage: function (foreignLanguage) {
        this.deleteForeignLanguage(foreignLanguage);
        this.set('deleteForeignLanguageDialog', false);
      },
      openDeleteForeignLanguageDialog: function () {
        this.set('deleteForeignLanguageDialog', true);
      },
      cancelDeleteForeignLanguageDialog: function () {
        this.set('deleteForeignLanguageDialog', false);
      }
    }
  });

  _exports.default = _default;
});