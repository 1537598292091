define("E4E/models/conversation", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    authorId: _emberData.default.attr('string'),
    receiverId: _emberData.default.attr('string'),
    messages: _emberData.default.hasMany("message", {
      async: false,
      inverse: null
    }),
    count: _emberData.default.attr('number'),
    // messages: DS.attr('string'),
    // hello: "abc",
    // messagesNotRead: computed('messages.@each', function(){
    // }),
    // extractArray: function(array, pushArray){
    //     array.map(message => {
    //         this.extractArray(message.messages, pushArray);
    //         pushArray.pushObject({ id: message.id, authorId: message.authorId, conversationId: message.conversationId, text: message.text });
    //     });
    // },
    fromUser: _emberData.default.belongsTo('user', {
      async: false,
      inverse: null
    }) // authorUser: DS.belongsTo('user', {async: false, inverse: null}),
    // receiverUser: DS.belongsTo('user', {async: false, inverse: null})

  });

  _exports.default = _default;
});