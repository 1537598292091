define("E4E/models/organization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    profilePicture: _emberData.default.belongsTo('picture', {
      async: false,
      inverse: null
    }),
    users: _emberData.default.hasMany('user', {
      async: false,
      inverse: null
    }),
    sectors: _emberData.default.hasMany('sector', {
      async: false,
      inverse: null
    }),
    jobOffers: _emberData.default.hasMany('joboffer', {
      async: false,
      inverse: null
    }),
    tags: _emberData.default.hasMany('tag', {
      async: false,
      inverse: null
    }),
    coverImage: _emberData.default.belongsTo('cover', {
      async: false,
      inverse: null
    }),
    links: _emberData.default.attr(),
    flags: _emberData.default.hasMany('flag', {
      async: false,
      inverse: null
    }),
    activeJobOffers: Ember.computed('jobOffers.@each', function () {
      return this.get('jobOffers').filter(item => item.get('deadline') >= new Date());
    }),
    finishedJobOffers: Ember.computed('jobOffers.@each', function () {
      return this.get('jobOffers').filter(item => item.get('deadline') < new Date());
    }),
    totalJobApplications: Ember.computed('jobOffers.@each', function () {
      let totalJobApplications = 0;
      this.get('jobOffers').forEach(function (jobOffer) {
        totalJobApplications = totalJobApplications + jobOffer.get('jobApplications').length;
      });
      return totalJobApplications;
    }),
    hiredApplications: Ember.computed('jobOffers.@each', function () {
      let totalJobApplications = 0;
      this.get('jobOffers').forEach(function (jobOffer) {
        totalJobApplications = totalJobApplications + jobOffer.get('jobApplications').filterBy('status', 'ACCEPTED').length;
      });
      return totalJobApplications;
    }),
    pendingApplications: Ember.computed('jobOffers.@each', function () {
      let totalJobApplications = 0;
      this.get('jobOffers').forEach(function (jobOffer) {
        totalJobApplications = totalJobApplications + jobOffer.get('jobApplications').filterBy('status', 'PENDING').length;
      });
      return totalJobApplications;
    }),
    deniedApplications: Ember.computed('jobOffers.@each', function () {
      let totalJobApplications = 0;
      this.get('jobOffers').forEach(function (jobOffer) {
        totalJobApplications = totalJobApplications + jobOffer.get('jobApplications').filterBy('status', 'REJECTED').length;
      });
      return totalJobApplications;
    }),
    videoDto: _emberData.default.belongsTo('video', {
      async: false,
      inverse: null
    }),
    videoId: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    companyType: _emberData.default.attr('string')
  });

  _exports.default = _default;
});