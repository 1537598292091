define("E4E/components/organizations/job-offers/search-container", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 0,
    page2: 0,
    intl: Ember.inject.service(),
    scopes: ["ECTS", "HOURS", "DAYS"],
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
      this.languageMatcher = this.languageMatcher.bind(this);
      this.get('getLanguages').perform();
    },
    getLanguages: (0, _emberConcurrency.task)(function* () {
      let languages = yield $.getJSON("/json-components/languages/languages_" + this.intl.locale + ".json");
      this.set('languages', languages);
    }),
    languageMatcher: function (language, term) {
      const ret = `${this.get('intl').t('label.languagesnames.' + language.Code.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    search: function () {
      this.set('jobTitles', Ember.A([]));
      this.get('getJobTitles').perform();
    },
    search2: function () {
      this.set('educations', Ember.A([]));
      this.get('getEducations').perform();
    },
    search3: function () {
      this.set('competences', Ember.A([]));
      this.get('getCompetences').perform();
    },
    loadMore: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      this.set('page', this.get('page') + 1);
      let jobTitles = yield this.store.query('jobtitle', {
        filter: {
          name: this.get('model.name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !jobTitles.meta.page.last);
      this.get('jobTitles').pushObjects(jobTitles.toArray());
      this.set('isLoading', false);
    }).restartable(),
    loadMore2: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading2', true);
      this.set('page2', this.get('page2') + 1);
      let educations = yield this.store.query('education', {
        filter: {
          name: this.get('model.name'),
          language: this.get('intl.locale')[0],
          page: this.get("page2"),
          size: 20
        }
      });
      this.set('canLoadMore2', !educations.meta.page.last);
      this.get('educations').pushObjects(educations.toArray());
      this.set('isLoading2', false);
    }).restartable(),
    loadMore3: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading3', true);
      this.set('page3', this.get('page3') + 1);
      let competences = yield this.store.query('competence', {
        filter: {
          name: this.get('model.name'),
          language: this.get('intl.locale')[0],
          page: this.get("page3"),
          size: 20
        }
      });
      this.set('canLoadMore3', !competences.meta.page.last);
      this.get('competences').pushObjects(competences.toArray());
      this.set('isLoading3', false);
    }).restartable(),
    getJobTitles: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      let jobTitles = yield this.store.query('jobtitle', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !jobTitles.meta.page.last);
      this.set('jobTitles', jobTitles.toArray());
      this.set('isLoading', false);
    }),
    getEducations: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading2', true);
      let educations = yield this.store.query('education', {
        filter: {
          name: this.get('name2'),
          language: this.get('intl.locale')[0],
          page: this.get("page2"),
          size: 20
        }
      });
      this.set('canLoadMore2', !educations.meta.page.last);
      this.set('educations', educations.toArray());
      this.set('isLoading2', false);
    }),
    getCompetences: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading3', true);
      let competences = yield this.store.query('competence', {
        filter: {
          name: this.get('name3'),
          language: this.get('intl.locale')[0],
          page: this.get("page3"),
          size: 20
        }
      });
      this.set('canLoadMore3', !competences.meta.page.last);
      this.get('competences').pushObjects(competences.toArray());
      this.set('isLoading3', false);
    }).restartable(),
    actions: {
      searchJobTitles: function (value) {
        this.set('name', value);
        this.set('page', 0);
        Ember.run.debounce(this, this.search, 1000);
      },
      searchEducations: function (value) {
        this.set('name2', value);
        this.set('page2', 0);
        Ember.run.debounce(this, this.search2, 1000);
      },
      searchCompetences: function (value) {
        this.set('name3', value);
        this.set('page3', 0);
        Ember.run.debounce(this, this.search3, 1000);
      },
      updateWorkExperience: function (value) {
        if (value == null) {
          this.set('name', "");
          this.set('page', 0);
          Ember.run.debounce(this, this.search, 1000);
          this.updateWorkExperience("");
        } else {
          let array = [];
          value.forEach(function (education) {
            array.push(education.get('name'));
          });
          this.updateWorkExperience(array);
        }

        this.set('jobTitle', value);
      },
      updateEducation: function (value) {
        if (value.length == 0) {
          this.set('name2', "");
          this.set('page2', 0);
          Ember.run.debounce(this, this.search2, 1000);
          this.updateEducation("");
        } else {
          let array = [];
          value.forEach(function (education) {
            array.push(education.get('name'));
          });
          this.updateEducation(array);
        }

        this.set('education', value);
      },
      updateLanguage: function (value) {
        if (value == null) {
          this.updateLanguage("");
        } else {
          let array = [];
          value.forEach(function (language) {
            array.push(language.Code);
          });
          this.updateLanguage(array);
        }

        this.set('language', value);
      },
      updateYearsExperience: function (value) {
        this.updateYearsExperience(value);
      },
      updateDrivingLicense: function (value) {
        this.updateDrivingLicense(value);
      },
      updateCompetences: function (value) {
        if (value.length == 0) {
          this.set('name3', "");
          this.set('page3', 0);
          Ember.run.debounce(this, this.search3, 1000);
          this.updateCompetences("");
        } else {
          let array = [];
          value.forEach(function (competence) {
            array.push(competence.get('name'));
          });
          this.updateCompetences(array);
        }

        this.set('competence', value);
      },
      updateJobOffer: function (value) {
        this.updateJobOffer(value);
      },
      updateCity: function (value) {
        this.updateCity(value);
      },
      updateCountry: function (value) {
        this.updateCountry(value);
      },
      updateTypes: function (value) {
        this.updateTypes(value);
      },
      updateStatus: function (value) {
        this.updateStatus(value);
      },
      selectJobOffer: function (id) {
        this.selectJobOffer(id);
      },
      showFilters: function () {
        this.set('showAllFilters', true);
      },
      hideFilters: function () {
        this.set('showAllFilters', false);
      }
    }
  });

  _exports.default = _default;
});