define("E4E/components/users/delete-workexperience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasEmployer: Ember.computed('workExperience', function () {
      if (this.get('workExperience.Employer') != null) {
        console.log(this.get('workExperience.Employer.Name'));

        if (this.get('workExperience.Employer.Name') === undefined || this.get('workExperience.Employer.Name') === null || this.get('workExperience.Employer.Name') === "") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      deleteWorkExperience: function (workExperience) {
        this.deleteWorkExperience(workExperience);
      },
      cancelDeleteWorkExperience: function () {
        this.cancelDeleteWorkExperience();
      }
    }
  });

  _exports.default = _default;
});