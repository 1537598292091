define("E4E/templates/components/dialog-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HN5fvJv+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"activeDialog\",\"show\"]]],null,{\"statements\":[[4,\"paper-dialog\",null,[[\"onClose\",\"clickOutsideToClose\",\"escapeToClose\"],[[27,\"action\",[[22,0,[]],[23,[\"onClose\"]],[23,[\"activeDialog\"]]],null],true,true]],{\"statements\":[[4,\"if\",[[23,[\"activeDialog\",\"inner\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"component\",[[23,[\"activeDialog\",\"inner\"]]],[[\"params\",\"action\"],[[23,[\"activeDialog\",\"params\"]],[23,[\"activeDialog\",\"action\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/dialog-handler.hbs"
    }
  });

  _exports.default = _default;
});