define("E4E/services/socket-connection", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stompClient: null,
    socket: null,
    connected: false,
    connectNotifier: function (url, token) {
      var self = this;
      self.set('socket', SockJS(url + '?access_token=' + token));
      self.stompClient = Stomp.over(self.socket);
      self.stompClient.connect({}, function (frame) {
        self.set('connected', true);
      });
    },
    disconnectNotifier: function () {
      if (this.stompClient != null) {
        this.stompClient.disconnect();
      }

      this.set('stompClient', null);
      this.set('connected', false);
    }
  });

  _exports.default = _default;
});