define("E4E/models/current-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    abbreviationName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName').charAt(0).toUpperCase() + '' + this.get('lastName').charAt(0).toUpperCase();
    }),
    userRole: _emberData.default.belongsTo('role', {
      async: false,
      inverse: null
    }),
    profilePicture: _emberData.default.belongsTo('picture', {
      async: false,
      inverse: null
    }),
    coverImage: _emberData.default.belongsTo('cover', {
      async: false,
      inverse: null
    }),
    userOrganization: _emberData.default.belongsTo('organization', {
      async: false,
      inverse: null
    }),
    organizationId: _emberData.default.attr('string'),
    jobOffers: _emberData.default.hasMany('joboffer', {
      async: false,
      inverse: null
    }),
    jobApplications: _emberData.default.hasMany('jobapplication', {
      async: false,
      inverse: null
    }),
    curriculumVitae: _emberData.default.belongsTo('curriculum-vitae', {
      async: false,
      inverse: null
    }),
    certificates: _emberData.default.hasMany('certificate', {
      async: false,
      inverse: null
    }),
    links: _emberData.default.attr(),
    id: _emberData.default.attr(),
    flags: _emberData.default.hasMany('flag', {
      async: false,
      inverse: null
    }),
    workExperience: _emberData.default.attr('string'),
    workExperienceJson: Ember.computed('workExperience', function () {
      if (this.get('workExperience') != null && this.get('workExperience') != "") {
        let workExperienceJson = JSON.parse(this.get('workExperience'));

        if (workExperienceJson.workExperiences != null) {
          return workExperienceJson.workExperiences;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    education: _emberData.default.attr('string'),
    educationJson: Ember.computed('education', function () {
      if (this.get('education') != null && this.get('education') != "") {
        let educationJson = JSON.parse(this.get('education'));

        if (educationJson.educations != null) {
          return educationJson.educations;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    skills: _emberData.default.attr('string'),
    skillsJson: Ember.computed('skills', function () {
      if (this.get('skills') != null && this.get('skills') != "") {
        let skillsJson = JSON.parse(this.get('skills'));

        if (skillsJson.skills != null) {
          return skillsJson.skills;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    careExperience: _emberData.default.attr('string')
  });

  _exports.default = _default;
});