define("E4E/templates/components/superadmin/reports/search-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gl4YvD1y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"width100P\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-24-type-1\"],[9],[1,[27,\"t\",[\"label.reports.title\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-start margin-top-16 width100P\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-center width100P\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"flex-40 flex-sm-100 flex-xs-100 layout-align-start-center \"],[9],[0,\"\\n        \"],[1,[27,\"paper-input\",null,[[\"class\",\"label\",\"value\",\"onChange\"],[\"flex height56 width100P\",[27,\"t\",[\"label.reports.searchReports\"],null],[23,[\"reportsName\"]],[27,\"action\",[[22,0,[]],\"updateReports\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/superadmin/reports/search-container.hbs"
    }
  });

  _exports.default = _default;
});