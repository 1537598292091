define("E4E/mixins/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
    The session service.
    @property session
    @readOnly
    @type SessionService
    @public
    */
    session: Ember.inject.service('session'),
    _isFastBoot: Ember.computed(function () {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),

    /**
      The route to transition to after successful authentication.
      @property routeAfterAuthentication
      @type String
      @default 'index'
      @public
    */
    routeAfterAuthentication: Ember.computed(function () {
      return "dashboard";
    }),

    init() {
      this._super(...arguments);

      this._subscribeToSessionEvents();
    },

    _subscribeToSessionEvents() {
      Ember.A([['authenticationSucceeded', 'sessionAuthenticated'], ['invalidationSucceeded', 'sessionInvalidated']]).forEach(([event, method]) => {
        this.get('session').on(event, Ember.run.bind(this, () => {
          this[method](...arguments);
        }));
      });
    },

    /**
      This method handles the session's
      {{#crossLink "SessionService/authenticationSucceeded:event"}}{{/crossLink}}
      event. If there is a transition that was previously intercepted by the
      {{#crossLink "AuthenticatedRouteMixin/beforeModel:method"}}
      AuthenticatedRouteMixin's `beforeModel` method{{/crossLink}} it will retry
      it. If there is no such transition, the `ember_simple_auth-redirectTarget`
      cookie will be checked for a url that represents an attemptedTransition
      that was aborted in Fastboot mode, otherwise this action transitions to the
      {{#crossLink "Configuration/routeAfterAuthentication:property"}}{{/crossLink}}.
      @method sessionAuthenticated
      @public
    */
    sessionAuthenticated() {
      const attemptedTransition = this.get('session.attemptedTransition');
      const cookies = Ember.getOwner(this).lookup('service:cookies');
      const redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else if (redirectTarget) {
        this.transitionTo(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget');
      } else {
        this.transitionTo(this.get('routeAfterAuthentication'));
      }
    }

  });

  _exports.default = _default;
});