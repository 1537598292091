define("E4E/components/users/edit-workexperience", ["exports", "pell", "ember-concurrency", "ember-data"], function (_exports, _pell, _emberConcurrency, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Ember.Component.extend({
    WorkExperience: {
      Period: {
        From: {
          Year: "",
          Month: "",
          Day: ""
        },
        To: {
          Year: "",
          Month: "",
          Day: ""
        },
        Current: ""
      },
      Title: "",
      Employer: {
        Name: "",
        ContactInfo: {
          Address: {
            Contact: {
              AddressLine: "",
              PostalCode: "",
              Municipality: "",
              Country: {
                Code: "",
                Label: ""
              }
            }
          },
          Website: {
            Contact: "",
            Use: {
              Code: ""
            }
          }
        },
        Sector: {
          Code: "",
          Label: ""
        }
      },
      Position: {
        Label: "",
        Code: ""
      },
      Activities: "",
      ReferenceTo: Ember.A([]),
      Competences: Ember.A([])
    },
    validDateTo: Ember.computed('dayTo', 'monthTo', 'yearTo', 'ongoing', function () {
      if (this.get('ongoing')) {
        return false;
      } else {
        let dayTo = this.get('dayTo') != null && this.get('dayTo') != "";
        let monthTo = this.get('monthTo') != null && this.get('monthTo') != "";
        let yearTo = this.get('yearTo') != null && this.get('yearTo') != "";
        let date = moment.utc(moment.utc(this.get('yearTo') + "/" + this.get('monthTo') + "/" + this.get('dayTo')).format('YYYY/MM/DD'), 'YYYY/MM/DD');

        if (dayTo && monthTo && yearTo || !dayTo && monthTo && yearTo || !dayTo && !monthTo && yearTo) {
          if ((1 <= this.get('dayTo') && this.get('dayTo') <= 31 || !dayTo) && (1 <= this.get('monthTo') && this.get('monthTo') <= 12 || !monthTo) && 1930 <= this.get('yearTo') && this.get('yearTo') <= 2030) {
            if (dayTo && monthTo && yearTo) {
              if (date.year() == this.get('yearTo') && date.month() + 1 == this.get('monthTo') && date.date() == this.get('dayTo')) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    }),
    validDateFrom: Ember.computed('dayFrom', 'monthFrom', 'yearFrom', function () {
      let dayFrom = this.get('dayFrom') != null && this.get('dayFrom') != "";
      let monthFrom = this.get('monthFrom') != null && this.get('monthFrom') != "";
      let yearFrom = this.get('yearFrom') != null && this.get('yearFrom') != "";
      let date = moment.utc(moment.utc(this.get('yearFrom') + "/" + this.get('monthFrom') + "/" + this.get('dayFrom')).format('YYYY/MM/DD'), 'YYYY/MM/DD');

      if (dayFrom && monthFrom && yearFrom || !dayFrom && monthFrom && yearFrom || !dayFrom && !monthFrom && yearFrom) {
        if ((1 <= this.get('dayFrom') && this.get('dayFrom') <= 31 || !dayFrom) && (1 <= this.get('monthFrom') && this.get('monthFrom') <= 12 || !monthFrom) && 1930 <= this.get('yearFrom') && this.get('yearFrom') <= 2030) {
          if (dayFrom && monthFrom && yearFrom) {
            if (date.year() == this.get('yearFrom') && date.month() + 1 == this.get('monthFrom') && date.date() == this.get('dayFrom')) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }),
    validDates: Ember.computed('validDateTo', 'validDateFrom', 'jobTitle', function () {
      if (this.get('validDateTo') || this.get('validDateFrom') || this.get('jobTitle') == null) {
        return true;
      } else {
        return false;
      }
    }),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    page: 0,
    name: '',
    skillsAdded: Ember.A([]),
    allReferences: Ember.A([]),
    certificates: Ember.computed('workExperience.ReferenceTo.@each', 'selectedTeamMember.certificates.@each', function () {
      let certificatesArray = Ember.A([]);

      if (this.get('workExperience.ReferenceTo') != null) {
        this.get('workExperience.ReferenceTo').forEach(element => {
          this.get('selectedTeamMember.certificates').filter(function (certificate) {
            if (certificate.get('europassCertificateId') == element.idref) {
              certificatesArray.pushObject(certificate);
            }
          });
        });
      }

      return certificatesArray;
    }),
    deletedCertificates: Ember.A([]),
    showAdditionalInformation: false,
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
      this.get('getJobTitles').perform();
      this.get('setDataToEdit').perform();
      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let unorderedList = this.intl.t('label.unorderedlist');
      let orderedList = this.intl.t('label.orderedlist');
      let increaseIdentation = this.intl.t('label.increaseidentation');
      let decreaseIdentation = this.intl.t('label.decreaseidentation');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      let subscript = this.intl.t('label.subscript');
      let superscript = this.intl.t('label.superscript');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'ulist',
          title: unorderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
        }, {
          name: 'olist',
          title: orderedList,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
        }, {
          name: 'indent',
          title: increaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
          result: () => (0, _pell.exec)('indent')
        }, {
          name: 'outdent',
          title: decreaseIdentation,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
          result: () => (0, _pell.exec)('outdent')
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        }, {
          name: 'subscript',
          title: subscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
          result: () => (0, _pell.exec)('subscript')
        }, {
          name: 'superscript',
          title: superscript,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
          result: () => (0, _pell.exec)('superscript')
        }]
      });
    },
    setRatingObject: (0, _emberConcurrency.task)(function* () {
      this.get('skillsAdded').forEach(function (skill) {
        if (skill.rating == null) {
          skill.rating = {
            value: 0,
            additionalText: ''
          };
        }
      });
    }),
    setDataToEdit: (0, _emberConcurrency.task)(function* () {
      this.set('dayFrom', this.get('workExperience.Period.From.Day'));
      this.set('monthFrom', this.get('workExperience.Period.From.Month'));
      this.set('yearFrom', this.get('workExperience.Period.From.Year'));
      this.set('dayTo', this.get('workExperience.Period.To.Day'));
      this.set('monthTo', this.get('workExperience.Period.To.Month'));
      this.set('yearTo', this.get('workExperience.Period.To.Year'));
      this.set('ongoing', this.get('workExperience.Period.Current')); // Job title goes here

      this.set('name', this.get('workExperience.Position.Label'));

      if (this.get('workExperience.Position.Label') != "") {
        yield this.getJobTitles.perform();
        let jobTitle = yield this.get('jobTitles').filterBy('name', this.get('workExperience.Position.Label'))[0];

        if (jobTitle != undefined) {
          this.set('jobTitle', jobTitle);
        } else {
          this.set('jobTitle', {
            name: this.get('workExperience.Position.Label'),
            code: this.get('workExperience.Position.Code')
          });
        }
      }

      this.set('employerName', this.get('workExperience.Employer.Name'));
      this.set('address', this.get('workExperience.Employer.ContactInfo.Address.Contact.AddressLine'));
      this.set('city', this.get('workExperience.Employer.ContactInfo.Address.Contact.Municipality'));
      this.set('postalCode', this.get('workExperience.Employer.ContactInfo.Address.Contact.PostalCode'));
      this.set('website', this.get('workExperience.Employer.ContactInfo.Website.Contact'));
      this.set('activities', this.get('workExperience.Activities'));

      if (this.get('workExperience.Competences') != null && this.get('workExperience.Competences') != undefined) {
        this.set('skillsAdded', this.get('workExperience.Competences'));
      } else {
        this.set('skillsAdded', Ember.A([]));
      }

      this.set('deletedCertificates', Ember.A([]));
      this.get('setRatingObject').perform();
    }),
    loadMore: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      this.set('page', this.get('page') + 1);
      let jobTitles = yield this.store.query('jobtitle', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !jobTitles.meta.page.last);
      this.get('jobTitles').pushObjects(jobTitles.toArray());
      this.set('isLoading', false);
    }).restartable(),
    getJobTitles: (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);
      let jobTitles = yield this.store.query('jobtitle', {
        filter: {
          name: this.get('name'),
          language: this.get('intl.locale')[0],
          page: this.get("page"),
          size: 20
        }
      });
      this.set('canLoadMore', !jobTitles.meta.page.last);
      this.set('jobTitles', jobTitles.toArray());
      this.set('isLoading', false);
    }),
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
      this.set('country', this.get('countries').findBy("name", this.get('workExperience.Employer.ContactInfo.Address.Contact.Country.Label')));
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    search: function () {
      this.set('jobTitles', Ember.A([]));
      this.get('getJobTitles').perform();
    },
    actions: {
      addJobTitle: function (select, e) {
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          if (this.get('jobTitles').filterBy('name', select.searchText)[0] == null) {
            let jobTitle = {
              name: select.searchText
            };
            this.set('jobTitle', jobTitle);
            this.get('jobTitles').pushObject(jobTitle);
            select.actions.choose(jobTitle);
          } else {
            this.set('jobTitle', this.get('jobTitles').filterBy('name', select.searchText)[0]);
          }
        }
      },
      closeEditWorkExperienceDialog: function () {
        this.closeEditWorkExperienceDialog();
      },
      saveWorkExperience: function () {
        let workExperience = Ember.copy(this.WorkExperience, true); // Setting Period of work experience

        workExperience.Period.From.Day = this.get('dayFrom');
        workExperience.Period.From.Month = this.get('monthFrom');
        workExperience.Period.From.Year = this.get('yearFrom');
        workExperience.Period.To.Day = this.get('dayTo');
        workExperience.Period.To.Month = this.get('monthTo');
        workExperience.Period.To.Year = this.get('yearTo');
        workExperience.Period.Current = this.get('ongoing'); // Setting title of work experience

        if (this.get('jobTitle') != null) {
          workExperience.Position.Label = this.get('jobTitle.name');
          workExperience.Position.Code = this.get('jobTitle.code');
        } // Setting Employer informstion of the work experience


        workExperience.Employer.Name = this.get('employerName');
        workExperience.Employer.ContactInfo.Address.Contact.AddressLine = this.get('address');

        if (this.get('country') != null) {
          workExperience.Employer.ContactInfo.Address.Contact.Country.Label = this.get('country.name');
          workExperience.Employer.ContactInfo.Address.Contact.Country.Code = this.get('country.iso3');
        }

        workExperience.Employer.ContactInfo.Address.Contact.Municipality = this.get('city');
        workExperience.Employer.ContactInfo.Address.Contact.PostalCode = this.get('postalCode');
        workExperience.Employer.ContactInfo.Website.Contact = this.get('website'); // Activities

        workExperience.Activities = this.get('activities'); // Competences

        workExperience.Competences = this.get('skillsAdded');
        workExperience.ReferenceTo = this.get('workExperience.ReferenceTo');
        this.saveWorkExperience(workExperience, this.certificates, this.deletedCertificates, this.get('index'));
      },
      searchJobTitles: function (value) {
        this.set('name', value);
        this.set('page', 0);
        Ember.run.debounce(this, this.search, 1000);
      },
      addSkills: function () {
        this.set("addSkills", true);
      },
      addSkillsToWorkExperience: function () {
        this.set('addSkills', false);
      },
      cancelAddSkills: function () {
        this.set("addSkills", false);
      },
      removeAddedSkills: function (value) {
        this.get("skillsAdded").removeObject(value);
      },
      didSelectFilesAttachment: function (files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          files[0].originalFileName = files[0].name;
          this.get('certificates').pushObject(files[0]);

          let done = function (url) {
            self.set('fileUrl', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      removeCertificate: function (certificate) {
        if (certificate instanceof Model) {
          this.get('certificates').removeObject(certificate);
          this.get('deletedCertificates').pushObject(certificate);
        } else {
          this.get('certificates').removeObject(certificate);
        }
      },
      showAdditionalInfo: function () {
        this.set('showAdditionalInformation', true);
      },
      hideAdditionalInformation: function () {
        this.set('showAdditionalInformation', false);
      }
    }
  });

  _exports.default = _default;
});