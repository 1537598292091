define("E4E/components/questions/add/likertscale-question", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    questions: Ember.A([]),
    options: Ember.A([]),
    store: Ember.inject.service(),
    clearFields: (0, _emberConcurrency.task)(function* () {
      this.set('options', Ember.A([]));
      this.set('questions', Ember.A([]));
    }),
    init: function () {
      this._super(...arguments);

      this.clearFields.perform();
    },
    actions: {
      saveQuestion: function () {
        let question = this.store.createRecord('question');
        question.set('json', JSON.stringify({
          options: this.get('options'),
          questions: this.get('questions')
        }));
        this.addQuestions(question);
      },
      cancelQuestion: function () {
        this.cancelQuestion();
      },
      addOption: function () {
        this.get('options').pushObject({
          optionText: ""
        });
      },
      addQuestion: function () {
        this.get('questions').pushObject({
          questionText: ""
        });
      },
      deleteQuestion: function (question) {
        this.get('questions').removeObject(question);
      },
      deleteOption: function (option) {
        this.get('options').removeObject(option);
      }
    }
  });

  _exports.default = _default;
});