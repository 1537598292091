define("E4E/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "E4E/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    menuState: Ember.inject.service('menu-state'),
    currentUserService: Ember.inject.service('current-user'),
    moment: Ember.inject.service(),
    socketConnection: Ember.inject.service('socket-connection'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),

    beforeModel() {
      return this._loadCurrentUser();
    },

    afterModel: function () {
      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');
      console.log(cookie);

      if (cookie !== undefined) {
        if (cookie === "pt") {
          this.set('intl.locale', "pt");
          this.get('moment').setLocale('pt');
        } else if (cookie === "de") {
          this.set('intl.locale', "de");
          this.get('moment').setLocale('de');
        } else if (cookie === "tr") {
          this.set('intl.locale', "tr");
          this.get('moment').setLocale('tr');
        } else if (cookie === "pl") {
          this.set('intl.locale', "pl");
          this.get('moment').setLocale('pl');
        } else {
          this.set('intl.locale', "en");
          this.get('moment').setLocale('en');
        }
      } else {
        var locales = this.get('intl.locales');
        var splitLanguage = navigator.language.split('-');

        if (locales.includes(splitLanguage[0])) {
          this.set('intl.locale', splitLanguage[0]);
          this.get('moment').setLocale(splitLanguage[0]);
          cookieService.write('locale', splitLanguage[0]);
        } else {
          this.set('intl.locale', 'en');
          this.get('moment').setLocale('en');
          cookieService.write('locale', 'en');
        }
      }
    },

    sessionAuthenticated() {
      let self = this;
      const targetToRedirect = this.get('session.targetToRedirect');

      if (targetToRedirect) {
        this.transitionTo(targetToRedirect);
        this.set('session.targetToRedirect', null);
      } else {
        this._loadCurrentUser().then(() => {
          switch (self.get('currentUserService.user.userRole.name')) {
            case "JOB_SEEKER":
              self.transitionTo('job-seekers.dashboard');
              break;

            case "ORGANIZATION_ADMIN":
              self.transitionTo('organizations.dashboard');
              break;

            case "ORGANIZATION_USER":
              self.transitionTo('users.profile', this.currentUserService.user.id);
              break;

            case "SUPER_ADMIN":
              self.transitionTo('superadmin.dashboard');
              break;

            case "RECRUITER":
              self.transitionTo('recruiters.dashboard');
              break;
          }
        });
      }
    },

    _loadCurrentUser() {
      return this.get('currentUserService').load().catch(() => {
        this.get('session').invalidate();
      });
    },

    actions: {
      willTransition() {
        this.get('menuState').closeMenu();
      },

      didTransition() {
        this.get('menuState').closeMenu();
      }

    }
  });

  _exports.default = _default;
});