define("E4E/services/jobofferquestionbundle", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    findByOrganization: function (id) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/jobofferquestionbundles/findByOrganization/" + id)).then(resp => {
        return resp;
      });
    }
  });

  _exports.default = _default;
});