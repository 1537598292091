define("E4E/templates/components/questions/preview/slider-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ft5yrPfZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-1\"],[9],[1,[27,\"t\",[\"label.slider\"],null],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-7 margin-top-8\"],[9],[1,[23,[\"question\",\"questionText\"]],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-16-type-7 margin-top-8 margin-bottom-32\"],[9],[1,[23,[\"question\",\"explanation\"]],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start width100P\"],[9],[0,\"\\n    \"],[1,[27,\"paper-slider\",null,[[\"class\",\"primary\",\"discrete\",\"disabled\",\"min\",\"step\",\"max\",\"value\",\"onChange\"],[\"width100P\",true,true,true,[23,[\"question\",\"sliderJson\",\"minValue\"]],1,[23,[\"question\",\"sliderJson\",\"maxValue\"]],[23,[\"foo\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"foo\"]]],null]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/questions/preview/slider-question.hbs"
    }
  });

  _exports.default = _default;
});