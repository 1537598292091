define("E4E/router", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('users', function () {
      this.route('profile', {
        path: '/profile/:id'
      });
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('organizations', function () {
      this.route('dashboard');
      this.route('job-offers', function () {
        this.route('index', {
          path: '/'
        });
        this.route('profile', {
          path: '/profile/:id'
        });
        this.route('overview', {
          path: '/overview/:id'
        });
      });
      this.route('profile', {
        path: '/profile/:id'
      });
      this.route('company-profile');
      this.route('job-applications', function () {});
      this.route('question-bundles', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('job-seekers', function () {
        this.route('index', {
          path: '/'
        });
      });
    });
    this.route('noaccess');
    this.route('superadmin', function () {
      this.route('dashboard');
      this.route('organizations', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('users', function () {});
      this.route('reports', function () {});
      this.route('job-offers', function () {});
    });
    this.route('job-seekers', function () {
      this.route('dashboard');
      this.route('job-offers', function () {});
      this.route('job-applications', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('carer-database', function () {});
    });
    this.route('verify-account');
    this.route('jobseeker-registration');
    this.route('company-registration');
    this.route('registration-successfull');
    this.route('recruiters', function () {
      this.route('dashboard');
      this.route('job-offers', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('team-members', function () {});
      this.route('organization', function () {
        this.route('profile');
      });
      this.route('job-seekers', function () {});
      this.route('question-bundles', function () {});
      this.route('job-applications', function () {});
    });
    this.route('forgot-password');
    this.route('reset-password');
    this.route('credits');
    this.route('data-protection');
    this.route('about-us');
    this.route('privacy-policy');
    this.route('public-profile');
    this.route('invalid-page');
    this.route('public-joboffer-profile');
    this.route('terms-service');
    this.route('registration-company-successfull');
    this.route('companies', function () {
      this.route('profile', {
        path: '/profile/:id'
      });
    });
    this.route('messages');
    this.route('blog', function () {
      this.route('index', {
        path: '/'
      });
      this.route('post', {
        path: '/post/:id'
      });
    });
    this.route('digitallibrary');
  });
  var _default = Router;
  _exports.default = _default;
});