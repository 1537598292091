define("E4E/models/video", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    vAuthor: _emberData.default.belongsTo('user', {
      async: false
    }),
    originalFileName: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    mainVideo: _emberData.default.attr('boolean'),
    file: _emberData.default.belongsTo('file', {
      async: false
    }),
    links: _emberData.default.attr(),
    jobApplications: _emberData.default.hasMany('jobapplication', {
      async: false,
      inverse: null
    }),
    comments: _emberData.default.hasMany('comment', {
      async: false,
      cascadeDelete: true,
      inverse: null
    }),
    privateVideo: _emberData.default.attr('boolean'),
    vOrganization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    fileId: _emberData.default.attr('string'),
    authorId: _emberData.default.attr('string'),
    organizationId: _emberData.default.attr('string'),
    commentsSorted: Ember.computed('comments', function () {
      return this.get('comments').toArray().sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.get('created')) - new Date(a.get('created'));
      });
    })
  });

  _exports.default = _default;
});