define("E4E/helpers/name-abbreviation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.nameAbbreviation = nameAbbreviation;

  function nameAbbreviation(params) {
    if (params[0] != undefined && params[0] != null && params[1] != undefined && params[1] != null) {
      return params[0].charAt(0).toUpperCase() + "" + params[1].charAt(0).toUpperCase();
    } else {
      return "";
    }
  }

  var _default = Ember.Helper.helper(nameAbbreviation);

  _exports.default = _default;
});