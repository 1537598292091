define("E4E/templates/components/files/play-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8495Ln2Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"cursorPointer\"],[12,\"onClick\",[27,\"action\",[[22,0,[]],\"showvideo\"],null]],[9],[0,\"\\n        \"],[1,[27,\"paper-icon\",[\"video_file\"],[[\"size\"],[30]]],false],[0,\"\\n        \"],[1,[21,\"originalFileName\"],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-center-center\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showvideo\"]]],null,{\"statements\":[[0,\"        \"],[7,\"video\"],[11,\"class\",\"width100P margin-top-12 maxWidth600\"],[12,\"src\",[28,[[21,\"serverUrl\"],\"/files/\",[21,\"fileId\"]]]],[11,\"preload\",\"metadata\"],[11,\"frameborder\",\"1\"],[11,\"allowfullscreen\",\"\"],[11,\"controls\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/files/play-video.hbs"
    }
  });

  _exports.default = _default;
});