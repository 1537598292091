define("E4E/components/organizations/job-applications/jobapplication-preview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service('screen'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    workExperiencesTitles: Ember.A([]),
    years: 0,
    hasEducation: Ember.computed('selectedJobApplication.qualificationTrainingJson.@each', function () {
      let formalEducation = this.get('selectedJobApplication.jAppJobOffer.formalEducationJson');
      let hasEducation = false;
      this.get('selectedJobApplication.qualificationTrainingJson').forEach(function (education) {
        if (formalEducation != null) {
          let educationTemp = formalEducation.filterBy('Title', education.Title);

          if (educationTemp.length > 0) {
            hasEducation = true;
          }
        }
      });
      return hasEducation;
    }),
    hasDrivingLicense: Ember.computed('selectedJobApplication.skillsCompetencesJson.Driving.Description.@each', function () {
      let drivingLicensesJobOffer = this.get('selectedJobApplication.jAppJobOffer.drivingLicenseJson.Description');
      let drivingLicensesJobApp = this.get('selectedJobApplication.skillsCompetencesJson.Driving.Description');
      let sameLicensesArray = Ember.A([]);

      if (drivingLicensesJobOffer != null) {
        drivingLicensesJobOffer.forEach(function (driving) {
          if (drivingLicensesJobApp != null) {
            let sameLicenses = drivingLicensesJobApp.filter(function (license) {
              return license === driving;
            });
            sameLicensesArray.push(sameLicenses);
          }
        });
      }

      return sameLicensesArray;
    }),
    hasLanguages: Ember.computed('selectedJobApplication.skillsCompetencesJson.Linguistic.MotherTongue.@each', 'selectedJobApplication.skillsCompetencesJson.Linguistic.ForeignLanguage.@each', function () {
      let self = this;
      let motherTongue = this.get('selectedJobApplication.skillsCompetencesJson.Linguistic.MotherTongue');
      let foreignLanguage = this.get('selectedJobApplication.skillsCompetencesJson.Linguistic.ForeignLanguage');
      let languages = Ember.A([]);
      languages.pushObjects(this.get('selectedJobApplication.jAppJobOffer.languagesJson.MotherTongue'));
      languages.pushObjects(this.get('selectedJobApplication.jAppJobOffer.languagesJson.ForeignLanguage'));
      let languagesUsed = Ember.A([]);
      languages.forEach(function (language) {
        if (motherTongue != null) {
          let languageTemp = motherTongue.filterBy('Description.Label', language.Description.Label);

          if (languageTemp.length > 0) {
            let languageNew = languageTemp[0];
            languageNew.Native = true;
            languagesUsed.pushObject(languageNew);
          }
        }

        if (foreignLanguage != null) {
          let languageTemp2 = foreignLanguage.filterBy('Description.Label', language.Description.Label);

          if (languageTemp2.length > 0) {
            let languageNew = languageTemp2[0];
            let avaliation = 0;
            let listeningNumber = self.avaliationNumber(languageNew.ProficiencyLevel.Listening);
            let readingNumber = self.avaliationNumber(languageNew.ProficiencyLevel.Reading);
            let spokenIntNumber = self.avaliationNumber(languageNew.ProficiencyLevel.SpokenInteraction);
            let spokenProdNumber = self.avaliationNumber(languageNew.ProficiencyLevel.SpokenProduction);
            let writingNumber = self.avaliationNumber(languageNew.ProficiencyLevel.Writing);
            avaliation = (listeningNumber + readingNumber + spokenIntNumber + spokenProdNumber + writingNumber) / 5;

            switch (Math.round(avaliation)) {
              case 1:
                languageNew.Avaliation = "A1";
                break;

              case 2:
                languageNew.Avaliation = "A2";
                break;

              case 3:
                languageNew.Avaliation = "B1";
                break;

              case 4:
                languageNew.Avaliation = "B2";
                break;

              case 5:
                languageNew.Avaliation = "C1";
                break;

              case 6:
                languageNew.Avaliation = "C2";
                break;

              default:
                languageNew.Avaliation = "None";
            }

            languagesUsed.pushObject(languageNew);
          }
        }
      });
      return languagesUsed;
    }),
    workExperienceYears: Ember.computed('selectedJobApplication.jAppJobOffer.jobTitleDto', 'workExperiencesTitles.@each', function () {
      let self = this;
      let jobTitleDto = this.get('selectedJobApplication.jAppJobOffer.jobTitleDto');
      this.get('selectedJobApplication.workExperienceJson').forEach(function (workExperience) {
        self.store.query('jobtitle', {
          filter: {
            name: workExperience.Position.Label,
            language: self.get('intl.locale')[0],
            page: 0,
            size: 10
          }
        }).then(jobTitles => {
          let jobTitlesTemp = jobTitles.toArray();

          if (jobTitlesTemp.length > 0) {
            if (jobTitlesTemp != null) {
              let jobTitle = jobTitlesTemp.filterBy('name', workExperience.Position.Label);

              if (jobTitle.length > 0) {
                if (jobTitleDto != null) {
                  if (jobTitle[0].get('category') == jobTitleDto.get('category')) {
                    if (!self.workExperiencesTitles.includes(workExperience)) {
                      self.workExperiencesTitles.pushObject(workExperience);
                    }
                  }
                }
              }
            }
          }
        });
      });
      self.workExperiencesTitles.forEach(function (workExperience) {
        let dateFromA = new Date(workExperience.Period.From.Year, workExperience.Period.From.Month - 1, workExperience.Period.From.Day);
        let dateToB;

        if (workExperience.Period.Current) {
          dateToB = moment();
        } else {
          if (workExperience.Period.To.Day != null) {
            dateToB = moment(new Date(workExperience.Period.To.Year, workExperience.Period.To.Month - 1, workExperience.Period.To.Day));
          } else if (workExperience.Period.To.Month != null) {
            dateToB = moment(new Date(workExperience.Period.To.Year, workExperience.Period.To.Month - 1, 1));
          } else {
            dateToB = moment(new Date(workExperience.Period.To.Year, 1, 1));
          }
        }

        self.years = self.years + dateToB.diff(dateFromA, 'years');
      });
      return self.years;
    }),
    avaliationNumber: function (avaliation) {
      switch (avaliation) {
        case "A1":
          return 1;

        case "A2":
          return 2;

        case "B1":
          return 3;

        case "B2":
          return 4;

        case "C1":
          return 5;

        case "C2":
          return 6;

        default:
          return 0;
      }
    },
    actions: {
      backToSearch: function () {
        this.sendAction("backToSearch");
      },
      editTags: function () {
        this.editTags();
      },
      rejectApplication: function () {
        this.rejectApplication();
      },
      acceptApplication: function () {
        this.acceptApplication();
      },
      showAnswers: function () {
        this.showAnswers();
      },
      openEvaluateApplication: function () {
        this.openEvaluateApplication();
      },
      seeEvaluation: function () {
        this.seeEvaluation();
      },
      downloadMotivationalLetter: function (originalFileName, id) {
        this.downloadMotivationalLetter(originalFileName, id);
      },
      inEvidenceApplication: function () {
        this.inEvidenceApplication();
      }
    }
  });

  _exports.default = _default;
});