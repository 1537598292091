define("E4E/controllers/about-us", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: '',
    password: '',
    authentication: Ember.inject.service('authentication'),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    changedLanguage: Ember.computed('locale', function () {
      if (this.get('language') === 'pt') {
        this.set('intl.locale', [this.get('language'), 'en', 'de']);
      } else if (this.get('language') === 'de') {
        this.set('intl.locale', [this.get('language'), 'en', 'pt']);
      } else {
        this.set('intl.locale', [this.get('language'), 'de', 'pt']);
      }
    }),
    language: null,
    languages: ['en', 'de', 'pt'],
    options: {
      actions: []
    },
    init: function () {
      this._super(...arguments);

      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('locale', cookie.toString());
      } else {
        this.set('locale', 'en');
      }
    },
    authorizedPost: function (url, content) {
      return Ember.$.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(content),
        contentType: "application/json"
      });
    },
    actions: {
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      register: function () {},
      changeLanguage: function (newLocale) {
        this.cookies.write('locale', newLocale.toString());
        this.intl.set('locale', newLocale.toLowerCase());
        this.set('locale', newLocale);
      },
      sendEmail: function () {
        let self = this;
        let content = {
          name: this.get('name'),
          email: this.get('email'),
          subject: this.get('subject'),
          message: this.get('message').replace(/(<([^>]+)>)/ig, ""),
          locale: this.get('locale')
        };
        this.authorizedPost(_environment.default.APP.SPRING_HOST + '/contacts/help', content).then(response => {
          self.set('emailSentSuccess', true);
          this.set('name', '');
          this.set('email', '');
          this.set('subject', '');
          this.set('message', '');
        }).catch(error => {});
      },
      closeToast: function () {
        this.set('emailSentSuccess', false);
      }
    }
  });

  _exports.default = _default;
});