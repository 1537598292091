define("E4E/components/pdf-js-toolbar", ["exports", "ember-pdf-js/components/pdf-js-toolbar"], function (_exports, _pdfJsToolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pdfJsToolbar.default;
    }
  });
});