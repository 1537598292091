define("E4E/routes/organizations/job-offers/index", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    jobOfferName: '',
    countryName: '',
    types: ["FULL_TIME", "PART_TIME", "FULL_TIME_PART_TIME", "FREELANCER", "NONE"],
    status: ["ACTIVE", "FINISHED", "NONE"],
    jobOffersIds: [],
    page: 0,
    selectedJobOffer: null,
    drivingLicenses: [],
    jobOfferId: null,
    beforeModel: function () {
      let cookieService = this.get('cookies');
      let jobOfferStatus = cookieService.read('jobOfferStatus');
      let jobOfferId = cookieService.read('jobOfferId');
      let jobOfferTitle = cookieService.read('jobOfferTitle');

      if (jobOfferStatus != undefined) {
        this.set('status', ["ACTIVE", "NONE"]);
        cookieService.clear('jobOfferStatus');
      } else {
        this.set('status', ["ACTIVE", "FINISHED", "NONE"]);
      }

      if (jobOfferId != undefined) {
        this.set('jobOfferId', jobOfferId);
        cookieService.clear('jobOfferId');
      } else {
        this.set('jobOfferId', null);
      }

      if (jobOfferTitle != undefined) {
        this.set('jobOfferName', jobOfferTitle);
        cookieService.clear('jobOfferTitle');
      } else {
        this.set('jobOfferName', '');
      }
    },

    model() {
      return Ember.RSVP.hash({
        selectedJobOffer: null,
        country: null,
        city: '',
        countryName: '',
        jobOfferName: this.get('jobOfferName'),
        language: [],
        languageName: [],
        yearsExperience: '',
        competenceName: [],
        workExperience: '',
        education: '',
        types: this.get('types'),
        status: this.get('status'),
        drivingLicenses: this.get('drivingLicenses'),
        page: this.get('page'),
        publicLink: null,
        jobOfferId: this.get('jobOfferId')
      });
    },

    actions: {
      didTransition: function () {
        if (this.get('jobOfferId') != null) {
          this.controller.send("searchAndFindJobOffer");
        } else {
          this.controller.send("searchJobOffers");
        }
      }
    }
  });

  _exports.default = _default;
});