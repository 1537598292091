define("E4E/components/landing-page-first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      registerJobSeeker: function () {
        this.registerJobSeeker();
      }
    }
  });

  _exports.default = _default;
});