define("E4E/helpers/not-readed-messeges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notReadedMesseges = notReadedMesseges;

  function notReadedMesseges(params
  /*, hash*/
  ) {
    let notReadMessages = 0;

    if (params[0] == null) {
      return 0;
    }

    params[0].forEach(function (message) {
      if (message.authorId != params[1] && !message.wasRead) {
        notReadMessages += 1;
      }
    });
    return notReadMessages;
  }

  var _default = Ember.Helper.helper(notReadedMesseges);

  _exports.default = _default;
});