define("E4E/helpers/jobapplications-haseducation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jobapplicationsHaseducation = jobapplicationsHaseducation;

  function jobapplicationsHaseducation(params) {
    let formalEducation = params[0].get('formalEducationJson');
    let qualificationTraining = params[1].get('qualificationTrainingJson');
    let hasEducation = false;
    qualificationTraining.forEach(function (education) {
      if (formalEducation != null) {
        let educationTemp = formalEducation.filterBy('Title', education.Title);

        if (educationTemp.length > 0) {
          hasEducation = true;
        }
      }
    });
    return hasEducation;
  }

  var _default = Ember.Helper.helper(jobapplicationsHaseducation);

  _exports.default = _default;
});