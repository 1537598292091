define("E4E/helpers/is-blink", ["exports", "ember-browser-checker/helpers/is-blink"], function (_exports, _isBlink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isBlink.default;
    }
  });
  Object.defineProperty(_exports, "isBlink", {
    enumerable: true,
    get: function () {
      return _isBlink.isBlink;
    }
  });
});