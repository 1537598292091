define("E4E/serializers/current-user", ["exports", "ember-data", "E4E/mixins/keep-only-changed"], function (_exports, _emberData, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _keepOnlyChanged.default, {
    attrs: {
      profilePicture: {
        serialize: false,
        deserialize: 'records'
      },
      userRole: {
        embedded: "always"
      },
      userOrganization: {
        embedded: "always"
      },
      jobOffers: {
        embedded: "always"
      },
      jobApplications: {
        embedded: "always"
      },
      coverImage: {
        embedded: "always"
      },
      curriculumVitae: {
        embedded: "always"
      },
      certificates: {
        embedded: "always"
      },
      flags: {
        embedded: "always"
      }
    },
    extractMeta: function (store, typeClass, payload) {
      let meta = {};

      if (payload.page) {
        meta.page = payload.page;
        delete payload.page;
      }

      return meta;
    },
    normalizeSingleResponse: function (store, primaryModelClass, payload, id, requestType) {
      let modelKey = primaryModelClass.modelName;
      let aux = {};
      Object.keys(payload).forEach(key => {
        aux[key] = payload[key];
        delete payload[key];
      });
      payload[modelKey] = aux;
      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, true);
    },
    normalizeQueryResponse: function (store, primaryModelClass, payload, id, requestType) {
      let usersPayload = {};
      let page = {};
      usersPayload.users = payload.content;
      page.totalPages = payload.totalPages;
      page.totalElements = payload.totalElements;
      page.size = payload.size;
      page.number = payload.number;
      usersPayload.page = page;
      return this._normalizeResponse(store, primaryModelClass, usersPayload, id, requestType, false);
    }
  });

  _exports.default = _default;
});