define("E4E/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    socketConnection: Ember.inject.service('socket-connection'),
    init: function () {
      this._super(...arguments);

      let self = this;
      $(window).on('beforeunload', function () {
        self.socketConnection.disconnectNotifier();
      });
    },
    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});