define("E4E/services/joboffer", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    save: function (jobOffer, jobOfferParams) {
      let self = this;
      return Ember.RSVP.resolve(jobOffer.save({
        include: jobOfferParams.toString()
      })).then(() => {
        self.get('paperToaster').show(self.intl.t('label.joboffer.saved'), {
          duration: 3000
        });
      }).catch(error => {
        self.get('paperToaster').show(self.intl.t('label.joboffer.error'), {
          duration: 3000
        });
        return error;
      });
    },

    load(id, jobOfferParams) {
      return this.store.findRecord('joboffer', id, {
        include: jobOfferParams.toString()
      });
    },

    filterCompanies: function (jobOfferName, country, types, city, workExperience, yearsExperience, education, languageName, drivingLicenses, competenceName, status, organizationId, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/joboffers/search/filterCompanies?" + "&title=" + jobOfferName.toLowerCase() + "&organizationId=" + organizationId + "&country=" + country + "&etype=" + types.toString() + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&languageName=" + languageName.toString() + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&status=" + status.toString() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobOffers: null,
          meta: null
        };
        searchResponse.jobOffers = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filter: function (jobOfferName, company, country, types, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, category, isFavorite, userId, page, size) {
      let partnerId = "";

      if (this.currentUserService.get('user.userRole.name') === "JOB_SEEKER") {
        partnerId = this.currentUserService.get('user.partnerId') != null ? this.currentUserService.get('user.partnerId') : "";
      } else {
        partnerId = this.currentUserService.get('user.userOrganization.selfPartner') != null ? this.currentUserService.get('user.userOrganization.selfPartner.id') : "";
      }

      let currentUser = "";

      if (userId != null) {
        currentUser = userId;
      }

      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/joboffers/search/filter?" + "title=" + jobOfferName.toLowerCase() + "&company=" + company.toLowerCase() + "&country=" + country + "&etype=" + types.toString() + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&userId=" + currentUser + "&partnerId=" + partnerId + "&category=" + category + "&isFavorite=" + isFavorite + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobOffers: null,
          meta: null
        };
        searchResponse.jobOffers = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterOutplacers: function (jobOfferName, company, country, types, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, category, isFavorite, userId, status, page, size) {
      let partnerId = "";

      if (this.currentUserService.get('user.userRole.name') === "JOB_SEEKER") {
        partnerId = this.currentUserService.get('user.partnerId') != null ? this.currentUserService.get('user.partnerId') : "";
      } else {
        partnerId = this.currentUserService.get('user.userOrganization.selfPartner') != null ? this.currentUserService.get('user.userOrganization.selfPartner.id') : "";
      }

      let currentUser = "";

      if (userId != null) {
        currentUser = userId;
      }

      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/joboffers/search/filterOutplacers?" + "title=" + jobOfferName.toLowerCase() + "&company=" + company.toLowerCase() + "&country=" + country + "&etype=" + types.toString() + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&userId=" + currentUser + "&partnerId=" + partnerId + "&category=" + category + "&isFavorite=" + isFavorite + "&status=" + status.toString() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobOffers: null,
          meta: null
        };
        searchResponse.jobOffers = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterSuggestions: function (jobOfferTitle, country, types, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, category, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/joboffers/search/filterSuggestions?" + "title=" + jobOfferTitle + "&country=" + country + "&etype=" + types.toString() + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&category=" + category + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobOffers: null,
          meta: null
        };
        searchResponse.jobOffers = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterForPartners: function (jobOfferName, country, types, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, status, organizationId, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/joboffers/search/filterForPartners?" + "&title=" + jobOfferName.toLowerCase() + "&organizationId=" + organizationId + "&country=" + country + "&etype=" + types.toString() + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&status=" + status.toString() + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobOffers: null,
          meta: null
        };
        searchResponse.jobOffers = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    }
  });

  _exports.default = _default;
});