define("E4E/routes/users/profile", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    userParams: ["joboffers", "image", "organization", "coverimage", "curriculumvitae", "certificates", "requests", "publiclink", "videos", "activitytracking"],
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel(transition) {
      this.set('id', transition.params["users.profile"].id);
      let user = this.store.findRecord('user', transition.params["users.profile"].id, {
        include: this.userParams.toString()
      });
      this.set('user', user);
    },

    model() {
      return Ember.RSVP.hash({
        user: this.get('user'),
        id: this.get('id'),
        userParams: this.get('userParams'),
        link: ''
      });
    },

    actions: {
      didTransition: function () {
        this.controller.send("createLink");
      }
    }
  });

  _exports.default = _default;
});