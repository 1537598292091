define("E4E/controllers/outplacers", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    closeSwiper: false,
    findRequests: (0, _emberConcurrency.task)(function* () {
      if (this.currentUserService.get('user.userOrganization.selfPartner') != null) {
        let requests = yield this.store.query('request', {
          filterRequests: {
            userId: null,
            requestTypes: ["REQUEST_JOB_SEEKER_PARTNER"].toString(),
            partnerId: this.currentUserService.get('user.userOrganization.selfPartner.id'),
            status: ["PENDING"].toString(),
            page: 0,
            size: 100
          }
        });
        this.set('requests', requests);
      }
    }),
    acceptRequest: (0, _emberConcurrency.task)(function* (request) {
      let user = yield this.store.findRecord('user', request.get('userId'));
      user.set('partnerId', request.get('partnerId'));
      user.save().then(() => {
        this.currentUserService.set('user.partnerId', request.get('partnerId'));
        request.set('status', "ACCEPTED");
        request.save().then(() => {
          this.findRequests.perform();
        });
      });
    }),
    denyRequest: (0, _emberConcurrency.task)(function* (request) {
      request.set('status', "REJECTED");
      request.save();
      this.findRequests().perform();
    }),
    actions: {
      searchRequests: function () {
        this.findRequests.perform();
      },
      closeSwiper: function () {
        this.set('closeSwiper', true);
      },
      denyRequest: function (request) {
        request.set('status', "PENDING");
        request.save();
      },
      acceptRequest: function (request) {
        this.acceptRequest.perform(request);
      }
    }
  });

  _exports.default = _default;
});