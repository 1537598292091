define("E4E/services/sector", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    authorizedPost: function (url, params) {
      return Ember.$.ajax({
        type: 'POST',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers'),
        data: JSON.stringify(params),
        contentType: "application/json"
      });
    },
    addSectorsToOrganization: function (organizationId, sectorsId) {
      let params = {
        id: organizationId,
        sectors: sectorsId
      };
      return Ember.RSVP.resolve(this.authorizedPost("/sectors/addtoorganization", params));
    }
  });

  _exports.default = _default;
});