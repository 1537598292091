define("E4E/templates/components/delete-account-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TaUQvOtD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"class\",\"onClose\",\"origin\"],[\"flex-50 flex-sm-70 flex-xs-80\",[27,\"action\",[[22,0,[]],\"logoutUser\"],null],[23,[\"dialogOrigin\"]]]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,[[\"class\"],[\"layout-row layout-align-space-between-center\"]],{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-24-type-1\"],[9],[1,[27,\"t\",[\"label.goodbye\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start width100P\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"text-16-type-10\"],[9],[1,[27,\"t\",[\"label.goodbyeuser\"],null],false],[10],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"primary\",\"raised\",\"onClick\"],[\"margin-top-16\",true,true,[27,\"action\",[[22,0,[]],\"logoutUser\"],null]]],{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"label.goodbye\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/delete-account-logout.hbs"
    }
  });

  _exports.default = _default;
});