define("E4E/components/recruiters/job-offers/joboffer-preview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    competences: Ember.computed('selectedJobOffer.skillsJson.@each', 'selectedJobOffer.formalEducationJson.@each', function () {
      let competencesFinal = Ember.A([]);

      if (this.get('selectedJobOffer.skillsJson') != null) {
        this.get('selectedJobOffer.skillsJson').forEach(function (competence) {
          competencesFinal.pushObject(competence);
        });
      }

      if (this.get('selectedJobOffer.formalEducationJson')) {
        this.get('selectedJobOffer.formalEducationJson').forEach(function (education) {
          let competences = education.Competences;
          competences.forEach(function (competence) {
            let temp = competencesFinal.filter(function (comp) {
              if (comp != undefined) {
                return comp.name == competence.name;
              }
            });

            if (temp == null || temp.length <= 0) {
              competencesFinal.pushObject(competence);
            }
          });
        });
      }

      return competencesFinal;
    }),
    init: function () {
      this._super(...arguments);
    },
    actions: {
      backToSearch: function () {
        this.sendAction("backToSearch");
      },
      editJobOffer: function () {
        this.editJobOffer();
      },
      generatePublicLink: function () {
        this.generatePublicLink();
      },
      goToOverview: function () {
        this.goToOverview();
      },
      goToJobApplications: function (jobApplication) {
        let cookieService = this.get('cookies');
        cookieService.write('jobApplicationId', jobApplication.get('id'));
        cookieService.write('jobSeekerName', jobApplication.get('jAppJobSeeker.firstName') + " " + jobApplication.get('jAppJobSeeker.lastName'));
        this.get('router').transitionTo("organizations.job-applications.index");
      },
      downloadJobOfferPdf: function () {
        this.downloadJobOfferPdf();
      }
    }
  });

  _exports.default = _default;
});