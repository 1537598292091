define("E4E/helpers/short-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.shortContent = shortContent;

  function shortContent(params
  /*, hash*/
  ) {
    if (typeof params[0] != "undefined") {
      if (params[0].length > 0) {
        var obj = "";
        var more = false;
        var objtmp = "";
        var htmlObject = $(params[0]);

        if (htmlObject.length > 0) {
          $(htmlObject).each(function () {
            if (obj.length < 200) {
              if (objtmp != "") obj += $(objtmp).get(0).outerHTML;
              objtmp = $(this);
            } else {
              more = true;
            }
          });
          if (more) objtmp = $(objtmp).append('<span class="cursorPointer">...</span>');
          return obj + $(objtmp).get(0).outerHTML;
        } else {
          return params;
        }
      } else {
        return "";
      }
    }
  }

  var _default = Ember.Helper.helper(shortContent);

  _exports.default = _default;
});