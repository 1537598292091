define("E4E/models/question", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    orderNumber: _emberData.default.attr('number'),
    questionText: _emberData.default.attr('string'),
    questionBundle: _emberData.default.belongsTo('questionbundle', {
      async: false
    }),
    questionType: _emberData.default.attr('string'),
    json: _emberData.default.attr('string'),
    links: _emberData.default.attr(),
    singleChoiceJson: Ember.computed('json', function () {
      return JSON.parse(this.get('json'));
    }),
    multipleChoiceJson: Ember.computed('json', function () {
      return JSON.parse(this.get('json'));
    }),
    likertScaleJson: Ember.computed('json', function () {
      return JSON.parse(this.get('json'));
    }),
    dateResponse: _emberData.default.attr('date'),
    numericResponse: _emberData.default.attr('number'),
    openTextResponse: _emberData.default.attr('string'),
    certificateResponse: Ember.computed('json', function () {
      let json = JSON.parse(this.get('json'));

      if (!("value" in json)) {
        json.value = null;
        json.file = null;
      }

      return json;
    }),
    sliderJson: Ember.computed('json', function () {
      return JSON.parse(this.get('json'));
    }),
    questionBundleId: _emberData.default.attr('string'),
    explanation: _emberData.default.attr('string')
  });

  _exports.default = _default;
});