define("E4E/components/dialogs/superadmin/delete-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
    },
    actions: {
      cancelDeleteUser: function () {
        this.cancelDeleteUser();
      },
      deleteUser: function () {
        this.deleteUser();
      }
    }
  });

  _exports.default = _default;
});