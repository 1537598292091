define("E4E/controllers/jobseeker-registration", ["exports", "ember-concurrency", "E4E/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: '',
    password: '',
    authentication: Ember.inject.service('authentication'),
    cookies: Ember.inject.service(),
    intl: Ember.inject.service(),
    uploadCvRegistration: false,
    registrationManual: false,
    router: Ember.inject.service(),
    seenPrivacy: false,
    validitySave: Ember.computed('email', 'confirmEmail', 'firstName', 'lastName', 'password', 'confirmPassword', 'seenPrivacy', function () {
      if (this.get('seenPrivacy') && this.get('email') != '' && this.get('confirmEmail') != '' && this.get('firstName') != '' && this.get('lastName') != '' && this.get('password') != '' && this.get('confirmPassword') != '' && !this.get('errorPasswordNotSame') && !this.get('errorEmailNotSame')) {
        return false;
      } else {
        return true;
      }
    }),
    emailValidation: null,
    init: function () {
      this._super(...arguments);

      this.set('model', null);
      let cookieService = this.get('cookies');
      let cookie = cookieService.read('locale');

      if (cookie != null && cookie != undefined) {
        this.set('intl.locale', cookie.toString());
      } else {
        this.set('intl.locale', 'en');
      }

      let message = this.intl.t("label.invalidformat");
      this.set('emailValidation', [{
        message: message,
        validate: inputValue => {
          let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }]);
    },
    xmlExtractionFromPdf: (0, _emberConcurrency.task)(function* () {
      yield;
      let self = this;
      var picReader = new FileReader();
      var XML_EXTRACTION_URL = 'https://europass.cedefop.europa.eu/rest/v1/document/extraction';
      picReader.addEventListener("load", function (event) {
        var pdfArrayResult = event.target.result;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', XML_EXTRACTION_URL, true);
        xhr.setRequestHeader("Content-Type", "application/pdf");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            self.get('XmlToJson').perform(this.responseText);
            return;
          } else if (xhr.readyState === 4 && xhr.status === 400) {
            self.set('showWaitingExtractingDialog', false);
            self.set('fileNotSupported', true);
          }
        };

        xhr.send(pdfArrayResult);
      });
      picReader.readAsArrayBuffer(self.get('file'));
    }),
    XmlToJson: (0, _emberConcurrency.task)(function* (xml) {
      yield;
      let self = this;
      var baseRestUrl = 'https://europass.cedefop.europa.eu/rest/v1/document/to/json';
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.open('POST', baseRestUrl, true);
      xmlhttp.responseType = "blob";

      xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
          var blob = new Blob([this.response], {
            type: xmlhttp.getResponseHeader('Content-Type')
          });
          var reader = new FileReader();

          reader.onload = function () {
            self.get('displayDataInFields').perform(reader.result);
            return;
          };

          reader.readAsText(blob);
        }

        if (xmlhttp.readyState === 4 && xmlhttp.status === 400) {
          self.set('showWaitingExtractingDialog', false);
          self.set('fileNotSupported', true);
        }
      };

      xmlhttp.setRequestHeader('Content-Type', 'application/xml');
      xmlhttp.setRequestHeader('Accept-Language', 'en');
      xmlhttp.send(xml);
    }),
    displayDataInFields: (0, _emberConcurrency.task)(function* (json) {
      yield;
      let jsonObject = JSON.parse(json);
      this.set('firstName', jsonObject.SkillsPassport.LearnerInfo.Identification.PersonName.FirstName);
      this.set('lastName', jsonObject.SkillsPassport.LearnerInfo.Identification.PersonName.Surname);

      if (jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.hasOwnProperty('Email')) {
        this.set('email', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Email.Contact);
        this.send('checkEmail', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Email.Contact);
      }

      if (jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.hasOwnProperty('Address')) {
        this.set('address', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.AddressLine);
        this.set('postalCode', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.PostalCode);
        this.set('city', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Municipality);

        if (jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.hasOwnProperty('Country')) {
          if (jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Country.hasOwnProperty('Code')) {
            this.set('country', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Country.Code.toLowerCase());
          }
        }
      }

      if (jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.hasOwnProperty('Website')) {
        this.set('website', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Website[0].Contact);
      }

      if (jsonObject.SkillsPassport.LearnerInfo.Identification.hasOwnProperty('Demographics')) {
        if (jsonObject.SkillsPassport.LearnerInfo.Identification.hasOwnProperty('Birthdate')) {
          let day = jsonObject.SkillsPassport.LearnerInfo.Identification.Demographics.Birthdate.Day;
          let month = jsonObject.SkillsPassport.LearnerInfo.Identification.Demographics.Birthdate.Month;
          let year = jsonObject.SkillsPassport.LearnerInfo.Identification.Demographics.Birthdate.Year;
          let date = new Date(year, month - 1, day);
          this.set('birthDate', date);
        }
      }

      if (jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.hasOwnProperty('Telephone')) {
        this.set('phoneNumber', jsonObject.SkillsPassport.LearnerInfo.Identification.ContactInfo.Telephone[0].Contact);
      }

      if (jsonObject.SkillsPassport.LearnerInfo.hasOwnProperty('WorkExperience')) {
        this.set('workExperience', JSON.stringify(jsonObject.SkillsPassport.LearnerInfo.WorkExperience));
      }

      if (jsonObject.SkillsPassport.LearnerInfo.hasOwnProperty('Education')) {
        this.set('education', JSON.stringify(jsonObject.SkillsPassport.LearnerInfo.Education));
      }

      if (jsonObject.SkillsPassport.LearnerInfo.hasOwnProperty('Skills')) {
        this.set('skills', JSON.stringify(jsonObject.SkillsPassport.LearnerInfo.Skills));
      }

      this.set('showWaitingExtractingDialog', false);
      this.set('uploadCvRegistration', true);
    }),
    actions: {
      authenticate: function (email, password) {
        this.get('authentication').authenticate(email, password);
      },

      doIt(event) {
        if (event.key === "Enter") {
          this.send('authenticate');
        }
      },

      openSelectionOptions: function () {
        this.set('registerOptions', true);
      },
      closeOptionSelection: function () {
        this.set('registerOptions', false);
      },
      didSelectFiles: function (files) {
        if (!Ember.isEmpty(files)) {
          this.set('file', files[0]);
        }

        this.set('showWaitingExtractingDialog', true);
        this.get('xmlExtractionFromPdf').perform();
      },
      removeFile: function () {
        this.set('file', null);
      },
      checkEmail: function (value) {
        this.set('email', value);
        Ember.$.get(_environment.default.APP.SPRING_HOST + "/users/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
          if (response === false) {
            return Ember.$.get(_environment.default.APP.SPRING_HOST + "/organizations/search/emailExists?email=" + Ember.$.trim(value)).then(response => {
              if (response === false) {
                this.set('errorEmail', false);
              } else {
                this.set('errorEmail', true);
              }
            });
          } else {
            this.set('errorEmail', true);
          }
        });

        if (value === this.get('confirmEmail')) {
          this.set('errorEmailNotSame', false);
        } else {
          if (this.get('confirmEmail') != '') {
            this.set('errorEmailNotSame', true);
          }
        }
      },
      checkConfirmEmail: function (value) {
        this.set('confirmEmail', value);

        if (value !== this.get('email')) {
          this.set('errorEmailNotSame', true);
        } else {
          this.set('errorEmailNotSame', false);
        }
      },
      checkPassword: function (value) {
        this.set('password', value);

        if (value === this.get('confirmPassword')) {
          this.set('errorPasswordNotSame', false);
        } else {
          if (this.get('confirmPassword') != '') {
            this.set('errorPasswordNotSame', true);
          }
        }
      },
      checkConfirmPassword: function (value) {
        this.set('confirmPassword', value);

        if (value !== this.get('password')) {
          this.set('errorPasswordNotSame', true);
        } else {
          this.set('errorPasswordNotSame', false);
        }
      },
      register: function () {
        let self = this;

        if (this.password.length <= 7) {
          self.set('errorInvalidPassword', true);
        } else {
          this.set('showWaitingDialog', true);
          self.set('errorInvalidPassword', false);
          let params = {
            firstName: this.get('firstName'),
            lastName: this.get('lastName'),
            email: this.get('email'),
            emailConfirmation: this.get('confirmEmail'),
            password: this.get('password'),
            passwordConfirmation: this.get('confirmPassword'),
            birthDate: this.get('birthDate'),
            phoneNumber: this.get('phoneNumber'),
            city: this.get('city'),
            country: this.get('country'),
            address: this.get('address'),
            postalCode: this.get('postalCode'),
            website: this.get('website'),
            locale: this.get('intl.locale')[0],
            workExperience: JSON.stringify({
              workExperiences: this.get('workExperience')
            }),
            education: JSON.stringify({
              educations: this.get('education')
            }),
            skills: JSON.stringify({
              skills: this.get('skills')
            })
          };
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.APP.SPRING_HOST + "/api/registration/registerJobSeeker",
            data: JSON.stringify(params),
            contentType: "application/json",
            success: function () {
              self.set('showWaitingDialog', false);
              self.router.transitionTo('registration-successfull');
            },
            error: function () {
              self.set('showWaitingDialog', false);
              self.set('showErrorMessage', true);
            }
          });
        }
      },
      closeFailureRegistrationToast: function () {
        this.set('showErrorMessage', false);
      },
      manualRegistration: function () {
        this.set('registrationManual', true);
      }
    }
  });

  _exports.default = _default;
});