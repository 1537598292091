define("E4E/components/superadmin/organizations/search-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
    },

    scrollContainer(event) {
      if (Math.ceil(Ember.$("#search_container_organizations").scrollTop() + Ember.$("#search_container_organizations").outerHeight()) == Ember.$("#search_container_organizations")[0].scrollHeight) {
        if (!this.get('meta.last')) {
          this.nextPage();
        }
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      Ember.$("#search_container_organizations").on('scroll', this._boundScroll);
    },

    actions: {
      updateCompany: function (value) {
        this.updateCompany(value);
      },
      updateSector: function (value) {
        this.updateSector(value);
      },
      updateCountry: function (value) {
        this.updateCountry(value);
      },
      updateStatus: function (value) {
        this.updateStatus(value);
      },
      selectOrganization: function (id) {
        this.selectOrganization(id);
      }
    }
  });

  _exports.default = _default;
});