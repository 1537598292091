define("E4E/controllers/organizations/profile", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    fileService: Ember.inject.service('file-service'),
    organizationService: Ember.inject.service('organization'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    sectorService: Ember.inject.service('sector'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    totalJobApplications: 0,
    hiredApplications: 0,
    pendingApplications: 0,
    deniedApplications: 0,
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    deleteCompanyDialog: false,
    waitingDeleteAccount: false,
    logoutDialog: false,
    showMembers: true,
    addNewTeamMember: false,
    userService: Ember.inject.service("user"),
    paperToaster: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    editCompanyVideoDialog: false,
    intl: Ember.inject.service(),
    authorizedGet: function (url) {
      return $.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    authorizedPost: function (url, content, done) {
      return $.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        data: JSON.stringify(content),
        contentType: "application/json",
        done: done
      });
    },
    init: function () {
      this._super(...arguments);

      this.set('model', {
        organization: null,
        topSectors: null
      });
    },
    search: function () {
      let self = this;
      var resultArr = [];
      this.get('model.organization.users').forEach(element => {
        var fullName = element.firstName + " " + element.lastName;

        if (fullName.toLowerCase().includes(self.get('searchName').toLowerCase())) {
          resultArr.push(element);
        }
      });
      self.set('fullMembersArr', resultArr);
    },
    findOrganization: (0, _emberConcurrency.task)(function* () {
      let organization = yield this.organizationService.load(this.get('model.id'), this.get('model.organizationParams'));
      this.set('model.organization', organization);
      this.set('fullMembersArr', organization.get('users'));
    }).enqueue(),
    getTopSectors: (0, _emberConcurrency.task)(function* () {
      let topSectors = yield this.authorizedGet('/sectors/search/jobOfferSectorsOrganization?organization=' + this.get('model.id'));
      this.set('model.topSectors', topSectors.toArray());
    }).enqueue(),
    deleteNormalCompanyUser: (0, _emberConcurrency.task)(function* (userId) {
      let self = this;
      $.ajax({
        type: "POST",
        headers: this.get('headers'),
        url: _environment.default.APP.SPRING_HOST + "/users/companyaccount/delete",
        data: {
          userDeactiveId: userId,
          substituteId: self.get('toSubstituteUser') != null ? self.get('toSubstituteUser.id') : null
        },
        success: function (resp) {
          if (resp) {
            if (self.get('model.user.id') == self.currentUserService.get('user.id')) {
              self.set('showByeDialog', true);
              self.set('model.selectedTeamMember', null);
            } else {
              self.get('paperToaster').show(self.intl.t('label.user.deleted'), {
                duration: 3000
              });
              self.set('model.user', null);
            }

            self.findOrganization.perform();
            Ember.run.debounce(self, self.search, 1000);
          } else {
            self.get('paperToaster').show(self.intl.t('label.user.deletederror'), {
              duration: 3000
            });
          }
        },
        error: function () {
          self.get('paperToaster').show(self.intl.t('label.user.deletederror'), {
            duration: 3000
          });
        }
      });
    }),
    actions: {
      closeAddMemberDialog: function () {
        this.set('addNewTeamMember', false);
      },
      addNewMember: function () {
        this.set('addNewTeamMember', true);
      },
      saveNewMember: function (params) {
        $.ajax({
          type: "POST",
          url: _environment.default.APP.SPRING_HOST + "/api/registration/registerCompanyAdminUser",
          data: JSON.stringify(params),
          contentType: "application/json"
        }).then(() => {
          this.set('addNewTeamMember', false);
          this.set('successAdd', true);
          this.get('findOrganization').perform();
        }).catch(adapterError => {
          console.log(adapterError);
          this.set('insuccessAdd', true);
        });
      },
      closeToast: function () {
        this.set('successAdd', false);
        this.set('insuccessAdd', false);
      },
      members: function () {
        if (this.get('showMembers') == true) {
          this.set('showMembers', false);
        } else {
          this.set('showMembers', true);
        }
      },
      searchMembers: function (value) {
        this.set('searchName', value);
        Ember.run.debounce(this, this.search, 500);
      },
      findOrganization: function () {
        this.get('findOrganization').perform();
      },
      getTopSectors: function () {
        this.get('getTopSectors').perform();
      },
      editCompany: function () {
        let params = {
          name: this.get('model.organization.name'),
          city: this.get('model.organization.city'),
          address: this.get('model.organization.address'),
          country: this.get('model.organization.country'),
          sector: this.get('model.organization.sectors'),
          email: this.get('model.organization.email'),
          phoneNumber: this.get('model.organization.phoneNumber'),
          description: this.get('model.organization.description'),
          website: this.get('model.organization.website'),
          postalCode: this.get('model.organization.postalCode'),
          companyType: this.get('model.organization.companyType')
        };
        this.set('params', params);
        this.set('editDialogCompany', true);
      },
      saveOrganization: function () {
        let sectorsId;

        if (this.get('params.sector') != null && this.get('params.sector').length > 0) {
          sectorsId = Ember.A([]);
          this.get('params.sector').forEach(function (sector) {
            sectorsId.push({
              id: sector.get('id')
            });
          });
        } else {
          sectorsId = null;
        }

        this.sectorService.addSectorsToOrganization(this.get('model.organization.id'), sectorsId);
        let organization = this.get('model.organization');
        organization.set('name', this.get('params.name'));
        organization.set('city', this.get('params.city'));
        organization.set('address', this.get('params.address'));
        organization.set('country', this.get('params.country'));
        organization.set('sector', this.get('params.sector'));
        organization.set('email', this.get('params.email'));
        organization.set('phoneNumber', this.get('params.phoneNumber'));
        organization.set('description', this.get('params.description'));
        organization.set('website', this.get('params.website'));
        organization.set('sectors', this.get('params.sector'));
        organization.set('postalCode', this.get('params.postalCode'));
        organization.set('companyType', this.get('params.companyType'));
        this.organizationService.save(organization, this.get('model.organizationParams'));
        this.set('editDialogCompany', false);
      },
      cancelEditCompany: function () {
        this.set('editDialogCompany', false);
      },
      goToJobOffers: function (status) {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferStatus', [status].toString());
        this.get('router').transitionTo("organizations.job-offers");
      },
      goToApplications: function (status) {
        let cookieService = this.get('cookies');

        if (status != null) {
          cookieService.write('jobApplicationStatus', [status]);
          this.get('router').transitionTo("organizations.job-applications");
        } else {
          this.get('router').transitionTo("organizations.job-applications");
        }
      },
      openAdvancedInfo: function () {
        this.set('advancedInformationOpened', true);
      },
      closeAdvancedInfo: function () {
        this.set('advancedInformationOpened', false);
      },
      didSelectFilesHeader: function (files) {
        let self = this;
        this.set('cropingCoverImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileCover', files[0]);

          let done = function (url) {
            self.set('fileCoverSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      didSelectFilesAvatar: function (files) {
        let self = this;
        this.set('cropingAvatarImage', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileAvatar', files[0]);

          let done = function (url) {
            self.set('fileAvatarSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      didSelectFilesVideo: function (files) {
        let self = this;
        this.set('uploadVideoDialog', true);

        if (!Ember.isEmpty(files)) {
          this.set('fileVideo', files[0]);

          let done = function (url) {
            self.set('fileVideoSrc', url);
          };

          if (URL) {
            done(URL.createObjectURL(files[0]));
          } else if (FileReader) {
            var reader = new FileReader();

            reader.onload = function () {
              done(reader.result);
            };

            reader.readAsDataURL(files[0]);
          }
        }
      },
      uploadVideo: function (video) {
        this.set('uploadVideoDialog', false);
        this.fileService.get('uploadCompanyVideo').perform(video, this.get('model.organization'), this.get('model.organizationParams'));
      },
      closeUploadVideoDialog: function () {
        this.set('uploadVideoDialog', false);
      },
      closeCoverImageDialog: function () {
        this.set('cropingCoverImage', false);
      },
      uploadCompanyCover: function (cover) {
        this.fileService.get('uploadCompanyCoverImage').perform(cover, this.get('model.organization'));
      },
      uploadCompanyAvatar: function (avatar) {
        this.fileService.get('uploadCompanyAvatar').perform(avatar, this.get('model.organization'));
      },
      closeAvatarImageDialog: function () {
        this.set('cropingAvatarImage', false);
      },
      deleteCompanyVideo: function () {
        let videoDto = this.get('model.organization.videoDto');
        videoDto.destroyRecord().then(() => {
          this.get('findOrganization').perform();
        });
      },
      openDeleteDialog: function () {
        this.set('deleteCompanyDialog', true);
      },
      deleteOrganization: function () {
        let self = this;
        this.set('deleteCompanyDialog', false);
        this.set('waitingDeleteAccount', true);
        this.get('model.organization').destroyRecord().then(() => {
          self.set('waitingDeleteAccount', false);
          self.set('deleteSuccessfull', true);
          self.set('model.organization', null);
          self.set('logoutDialog', true);
        }).catch(function (error) {
          console.log(error);
          self.set('waitingDeleteAccount', false);
          self.set('deleteFailure', true);
        });
      },
      cancelDeleteOrganization: function () {
        this.set('deleteCompanyDialog', false);
      },
      closeDeleteAccountToast: function () {
        this.set('deleteSuccessfull', false);
        this.set('deleteFailure', false);
      },
      deleteUser: function (user) {
        this.userService.checkCanDelete(user.get('id')).then(resp => {
          this.set('toDeleteUser', user);

          if (resp) {
            this.set('toSubstituteUser', null);
            this.set('deleteAccountStep', true);
          } else {
            this.set('chooseTeamMember', true);
          }
        });
      },
      closeDeleteStepDialog: function () {
        this.set('toDeleteUser', null);
        this.set('toSubstituteUser', null);
        this.set('deleteAccountStep', false);
      },
      closeChooseTeamMemberDialog: function () {
        this.set('chooseTeamMember', false);
      },
      saveChooseTeamMemberDialog: function (user) {
        this.set('toSubstituteUser', user);
        this.set('deleteAccountStepOrganizations', true);
        this.set('chooseTeamMember', false);
      },
      deleteAccount: function () {
        let self = this;
        this.set('waitingDialog', true);
        $.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: _environment.default.APP.SPRING_HOST + "/users/normal/delete",
          data: {
            userDelete: this.get("toDeleteUser.id")
          },
          success: function (resp) {
            if (resp) {
              self.set('waitingDialog', false);
              self.set('deleteSuccessToast', true);
            } else {
              self.set('waitingDialog', false);
              self.set('deleteFailureToast', true);
            }
          },
          error: function () {
            self.set('waitingDialog', false);
            self.set('deleteFailureToast', true);
          }
        }).then(function (response) {
          if (response) {
            self.set('waitingDialog', false);
            self.set('deleteSuccessToast', true);
          } else {
            self.set('waitingDialog', false);
            self.set('deleteFailureToast', true);
          }
        });
      },
      deleteTeamMemberAccount: function (id) {
        this.deleteNormalCompanyUser.perform(id);
        this.set('deleteAccountStepOrganizations', false);
      },
      editCompanyVideo: function () {
        this.set('video', this.get('model.organization.videoDto'));
        this.set('editCompanyVideoDialog', true);
      },
      cancelEditVideo: function () {
        this.set('video', null);
        this.set('editCompanyVideoDialog', false);
      },
      saveEditVideo: function (video) {
        let self = this;
        video.save().then(() => {
          self.set('editCompanyVideoDialog', false);
          self.get('findOrganization').perform();
        });
      }
    }
  });

  _exports.default = _default;
});