define("E4E/routes/recruiters/job-applications/index", ["exports", "ember-simple-auth/mixins/application-route-mixin", "E4E/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    jobOfferName: '',
    sectorName: '',
    countryName: '',
    drivingLicenses: [],
    page: 0,
    questionId: '',
    tagName: '',
    status: ["ACCEPTED", "REJECTED", "PENDING"],
    selectedJobOffer: null,
    sector: null,
    authorId: '',
    jobApplicationId: null,
    jobSeeker: '',
    inEvidence: false,
    beforeModel: function () {
      let cookieService = this.get('cookies');
      let jobApplicationId = cookieService.read('jobApplicationId');
      let jobSeekerName = cookieService.read('jobSeekerName');

      if (jobApplicationId != undefined) {
        this.set('jobApplicationId', jobApplicationId);
        cookieService.clear('jobApplicationId');
      } else {
        this.set('jobApplicationId', null);
      }

      if (jobSeekerName != undefined) {
        this.set('jobSeeker', jobSeekerName);
        cookieService.clear('jobSeekerName');
      } else {
        this.set('jobSeeker', '');
      }
    },

    model() {
      return Ember.RSVP.hash({
        selectedJobApplication: null,
        country: null,
        city: '',
        countryName: '',
        jobOfferName: '',
        educationDateIssue: '',
        educationNumber: '',
        educationScope: '',
        language: [],
        languageName: [],
        languageScope: '',
        languageNumber: '',
        languageDateIssue: '',
        yearsExperience: '',
        competenceName: [],
        workExperience: '',
        education: '',
        jobApplications: null,
        status: this.get('status'),
        drivingLicenses: this.get('drivingLicenses'),
        jobSeeker: this.get('jobSeeker'),
        tag: [],
        page: this.get('page'),
        jobApplicationId: this.get('jobApplicationId'),
        inEvidence: this.get('inEvidence')
      });
    },

    actions: {
      didTransition: function () {
        if (this.get('jobApplicationId') != null) {
          this.controller.send("searchAndFindJobApplication");
        } else {
          this.controller.send("searchJobApplications");
        }
      }
    }
  });

  _exports.default = _default;
});