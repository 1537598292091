define("E4E/templates/components/not-found-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0p4LCWOy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"width100P heightView layout-row layout-align-space-between-center layout-sm-column layout-align-sm-center-center layout-xs-column layout-align-xs-center-center padding-16\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-96-type-1\"],[9],[0,\"404\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"margin-top-12 text-24-type-3\"],[9],[1,[27,\"t\",[\"label.notfound\"],null],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"margin-top-9\"],[9],[1,[27,\"t\",[\"label.reasonnotfound\"],null],false],[10],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"primary\",\"raised\",\"onClick\"],[\"margin-top-24\",true,true,[27,\"action\",[[22,0,[]],\"goBack\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"label.goback\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-end\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"flex width100P\"],[11,\"src\",\"/pix/404_image.svg\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/not-found-component.hbs"
    }
  });

  _exports.default = _default;
});