define("E4E/routes/messages", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    cookies: Ember.inject.service(),

    beforeModel() {
      let cookieService = this.get('cookies');
      let convIdToOpen = cookieService.read('convIdToOpen');

      if (convIdToOpen != undefined) {
        this.set('convId', convIdToOpen);
        cookieService.clear('convIdToOpen');
      } else {
        this.set('convId', null);
      }
    },

    model() {
      return Ember.RSVP.hash({
        selectedConversation: null,
        conversationId: this.get('convId')
      });
    },

    actions: {
      didTransition: function () {
        let self = this;
        this.controller.get("findConversations").perform().then(function () {
          if (self.get('convId') != null) {
            self.controller.send('findConversationOnOpenPage');
          }
        });
      }
    }
  });

  _exports.default = _default;
});