define("E4E/components/public/users/digitalskills-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      showAttachments: function () {
        this.set('showAttachments', true);
      }
    }
  });

  _exports.default = _default;
});