define("E4E/components/superadmin/organizations/organization-preview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    fileService: Ember.inject.service('file-service'),
    organizationService: Ember.inject.service('organization'),
    advancedInformationOpened: false,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    totalJobApplications: 0,
    hiredApplications: 0,
    pendingApplications: 0,
    deniedApplications: 0,
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    authorizedGet: function (url) {
      return $.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    authorizedPost: function (url, content, done) {
      return $.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        data: JSON.stringify(content),
        contentType: "application/json",
        done: done
      });
    },
    init: function () {
      this._super(...arguments);
    },
    currentUserService: Ember.inject.service('current-user'),
    actions: {
      goToJobOffers: function (status) {
        let cookieService = this.get('cookies');
        cookieService.write('jobOfferStatus', [status].toString());
        this.get('router').transitionTo("superadmin.job-offers");
      },
      goToApplications: function (status) {
        let cookieService = this.get('cookies');

        if (status != null) {
          cookieService.write('jobApplicationStatus', [status]);
          this.get('router').transitionTo("superadmin.job-applications");
        } else {
          this.get('router').transitionTo("superadmin.job-applications");
        }
      },
      acceptCompany: function () {
        this.acceptCompany();
      },
      rejectCompany: function () {
        this.rejectCompany();
      },
      openDeleteDialog: function () {
        this.openDeleteDialog();
      }
    }
  });

  _exports.default = _default;
});