define("E4E/components/confirmdelete-video", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    actions: {
      deleteVideo: function (video) {
        this.deleteVideo(video);
      },
      cancelDelete: function () {
        this.cancelDelete();
      }
    }
  });

  _exports.default = _default;
});