define("E4E/templates/components/users/attachments-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I1H2bk87",
    "block": "{\"symbols\":[\"certificate\"],\"statements\":[[7,\"span\"],[11,\"class\",\"text-20-type-2\"],[9],[1,[27,\"t\",[\"label.attachments\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start margin-top-20\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"not-eq\",[[23,[\"certificates\",\"length\"]],0],null]],null,{\"statements\":[[4,\"each\",[[23,[\"certificates\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"class\",\"primary\",\"onClick\"],[\"textTransformNone\",true,[27,\"action\",[[22,0,[]],\"downloadCertificate\",[22,1,[\"originalFileName\"]],[22,1,[\"fileId\"]]],null]]],{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"text-16-type-9\"],[9],[1,[22,1,[\"originalFileName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[27,\"t\",[\"label.noattachments\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/users/attachments-card.hbs"
    }
  });

  _exports.default = _default;
});