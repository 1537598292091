define("E4E/controllers/superadmin/users/index", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    userParams: ["joboffers", "image", "organization", "coverimage", "curriculumvitae", "certificates"],
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    screen: Ember.inject.service('screen'),
    userService: Ember.inject.service('user'),
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    fileService: Ember.inject.service('file-service'),
    intl: Ember.inject.service(),
    pdfJs: Ember.inject.service('pdf-js'),
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    paperToaster: Ember.inject.service(),
    deleteUserDialog: false,
    deleteSuccessfull: false,
    deleteFailure: false,
    waitingDeleteAccount: false,
    adviceDeleteDialog: false,
    blockUserSuccessDialog: false,
    unblockUserSuccessDialog: false,
    init: function () {
      this._super(...arguments);

      this.set('model', {
        selectedUser: null
      });

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.users', Ember.A([]));
      this.set('model.page', 0);
      this.set('model.meta', null);
      this.get('searchUsers').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedUser') != null) {
          if (Ember.$('#search_container_users').hasClass('hide')) {
            Ember.$('#users-container').addClass('show');
          } else {
            Ember.$('#search_container_users').addClass('hide');
            Ember.$('#users-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_users').removeClass('hide');
          Ember.$('#users-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_users').removeClass('hide');
        Ember.$('#users-container').removeClass('show');
      }
    },

    searchUsers: (0, _emberConcurrency.task)(function* (nextPage) {
      this.userService.filterSuperAdmin(this.get('model.name'), this.get('model.organization'), this.get('model.countryName'), this.get('model.roles'), this.get('model.status'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.users').pushObjects(resp.users.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.users', resp.users.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    findUser: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('user', id, {
        include: this.userParams.toString()
      }).then(user => {
        this.set('model.selectedUser', user);
        Ember.$('#users-container').scrollTop(0, 0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_users').addClass('hide');
          Ember.$('#users-container').addClass('show');
        }
      });
    }).restartable(),
    actions: {
      updateName: function (value) {
        this.set('model.name', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompany: function (value) {
        this.set('model.organization', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        this.set('model.country', value);

        if (value) {
          this.set('model.countryName', value.iso3);
        } else {
          this.set('model.countryName', '');
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateStatus: function (value) {
        if (this.get('model.status').includes(value)) {
          this.get('model.status').removeObject(value);
        } else {
          this.get('model.status').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateRoles: function (value) {
        if (this.get('model.roles').includes(value)) {
          this.get('model.roles').removeObject(value);
        } else {
          this.get('model.roles').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      selectUser: function (id) {
        this.get('findUser').perform(id);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchUsers').perform(true);
      },
      searchUsers: function () {
        this.searchUsers.perform();
      },
      rejectUser: function () {
        let self = this;
        this.set('waitingDialog', true);
        Ember.$.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: _environment.default.APP.SPRING_HOST + "/users/reject/" + this.get('model.selectedUser.id'),
          success: function (resp) {
            if (resp) {
              self.get('paperToaster').show(self.intl.t('label.user.saved'), {
                duration: 3000
              });
              self.userService.load(self.get('model.selectedUser.id'), self.userParams);
            } else {
              self.get('paperToaster').show(self.intl.t('label.user.error'), {
                duration: 3000
              });
            }
          },
          error: function () {
            self.get('paperToaster').show(self.intl.t('label.user.error'), {
              duration: 3000
            });
          }
        });
      },
      acceptUser: function () {
        let self = this;
        this.set('waitingDialog', true);
        Ember.$.ajax({
          type: "POST",
          headers: this.get('headers'),
          url: _environment.default.APP.SPRING_HOST + "/users/accept/" + this.get('model.selectedUser.id'),
          success: function (resp) {
            if (resp) {
              self.get('paperToaster').show(self.intl.t('label.user.saved'), {
                duration: 3000
              });
              self.userService.load(self.get('model.selectedUser.id'), self.userParams);
            } else {
              self.get('paperToaster').show(self.intl.t('label.user.error'), {
                duration: 3000
              });
            }
          },
          error: function () {
            self.get('paperToaster').show(self.intl.t('label.user.error'), {
              duration: 3000
            });
          }
        });
      },
      openDeleteDialog: function () {
        this.set('adviceDeleteDialog', false);
        this.set('deleteUserDialog', true);
      },
      deleteUser: function () {
        let self = this;
        this.set('deleteUserDialog', false);
        this.set('waitingDeleteAccount', true);
        this.get('model.selectedUser').destroyRecord().then(resp => {
          self.set('waitingDeleteAccount', false);
          self.set('deleteSuccessfull', true);
          self.set('model.selectedUser', null);
          self.get('searchUsers').perform();
        }).catch(function (error) {
          console.log('here i am');
          self.set('waitingDeleteAccount', false);
          self.set('deleteFailure', true);
        });
      },
      cancelDeleteUser: function () {
        this.set('deleteUserDialog', false);
      },
      closeDeleteAccountToast: function () {
        this.set('deleteSuccessfull', false);
        this.set('deleteFailure', false);
      },
      showAdviceDelete: function () {
        this.set('adviceDeleteDialog', true);
      },
      closeAdviceDelete: function () {
        this.set('adviceDeleteDialog', false);
      },
      downloadCV: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      downloadCVWord: function (originalFileName, id) {
        let self = this;
        this.get('store').findRecord('file', id).then(function (record) {
          record.download().then(wordContent => self.saveFileAs(originalFileName + ".docx", wordContent, 'application/msword'));
        });
      },
      blockUserinBlog: function (user) {
        let self = this;
        let u = user;
        u.set("blogBlocked", true);
        u.save().then(() => {
          self.set("blockUserSuccessDialog", true);
          this.get('findUser').perform();
        });
      },
      closeBlockUserSuccessDialog: function () {
        this.set("blockUserSuccessDialog", false);
      },
      unblockUserinBlog: function (user) {
        let self = this;
        let u = user;
        u.set("blogBlocked", false);
        u.save().then(() => {
          self.set("unblockUserSuccessDialog", true);
          this.get('findUser').perform();
        });
      },
      closeUnblockUserSuccessDialog: function () {
        this.set("unblockUserSuccessDialog", false);
      }
    }
  });

  _exports.default = _default;
});