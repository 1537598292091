define("E4E/serializers/question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      questionBundle: {
        serialize: false,
        deserialize: 'records'
      }
    },
    extractMeta: function (store, typeClass, payload) {
      let meta = {};
      let links;

      if (payload.page) {
        meta.page = payload.page;
        delete payload.page;
      }

      return meta;
    },
    normalizeSingleResponse: function (store, primaryModelClass, payload, id, requestType) {
      let modelKey = primaryModelClass.modelName;
      let aux = {};
      Object.keys(payload).forEach(key => {
        aux[key] = payload[key];
        delete payload[key];
      });
      payload[modelKey] = aux;
      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, true);
    },
    normalizeFindAllResponse: function (store, primaryModelClass, payload, id, requestType) {
      return this.normalizeArrayResponse(store, primaryModelClass, payload, id, requestType);
    },
    normalizeArrayResponse: function (store, primaryModelClass, payload, id, requestType) {
      let questionsPayload = {};
      questionsPayload.questions = payload;
      return this._normalizeResponse(store, primaryModelClass, questionsPayload, id, requestType, false);
    },
    normalizeQueryResponse: function (store, primaryModelClass, payload, id, requestType) {
      let questionsPayload = {};
      let page = {};
      questionsPayload.questions = payload.content;
      page.totalPages = payload.totalPages;
      page.totalElements = payload.totalElements;
      page.size = payload.size;
      page.number = payload.number;
      questionsPayload.page = page;
      return this._normalizeResponse(store, primaryModelClass, questionsPayload, id, requestType, false);
    }
  });

  _exports.default = _default;
});