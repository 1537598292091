define("E4E/controllers/messages", ["exports", "E4E/config/environment", "ember-concurrency", "pell"], function (_exports, _environment, _emberConcurrency, _pell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cookies: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    conversations: null,
    userId: null,
    messageText: "",
    socketConnection: Ember.inject.service('socket-connection'),
    showLoader: false,
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    session: Ember.inject.service(),
    showFindConversationsDialog: false,
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },
    authorizedPost: function (url, content, done) {
      console.log('authorized post');
      console.log(url);
      return Ember.$.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        data: JSON.stringify(content),
        contentType: "application/json",
        done: done
      });
    },
    init: function () {
      this._super(...arguments);

      let self = this;

      if (this.get('socketConnection').connected) {
        this.wsSubscibe();
      } else {
        let interval = setInterval(() => {
          if (self.get('socketConnection').connected) {
            clearInterval(interval);
            self.wsSubscibe();
          }
        }, 2000);
      }

      let bold = this.intl.t('label.bold');
      let italic = this.intl.t('label.italic');
      let underline = this.intl.t('label.underline');
      let addLink = this.intl.t('label.addlink');
      let removeLink = this.intl.t('label.removelink');
      this.set('options', {
        actions: [{
          name: 'bold',
          title: bold,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
        }, {
          name: 'italic',
          title: italic,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
        }, {
          name: 'underline',
          title: underline,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
        }, {
          name: 'link',
          title: addLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }, {
          name: 'unlink',
          title: removeLink,
          icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
          result: () => (0, _pell.exec)('unlink')
        } // {
        //   name: 'image',
        //   result: () => {
        //     const url = window.prompt('Enter the image URL')
        //     if (url) exec('insertImage', url)
        //   }
        // }
        ]
      });
    },
    wsSubscibe: function () {
      var self = this;
      this.get('socketConnection').stompClient.subscribe('/user/topic/messeges', function (resp) {
        if (self.get('model.selectedConversation.id') == resp.body) {
          self.get('findConversation').perform(self.get('model.selectedConversation.id'), true);
          let interval = setInterval(() => {
            clearInterval(interval);
            self.scrollMessenger(Ember.$('.message-container'), Ember.$('#message_container_parent'));
          }, 100);
        } else {
          self.get('findConversation').perform(resp.body, false);
        }
      });
    },
    findConversations: (0, _emberConcurrency.task)(function* () {
      console.log('find convs');
      let self = this;
      yield this.store.findAll('conversation').then(resp => {
        console.log(resp);
        self.set('conversations', resp);
      });
    }),
    findConversation: (0, _emberConcurrency.task)(function* (id, setSelected) {
      let self = this;
      yield this.store.findRecord('conversation', id).then(resp => {
        if (setSelected) {
          self.set('model.selectedConversation', resp);
        } //return true;

      });
    }),
    scrollMessenger: function (mContainer, mContainerParent) {
      var height = mContainer.height();
      mContainerParent.scrollTop(height);
    },
    sendMessage: function () {
      if (this.get("messageText").length > 0) {
        let self = this;
        let message = this.store.createRecord('message');
        message.set('authorId', this.currentUserService.get('user.id'));
        message.set('conversationId', this.get('model.selectedConversation').id);
        message.set('text', this.get('messageText'));
        message.save().then(resp => {
          self.get('findConversation').perform(self.get('model.selectedConversation.id'), true);
          self.set('messageText', "");
          Ember.$('#message_editor .pell-content').focus();
          let interval = setInterval(() => {
            clearInterval(interval);
            self.scrollMessenger(Ember.$('.message-container'), Ember.$('#message_container_parent'));
          }, 100);
        }); //.catch((err) => {
        //   console.log('err - ', err)
        // });
      }
    },
    selectConversation: function (id) {
      var self = this;
      this.set("showLoader", true);
      this.set('messageText', "");
      Ember.$('#message_editor .pell-content').blur();
      let val = this.get('findConversation').perform(id, true);
      let interval = setInterval(() => {
        if (!val.isRunning && self.get("model.selectedConversation") != null) {
          clearInterval(interval);
          self.scrollMessenger(Ember.$('.message-container'), Ember.$('#message_container_parent'));
          Ember.$('#message_editor').off('keydown').on('keydown', function (e) {
            if (e.keyCode === 13 && !e.shiftKey) {
              e.preventDefault();
              self.sendMessage();
            }
          });
          Ember.$('#message_editor .pell-content').off('focus').on('focus', function () {
            if (self.get("model.selectedConversation.count") > 0) {
              let userIdToRead;

              if (self.get("model.selectedConversation.authorId") == self.currentUserService.user.id) {
                userIdToRead = self.get("model.selectedConversation.receiverId");
              } else if (self.get("model.selectedConversation.receiverId") == self.currentUserService.user.id) {
                userIdToRead = self.get("model.selectedConversation.authorId");
              }

              self.authorizedPost(self.serverUrl + '/messages/set/read', {
                conversationId: self.get('model.selectedConversation.id'),
                fromUserId: userIdToRead
              }, null).then(resp => {
                self.get('findConversation').perform(self.get('model.selectedConversation.id'), true);
              });
            }
          });
          Ember.$('#message_editor .pell-content').focus();
          self.set("showLoader", false);
        }
      }, 10);
    },
    actions: {
      findConversations: function () {
        this.get('findConversations').perform();
      },
      findConversationOnOpenPage: function () {
        //this.get('findConversation').perform(this.get('model.conversationId'), true);
        this.selectConversation(this.get('model.conversationId'));
      },
      addMessage: function () {
        this.sendMessage();
      },
      selectConversation: function (conv) {
        this.selectConversation(conv.id);
      },
      showSearchDialog: function () {
        this.set("showFindConversationsDialog", true);
      },
      closeDialog: function () {
        this.set("showFindConversationsDialog", false);
      },
      addConversation: function () {
        console.log('add conv action');
      }
    }
  });

  _exports.default = _default;
});