define("E4E/helpers/number-conflicts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numberConflicts = numberConflicts;

  function numberConflicts(params
  /*, hash*/
  ) {
    if (params[0].length > 0) {
      let numberConflicts = 0;
      params[0].forEach(function (appointment) {
        if (appointment.extendedProps.hasOverlaps) {
          numberConflicts++;
        }
      });
      return numberConflicts;
    } else {
      return 0;
    }
  }

  var _default = Ember.Helper.helper(numberConflicts);

  _exports.default = _default;
});