define("E4E/components/files/list-file", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    delete: false,
    new: false,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    fileService: Ember.inject.service('file-service'),
    currentUserService: Ember.inject.service('current-user'),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    actions: {
      openUploadDialog: function () {
        this.openUploadDialog();
      },
      getDocuments: function () {
        this.getDocuments();
      },
      downloadfile: function (originalFileName, file, mimeType) {
        this.downloadfile(originalFileName, file, mimeType);
      },
      documentupload: function (files) {
        let self = this;

        if (!Ember.isEmpty(files)) {
          this.fileService.uploadDocument.perform(files[0]).then(document => {
            self.getDocuments();
          });
        }
      },
      deleteDocument: function (document) {
        this.deleteDocument(document);
      }
    }
  });

  _exports.default = _default;
});