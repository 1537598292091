define("E4E/components/recruiters/job-seekers/jobseeker-preview", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cookies: Ember.inject.service(),
    showReportUser: false,
    selectedJobSeeker: null,
    store: Ember.inject.service(),
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    favorite: Ember.computed('currentUserService.user.userOrganization.flags.@each', 'selectedJobSeeker', function () {
      let self = this;
      let flagTemp = null;
      this.get('currentUserService.user.userOrganization.flags').forEach(function (flag) {
        if (flag.userId == self.get('selectedJobSeeker.id')) {
          flagTemp = flag;
        }
      });
      return flagTemp;
    }),
    currentUserService: Ember.inject.service('current-user'),
    dialogService: Ember.inject.service('dialog-service'),
    goToMessenger: function () {
      let self = this;
      let cookieService = this.get('cookies');
      this.get('selectedJobSeeker.conversations').filter(function (conv) {
        if (conv.authorId == self.get('currentUserService.user.id') || conv.receiverId == self.get('currentUserService.user.id')) {
          cookieService.write('convIdToOpen', conv.id);
          location.href = "/messages";
        }
      });
    },
    actions: {
      downloadCV: function () {
        this.downloadCV(this.get('selectedJobSeeker.curriculumVitae.originalFileName'), this.get('selectedJobSeeker.curriculumVitae.fileId'));
      },
      addToFavorites: function () {
        this.addToFavorites(this.get('favorite'));
      },
      removeFromFavorites: function () {
        this.removeFromFavorites(this.get('favorite'));
      },
      addConversation: function () {
        this.addConversation(this.get('selectedJobSeeker.id'));
        Ember.run.debounce(this, this.goToMessenger, 1000);
      },
      goToMessenger: function () {
        this.goToMessenger();
      },
      reportUser: function (selectedJobSeeker) {
        this.set("showReportUser", true);
        this.set("selectedJobSeeker", selectedJobSeeker);
      },
      closeDialogReportUser: function () {
        this.set("showReportUser", false);
      },
      saveReportUser: function (content) {
        let self = this;
        let reportBlogItem = this.store.createRecord('reportblogitem');
        reportBlogItem.set('reasonReport', content);
        reportBlogItem.set('blogPostId', null);
        reportBlogItem.set('blogCommentId', null);
        reportBlogItem.set('userId', this.get("selectedJobSeeker.id"));
        reportBlogItem.save().then(() => {
          self.set("content", "");
          self.set("showReportUser", false);
          self.set("ReportUserSuccessDialog", true);
        });
      },
      closeReportUserSuccessDialog: function () {
        this.set("ReportUserSuccessDialog", false);
      }
    }
  });

  _exports.default = _default;
});