define("E4E/templates/components/questions/add/date-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AuV3zX59",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layoput-align-start-start width100P margin-top-16\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-14-type-10\"],[9],[1,[27,\"t\",[\"label.date\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center margin-top-20 layout-xs-column layout-align-xs-center-center\"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"primary\",\"raised\",\"onClick\"],[\"margin-right-8\",true,true,[27,\"action\",[[22,0,[]],\"saveQuestion\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"label.createquestion\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"class\",\"primary\",\"onClick\"],[\"margin-left-8\",true,[27,\"action\",[[22,0,[]],\"cancelQuestion\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"label.discard\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/questions/add/date-question.hbs"
    }
  });

  _exports.default = _default;
});