define("E4E/services/jobapplication", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    save: function (jobApplication, jobApplicationParams) {
      let self = this;
      return Ember.RSVP.resolve(jobApplication.save()).then(() => {
        self.get('paperToaster').show(self.intl.t('label.jobapplication.saved'), {
          duration: 3000
        });
        return this.load(jobApplication.get('id'), jobApplicationParams);
      }).catch(error => {
        self.get('paperToaster').show(self.intl.t('label.jobapplication.error'), {
          duration: 3000
        });
        return error;
      });
    },

    load(id, jobApplicationParams) {
      return this.store.findRecord('jobapplication', id, {
        include: jobApplicationParams.toString()
      });
    },

    acceptJobApplication: function (jobApplication, jobApplicationParams) {
      let self = this;
      return Ember.RSVP.resolve(jobApplication.save()).then(() => {
        self.paperToaster.show(self.intl.t("label.acceptedapplication"), {
          duration: 3000
        });
        return this.load(jobApplication.get('id'), jobApplicationParams);
      }).catch(error => {
        self.paperToaster.show(self.intl.t("label.acceptedapplicationerror"), {
          duration: 3000
        });
        return error;
      });
    },
    rejectJobApplication: function (jobApplication, jobApplicationParams) {
      let self = this;
      return Ember.RSVP.resolve(jobApplication.save()).then(() => {
        self.paperToaster.show(self.intl.t("label.rejectedapplication"), {
          duration: 3000
        });
        return this.load(jobApplication.get('id'), jobApplicationParams);
      }).catch(error => {
        self.paperToaster.show(self.intl.t("label.rejectedapplicationerror"), {
          duration: 3000
        });
        return error;
      });
    },
    filterJobApplicationsOfOrganization: function (jobOfferName, jobSeeker, country, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, status, tag, isOutplacer, inEvidence, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/jobapplications/search/filterJobApplicationsOfOrganization?" + "jobOffer=" + jobOfferName.toLowerCase() + "&organizationId=" + this.get('currentUserService.user.organizationId') + "&jobSeeker=" + jobSeeker.toLowerCase() + "&country=" + country + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&status=" + status.toString() + "&tag=" + tag.toString() + "&isOutplacer=" + isOutplacer + "&inEvidence=" + inEvidence + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobApplications: null,
          meta: null
        };
        searchResponse.jobApplications = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterJobSeekers: function (jobOfferName, companyName, country, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, status, inEvidence, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/jobapplications/search/filterJobSeekers?" + "jobOffer=" + jobOfferName.toLowerCase() + "&companyName=" + companyName.toLowerCase() + "&jobSeekerId=" + this.get('currentUserService.user.id') + "&country=" + country + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&status=" + status.toString() + "&inEvidence=" + inEvidence + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobApplications: null,
          meta: null
        };
        searchResponse.jobApplications = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    },
    filterJobApplicationsOfOutplacer: function (jobOfferName, jobSeeker, country, city, workExperience, yearsExperience, education, educationScope, educationNumber, educationDateIssue, languageName, languageScope, languageNumber, languageDateIssue, drivingLicenses, competenceName, status, tag, outplacerId, inEvidence, page, size) {
      return Ember.RSVP.resolve(Ember.$.getJSON(this.get("serverUrl") + "/jobapplications/search/filterJobApplicationsOfOutplacer?" + "jobOffer=" + jobOfferName.toLowerCase() + "&organizationId=" + this.get('currentUserService.user.organizationId') + "&jobSeeker=" + jobSeeker.toLowerCase() + "&country=" + country + "&city=" + city + "&workExperience=" + workExperience.toString() + "&yearsExperience=" + yearsExperience + "&education=" + education.toString() + "&educationScope=" + educationScope + "&educationNumber=" + educationNumber + "&educationDateIssue=" + educationDateIssue + "&languageName=" + languageName.toString() + "&languageScope=" + languageScope + "&languageNumber=" + languageNumber + "&languageDateIssue=" + languageDateIssue + "&drivingLicenses=" + drivingLicenses.toString() + "&competences=" + competenceName.toString() + "&status=" + status.toString() + "&tag=" + tag.toString() + "&outplacerId=" + outplacerId + "&inEvidence=" + inEvidence + "&page=" + page + "&size=" + size).then(resp => {
        let searchResponse = {
          jobApplications: null,
          meta: null
        };
        searchResponse.jobApplications = resp.content.toArray();
        searchResponse.meta = {
          totalPages: resp.totalPages,
          totalElements: resp.totalElements,
          last: resp.last,
          size: resp.size,
          number: resp.number,
          sort: resp.sort,
          numberOfElements: resp.numberOfElements,
          first: resp.first
        };
        return searchResponse;
      }));
    }
  });

  _exports.default = _default;
});