define("E4E/components/paper-expansion-panel/expanded/header", ["exports", "ember-paper-expansion-panel/components/paper-expansion-panel/expanded/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _header.default;
    }
  });
});