define("E4E/components/dialogs/organizations/choose-teammember", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    userService: Ember.inject.service('user'),
    userRole: ["RECRUITER", "ORGANIZATION_ADMIN"],
    teamMembers: Ember.A([]),
    suitableUsers: Ember.computed('teamMembers.@each', function () {
      let self = this;

      if (this.get('teamMembers').length > 0) {
        return this.get('teamMembers').filter(function (user) {
          return user.userRole.name === self.get('selectedTeamMember.userRole.name');
        });
      } else {
        console.log('empty erray');
        return Ember.A([]);
      }
    }),
    disableContinueDelete: Ember.computed('suitableUser.@each', 'teamMembers.@each', 'selectedMember', function () {
      if (this.get('suitableUsers').length > 1) {
        if (this.get('selectedTeamMember.userRole.name') === "ORGANIZATION_ADMIN") {
          if (this.get('selectedMember') != null) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        if (this.get('selectedTeamMember.userRole.name') !== "ORGANIZATION_ADMIN") {
          return false;
        } else {
          return true;
        }
      }
    }),
    init: function () {
      this._super(...arguments);

      this.getTeamMembers.perform();
    },
    getTeamMembers: (0, _emberConcurrency.task)(function* () {
      yield this.userService.filter('', this.userRole, '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 1000).then(resp => {
        this.set('teamMembers', resp.users.toArray());
      });
    }),
    selectedMember: null,
    actions: {
      closeChooseTeamMemberDialog: function () {
        this.closeChooseTeamMemberDialog();
      },
      saveChooseTeamMemberDialog: function () {
        this.saveChooseTeamMemberDialog(this.get('selectedMember'));
      },
      chooseTeamMember: function (user) {
        this.set('selectedMember', user);
      }
    }
  });

  _exports.default = _default;
});