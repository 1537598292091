define("E4E/helpers/jobapplications-hasdrivinglicense", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jobapplicationsHasdrivinglicense = jobapplicationsHasdrivinglicense;

  function jobapplicationsHasdrivinglicense(params) {
    let drivingLicensesJobOffer = params[0].get('drivingLicenseJson.Description');
    let drivingLicensesJobApp = params[1].get('skillsCompetencesJson.Driving.Description');
    let sameLicensesArray = Ember.A([]);

    if (drivingLicensesJobOffer != null) {
      drivingLicensesJobOffer.forEach(function (driving) {
        if (drivingLicensesJobApp != null) {
          let sameLicenses = drivingLicensesJobApp.filter(function (license) {
            return license === driving;
          });
          sameLicensesArray.push(sameLicenses);
        }
      });
    }

    return sameLicensesArray.length;
  }

  var _default = Ember.Helper.helper(jobapplicationsHasdrivinglicense);

  _exports.default = _default;
});