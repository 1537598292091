define("E4E/templates/components/organizations/waiting-deleteaccount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YfbuMeYN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"class\",\"origin\"],[\"flex-50 flex-sm-70 flex-xs-80\",[23,[\"dialogOrigin\"]]]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,[[\"class\"],[\"layout-row layout-align-space-between-center\"]],{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-24-type-1\"],[9],[1,[27,\"t\",[\"label.deleteaccount\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-center-center width100P\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[27,\"t\",[\"label.deletingwaitprofile\"],null],false],[10],[0,\"\\n      \"],[1,[27,\"paper-progress-circular\",null,[[\"class\",\"primary\",\"diameter\",\"strokeRatio\"],[\"margin-top-16 md-hue-3\",true,100,0.015]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/organizations/waiting-deleteaccount.hbs"
    }
  });

  _exports.default = _default;
});