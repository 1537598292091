define("E4E/templates/superadmin/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W7docJyN",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n<div class=\\\"layout-row layout-align-start-start maxWidth1200 width100P height100vh margin-auto   padding-16\\\">\\n    {{files/list-file class=\\\"layout-row layout-align-start-start width100P\\\" \\n    documentItems=documentItems \\n    getDocuments=(action \\\"getDocuments\\\")  \\n    deleteDocument=(action \\\"deleteDocument\\\") \\n    downloadfile = (action \\\"downloadfile\\\")\\n    new=true\\n    delete=true \\n    }}\\n</div>\\n{{#if documentDeleteSuccessfull}}\\n  {{#paper-toast duration=5000 position=(concat bottom \\\" \\\" right) capsule=capsule swipeToClose=false onClose=(action \\\"closeDocumentDeleteSuccessfull\\\") as |toast|}}\\n    {{#toast.text}}\\n      {{paper-icon \\\"done\\\"}} {{t \\\"label.document.documentDeleteSuccessfull\\\"}}\\n    {{/toast.text}}\\n  {{/paper-toast}}\\n{{/if}}{{#if documentDeleteFailure}}\\n  {{#paper-toast duration=5000 position=(concat bottom \\\" \\\" right) capsule=capsule swipeToClose=false onClose=(action \\\"closeDocumentDeleteFailure\\\") as |toast|}}\\n    {{#toast.text}}\\n      {{paper-icon \\\"done\\\"}} {{t \\\"label.document.documentDeleteFailure\\\"}}\\n    {{/toast.text}}\\n  {{/paper-toast}}\\n{{/if}}\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/superadmin/dashboard.hbs"
    }
  });

  _exports.default = _default;
});