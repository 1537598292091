define("E4E/models/notification", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    userSender: _emberData.default.belongsTo('user', {
      async: false,
      inverse: false
    }),
    userReceiver: _emberData.default.belongsTo('user', {
      async: false,
      inverse: false
    }),
    context: _emberData.default.attr('string'),
    seenNotification: _emberData.default.attr('boolean'),
    notificationType: _emberData.default.attr('string'),
    permissionProfile: _emberData.default.attr('boolean'),
    senderId: _emberData.default.attr('string'),
    receiverId: _emberData.default.attr('string'),
    jobOfferDto: _emberData.default.belongsTo('joboffer', {
      async: false,
      inverse: false
    }),
    links: _emberData.default.attr()
  });

  _exports.default = _default;
});