define("E4E/templates/components/curriculumvitaes/find-certificates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUeGNN+d",
    "block": "{\"symbols\":[\"certificate\",\"index\"],\"statements\":[[4,\"if\",[[23,[\"showAttachments\"]]],null,{\"statements\":[[4,\"if\",[[27,\"gt\",[[23,[\"certificatesArray\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center margin-top-16 layout-wrap\"],[9],[0,\"\\n      \"],[4,\"each\",[[23,[\"certificatesArray\"]]],null,{\"statements\":[[4,\"if\",[[27,\"not-eq\",[[22,2,[]],0],null]],null,{\"statements\":[[0,\", \"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"layout-row cursorPointer\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"downloadCV\",[22,1,[\"originalFileName\"]],[22,1,[\"fileId\"]]],null]],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"text-16-type-9\"],[9],[0,\" \"],[1,[22,1,[\"originalFileName\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center cursorPointer\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"showAttachments\"],null]],[9],[0,\"\\n    \"],[1,[27,\"paper-icon\",[\"attach_file\"],[[\"size\"],[\"14\"]]],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-12-type-2 margin-left-4\"],[9],[1,[27,\"t\",[\"label.numberattachments\"],[[\"numberOfAttachments\"],[[23,[\"certificatesArray\",\"length\"]]]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/curriculumvitaes/find-certificates.hbs"
    }
  });

  _exports.default = _default;
});