define("E4E/helpers/finished-joboffers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.finishedJobOffers = finishedJobOffers;

  function finishedJobOffers(params) {
    return params[0].filter(item => Date.parse(item.deadline) < new Date()).length;
  }

  var _default = Ember.Helper.helper(finishedJobOffers);

  _exports.default = _default;
});