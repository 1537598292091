define("E4E/controllers/recruiters/job-applications/index", ["exports", "ember-concurrency", "E4E/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    screen: Ember.inject.service('screen'),
    jobApplicationService: Ember.inject.service("jobapplication"),
    jobApplicationParams: ["jobseeker", "joboffer", "video", "motivationalletter", "tags", "request"],
    dialogService: Ember.inject.service('dialog-service'),
    fileService: Ember.inject.service('file-service'),
    tags: Ember.A([]),
    showAnswersDialog: false,
    openEvaluateDialog: false,
    openSeeEvaluateDialog: false,
    paperToaster: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.get('findTags').perform();

      this._boundResize = () => Ember.run.debounce(this, 'resizeWindow', 0);

      window.addEventListener('resize', this._boundResize, false);
    },
    search: function () {
      this.set('model.jobApplications', Ember.A([]));
      this.set('model.meta', null);
      this.get('searchJobApplications').perform();
    },

    resizeWindow() {
      if (this.get('screen.is959')) {
        if (this.get('model.selectedJobApplication') != null) {
          if (Ember.$('#search_container_job_appications').hasClass('hide')) {
            Ember.$('#job-application-container').addClass('show');
          } else {
            Ember.$('#search_container_job_appications').addClass('hide');
            Ember.$('#job-application-container').addClass('show');
          }
        } else {
          Ember.$('#search_container_job_appications').removeClass('hide');
          Ember.$('#job-application-container').removeClass('show');
        }
      } else {
        Ember.$('#search_container_job_appications').removeClass('hide');
        Ember.$('#job-application-container').removeClass('show');
      }
    },

    findTags: (0, _emberConcurrency.task)(function* () {
      let self = this;
      Ember.$.ajax({
        type: "GET",
        headers: this.get('headers'),
        url: this.get('serverUrl') + "/tags/search/findByOrganizationId/" + self.get('currentUserService.user.organizationId'),
        contentType: "application/json",
        success: function (resp) {
          self.set("tags", resp.toArray());
        }
      });
    }),
    findJobApplication: (0, _emberConcurrency.task)(function* (id) {
      yield this.store.findRecord('jobapplication', id, {
        include: this.jobApplicationParams.toString()
      }).then(jobApplication => {
        this.set('model.selectedJobApplication', jobApplication);
        Ember.$('#job-application-container').scrollTop(0);

        if (this.get('screen.is959')) {
          Ember.$('#search_container_job_appications').addClass('hide');
          Ember.$('#job-application-container').addClass('show');
        }
      });
    }).restartable(),
    searchJobApplications: (0, _emberConcurrency.task)(function* (nextPage) {
      this.jobApplicationService.filterJobApplicationsOfOrganization(this.get('model.jobOfferName'), this.get('model.jobSeeker'), this.get('model.countryName'), this.get('model.city'), this.get('model.workExperience'), this.get('model.yearsExperience'), this.get('model.education'), this.get('model.educationScope'), this.get('model.educationNumber'), this.get('model.educationDateIssue'), this.get('model.languageName'), this.get('model.languageScope'), this.get('model.languageNumber'), this.get('model.languageDateIssue'), this.get('model.drivingLicenses'), this.get('model.competenceName'), this.get('model.status'), this.get('model.tag'), false, this.get('model.inEvidence'), this.get('model.page'), 10).then(resp => {
        if (nextPage) {
          this.get('model.jobApplications').pushObjects(resp.jobApplications.toArray());
          this.set('model.meta', resp.meta);
        } else {
          this.set('model.jobApplications', resp.jobApplications.toArray());
          this.set('model.meta', resp.meta);
        }
      });
    }),
    actions: {
      searchAndFindJobApplication: function (id) {
        this.get("searchJobApplications").perform();
        this.get("findJobApplication").perform(this.get('model.jobApplicationId'));
      },
      rejectApplication: function () {
        this.set('params', {
          jobApplication: this.get('model.selectedJobApplication'),
          jobApplicationParams: this.jobApplicationParams,
          jobApplications: this.get('model.jobApplications'),
          meta: this.get('model.meta'),
          jobOfferName: this.get('model.jobOfferName'),
          jobSeeker: this.get('model.jobSeeker'),
          status: this.get('model.status'),
          tagName: this.get('model.tagName'),
          questionId: this.get('model.questionId'),
          page: this.get('model.page')
        });
        this.set('denyApplication', true);
      },
      acceptApplication: function () {
        let self = this;
        let jobApplication = this.get('model.selectedJobApplication');
        jobApplication.set('status', 'ACCEPTED');
        jobApplication.set('acceptanceDate', new Date());
        this.jobApplicationService.acceptJobApplication(jobApplication, this.jobApplicationParams);
        Ember.run.debounce(this, this.search, 1000);

        if (self.get('model.selectedJobApplication.jAppJobSeeker.activeEmail')) {
          let emailObject = {
            firstName: self.get('model.selectedJobApplication.jAppJobSeeker.firstName'),
            lastName: self.get('model.selectedJobApplication.jAppJobSeeker.lastName'),
            email: self.get('model.selectedJobApplication.jAppJobSeeker.email'),
            notificationType: 'NOTIFICATION_APPLICATION_ACCEPTED',
            context: jobApplication.get('id'),
            locale: self.get('intl.locale'),
            jobOfferTitle: self.get('model.selectedJobApplication.jAppJobOffer.title')
          };
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.APP.SPRING_HOST + "/api/notifications/sendNotification",
            data: JSON.stringify(emailObject),
            contentType: "application/json"
          }).then(function (response) {
            self.set('message', response.message);
          });
        }
      },
      closeToastAccepted: function () {
        this.set('acceptedTextSuccess', false);
        this.set('jobApplicationAccepted', false);
      },
      editTags: function () {
        this.set('params', {
          jobApplication: this.get('model.selectedJobApplication'),
          jobApplicationParams: this.get('jobApplicationParams'),
          tags: this.get('tags')
        });
        this.set('editTags', true);
      },
      closeTagManagement: function () {
        this.set('editTags', false);
      },
      resetFilters: function () {
        this.set('model.jobOfferName', '');
        Ember.run.debounce(this, this.search, 1000);
      },
      updateTags: function (value) {
        this.set('model.tag', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateJobSeekersName: function (value) {
        this.set('model.jobSeeker', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateJobOffer: function (value) {
        if (value != null) {
          this.set('model.jobOfferName', value.title);
        } else {
          this.set('model.jobOfferName', '');
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCity: function (value) {
        this.set('model.city', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateWorkExperience: function (value) {
        this.set('model.workExperience', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducation: function (value) {
        this.set('model.education', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateStatus: function (value) {
        if (this.get('model.status').includes(value)) {
          this.get('model.status').removeObject(value);
        } else {
          this.get('model.status').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationScope: function (value) {
        if (value == null) {
          this.set('model.educationScope', "");
        } else {
          this.set('model.educationScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationNumber: function (value) {
        if (value == null) {
          this.set('model.educationNumber', "");
        } else {
          this.set('model.educationNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateEducationDateIssue: function (value) {
        if (value == null) {
          this.set('model.educationDateIssue', "");
        } else {
          this.set('model.educationDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageScope: function (value) {
        if (value == null) {
          this.set('model.languageScope', "");
        } else {
          this.set('model.languageScope', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageNumber: function (value) {
        if (value == null) {
          this.set('model.languageNumber', "");
        } else {
          this.set('model.languageNumber', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguageDateIssue: function (value) {
        if (value == null) {
          this.set('model.languageDateIssue', "");
        } else {
          this.set('model.languageDateIssue', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCountry: function (value) {
        if (value == null) {
          this.set('model.countryName', "");
        } else {
          this.set('model.countryName', value.iso3);
        }

        this.set('model.country', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateLanguage: function (value) {
        this.set('model.languageName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      updateYearsExperience: function (value) {
        if (value == null) {
          this.set('model.yearsExperience', "");
        } else {
          this.set('model.yearsExperience', value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateDrivingLicense: function (value) {
        if (this.get('model.drivingLicenses').includes(value)) {
          this.get('model.drivingLicenses').removeObject(value);
        } else {
          this.get('model.drivingLicenses').pushObject(value);
        }

        Ember.run.debounce(this, this.search, 1000);
      },
      updateCompetences: function (value) {
        this.set('model.competenceName', value);
        Ember.run.debounce(this, this.search, 1000);
      },
      nextPage: function () {
        this.set('model.page', this.get('model.page') + 1);
        this.get('searchJobApplications').perform(true);
      },
      searchJobApplications: function () {
        this.get("searchJobApplications").perform();
      },
      closeRejectDialog: function () {
        this.get("searchJobApplications").perform();
        this.set('denyApplication', false);
      },
      selectJobApplication: function (jobApplicationId) {
        this.get("findJobApplication").perform(jobApplicationId);
      },
      backToSearch: function () {
        this.set('addJobApplication', false);
        this.set('model.selectedJobApplication', null);

        if (this.get('screen.is959')) {
          Ember.$('#job_application-container').removeClass('hide');
          Ember.$('#search_container_job_appications').removeClass('show');
        }
      },
      showAnswers: function () {
        this.set('showAnswersDialog', true);
      },
      closeAnswersDialog: function () {
        this.set('showAnswersDialog', false);
      },
      evaluateApplication: function (jobApplication) {
        this.set('model.selectedJobApplication.jsonEvaluation', JSON.stringify(jobApplication.jsonTemp));
        this.jobApplicationService.save(this.get('model.selectedJobApplication'), this.jobApplicationParams).then(() => {
          this.get("searchJobApplications").perform();
          this.set('showAnswersDialog', false);
          this.set('openEvaluateDialog', false);
          this.get("findJobApplication").perform(this.get('model.selectedJobApplication.id'));
        });
      },
      cancelEvaluateApplication: function () {
        this.set('openEvaluateDialog', false);
      },
      openEvaluateApplication: function () {
        this.set('openEvaluateDialog', true);
      },
      seeEvaluation: function () {
        this.set('openSeeEvaluateDialog', true);
      },
      closeSeeEvaluation: function () {
        this.set('openSeeEvaluateDialog', false);
      },
      downloadMotivationalLetter: function (originalFileName, file) {
        let self = this;
        this.get('store').findRecord('file', file).then(function (record) {
          record.download().then(pdfContent => self.saveFileAs(originalFileName, pdfContent, 'application/pdf'));
        });
      },
      inEvidenceApplication: function () {
        let request = this.store.createRecord('request');
        request.set('userId', this.get('model.selectedJobApplication.jobSeekerId'));
        request.set('jobApplicationId', this.get('model.selectedJobApplication.id'));
        request.set('requestType', "REQUEST_APPLICATION_IN_EVIDENCE");
        request.set('status', "PENDING");
        request.save().then(() => {
          this.get('paperToaster').show(this.intl.t('label.requestsent'), {
            duration: 3000
          });
          this.get("findJobApplication").perform(this.get('model.selectedJobApplication.id'));
        });
      },
      updateInEvidence: function (value) {
        this.set('model.inEvidence', value);
        Ember.run.debounce(this, this.search, 1000);
      }
    }
  });

  _exports.default = _default;
});