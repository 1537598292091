define("E4E/services/current-user", ["exports", "E4E/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    socketConnection: Ember.inject.service('socket-connection'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      let token = this.get('session.data.authenticated.access_token');
      return {
        Authorization: `Bearer ${token}`
      };
    }),
    authorizedGet: function (url) {
      return Ember.$.ajax({
        type: 'GET',
        url: _environment.default.APP.SPRING_HOST + url,
        headers: this.get('headers')
      });
    },

    load() {
      let self = this;

      if (this.get('session.isAuthenticated')) {
        this.get('socketConnection').connectNotifier(this.serverUrl + '/notification/', this.get('session.data.authenticated.access_token'));
        return Ember.RSVP.resolve(this.authorizedGet("/users/search/me").then(resp => {
          resp.id = resp.id + "";
          this.set('user', resp);
        }));
        /*
        
        return this.get('store').queryRecord('current-user', { me: true }).then((currentUser) => {
            this.set('user', currentUser);
        });
        */
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});