define("E4E/helpers/is-firefox", ["exports", "ember-browser-checker/helpers/is-firefox"], function (_exports, _isFirefox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isFirefox.default;
    }
  });
  Object.defineProperty(_exports, "isFirefox", {
    enumerable: true,
    get: function () {
      return _isFirefox.isFirefox;
    }
  });
});