define("E4E/components/recruiters/question-bundles/search-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);
    },

    scrollContainer(event) {
      if (Math.ceil(Ember.$("#search_container_question_bundles").scrollTop() + Ember.$("#search_container_question_bundles").outerHeight()) == Ember.$("#search_container_question_bundles")[0].scrollHeight) {
        if (!this.get('meta.last')) {
          this.nextPage();
        }
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._boundScroll = () => Ember.run.debounce(this, 'scrollContainer', 0);

      Ember.$("#search_container_question_bundles").on('scroll', this._boundScroll);
    },

    actions: {
      updateBundle: function (value) {
        this.updateBundle(value);
      },
      updateTypes: function (value) {
        this.updateTypes(value);
      },
      addNewBundle: function () {
        this.addNewBundle();
      },
      selectQuestionBundle: function (id) {
        this.selectQuestionBundle(id);
      }
    }
  });

  _exports.default = _default;
});