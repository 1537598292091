define("E4E/models/joboffer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    created: _emberData.default.attr('date'),
    modified: _emberData.default.attr('date'),
    jobOfferAuthor: _emberData.default.belongsTo('user', {
      async: false,
      inverse: null
    }),
    jobOfferOrganization: _emberData.default.belongsTo('organization', {
      async: false,
      inverse: null
    }),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    etype: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    hoursWeek: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    deadline: _emberData.default.attr('date'),
    contractDuration: _emberData.default.attr('string'),
    experience: _emberData.default.attr('string'),
    formalEducation: _emberData.default.attr('string'),
    languages: _emberData.default.attr('string'),
    drivingLicense: _emberData.default.attr('string'),
    travel: _emberData.default.attr('string'),
    salary: _emberData.default.attr('string'),
    ageRange: _emberData.default.attr('string'),
    deletionDate: _emberData.default.attr('date'),
    sectors: _emberData.default.hasMany('sector', {
      async: false,
      inverse: null
    }),
    profilePicture: _emberData.default.belongsTo('picture', {
      async: false,
      inverse: null
    }),
    likes: _emberData.default.attr(),
    activeEmail: _emberData.default.attr('boolean'),
    authorId: _emberData.default.attr('string'),
    organizationId: _emberData.default.attr('string'),
    qualifications: _emberData.default.attr('string'),
    jobTitleId: _emberData.default.attr('string'),
    jobOfferPdfId: _emberData.default.attr('string'),
    skills: _emberData.default.attr('string'),
    jobApplications: _emberData.default.hasMany('jobapplication', {
      async: false,
      inverse: null
    }),
    jobTitleDto: _emberData.default.belongsTo('jobtitle', {
      async: true,
      inverse: null
    }),
    questionBundles: _emberData.default.hasMany('jobofferquestionbundle', {
      async: false,
      inverse: null
    }),
    eSchemeBundles: _emberData.default.hasMany('jobofferevaluationschemebundle', {
      async: false,
      inverse: null
    }),
    yearsExperience: _emberData.default.attr('number'),
    languagesJson: Ember.computed('languages', function () {
      if (this.get('languages') != null && this.get('languages') != "") {
        let languagesJson = JSON.parse(this.get('languages'));

        if (languagesJson.languages != null) {
          return languagesJson.languages;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    skillsJson: Ember.computed('skills', function () {
      if (this.get('skills') != null && this.get('skills') != "") {
        let skillsJson = JSON.parse(this.get('skills'));

        if (skillsJson.competences != null) {
          return skillsJson.competences;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    drivingLicenseJson: Ember.computed('drivingLicense', function () {
      if (this.get('drivingLicense') != null && this.get('drivingLicense') != "") {
        let drivingLicensesJson = JSON.parse(this.get('drivingLicense'));

        if (drivingLicensesJson.drivingLicenses != null && drivingLicensesJson.drivingLicenses.Description.length > 0) {
          return drivingLicensesJson.drivingLicenses;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    qualificationsJson: Ember.computed('qualifications', function () {
      if (this.get('qualifications') != null && this.get('qualifications') != "") {
        let qualificationsJson = JSON.parse(this.get('qualifications'));

        if (qualificationsJson.educations != null) {
          return qualificationsJson.educations;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    formalEducationJson: Ember.computed('formalEducation', function () {
      if (this.get('formalEducation') != null && this.get('formalEducation') != "") {
        let formalEducationJson = JSON.parse(this.get('formalEducation'));

        if (formalEducationJson.educations != null) {
          return formalEducationJson.educations;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    links: _emberData.default.attr(),
    companyName: _emberData.default.attr('string'),
    forCompany: _emberData.default.attr('boolean'),
    showCompanyFor: _emberData.default.attr('boolean'),
    jobApplicationCount: _emberData.default.attr('number'),
    publicLink: _emberData.default.belongsTo('job-offer-shareable-link', {
      async: false,
      inverse: null
    }),
    questionBundlesTemp: Ember.computed('questionBundles', function () {
      let bundleArray = [];

      if (this.get('questionBundles') != null) {
        this.get('questionBundles').forEach(function (questionBundle) {
          bundleArray.push(questionBundle.qBundle);
        });
      }

      return bundleArray;
    }),
    questionBundlesTempEval: Ember.computed('eSchemeBundles', function () {
      let bundleArray = [];

      if (this.get('eSchemeBundles') != null) {
        this.get('eSchemeBundles').forEach(function (questionBundle) {
          bundleArray.push(questionBundle.qBundle);
        });
      }

      return bundleArray;
    }),
    numberApplications: _emberData.default.attr('number'),
    jobOfferPdfFile: _emberData.default.belongsTo("jobofferpdf", {
      async: false,
      inverse: null
    }),
    healthStatus: _emberData.default.attr('string'),
    specialConditions: _emberData.default.attr('string')
  });

  _exports.default = _default;
});