define("E4E/templates/components/superadmin/job-offers/results-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jdoBgE1y",
    "block": "{\"symbols\":[\"jobOffer\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"jobOffers\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-14-type-2 margin-top-16\"],[9],[1,[27,\"t\",[\"label.noresultsfound\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-center margin-top-16 width100P\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"text-14-type-2 \"],[9],[1,[27,\"t\",[\"label.results.found\"],[[\"resultsFound\"],[[23,[\"meta\",\"totalElements\"]]]]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"paper-list\",null,[[\"class\"],[\"width100P\"]],{\"statements\":[[4,\"each\",[[23,[\"jobOffers\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"selectedJobOffer\",\"id\"]],[27,\"concat\",[[22,1,[\"id\"]],\"\"],null]],null]],null,{\"statements\":[[4,\"paper-item\",null,[[\"class\"],[\"selectedListItem\"]],{\"statements\":[[0,\"            \"],[1,[27,\"joboffers/search-card\",null,[[\"class\",\"jobOffer\"],[\"layout-row layout-align-space-between-center width100P\",[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"paper-item\",null,[[\"onClick\"],[[27,\"action\",[[22,0,[]],\"selectJobOffer\",[22,1,[\"id\"]]],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"joboffers/search-card\",null,[[\"class\",\"jobOffer\"],[\"layout-row layout-align-space-between-center width100P\",[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[27,\"not-eq\",[[22,2,[]],[27,\"sub\",[[23,[\"jobOffers\",\"length\"]],1],null]],null]],null,{\"statements\":[[0,\"          \"],[1,[21,\"paper-divider\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/superadmin/job-offers/results-container.hbs"
    }
  });

  _exports.default = _default;
});