define("E4E/templates/components/jobseekers/search-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4zsnXpk5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-start width100P\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-start\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"jobSeeker\",\"profilePicture\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"alt\",\"User Avatar\"],[11,\"class\",\"userDefaultAvatar-40\"],[12,\"src\",[28,[[21,\"serverUrl\"],\"/files/\",[23,[\"jobSeeker\",\"profilePicture\",\"fileId\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"userDefaultAvatar-40 layout-row layout-align-center-center\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"text-15-type-1\"],[9],[1,[27,\"name-abbreviation\",[[23,[\"jobSeeker\",\"firstName\"]],[23,[\"jobSeeker\",\"lastName\"]]],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"div\"],[11,\"class\",\"layout-column layout-align-start-start margin-left-16\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center layout-wrap\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"text-16-type-1 margin-right-8\"],[9],[1,[23,[\"jobSeeker\",\"firstName\"]],false],[0,\" \"],[1,[23,[\"jobSeeker\",\"lastName\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"jobSeeker\",\"flag\",\"isFavorite\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"paper-icon\",[\"star\"],[[\"size\",\"class\"],[14,\"yellowFavorite\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[23,[\"jobSeeker\",\"profession\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-14-type-2\"],[9],[1,[27,\"t\",[\"label.registered\"],null],false],[0,\" \"],[1,[27,\"format-relative\",[[23,[\"jobSeeker\",\"created\"]]],[[\"units\"],[\"month\"]]],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "E4E/templates/components/jobseekers/search-card.hbs"
    }
  });

  _exports.default = _default;
});