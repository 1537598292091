define("E4E/components/recruiters/team-members/add-teammember", ["exports", "E4E/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: {
      actions: [{
        name: 'bold',
        title: 'Bold',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_bold" md-font-icon="format_bold" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_bold</md-icon>'
      }, {
        name: 'italic',
        title: 'Italic',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_italic" md-font-icon="format_italic" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_italic</md-icon>'
      }, {
        name: 'underline',
        title: 'Underline',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_underline" md-font-icon="format_underline" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_underline</md-icon>'
      }, {
        name: 'ulist',
        title: 'Unordered List',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_bulleted" md-font-icon="format_list_bulleted" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_bulleted</md-icon>'
      }, {
        name: 'olist',
        title: 'Ordered List',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_list_numbered" md-font-icon="format_list_numbered" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_list_numbered</md-icon>'
      }, {
        name: 'indent',
        title: 'Increase Identation',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_increase" md-font-icon="format_indent_increase" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_increase</md-icon>',
        result: () => exec('indent')
      }, {
        name: 'outdent',
        title: 'Decrease Identation',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="format_indent_decrease" md-font-icon="format_indent_decrease" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">format_indent_decrease</md-icon>',
        result: () => exec('outdent')
      }, {
        name: 'link',
        title: 'Add link',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="insert_link" md-font-icon="insert_link" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">insert_link</md-icon>',
        result: () => {
          const url = window.prompt('Enter the link URL');
          if (url) exec('createLink', url);
        }
      }, {
        name: 'unlink',
        title: 'Remove Link',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="link_off" md-font-icon="link_off" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">link_off</md-icon>',
        result: () => exec('unlink')
      }, {
        name: 'subscript',
        title: 'Subscript',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_bottom" md-font-icon="vertical_align_bottom" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_bottom</md-icon>',
        result: () => exec('subscript')
      }, {
        name: 'superscript',
        title: 'Superscript',
        icon: '<md-icon id="ember1636" style="height: 16px; min-height: 16px; min-width: 16px; font-size: 16px; line-height: 16px;" aria-label="vertical_align_top" md-font-icon="vertical_align_top" class="editorIcon ember-view paper-icon md-font material-icons md-default-theme">vertical_align_top</md-icon>',
        result: () => exec('superscript')
      }]
    },
    serverUrl: Ember.computed('ENV.APP.SPRING_HOST', function () {
      return _environment.default.APP.SPRING_HOST;
    }),
    intl: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    email: '',
    roles: ['ORGANIZATION_USER'],
    userTypes: ["EMPLOYEE", "EXTERNAL"],
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    roleTemp: null,
    errorPasswordNotSame: false,
    errorEmailNotSame: false,
    userTypeTemp: null,
    validitySave: Ember.computed('email', 'confirmEmail', 'firstName', 'lastName', 'password', 'confirmPassword', 'roleTemp', 'userTypeTemp', function () {
      if (this.get('email') != '' && this.get('confirmEmail') != '' && this.get('firstName') != '' && this.get('lastName') != '' && this.get('password') != '' && this.get('confirmPassword') != '' && this.get('roleTemp') != null && !this.get('errorPasswordNotSame') && !this.get('errorEmailNotSame')) {
        if (this.get('roleTemp') == "ORGANIZATION_USER") {
          if (this.get('userTypeTemp') != null) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: inputValue => {
        let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(inputValue);
      }
    }],
    init: function () {
      this._super(...arguments);

      this.countryMatcher = this.countryMatcher.bind(this);
      this.get('getCountries').perform();
    },
    getCountries: (0, _emberConcurrency.task)(function* () {
      let countries = yield $.getJSON('/json-components/countries/countries.json');
      this.set('countries', countries);
    }),
    countryMatcher: function (country, term) {
      const ret = `${this.get('intl').t('label.' + country.iso3.toLowerCase())}`.toLowerCase();
      return `${ret}`.indexOf(term.toLowerCase());
    },
    actions: {
      saveTeamMember: function () {
        let self = this;
        let params = {
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          email: this.get('email'),
          emailConfirmation: this.get('confirmEmail'),
          password: this.get('password'),
          passwordConfirmation: this.get('confirmPassword'),
          birthDate: moment.utc(moment.utc(self.get('birthDate')).format('YYYY/MM/DD'), 'YYYY/MM/DD').toDate(),
          website: this.get('website'),
          profession: this.get('profession'),
          phoneNumber: this.get('phoneNumber'),
          city: this.get('city'),
          country: this.get('country.iso3').toLowerCase(),
          role: this.get('roleTemp'),
          userType: this.get('userTypeTemp'),
          introduction: this.get('introduction'),
          organization: this.get('currentUserService.user.userOrganization.email'),
          locale: "de"
        };
        this.saveTeamMember(params);
      },
      cancelTeamMember: function () {
        this.cancelTeamMember();
      },
      checkEmail: function (value) {
        this.set('email', value);
        $.get(_environment.default.APP.SPRING_HOST + "/users/search/emailExists?email=" + $.trim(value)).then(response => {
          if (response === false) {
            return $.get(_environment.default.APP.SPRING_HOST + "/organizations/search/emailExists?email=" + $.trim(value)).then(response => {
              if (response === false) {
                this.set('errorEmail', false);
              } else {
                this.set('errorEmail', true);
              }
            });
          } else {
            this.set('errorEmail', true);
          }
        });

        if (value === this.get('confirmEmail')) {
          this.set('errorEmailNotSame', false);
        } else {
          if (this.get('confirmEmail') != '') {
            this.set('errorEmailNotSame', true);
          }
        }
      },
      checkConfirmEmail: function (value) {
        this.set('confirmEmail', value);

        if (value !== this.get('email')) {
          this.set('errorEmailNotSame', true);
        } else {
          this.set('errorEmailNotSame', false);
        }
      },
      checkPassword: function (value) {
        this.set('password', value);

        if (value === this.get('confirmPassword')) {
          this.set('errorPasswordNotSame', false);
        } else {
          if (this.get('confirmPassword') != '') {
            this.set('errorPasswordNotSame', true);
          }
        }
      },
      checkConfirmPassword: function (value) {
        this.set('confirmPassword', value);

        if (value !== this.get('password')) {
          this.set('errorPasswordNotSame', true);
        } else {
          this.set('errorPasswordNotSame', false);
        }
      }
    }
  });

  _exports.default = _default;
});